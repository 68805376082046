/**
 * Get window title
 * @param {boolean} bare - Append counter to string
 * @returns {string} Window title
 */
export default function title(bare = false) {
  return (
    (this.customTitle ||
      this.output.Title ||
      this.session.translations.LoadingWindow) +
    (!bare && this.counter !== 0 && !this.session.kiosk
      ? " (" + this.counter + ")"
      : "")
  );
}
