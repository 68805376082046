/**
 * Retrieves persisted color rules from local storage.
 *
 * @function getPersistedColorRules
 * @returns {Array.<{
 *   FullTableName: string,
 *   ColorPolicy: string,
 *   Color: string,
 *   TableColorCriteria: Array.<{
 *     ColumnName: string,
 *     Criteria: string,
 *     Color: string,
 *     Ranking: null,
 *   }>
 * }>|null} - Returns an array of color rule objects if found, otherwise returns null.
 * Each object includes the full table name, the color policy,
 * the default color, and an array of table color criteria.
 * Each table color criteria includes the column name,
 * the criteria for the color, the color, and the ranking.
 * @example
 * // A color rule object might look like this:
 * {
 *   "FullTableName": "Rental.Order",
 *   "ColorPolicy": "SubtleColor",
 *   "Color": "#5778a2",
 *   "TableColorCriteria": [
 *     {
 *       "ColumnName": "CustomerID",
 *       "Criteria": "00003",
 *       "Color": "#28af82",
 *       "Ranking": null
 *     }
 *   ]
 * }
 * @throws Will log any errors encountered during execution to the console.
 */
export function getPersistedColorRules() {
  try {
    const colorString = localStorage.getItem("color-rules");
    return colorString ? JSON.parse(colorString) : null;
  } catch (e) {
    console.log(e);
  }
}
