
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/elements/itemAvailabilityVisRow.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["dataItem", "subject"], 
[], 
function (l_dataItem, l_subject, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("dataItem", l_dataItem);
frame.set("subject", l_subject);
var t_2 = "";t_2 += "\n    ";
if(l_subject == "Rental.virtual_ItemAvailability") {
t_2 += "\n        <button\n            style='margin-left:2px;'\n            class='btn btn-sm btn-secondary openItemWarehouseChart'\n            data-item-description=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_dataItem),"item")),"description"), env.opts.autoescape);
t_2 += "\"\n            data-item-id=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_dataItem),"item")),"itemID"), env.opts.autoescape);
t_2 += "\"\n            data-open-ItemWarehouseAvailability\n            >\n            <i class=\"fad fa-warehouse\" style='pointer-events:none;'></i>\n        </button>\n    ";
;
}
t_2 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("warehouseButton");
context.setVariable("warehouseButton", macro_t_1);
output += "\n\n";
var macro_t_3 = runtime.makeMacro(
["dataItem", "subject"], 
[], 
function (l_dataItem, l_subject, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("dataItem", l_dataItem);
frame.set("subject", l_subject);
var t_4 = "";t_4 += "\n    ";
if(l_subject == "Rental.virtual_ItemAvailability") {
t_4 += "\n        <button\n            style='margin-left:2px;'\n            class='btn btn-sm btn-secondary'\n            data-item-description=\"";
t_4 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_dataItem),"item")),"description"), env.opts.autoescape);
t_4 += "\"\n            data-item-id=\"";
t_4 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_dataItem),"item")),"itemID"), env.opts.autoescape);
t_4 += "\"\n            data-open-ItemDetailAvailability\n            >\n            <i class=\"fad fa-search\" style='pointer-events:none;'></i>\n        </button>\n    ";
;
}
t_4 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_4);
});
context.addExport("detailButton");
context.setVariable("detailButton", macro_t_3);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "isItemID")) {
output += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "subject") != "Rental.virtual_ItemDetailAvailability" && runtime.contextOrFrameLookup(context, frame, "subject") != "Rental.virtual_ItemWarehouseAvailability") {
output += "\n        <button\n            class='btn btn-small btn-primary addSerialFromModal'\n            data-item-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"item")),"itemID"), env.opts.autoescape);
output += "\"\n            data-item-serial=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"item")),"serialID"), env.opts.autoescape);
output += "\"\n        >\n\n            <i class='fas fa-plus' style='pointer-events:none;'></i>\n        </button>\n\n        <span class=\"pl-1\">\n            ";
output += runtime.suppressValue((lineno = 40, colno = 30, runtime.callWrap(macro_t_1, "warehouseButton", context, [runtime.contextOrFrameLookup(context, frame, "dataItem"),runtime.contextOrFrameLookup(context, frame, "subject")])), env.opts.autoescape);
output += "\n            ";
output += runtime.suppressValue((lineno = 41, colno = 27, runtime.callWrap(macro_t_3, "detailButton", context, [runtime.contextOrFrameLookup(context, frame, "dataItem"),runtime.contextOrFrameLookup(context, frame, "subject")])), env.opts.autoescape);
output += "\n        </span>\n    ";
;
}
output += "\n    <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"segmentGroupDescription"), env.opts.autoescape);
output += "</span>\n";
;
}
else {
output += "\n    ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"item")),"useSerials")) {
output += "\n        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"item")),"hasSerials")) {
output += "\n            ";
if(runtime.contextOrFrameLookup(context, frame, "subject") == "Rental.virtual_ItemAvailability") {
output += "\n                <button\n                    class='btn btn-primary btn-sm addItemFromModal'\n                    data-item-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"item")),"itemID"), env.opts.autoescape);
output += "\"\n                >\n                    <i class='fas fa-plus' style='pointer-events:none;'></i>\n                </button>\n                <button\n                    style='margin-left:2px;'\n                    class='btn btn-sm btn-primary'\n                    data-item-start-date=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "this")),"startDate"), env.opts.autoescape);
output += "\"\n                    data-item-end-date=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "this")),"endDate"), env.opts.autoescape);
output += "\"\n                    data-item-description=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"item")),"description"), env.opts.autoescape);
output += "\"\n                    data-item-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"item")),"itemID"), env.opts.autoescape);
output += "\"\n                    data-open-SerialAvailability\n                >\n                    <i class='fas fa-tag' style='pointer-events:none;'></i>\n                </button>\n\n                <span class=\"pl-1\">\n                    ";
output += runtime.suppressValue((lineno = 68, colno = 38, runtime.callWrap(macro_t_1, "warehouseButton", context, [runtime.contextOrFrameLookup(context, frame, "dataItem"),runtime.contextOrFrameLookup(context, frame, "subject")])), env.opts.autoescape);
output += "\n                    ";
output += runtime.suppressValue((lineno = 69, colno = 35, runtime.callWrap(macro_t_3, "detailButton", context, [runtime.contextOrFrameLookup(context, frame, "dataItem"),runtime.contextOrFrameLookup(context, frame, "subject")])), env.opts.autoescape);
output += "\n                </span>\n            ";
;
}
output += "\n            <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"segmentGroupDescription"), env.opts.autoescape);
output += "</span>\n        ";
;
}
else {
output += "\n            ";
if(runtime.contextOrFrameLookup(context, frame, "subject") == "Rental.virtual_ItemAvailability") {
output += "\n                <button\n                    class='btn btn-sm btn-primary addItemFromModal'\n                    data-item-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"item")),"itemID"), env.opts.autoescape);
output += "\"\n                >\n                    <i class='fas fa-plus' style='pointer-events:none;'></i>\n                </button>\n                <button\n                    style='margin-left:2px;'\n                    class='btn btn-sm btn-primary disabled'\n                    data-item-start-date=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "this")),"startDate"), env.opts.autoescape);
output += "\"\n                    data-item-end-date=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "this")),"endDate"), env.opts.autoescape);
output += "\"\n                    data-item-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"item")),"itemID"), env.opts.autoescape);
output += "\"\n                >\n                    <i class='fas fa-tag' style='pointer-events:none;'></i>\n                </button>\n                <span class=\"pl-1\">\n                    ";
output += runtime.suppressValue((lineno = 91, colno = 38, runtime.callWrap(macro_t_1, "warehouseButton", context, [runtime.contextOrFrameLookup(context, frame, "dataItem"),runtime.contextOrFrameLookup(context, frame, "subject")])), env.opts.autoescape);
output += "\n                    ";
output += runtime.suppressValue((lineno = 92, colno = 35, runtime.callWrap(macro_t_3, "detailButton", context, [runtime.contextOrFrameLookup(context, frame, "dataItem"),runtime.contextOrFrameLookup(context, frame, "subject")])), env.opts.autoescape);
output += "\n                </span>\n            ";
;
}
output += "\n            <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"segmentGroupDescription"), env.opts.autoescape);
output += "</span>\n        ";
;
}
output += "\n    ";
;
}
else {
output += "\n        ";
if(runtime.contextOrFrameLookup(context, frame, "subject") == "Rental.virtual_ItemAvailability") {
output += "\n            <button\n                style='margin-right: 42px;'\n                class='btn btn-sm btn-primary addItemFromModal'\n                data-item-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"item")),"itemID"), env.opts.autoescape);
output += "\"\n            >\n                <i class='fas fa-plus' style='pointer-events:none;'></i>\n            </button>\n            <span class=\"pl-1\">\n                ";
output += runtime.suppressValue((lineno = 107, colno = 34, runtime.callWrap(macro_t_1, "warehouseButton", context, [runtime.contextOrFrameLookup(context, frame, "dataItem"),runtime.contextOrFrameLookup(context, frame, "subject")])), env.opts.autoescape);
output += "\n                ";
output += runtime.suppressValue((lineno = 108, colno = 31, runtime.callWrap(macro_t_3, "detailButton", context, [runtime.contextOrFrameLookup(context, frame, "dataItem"),runtime.contextOrFrameLookup(context, frame, "subject")])), env.opts.autoescape);
output += "\n            </span>\n        ";
;
}
output += "\n        <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dataItem")),"segmentGroupDescription"), env.opts.autoescape);
output += "</span>\n    ";
;
}
output += "\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/elements/itemAvailabilityVisRow.html", ctx);
          }
        
          return nunjucks.render("views/elements/itemAvailabilityVisRow.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/elements/itemAvailabilityVisRow.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        