import {api} from "@/services/api";

export default async function postSetUpTOTPEmail({loginToken}) {
  const result = await api.get(`/v2/totp/email/set-up`, {
    headers: {
        Authorization: loginToken,
        NormalizeEnums: false
    },
  });

  return result;
}