export const convertSixNumbersToDate = (value) => {
  let newValue = value;
  if (newValue.length === 6) {
    // if 3th and 4th number is above 12 return
    if (parseInt(newValue.charAt(2) + newValue.charAt(3)) > 12) return newValue;

    const day = newValue.slice(0, 2);
    const month = newValue.slice(2, 4);
    const year = newValue.slice(4, 6);
    newValue = day + "-" + month + "-20" + year;
  }
  return newValue;
};
