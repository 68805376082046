var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'relative',
    'flex-col',
    'w-full',
    'px-[15px]',
    'min-h-[1px]',
    'md:grow-0',
    'md:shrink-0',
    _vm.computedLayoutClasses,
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }