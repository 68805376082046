export function getRowColorFromTableColorCriteria({tableColorCriteria, row}) {
  for (const colorCriteria of tableColorCriteria.sort(
    (a, b) => a.Ranking - b.Ranking,
  )) {
    const cell = row.find((r) => r.Column.Name === colorCriteria.ColumnName);
    if (cell?.Value == colorCriteria.Criteria) {
      return colorCriteria.Color;
    }
  }
}
