import arrayToTree from "array-to-tree";

/**
 * Treeview functionality
 */
export default class Treeview {
  /**
   * Generate treeview from array
   * @param {Array} rows - Array of rows
   * @param {string | number} parentProperty - A name of a property where a link to a parent node could be found.
   * @param {string | number} customID - An unique node identifier
   * @returns {Object} Tree object
   * @static
   */
  static fromRows(rows, parentProperty, customID) {
    let tree = arrayToTree(rows, {
      parentProperty,
      customID,
    });
    return tree;
  }

  /**
   * Sort tree
   * @param {Object} tree - Tree object
   * @param {*} textProperty - Property that has to be sorted
   * @returns {Object} Tree object
   * @static
   */
  static sort(tree, textProperty) {
    tree.sort(function compare(a, b) {
      if (a === null || b === null) {
        return 0;
      }

      if (a.children) {
        Treeview.sort(a.children, textProperty);
      }

      if (a.children && !b.children) {
        return -1;
      } else if (!a.children && b.children) {
        return 1;
      } else {
        if (a[textProperty] < b[textProperty]) {
          return -1;
        }

        if (a[textProperty] > b[textProperty]) {
          return 1;
        }

        return 0;
      }
    });
  }
}
