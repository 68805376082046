import {updateValueInWindowFormObject} from "./updateValueInWindowFormObject.js";
import {getChangedFormValueKeys} from "./getChangedFormValueKeys.js";
import {updateVueDateValues} from "./updateVueDateValues.js";

export const updateWindowFormValues = async ({
  vueColumns,
  newRow,
  oldRow,
  windowId,
}) => {
  const changedFieldNames = getChangedFormValueKeys({newRow, oldRow});
  const changedFieldValues = changedFieldNames.map((field) => newRow[field]);

  for (const column in vueColumns) {
    if (changedFieldNames.includes(column)) {
      const changedFieldArrayIndex = changedFieldNames.indexOf(column);
      const changedFieldName = changedFieldNames[changedFieldArrayIndex];
      const changedFieldValue = changedFieldValues[changedFieldArrayIndex];

      vueColumns[column].$children[0].value = changedFieldValue;

      updateValueInWindowFormObject({
        value: changedFieldValue,
        name: changedFieldName,
        windowId: windowId,
      });

      if (changedFieldName.includes("DateTime"))
        updateVueDateValues({
          changedFieldValue,
          changedFieldName,
          vueColumns,
        });
    }
  }
};
