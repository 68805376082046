export const getCaretPosition = (editableDiv) => {
  if (window.getSelection().toString().length) {
    return -1;
  }

  if (window.getSelection) {
    if (typeof editableDiv.selectionStart == "number") {
      return editableDiv.selectionStart;
    }
    let sel = window.getSelection();
    if (sel.rangeCount) {
      let range = sel.getRangeAt(0);
    }
  } else if (document.selection && document.selection.createRange) {
    let range = document.selection.createRange();
    if (range.parentElement() == editableDiv) {
      let tempEl = document.createElement("span");
      editableDiv.insertBefore(tempEl, editableDiv.firstChild);
      let tempRange = range.duplicate();
      tempRange.moveToElementText(tempEl);
      tempRange.setEndPoint("EndToEnd", range);
      return tempRange.text.length;
    }
  }

  return -1;
};
