// @ts-nocheck
import $ from "jquery";
import notificationTemplate from "../../views/snippet/notification.html";
import {playBehaviorSound} from "../interface/playBehaviorSound.js";

export function notify({message, type = "primary", messageDelay = 5000} = {}) {
  playBehaviorSound({sound: "notification"});
  removeExistingNotification(message);
  $.notify(
    {message},
    {
      z_index: 12000,
      delay: messageDelay,
      allow_dismiss: true,
      type: type,
      placement: {
        from: "bottom",
        align: "right",
      },
      animate: {
        enter: "animated flipInX",
        exit: "animated flipOutX",
      },
      template: notificationTemplate({
        message,
        type,
        product: "RentMagic",
      }),
      width: "unset",
    },
  );
}

function removeExistingNotification(message) {
  $(".sleek-notification-body-text").each(function () {
    if ($(this).text() === message) {
      $(this).parents('div[role="alert"]').remove();
    }
  });
}
