import dayjs from "dayjs";

const autocompleteUnits = {
  d: "day",
  w: "week",
  m: "month",
  y: "year",
};

const operators = {
  "+": "add",
  "-": "subtract",
};

// default to + if no operator is specified
// e.g. "d3" is the same as "d+3"

export function processAutocompleteSyntax({value}) {
  const operatorCharacter = value.match(/[+-]/) || ["+"];
  const operator = operators[operatorCharacter[0]];

  const unitCharacter = value.match(/[dwmy]/);
  const unit = autocompleteUnits[unitCharacter[0]];

  const number = value.match(/\d+/) || 1;

  return dayjs()[operator](number, unit).format("YYYY-MM-DD");
}
