/**
 * Opens a window with the given arguments.
 * @param {Object} data
 * @param {String} data.Subject
 * @param {String} data.Prefix
 * @param {Array} data.Criteria
 * @param {Boolean} data.Headless
 */
export default function (data) {
  global.session.openWindow(
    {
      Subject: data.Subject,
      Prefix: data.Prefix,
      Criteria: data.Criteria,
      Headless: data.Headless,
    },
    null,
    data.customTitle,
  );
}
