import equal from "deep-equal";

/**
 * Checks if the given row is selected
 * @param {number} rowIndex - Row number
 * @returns {boolean} True if the row is selected
 */
export default function isSelectedRow(rowIndex) {
  // We do not store the row indexes, so this way you can still check if the row is selected
  // room for improvement
  let primaryKeys = this.buildCriteriaNew(this.output.FullTable.Rows[rowIndex]);
  return (
    this.selection.filter((existing) => equal(existing, primaryKeys)).length > 0
  );
}
