<template>
  <div class="actions mb-4" v-if="buttons.length > 0">
    <p class="title">{{ $store.state.translations.Actions }}</p>
    <hr />
    <action-bar-action
      v-for="button in buttons"
      :key="button.ActionName"
      :button="button"
      :window="window"
      :sub-windowid="subWindowid"
      :subject="subject"
      @process-actions="processActions"
    />
  </div>
</template>
<script>
import processComponentActions from "@/functions/processComponentActions";
import ActionBarAction from "./ActionBarAction.vue";
import {handleJsFunctionPayload} from "../../../src/functions/window/handleJsFunctionPayload";
import {getActiveVueComponentFromWindow} from "@/functions/window/getActiveVueComponentFromWindow";
export default {
  components: {
    ActionBarAction,
  },
  props: {
    window: {
      required: true,
      type: Object,
    },
    subWindowid: {
      required: false,
      type: String,
      default: null,
    },
    buttons: {
      required: true,
      type: Array,
    },
    subWindow: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    subject: function () {
      return (
        this.subWindow?.output?.Data?.Subject ??
        this.window?.output?.Data?.Subject ??
        this.subWindow?.output?.Request?.Subject ??
        this.window?.output?.Request?.Subject
      );
    },
    loading: function () {
      return this.$store.state.loading;
    },
  },
  methods: {
    async processActions(button) {
      const activeVueInstanceComponent = getActiveVueComponentFromWindow({
        window: global.session.activeWindow,
      });

      const vueFunction =
        activeVueInstanceComponent?.[`custom${button.ActionName}`];

      if (vueFunction) {
        global.session.activeWindow.toggleLoading(true);
        await vueFunction();
        global.session.activeWindow.toggleLoading(false);
        return;
      }

      if (button.JsFunctionPayload) {
        await handleJsFunctionPayload({
          jsFunctionPayload: button.JsFunctionPayload,
        });
        return;
      }
      if (button.GlobalActions && button.GlobalActions.length)
        this.processGlobalActions(button.GlobalActions);
      if (button.ComponentActions && button.ComponentActions.length)
        this.processComponentActions(button.ComponentActions);
    },
    async processGlobalActions(actions) {
      for (const action of actions) {
        const importedModule = await import(`@/actions/${action.Function}.js`);
        importedModule.default(action.Data);
      }
    },
    processComponentActions,
  },
};
</script>
<style scoped lang="scss">
.title {
  font-weight: 600;
  font-size: 15.40px;
}
</style>
