import {setOriginalFormRowOnWindow} from "../../functions/form/setOriginalFormRowOnWindow.js";
import store from "../../../state/store";
import $ from "jquery";

/**
 * Reload with new data
 * @param {boolean} keepSelection - If window should keep selection
 * @returns {Object} fetched data
 */
export default async function reload(keepSelection = false, extraParams) {
  $("body").addClass("avoid-clicks");
  if (!keepSelection) {
    // Always use criteria as selection for Form's (single's or custom prefixes)
    if (
      this.output &&
      this.output.Data &&
      this.output.Data.Type &&
      this.output.Data.Type == "form" &&
      this.output.Request.Prefix != "New"
    ) {
      this.selection = this.output.Request.Criteria;
    } else {
      this.selection = [];
    }
  }

  // not useable with bulk edit
  this.bulkedit = false;

  // get new data
  let result = await this.fetch(null, extraParams);
  $("body").removeClass("avoid-clicks");
  $(this.element).find("[data-select-count]").text(this.selection.length);

  store.commit("refreshTabs");
  store.commit("updateWindow");
  setOriginalFormRowOnWindow({window: this, refresh: true});

  return result;
}
