export const removeQueuedProcesses = ({windowId}) => {
  global.runningProcesses = global.runningProcesses.filter(
    (process) =>
      !(
        process.type === "resetWindow" &&
        process.windowId === windowId &&
        process.state === "queued"
      ),
  );
};
