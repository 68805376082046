/**
 * Open tab in sub window
 * @param {*} tabId - Tab ID
 * @returns {Promise} Promise to open tab
 */
export default async function opentab(tabId) {
  // get tab
  let tab = this.tabs.filter((t) => t.Id === tabId).pop();

  // set request options
  let opts = {
    selectionInfo: {
      ActionName: tab.ActionName,
      Arguments: {},
      Request: this.output.Request,
      Data: {
        Selection: this.selection,
      },
    },
  };

  // Send request to server to open tab
  let result = await this.session.request(
    tab.WebService + "/" + tab.WebMethod,
    opts,
  );

  if (result !== null) {
    // set new selected tab
    this.tabs.forEach((x) => {
      x.Selected = tab === x;
    });

    // Store ActionName in input.Data.SelectedTabActionName to keep selected tab after reset
    this.input.Data.SelectedTabActionName = tab.ActionName;

    // Load and render sub window
    await this.injectSub(result);
    await this.sub.window.render();
  }
}
