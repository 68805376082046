const confirm = require("./confirm").default;

export default async function resetSub(
  shouldConfirm = null,
  shouldFocus = true,
  extraParams,
) {
  const subWindow = this.sub.window;
  subWindow.subWindow = this.sub != null;

  // check if a closing confimation should be asked for
  if (
    (shouldConfirm === "confirm" || this.isDirty()) &&
    !confirm(this.session.translations.WindowWarningOnClose)
  ) {
    return;
  }

  if (subWindow.parent) {
    await subWindow.parent.toggleLoading(true);

    subWindow.dispose(false, true);

    let newWindow = subWindow.session.newWindow(
      subWindow.input,
      null,
      null,
      null,
      extraParams,
    );
    newWindow.parent = subWindow.parent;
    subWindow.parent.sub = {
      window: newWindow,
    };

    await subWindow.parent.toggleLoading(false);
    await subWindow.parent.render();
    await newWindow.render();
    await newWindow.reload(null, extraParams);
  }
}
