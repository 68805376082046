import {toggleLoadingOnLegacyFormFieldDropdowns} from "./toggleLoadingOnLegacyFormFieldDropdowns.js";
import {processLegacyFormFieldExtraCriteria} from "./processLegacyFormFieldExtraCriteria.js";
import {formHasExtraCriteria} from "./formHasExtraCriteria.js";

export const processLegacySelectField = async ({
  field,
  column,
  window,
  vueInstance,
}) => {
  const vueColumns = window.vueColumns;
  toggleLoadingOnLegacyFormFieldDropdowns({vueColumns, value: true});

  if (field.postback === true || field.postback === "true") {
    try {
      await vueInstance.processPostback({window});
    } catch (err) {
      console.log(err);
    }
  }

  if (formHasExtraCriteria({window}))
    // wait a bit for the Watcher to update value
    setTimeout(async () => {
      await processLegacyFormFieldExtraCriteria({
        changedColumn: column,
        window,
      });
    }, 10);

  toggleLoadingOnLegacyFormFieldDropdowns({
    vueColumns,
    value: false,
  });
};
