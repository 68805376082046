export default async function focusOrOpenNewWindow(
  output,
  inSub = false,
  noRender = false,
  noProcessing = false,
  mustConfirm = true,
) {
  const subject = output.Request.Subject;
  const prefix = output.Request.Prefix;
  const windows = Object.keys(global.session.windows).map(
    (key) => global.session.windows[key],
  );

  let targetWindow = null;

  windows.forEach(function (window) {
    if (
      window.output?.Request.Subject === subject &&
      window.output?.Request.Prefix === prefix
    ) {
      targetWindow = window;
    }
  });

  if (targetWindow) {
    this.focus(targetWindow);
  } else {
    this.insertWindow(
      output,
      (inSub = false),
      (noRender = false),
      (noProcessing = false),
      (mustConfirm = true),
    );
  }
}
