let lastChangeID = 0;

/**
 * Send postback to server
 * @param {Object} values - Row values
 * @returns {Promise} Promise
 */
export default async function postbackValues(values) {
  let previousValues = this.previousPostbackValues;

  const changeID = ++lastChangeID;
  let result = await this.session.request(
    "/Admin/WebServices/CoreWebServices.asmx/PostbackValues",
    {
      request: this.input,
      values: values,
      previousValues: previousValues,
    },
  );

  if (changeID !== lastChangeID) {
    return;
  }

  // only overwrite the previousPostbackValues after the call to allow changes to be combined when changes happen faster than the call returns
  this.previousPostbackValues = values;

  await this.handleActionResponse(result);
  this.dirty = true;
}
