import serializeForm from "@/util/serializeForm";

/**
 * Save checked (and unchecked) values from treeview
 */
export default async function saveTreeView() {
  let initialValues = this.output.Data.initialValues;
  let values = serializeForm(this.element);

  // remove values that are the same in the currentlist and in the initial list
  for (let property in initialValues) {
    if (
      initialValues.hasOwnProperty(property) &&
      values.hasOwnProperty(property) &&
      initialValues[property] === values[property]
    ) {
      delete values[property];
    }
  }

  let data = {
    subject: this.output.Request.LinkedSubject,
    criteria:
      this.output.Request.Criteria.length > 0
        ? this.output.Request.Criteria[0]
        : null,
    values: values,
  };

  let output = await this.session.request(
    "/Admin/WebServices/CoreWebServices.asmx/SaveTree",
    data,
  );

  this.handleActionResponse(output);
}
