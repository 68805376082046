<template>
  <div
    class="col-md-12 sidebar-wrapper row justify-end"
    :class="hideRailRoad ? 'col-lg-2' : 'col-lg-4'"
  >
    <div
      v-if="!hideRailRoad"
      class="flex-grow-0 flex-shrink-0 w-full lg:max-w-[55%] xl:2xl:max-w-[50%] lg:pr-[7.5px] xl:pr-[12px] 2xl:pr-[16px]"
    >
      <form-sidebar-railroad
        :guides="railroadGuides"
        class="sticky top-0 pb-5"
      />
    </div>
    <div
      class="flex-grow-0 flex-shrink-0 w-full xl:px-[10px] 2xl:px-[15px]"
      :class="{
        'lg:max-w-[45%] xl:2xl:max-w-[50%]':
          !hideRailRoad && (!hideFormSections || hideFormSections),
      }"
    >
      <r-row class="sidebar mx-0">
        <r-col
          v-if="sidebarFields.length"
          :cols="12"
          class="flex-col lg:pl-[9px] lg:pr-0 xl:px-0 2xl:px-[15px]"
        >
          <field
            v-for="field in sidebarFields"
            :key="field.Name"
            :field="field"
            :fields="fields"
          />
        </r-col>
        <r-col :cols="12" class="lg:ml-3 xl:ml-2 2xl:ml-0">
          <sidebar-checklist v-show="guides.length" :guides="checklistGuides" />
        </r-col>
      </r-row>
    </div>
  </div>
</template>

<script>
import {fetchGuides} from "../../src/functions/form-sidebar/fetchGuides.js";
import {getSettings} from "../../src/util/getSettings.js";
import {fetchRailroadGuides} from "../../src/functions/form-sidebar/fetchRailroadGuides.js";
import {findColumnValue} from "../../src/functions/form-sidebar/findColumnValue.js";
import {findComboboxByColumnName} from "../../src/functions/form-sidebar/findComboboxByColumnName.js";
import {findMetadataByColumnName} from "../../src/functions/form-sidebar/findMetadataByColumnName.js";
import {processSidebarFields} from "../../src/functions/form-sidebar/processSidebarFields.js";
import {getWindowFromWindowId} from "../../src/functions/window/getWindowFromWindowId.js";
import Field from "../content/fields/Field.vue";
import SidebarChecklist from "./formSidebar/FormSidebarChecklist.vue";
import FormSidebarRailroad from "./formSidebar/FormSidebarRailroad";
import RRow from "@/components/elements/RRow";
import RCol from "@/components/elements/RCol";

// Helper functions
const fetchData = async ({state, subject, criteria, prefix}) => {
  const guides = await fetchGuides({state, subject, criteria});
  const railroadGuides = await fetchRailroadGuides({prefix, subject, criteria});
  return {guides, railroadGuides};
};

const getSidebarFields = ({columns, row, metadata, targetWindow}) => {
  return processSidebarFields({
    columns,
    window: targetWindow,
    getValueByColumnName: (name) => findColumnValue(row, name),
    getMetadataByColumnName: (name) => findMetadataByColumnName(metadata, name),
    getComboboxByColumnName: (name) => findComboboxByColumnName(row, name),
  });
};

export default {
  name: "FormSidebar",
  components: {
    FormSidebarRailroad,
    Field,
    SidebarChecklist,
    RRow,
    RCol,
  },
  props: {
    windowId: {
      type: String,
      required: true,
    },
    window: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      guides: [],
      railroadGuides: [],
      sidebarFields: [],
      fields: [],
      hideRailRoad: getSettings()?.HideRailRoad,
      hideFormSections: getSettings()?.HideFormSections,
      subject: null,
      prefix: null,
      criteria: {},
    };
  },
  computed: {
    checklistGuides() {
      return this.guides.filter((guide) => guide.TutorialStep === false);
    },
    columns() {
      return this.window?.output?.FullTable?.Columns;
    },
    metadata() {
      return this.window?.output?.Data.Metadata[0] ?? {};
    },
    row() {
      return this.window?.output?.FullTable?.Rows[0];
    },
  },
  created() {
    let window =
      getWindowFromWindowId({
        windowId: this.windowId,
        session: global.session,
      }) ?? {};

    if (!window.output?.Request?.Prefix && window.sub?.window)
      window = window.sub.window;

    this.prefix = window.output?.Request?.Prefix;
    this.subject = window.output?.Request?.Subject;
    this.criteria = window.output?.Request?.Criteria?.length
      ? window.output?.Request?.Criteria[0]
      : {};

    this.fetchData({window});
  },
  mounted() {
    global.eventBus.$on("afterFocus", this.checkIfRailroadGuideExists);
  },
  beforeDestroy() {
    global.eventBus.$off("afterFocus", this.checkIfRailroadGuideExists);
  },
  methods: {
    getRowFromWindow(window) {
      return window?.output?.FullTable?.Rows[0];
    },
    async checkIfRailroadGuideExists({windowId}) {
      if (windowId !== this.windowId) return;
      if (this.railroadGuides.length) return;
      const window =
        getWindowFromWindowId({
          windowId: this.windowId,
          session: global.session,
        }) ?? {};
      this.prefix = window.output?.Request?.Prefix;
      this.subject = window.output?.Request?.Subject;
      this.criteria = window.output?.Request?.Criteria?.length
        ? window.output?.Request?.Criteria[0]
        : {};
      await this.fetchData({window});
    },
    getFieldsFromRow({row, columns}) {
      const fields = [];

      if (!row) return [];

      for (const index in row) {
        fields.push({...row[index], ...columns[index]});
      }
      return fields;
    },
    async fetchData({window}) {
      const {criteria, subject} = this;
      const {guides, railroadGuides} = await fetchData({
        state: this.$store.state,
        subject,
        criteria,
        prefix: this.prefix,
      });
      this.guides = guides;
      this.railroadGuides = railroadGuides;

      const targetWindow = window.sub?.window ?? window;
      const columns = targetWindow.output?.FullTable?.Columns;
      const row = targetWindow.output?.FullTable?.Rows[0];
      const metadata = targetWindow.output?.Data.Metadata?.[0];

      this.fields = this.getFieldsFromRow({row, columns});
      this.sidebarFields = getSidebarFields({
        columns,
        row,
        metadata,
        targetWindow,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  border-left: 2px solid #eeeeee;
  position: sticky;
  top: 0px;
}
</style>
