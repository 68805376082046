import Hook from "../hook";
import dayjs from "dayjs";

/**
 * This hook updates the window.input.Criteria if window.output.Request.Criteria contains serialized DateTimes, because otherwise on reload the server will throw an error.
 */
class ParseCriteriaHook extends Hook {
  async afterRender(window) {
    if (window.output.Request.Criteria) {
      let criteriaArray = window.output.Request.Criteria;
      for (let criteria of criteriaArray) {
        for (let keyValue in criteria) {
          if (
            window.input.Criteria &&
            criteria[keyValue] &&
            criteria[keyValue].indexOf &&
            criteria[keyValue].indexOf("/Date(") === 0
          ) {
            criteria[keyValue] = dayjs(criteria[keyValue]).format(
              "YYYY-MM-DD HH:mm:ss",
            );
            // updating input.Criteria because that is what window.fetch() uses as request to the server
            window.input.Criteria = criteriaArray;
          }
        }
      }
    }
  }
}

/** @ignore */
export default new ParseCriteriaHook();
