export const getIconString = ({icon}) => {
  const iconMap = {
    success: "far fa-check",
    primary: "far fa-exclamation-triangle",
    warning: "far fa-exclamation-triangle",
    danger: "far fa-times-circle",
    error: "far fa-times-circle",
    info: "far fa-info-circle",
  };
  return iconMap[icon] || icon;
};
