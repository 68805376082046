<template>
  <div>
    <transition name="fade">
      <div
        id="pagetop"
        class="fixed right-0 bottom-0"
        v-show="scY > 300"
        @click="toTop"
      >
        <i class="fas fa-chevron-up"></i>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    document
      .getElementById("scrollContainer")
      .addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.scY = document.getElementById("scrollContainer").scrollTop;
    },
    toTop() {
      document.getElementById("scrollContainer").scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
#pagetop {
  position: fixed;
  bottom: 15px;
  right: 35px;
  height: 32px;
  z-index: 2000;
  background-color: #343a40;
  color: white;
  line-height: 1.4;
  display: flex;
  align-items: center;
  font-size: 0.99rem;
  text-align: center;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 1px 1px 1px 0px rgb(0 0 0 / 17%) !important;
  border-radius: 3px !important;
  cursor: pointer;
}
</style>
