import store from "../../../state/store";
import $ from "jquery";
import {dirtyCheckWithPrompt} from "@/interface/window/dirty";

/**
 * Update window with new output
 * @param {Object} output - New output object
 * @returns {Promise} Promise
 */
export default async function updateWindow(output) {
  const multiEdit = output.Request.Prefix === "MultiEdit";
  if (this.parent) {
    if (
      output.Data &&
      (output.Data.Type === "rentform" ||
        multiEdit ||
        output.Data.Type === "partialdelivery")
    ) {
      if (this.bulkedit) {
        if (!dirtyCheckWithPrompt(window)) return;
      }

      await this.toggleLoading(true);

      // process and render
      await this.process(output, true);
      await this.toggleLoading(false);

      await this.render();
      store.commit("updateWindow");
      $(this.element).removeClass("dirty");

      return;
    }

    this.parent.input.Criteria = output.Request.Criteria;
    this.parent.fetch();
    $(this.element).removeClass("dirty");
    return;
  } else {
    if (this.sub && !(await dirtyCheckWithPrompt(this.sub?.window))) return;
    // set loading (get's removed by process)
    await this.toggleLoading(true);

    // process and render
    await this.process(output, false);
    await this.toggleLoading(false);

    await this.render();
    $(this.element).removeClass("dirty");
    store.commit("updateWindow");
  }
}
