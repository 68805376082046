import {processGlobalActions} from "../../functions/window/processGlobalActions.js";

/**
 * Handle action response
 * @param {Object} response - Action response
 * @returns {Promise} Promise to handle action
 * @throws {Error} Type mismatch, expected an action response
 * @param {boolean} globalActionsPending - Execute global actions
 *
 */
export default async function handleActionResponse(response) {
  if (!response || !response.IsActionResponse) {
    console.error("Not an action response:", response);
    // throw new Error("Type mismatch, expected an action response.")
    return;
  }

  if (response.Events?.length) {
    for (let eventArgs of response.Events) {
      await this.event(...eventArgs);
    }
  }
  if (response.GlobalActions?.length) {
    await processGlobalActions({globalActions: response.GlobalActions});
  }
}
