<template>
  <div class="tab">
    <div
      class="relative inline-flex align-middle"
      @click.middle.stop.prevent="dispose(tab.id)"
    >
      <r-button
        :variant="'primary'"
        :size="'sm'"
        :title="tab.title"
        :active="active"
        :is-tab="true"
        :class="{
          'fade-in': true,
        }"
        class="customBorderRight w-[100%] text-ellipsis whitespace-nowrap overflow-hidden !max-w-[200px]"
        :override-classes="'block relative ml-0'"
        @click="focusWindowId(tab.id)"
        @click.middle.stop.prevent="disposeWindowId(tab.id)"
        v-html="overrideTitle || tab.title"
      />

      <r-button
        variant="dark"
        size="sm"
        class="customBorderLeft ml-[-4px] w-[30px]"
        @click.middle.stop.prevent="disposeWindowId(tab.id)"
        @click="disposeWindowId(tab.id)"
        ><i class="fas fa-times"></i
      ></r-button>
    </div>
  </div>
</template>

<script>
import {getWindowFromWindowId} from "@/functions/window/getWindowFromWindowId";
import RButton from "@/components/elements/RButton";

export default {
  components: {
    RButton,
  },
  props: {
    tab: {
      required: true,
      type: Object,
    },
    activeTab: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    active: function () {
      return this.activeTab === this.tab.id;
    },
    target: function () {
      if (this.active) {
        return "active";
      } else {
        return this.tab.id;
      }
    },
    overrideTitle: function () {
      if (this.active && this.$store.state.loading) {
        return "<i class='fas fa-spinner fa-spin'></i>";
      }
      return null;
    },
  },
  methods: {
    dispose(tabID) {
      // eslint-disable-next-line no-undef
      session.windows[tabID].dispose();
    },
    disposeWindowId(windowId) {
      const window = getWindowFromWindowId({windowId, session: global.session});
      window.dispose();
    },
    focusWindowId(windowId) {
      const window = getWindowFromWindowId({windowId, session: global.session});
      if (window) {
        window.focus();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tab {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  margin-right: 8px;
}

.customBorderRight {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.customBorderLeft {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -4px !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
