import store from "../../../state/store";
import $ from "jquery";

/**
 * Replace window with new output
 * @param {Object} output - New output object
 */
export default async function replaceWindow(output) {
  if (this.parent) {
    await this.toggleLoading(true);
    // Ensure no changes are recorded

    // process and render
    await this.process(output);
    await this.toggleLoading(false);
    await this.fetch();
    await this.render();

    store.commit("updateWindow");
    $(this.element).removeClass("dirty");
    return;
  } else {
    // set loading (get's removed by process)
    await this.toggleLoading(true);

    // process and render
    await this.process(output);
    await this.toggleLoading(false);

    await this.render();

    $(this.element).removeClass("dirty");
    store.commit("updateWindow");
  }
}
