import Hook from "../hook";
import store from "../../state/store";

class IframeHook extends Hook {
    async beforeRender(window) {
        if (window.output?.Request?.Subject === "Core.virtual_Iframe") {
            if (window.output.Request.MenuIframe) {
                window.output.Data.Url = window.output?.Request?.Url?.includes("app.powerbi")
                    ? `${window.output.Request.Url}`
                    : `${window.output.Request.Url}?token=${store.state.accessToken}`;
                return;
            }
            if (window.output.Sub) {
                window.output.Sub.Data.Url = window.output?.Request?.Criteria[0]?.Url?.includes("app.powerbi")
                    ? `${window.output.Request.Criteria[0].Url}`
                    : `${window.output.Request.Criteria[0].Url}&Authorization=Bearer ${store.state.accessToken}`;
                return;
            }
            window.output.Data.Url = window.output?.Request?.Criteria[0]?.Url?.includes("app.powerbi")
                ? `${window.output.Request.Criteria[0].Url}`
                : `${window.output.Request.Criteria[0].Url}&Authorization=Bearer ${store.state.accessToken}`;
        }
    }
}

export default new IframeHook();
