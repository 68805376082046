export const cleanTreeBranches = ({branches}) => {
  branches.forEach((branch) => {
    if (branch.children) {
      try {
        branch.children = branch.children.filter(
          (child) => child.children && child.children.length,
        );
      } catch (e) {
        console.error(e);
      }
    }
  });
};
