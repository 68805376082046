import Hook from "../hook";

import DaysCalculatedListener from "@/functions/events/daysCalculatedChangeEvent";

class RentalReservationDaysCalculatedHook extends Hook {
  listener;

  afterRender(eventWindow) {
    this.addEvent(eventWindow);
  }

  afterFocus(eventWindow) {
    this.addEvent(eventWindow);
  }

  addEvent(eventWindow) {
    const selectedWindow = eventWindow?.sub?.window ?? eventWindow;
    const {Subject, Prefix} = selectedWindow.output?.Request;
    if (
      Subject === "Rental.Reservation" &&
      (Prefix === "New" || Prefix === "Single")
    ) {
      if (!this.listener) {
        this.listener = new DaysCalculatedListener();
      } else {
        this.listener.stop();
      }

      this.listener.start(selectedWindow);
    }
  }
}
export default new RentalReservationDaysCalculatedHook();
