import {alertPopup} from "../../src/interface/alertPopup/alertPopup";

export const handleConfirmation = async ({window, shouldConfirm}) => {
  if (shouldConfirm === false) return true;

  const dirtySub = window.sub ? window.sub.window.isDirty() : false;
  if (shouldConfirm === "confirm" || window.isDirty() || dirtySub) {
    const alertObject = {
      text: global.session.translations.WindowWarningOnClose,
      icon: "warning",
      dangerMode: true,
      buttons: [global.session.translations.Cancel, true],
    };
    return await alertPopup(alertObject);
  }
  return true;
};
