<template>
  <div>
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="svg-inline--fa fa-circle text-gray bg-white"
      :class="classes"
    >
      <path fill="currentColor" :d="pathD"></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "FormSidebarRailroadItemIconSuccess.vue",
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
    colorClass: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    classes() {
      return {
        "!text-gray-300": !this.isAvailable,
        "!bg-[#eee]": !this.isAvailable && !this.small,
        "w-8 h-7": !this.small,
        "w-6 h-5 ml-1.5 mt-1.5": this.small,
        ...this.colorClass,
      };
    },
    pathD() {
      return this.isAvailable
        ? "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"
        : "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z";
    },
  },
};
</script>

<style scoped></style>
