import Window from "@/model/window";

export default async function resetSubPropper(shouldConfirm = null) {
  if (!this.parent) return;
  const parentWindow = this.parent;
  const newWindow = new Window(this.session);

  newWindow.input = this.input;

  await this.dispose();

  parentWindow.sub.window = newWindow;

  await newWindow.fetch();
  newWindow.render();
}
