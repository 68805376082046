import download from "@/util/download";

export default async function actionDownLoadTemplate() {
  let downloadLink = `/Admin/Pages/DownloadTemplate.aspx?Warehouse=${this.session.comboboxes.selectedValues.Warehouse}`;
  let context = {
    ActionName: "Excel",
    Request: this.input,
    Arguments: {},
    Data: {},
  };

  await download(downloadLink, {
    context,
  });
}
