import $ from "jquery";

import Calendar from "./interface/calendar";
import Combobox from "./interface/combobox";
import PlusMinusInput from "./interface/plusminusinput";
import Dropdown from "./interface/dropdown";
import ScrollContainer from "./interface/scrollContainer";
import Searchbar from "./interface/searchbar";
import ToggleBTW from "./interface/toggleBTW";
import TreeView from "./interface/treeview";


/**
 * Interface components
 * @property {Array} list - List of all components
 * @property {Session} session - The current Session
 */
export default class Components {
  list = [];
  session = null;

  /**
   * Components constructor
   * @param {Session} session - The current session
   */
  constructor(session) {
    this.session = session;

    // Add components
    this.add(Combobox);
    this.add(PlusMinusInput);




    this.add(Dropdown);
    this.add(Searchbar);
    this.add(TreeView);
    this.add(Calendar);
    this.add(ToggleBTW);
    this.add(ScrollContainer);
  }

  /**
   * Adds a component
   * @param {Object} component - Component to add
   * @returns {void}
   */
  add(component) {
    component.session = this.session;
    this.list.push(component);
  }

  /**
   * Initialise the components
   * @param {document} doc - The page document
   * @returns {void}
   */
  init(doc) {
    this.list.forEach((e) => {
      if (typeof e.init === "function") {
        e.init(doc);
      }
    });
  }

  /**
   * Update event triggered by DiffHTML
   * @param {Element} el - HTML element
   * @returns {void}
   */
  update(el) {
    this.runMatched("update", el);
  }

  /**
   * Replace event triggered by DiffHTML
   * @param {Element} el - HTML element
   * @returns {void}
   */
  replace(el) {
    this.runMatched("replace", el);
  }

  /**
   * Change event triggered by DiffHTML
   * @param {Element} el - HTML element
   * @returns {void}
   */
  change(el) {
    this.runMatched("change", el);
  }

  /**
   * Attach event triggered by DiffHTML
   * @param {Element} el - HTML element
   * @returns {void}
   */
  attach(el) {
    this.runMatched("attach", el);
  }

  /**
   * Run component based of css selector
   * @param {string} func - Function type to run
   * @param {Element} el - HTML element
   * @returns {void}
   */
  runMatched(func, el) {
    this.list.forEach((component) => {
      // check if the component has defined an updateMatch, and if so, if it matches
      let run =
        typeof component.updateMatch !== "undefined" &&
        (component.updateMatch === "all" || $(el).is(component.updateMatch));

      // run function if it exists and is needed
      if (run && typeof component[func] === "function") {
        component[func](el);
      }
    });
  }
}
