/**
 * Open from tree
 * @param {*} id - Tree id
 * @returns {Promise} Promise
 */
export default async function openWindowFromTree(id) {
  if (!id) {
    return;
  }

  // Criteria is TableName + ID (f.e. CategoryID)
  let criteria = this.output.Request.Subject.split(".")[1] + "ID";
  let input = {
    Subject: this.output.Request.Subject,
    Prefix: "Single",
    Criteria: [{[criteria]: id}],
  };
  this.session.openWindow(input);
}
