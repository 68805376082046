var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between font-medium block rounded-tr-md rounded-bl-md rounded-br-md max-w-full",class:{
    '!text-[#888] !bg-[#eee] !rounded-lg': !_vm.railroadItem.Available,
  }},[_c('div',{staticClass:"hover:cursor-pointer overflow-hidden",on:{"click":function($event){return _vm.processEvent(
        _vm.railroadItem.Action,
        _vm.railroadItem.LegacyAction,
        !_vm.railroadItem.Sub,
      )}}},[_vm._v(" "+_vm._s(_vm.railroadItem.Description)+" "),(_vm.railroadItem.Blocking)?_c('span',{staticClass:"text-primary"},[_vm._v("*")]):_vm._e()]),(_vm.railroadItem.EditAction && _vm.railroadItem.Enabled)?_c('div',{staticClass:"text-primary cursor-alias mt-[0.5px] text-[14px] lg:mr-1",on:{"click":function($event){return _vm.processEvent(_vm.railroadItem.EditAction, _vm.railroadItem.LegacyAction)}}},[_c('i',{staticClass:"fas fa-external-link-alt"})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }