/**
 * Initiate canvas listeners
 * @object {session} Session object for functionality in listeners
 */
export function initiateCanvasListeners({canvas}) {
  // Makes a subwindow go fullscreen on click
  $(document).on("click", "[data-window-toggle-fullscreen]", (event) => {
    canvas.toggleWindowSize(event.currentTarget);
  });

  $("body").on("click keyup", ".window", function checkForMsgBoxes() {
    $(this).find(".modal-content form.simple").submit();
  });

  $("body").on("click", ".counter", (e) => {
    if ($(this).hasClass("decrement") || $(this).hasClass("increment")) {
      e.preventDefault();
      e.stopImmediatePropagation();
      let minValue = Number(
        $(this).parent().find(".counter.value").attr("min"),
      );
      let maxValue = Number(
        $(this).parent().find(".counter.value").attr("max"),
      );
      let current = Number($(this).parent().find(".counter.value").val()) || 0;
      let newValue = Math.min(
        !isNaN(maxValue) ? maxValue : 9999,
        Math.max(
          !isNaN(minValue) ? minValue : -9999,
          current + ($(this).hasClass("increment") ? 1 : -1),
        ),
      );
      $(this)
        .parent()
        .find(".counter.value")
        .val(String(newValue || "") || null);
      $(this).parent().find(".counter.display").text(String(newValue));
    }
  });
}
