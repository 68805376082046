import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

import user from "./modules/user";
import dialog from "./modules/dialog";
import windows from "./modules/windows";
import datagrid from "./modules/datagrid";
import kanbanboard from "./modules/kanbanboard";
import windowFilters from "./modules/windowFilters";
import tables from "./modules/tables";

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    datagrid,
    dialog,
    user,
    windows,
    kanbanboard,
    windowFilters,
    tables,
  },
});
