import $ from "jquery";

/**
 * Toggle BTW field
 * @property {string} currency - default currency
 * @property {string} updateMatch - CSS selector
 */
export default class ToggleBTW {
  static currency = "€";
  static updateMatch = "[data-toggle-btw]";

  /**
   * Initialise btw fields
   * @param {document} doc - page document
   * @returns {void}
   */
  static init(doc = document) {
    $(doc)
      .on("mousedown", "[data-toggle-btw]", function toggle() {
        let $input = $(this).closest(".form-field").find("input[type='text']");

        // get input
        let val = $input.val();
        val = val.replace(/($|€|\s)/g, "");
        val = Number(val);

        // check if value is numerical
        if (!val || isNaN(val)) {
          return;
        }

        // set properties
        $(this).attr("data-original-value", val);
        $(this).attr("data-isDowned", 1);

        // calculate new value
        let btwPercent = Number($(this).attr("data-toggle-btw")) || 0;
        let newValue = (val * (100 + btwPercent)) / 100;

        // set input (read friendly)
        $input.val(ToggleBTW.twoAfterCommaFormat(newValue, ToggleBTW.currency));
      })
      .on("mouseup mouseout", "[data-toggle-btw]", function toggleback() {
        // make sure it was pressed (could cause all sorts of problems if we didn't)
        if (!$(this).attr("data-isDowned")) {
          return;
        }

        // reset value
        $(this).attr("data-isDowned", 0);

        // reset value
        let $input = $(this).closest(".form-field").find("input[type='text']");
        let oldval = Number($(this).attr("data-original-value"));

        if (oldval && !isNaN(oldval)) {
          $input.val(ToggleBTW.twoAfterCommaFormat(oldval, ToggleBTW.currency));
        }
      });
  }

  /**
   * Transform element when found
   * @param {Element} el - HTML element matching selector
   * @returns {void}
   */
  static attach(el) {
    if ($(el).closest(".form-field").find("input").length > 1) {
      return;
    }

    let $input = $(el).closest(".form-field").find("input");

    let originalValue = $input.val();
    $input.val(
      ToggleBTW.twoAfterCommaFormat(originalValue, ToggleBTW.currency),
    );
  }

  /**
   * Format function
   * @private
   * @param {*} value - Value
   * @param {string} cur - Currency ratio(unused)
   * @todo fix for currency?
   * @returns {string} Formatted currency string
   */
  static twoAfterCommaFormat(value, cur) {
    if (!value) {
      return "0.00";
    }

    let res = Number(value) || 0;
    return res.toFixed(2);
  }
}
