export default function getTextColor(
  bgColor,
  lightColor = "#FFFFFF",
  darkColor = "#242424",
) {
  const getLuminance = function (hexColor) {
    var color =
      hexColor.charAt(0) === "#" ? hexColor.substring(1, 7) : hexColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) =>
      col <= 0.03928 ? col / 12.92 : ((col + 0.055) / 1.055) ** 2.4,
    );

    return 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  };

  var L = getLuminance(bgColor);
  var L1 = getLuminance(lightColor);
  var L2 = getLuminance(darkColor);

  return L > Math.sqrt((L1 + 0.05) * (L2 + 0.05)) - 0.05
    ? darkColor
    : lightColor;
}
