import Treeview from "../model/treeview";
import $ from "jquery";
import Hook from "../hook";

/** Item hook */
class ItemHook extends Hook {
  /**
   * Set comboboxes
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterFetch(window) {
    if (
      window.input.Subject != "Rental.Item" ||
      window.input.Prefix != "Multi"
    ) {
      return;
    }
    let items = await window.session.combobox("Rental.Category", "CategoryID");
    items.forEach((x) => (x.parentID = x.Attributes.ParentCategoryID));
    let tree = Treeview.fromRows(items, "parentID", "Value");
    Treeview.sort(tree, "Text");
    window.filters.other = [];
    window.filters.other.push({
      type: "Treeview",
      text: "Categorie selecteren",
      value: null,
      name: "catID",
      list: tree,
    });
  }

  async afterRender(window) {
    if (
      window.output.Request.Subject == "Rental.Item" &&
      window.output.Request.Prefix === "New"
    ) {
      $(window.element).on("change", '[name="UseSerials"]', function () {
        if (this.checked) {
          $(window.element)
            .find('[name="IsOutOfStockOrderAccepted"]')
            .attr("disabled", true);
        } else {
          $(window.element)
            .find('[name="IsOutOfStockOrderAccepted"]')
            .attr("disabled", false);
        }
      });
    }
    // return for other tables and prefixes
    if (
      window.output.Request.Subject != "Rental.Item" ||
      window.output.Request.Prefix != "New"
    ) {
      return;
    }

    // When the field for the ItemType is entered, check the ItemType and enable or disable the useSerials
    let $inputItemType = $("[name='ItemType']", window.element);
    let $inputUseSerials = $("[name='UseSerials']", window.element);
    let $inputPriceGross = $("[name='Price']", window.element);

    // Disable the inputPriceGross and inputUseSerials so no wrong combination can be made
    // $inputPriceGross.prop("disabled", true)
    // $inputUseSerials.prop("disabled", true)

    $inputItemType.off("change");
    $inputItemType.change(() => {
      let itemType = $inputItemType.val();

      // removed the itemType == "RentalPeriod" as of 19-12-2018 for RentalTec because usage of ItemPeriod with Percentages makes use of the Item.Price column!
      // Disable inputPriceGross if itemType is an empty string, else enable it (except if it's an RentalPeriod)
      $inputPriceGross.prop(
        "disabled",
        /*itemType == "RentalPeriod" ||*/ itemType === "",
      );

      /*if (itemType == "RentalPeriod") {
				// $inputPriceGross.prop("disabled", true)
				$inputPriceGross.val("0.00")

			}*/

      // Check for the following types, if any of them is selected, check or uncheck the useSerials checkbox.
      if (itemType == "RentalCounter" || itemType == "RentalBoth") {
        $inputUseSerials.prop("disabled", true);
        if (!$inputUseSerials.prop("checked")) {
          $inputUseSerials.prop("checked", true).change();
        }
      } else if (itemType == "Sales" || itemType == "Deposit") {
        $inputUseSerials.prop("disabled", true);
        if ($inputUseSerials.prop("checked")) {
          $inputUseSerials.prop("checked", false).change();
        }
      } else if (itemType == "RentalPeriod") {
        $inputUseSerials.prop("disabled", false);
      }
    });

    $inputUseSerials.off("change");
    $inputUseSerials
      .change(() => {
        let useSerialsRequired = $(window.element).find(
          "[name='AutoSelectSerials'], [name='IsPersonal']",
        );

        useSerialsRequired.prop("disabled", !$inputUseSerials.prop("checked"));

        if (
          !$inputUseSerials.prop("checked") &&
          useSerialsRequired.prop("checked")
        ) {
          useSerialsRequired.prop("checked", false).change();
        }
      })
      .change();
  }
}

/** @ignore */
export default new ItemHook();
