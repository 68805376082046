/**
 * Set bulkedit
 * @param {string} targetTableName - Action target table
 * @param {string} webserviceUrl - Service URL
 * @param {string} webMethodName - Method name
 * @returns {Promise} Promise
 */
export default async function actionBulk(
  targetTableName,
  webserviceUrl,
  webMethodName,
) {
  this.bulkedit = targetTableName;

  this.toggleLoading(true);
  await this.render();
  this.toggleLoading(false);
}
