import Hook from "../hook";
import store from "../../state/store";
import Vue from "../interface/vue";
import Upload from "../../views/content/Upload";

class UploadHook extends Hook {
  async afterRender(window) {
    if (window.output?.Data?.Type === "upload") {
      if (window.element?.querySelectorAll("[vue-component]").length > 0) {
        new Vue({
          el: window.element.querySelectorAll("[vue-component]")[0],
          store,
          render: (h) => h(Upload),
        });
      }
    }
  }
}
export default new UploadHook();
