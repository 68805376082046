<template>
  <div v-if="activeGuides.length > 0" class="checklist">
    <p class="title">{{ translations[`${subject}-Guide-Title`] }}</p>

    <ul>
      <li
        v-for="guide of activeGuides"
        :key="guide.Message"
        :data-window-event="guide.Action"
        data-window-target="parent"
        :class="{completed: guide.Completed}"
      >
        <div class="guide-line">
          <div :class="{link: guide.Action !== ''}">
            &nbsp;{{ guide.Message }}
          </div>
          <div :class="`ml-2 text-${toLowerCase(guide.Type)} `">
            <i class="fas fa-exclamation-circle"></i>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    guides: {
      required: false,
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  computed: {
    translations() {
      return this.$store.state.translations;
    },
    subject() {
      return this.$store.getters.window.output?.Request.Subject;
    },
    activeGuides() {
      return this.guides.filter((guide) => guide.Completed === false);
    },
  },
  methods: {
    toLowerCase(value) {
      return value?.toLowerCase() ?? "";
    },
  },
};
</script>

<style scoped lang="scss">
.checklist {
  margin-bottom: 10px;
  border-top: 1px solid #eeeeee;
  text-align: right;
}
ul {
  padding-left: 0px;
  font-weight: 500;
  margin: 0;
  list-style: none;
  li {
    padding-bottom: 5px;
  }
  li.completed {
    text-decoration: line-through;
  }
}
.title {
  font-weight: 900;
  text-align: right;
  margin-right: 0px !important;
  padding-left: 0px;
  color: #626262;
}
.guide-line {
  display: flex;
  justify-content: flex-end;

  .link:hover {
    color: #f26522;
    cursor: pointer;
  }
}
</style>
