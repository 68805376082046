
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/startup.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"content\">\n  ";
var t_1;
t_1 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"language");
frame.set("languageID", t_1, true);
if(frame.topLevel) {
context.setVariable("languageID", t_1);
}
if(frame.topLevel) {
context.addExport("languageID", t_1);
}
output += " ";
if(runtime.contextOrFrameLookup(context, frame, "languageID") == "fr") {
output += " ";
var t_2;
t_2 = "en";
frame.set("languageID", t_2, true);
if(frame.topLevel) {
context.setVariable("languageID", t_2);
}
if(frame.topLevel) {
context.addExport("languageID", t_2);
}
output += " ";
;
}
output += "\n  <div style=\"max-width: 800px\">\n    <!--<h3>Welkom bij ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"product"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"username"), env.opts.autoescape);
output += "!</h3> -->\n    <h3>\n      ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"WelcomeMessage"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"username"), env.opts.autoescape);
output += "!\n    </h3>\n    <!-- <p>Test vertalingen inladen: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"NoRowsFound"), env.opts.autoescape);
output += "</p> -->\n\n    <h2>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"AssistanceMessage"), env.opts.autoescape);
output += "</h2>\n\n    <p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"SupportMessage"), env.opts.autoescape);
output += "</p>\n\n    <div class=\"startup-row\">\n      <i class=\"fab fa-youtube fa-lg\" aria-hidden=\"true\"></i>\n\n      <a href=\"https://www.youtube.com/c/InfodatekOfficial/\" target=\"_blank\">\n        <button class=\"button button-default pull-right\" disabled>\n          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"MoreInfo"), env.opts.autoescape);
output += "\n        </button>\n      </a>\n      <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"TutorialsMessage"), env.opts.autoescape);
output += "</span>\n    </div>\n\n    <div class=\"startup-row\">\n      <i class=\"fa fa-book fa-lg\" aria-hidden=\"true\"></i>\n      <a\n        href=\" https://rentmagic.net/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "languageID"), env.opts.autoescape);
output += "/support/manual/\"\n        target=\"_blank\"\n      >\n        <button class=\"button button-default pull-right\">\n          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"MoreInfo"), env.opts.autoescape);
output += "\n        </button>\n      </a>\n      <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ManualsMessage"), env.opts.autoescape);
output += "</span>\n    </div>\n\n    <div class=\"startup-row\">\n      <i class=\"fa fa-envelope fa-lg\" aria-hidden=\"true\"></i>\n      <a href=\"mailto:software@infodatek.com\" target=\"_blank\">\n        <button class=\"button button-default pull-right\">\n          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"MoreInfo"), env.opts.autoescape);
output += "\n        </button>\n      </a>\n      <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"SupportDepartmentMessage"), env.opts.autoescape);
output += "</span>\n    </div>\n\n    <hr />\n\n    <h2>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"GeneralInfo"), env.opts.autoescape);
output += "</h2>\n\n    <div class=\"startup-row\">\n      <i class=\"fa fa-globe fa-lg\" aria-hidden=\"true\"></i>\n      <a href=\"https://rentmagic.net\" target=\"_blank\">\n        <button class=\"button button-default pull-right\">\n          ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"MoreInfo"), env.opts.autoescape);
output += "\n        </button>\n      </a>\n      <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"WebsiteMessage"), env.opts.autoescape);
output += "</span>\n    </div>\n  </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/startup.html", ctx);
          }
        
          return nunjucks.render("views/content/startup.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/startup.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        