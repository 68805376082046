/**
 * All Possible keys (before, after)
 * @constant
 * @type {string[]}
 */
const KEYWORDS = ["before", "after"];

/**
 * All possible event names
 * @constant
 * @type {string[]}
 */
const EVENTNAMES = [
  "render",
  "fetch",
  "focus",
  "process",
  "openrow",
  "create",
  "dispose",
  "message",
  "paginate",
  "resize",
  "savebulk",
  "toggleloading",
];

/**
 * Void function
 * @ignore
 * @returns {void}
 */
let _noop = function () {
  return;
};

/**
 * Asynchronous hook system
 */
export default class Hook {
  /**
   * Sequences object
   * @private
   * @type {Object}
   */
  static _sequences = {};

  /**
   * Run a function that can be hooked upon
   * @param {string} name - Sequence name
   * @param {Window} window - Window
   * @param {function} fn - Function to be run
   * @param {Object} event - Event data
   * @returns {Promise} Promise
   */
  static async run(name, window, fn = _noop, event = {}) {
    let _name = name.toLocaleLowerCase();
    if (EVENTNAMES.indexOf(_name) === -1) {
      return event;
    }

    event.cancel = false;

    if (!Hook._sequences[_name]) {
      // no hooks, run only function
      await fn(event);
      return event;
    }

    // run before events
    if (Hook._sequences[_name].before.length) {
      await Promise.all(
        Hook._sequences[_name].before.map(async (f) => {
          return await f.function.call(f.context, window, event);
        }),
      );

      if (event.cancel) {
        return event;
      }
    }

    // run event
    if (typeof fn === "function") {
      await fn.call(window, event);
    }

    if (event.cancel) {
      return event;
    }

    // run after event
    if (Hook._sequences[_name].after.length) {
      await Promise.all(
        Hook._sequences[_name].after.map(async (f) => {
          return await f.function.call(f.context, window, event);
        }),
      );
    }

    return event;
  }

  /**
   * Register a hook manually. This function assumes that if you have to register
   * manually that the hook is a priority hook.
   * @param {string} name - Name containing keyword and event name (camelCase)
   * @param {*} context - Function context
   * @param {function} fn - Async ook function
   * @param {boolean} isPriority - If the function should be run first (default true)
   * @returns {void}
   * @example register("afterProcess", async () => { alert("hi") })
   */
  static register(name, context, fn, isPriority = true) {
    let isValid = false;
    let key = null;
    let event = null;

    // Get if key-event pair exists
    KEYWORDS.forEach((x) => {
      if (name.indexOf(x) === 0) {
        event = name.substr(x.length).toLowerCase();
        if (EVENTNAMES.indexOf(event) > -1) {
          key = x;
          isValid = true;
        }
      }
    });

    if (!isValid) {
      return;
    }

    Hook.createSequenceIfNull(event);
    let insert = {
      function: fn,
      context: context || Hook,
    };

    if (isPriority) {
      Hook._sequences[event][key].unshift(insert);
    } else {
      Hook._sequences[event][key].push(insert);
    }
  }
  /**
   * Create sequence object if not null
   * @private
   * @param {string} name - Name of the sequence
   * @returns {void}
   */
  static createSequenceIfNull(name) {
    if (Hook._sequences[name]) {
      return;
    }

    Hook._sequences[name] = {
      before: [],
      after: [],
    };
  }

  constructor() {
    // get class prototype (which contains class functions)
    let proto = Object.getPrototypeOf(this);

    // loop through properties
    for (let key of Object.getOwnPropertyNames(proto)) {
      // register as non priority hook
      Hook.register(key, this, proto[key], false);
    }
  }
}
