import {api} from "@/services/api";

export async function getExcelImportTemplate({fullTableName, criteria}) {
  const result = await api.get(`/v2/excel/template/`, {
    params: {
      FullTableName: fullTableName,
      ...criteria?.[0]
    },
    responseType: "arraybuffer",
  });

  return result.data;
}
