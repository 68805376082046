import Hook from "@/hook";

/**
 * Emit event
 * @deprecated
 * @param {*} eventName - Event name
 * @param {Array} args - Arguments
 * @returns {Promise} Promise
 */
export default async function emit(eventName, ...args) {
  try {
    // await this.session.hooks.run(eventName, this, { args })
    Hook.run(eventName, this, null, {args});
  } catch (err) {
    this.setError(err);
  }
}
