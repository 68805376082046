import {getChangedFormValueKeys} from "./getChangedFormValueKeys.js";

export const changedFieldsAreVueFormFields = ({newRow, oldRow, window}) => {
  const changedFieldNames = getChangedFormValueKeys({newRow, oldRow});
  const vueFormFields = window?.vueColumns;

  const vueFormFieldNames = [];
  for (const field in vueFormFields) {
    vueFormFieldNames.push(
      vueFormFields[field]?.$children[0]?.name ??
        vueFormFields[field]?.$children[0]?.field?.name,
    );
  }

  return changedFieldNames.every((changedField) =>
    vueFormFieldNames.includes(changedField),
  );
};
