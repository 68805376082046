import download from "@/util/download";
import store from "@/../state/store";

/**
 * Export table to excel
 * @param {string} targetTableName - Action target table
 * @param {string} webserviceUrl - Service URL
 * @param {string} webMethodName - Method name
 * @returns {Promise} Promise
 */
export default async function actionExcel() {
  let downloadLink = `/Admin/Pages/ExportToExcel.aspx?Warehouse=${
    this.session.comboboxes.selectedValues.Warehouse
  }&Authorization=${encodeURIComponent(`Bearer ${store.state.accessToken}`)}`;
  let context = {
    ActionName: "Excel",
    Request: this.input,
    Arguments: {},
    Data: {},
  };

  await download(downloadLink, {
    context,
  });
}
