import {api} from "./api";
import store from "../../state/store";

export async function quickSearch({searchValue}) {
  const result = await api.post(
    `/v2/dropdown`,
    {
      Text: searchValue,
      NumberOfItems: 0,
      DiValues: {},
      DiPrimaryKeys: {},
      TableName: "Core.virtual_Scanbox",
      ColumnName: "Value",
      RowCount: null,
      Subject: null,
      Prefix: null,
    },
    {
      params: {Warehouse: store.state.activeWarehouse},
    },
  );

  return result.data;
}
