
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/elements/availabilityList.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"availabilityList container pt-3\" id=\"availabilityModal\">\n  <div class=\"text-center\">\n    <p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"ItemID"), env.opts.autoescape);
output += " <br /><strong>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "itemID"), env.opts.autoescape);
output += "</strong></p>\n    <p>\n      ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_SelectedPeriod"), env.opts.autoescape);
output += ": <br /><strong\n        >";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "startDate"), env.opts.autoescape);
output += " - ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "endDate"), env.opts.autoescape);
output += "</strong\n      >\n    </p>\n  </div>\n\n  <table class=\"table table-sm\">\n    <tbody>\n      ";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "availabilityList");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("status", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += " ";
if(runtime.memberLookup((t_4),"highlight")) {
output += "\n      <strong>\n        <tr>\n          <th>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_" + runtime.memberLookup((t_4),"key")), env.opts.autoescape);
output += "</th>\n          <th class=\"text-right text-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.memberLookup((t_4),"key")), env.opts.autoescape);
output += "\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"value"), env.opts.autoescape);
output += "\n          </th>\n        </tr>\n      </strong>\n      ";
;
}
else {
output += "\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_" + runtime.memberLookup((t_4),"key")), env.opts.autoescape);
output += "</td>\n        <td class=\"text-right text-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.memberLookup((t_4),"key")), env.opts.autoescape);
output += "\">\n          ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"value"), env.opts.autoescape);
output += "\n        </td>\n      </tr>\n      ";
;
}
output += " ";
;
}
}
frame = frame.pop();
output += "\n    </tbody>\n  </table>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/elements/availabilityList.html", ctx);
          }
        
          return nunjucks.render("views/elements/availabilityList.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/elements/availabilityList.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        