<template>
  <div class="option-qr">
    <!-- && totpData?.SetUpCode -->
    <div v-if="translations">
      <div v-if="$data.error" class="error-box">
        {{ $data.error }}
      </div>
      <div class="title mb-1">
        1. {{ translations.TwoFactorSetupAppDownloadsTitle }}
      </div>
      <div class="downloads flex justify-center">
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          ><img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" /></a
        ><!-- /en_badge_web_generic_playstore.png -->
        <a
          href="https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200"
          target="_blank"
          ><img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1284940800&h=7fc6b39acc8ae5a42ad4b87ff8c7f88d"
            alt="Download on the App Store"
            style="padding: 11px"
        /></a>
      </div>
      <div class="w-100 mt-3">
        <p>
          {{ translations.TwoFactorSetupAppDownloadsDescription }}
        </p>
      </div>
      <div class="title mb-1">
        2. {{ translations.TwoFactorSetupAddTokenTitle }}
      </div>

      <div class="qr">
        <p class="text-left ml-1 mb-4">
          {{ translations.TwoFactorSetupAddTokenDescription }}
        </p>
        <div class="flex justify-center">
          <qrcode-vue
            v-if="qrCodeValue"
            class="qrcode text-center pl-3 pt-3 pr-3 !pb-[10px]"
            :value="qrCodeValue"
            :size="160"
          />
        </div>
        <div class="text-center">
          <p class="text-left mt-4 ml-1">
            {{ translations.TwoFactorSetupAddTokenManualDescription }}
          </p>
          <div class="flex justify-center">
            <div
              class="flex justify-between items-center w-2/3 p-2 border border-gray-200 rounded-md shadow-sm"
            >
              <div
                class="font-mono text-md text-gray-700 text-ellipsis overflow-hidden px-2"
              >
                {{ totpData?.SetUpCode }}
              </div>
              <RButton :variant="'secondary'" @click="copyToClipboard">
                {{ translations.Copy }}
              </RButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import RButton from "../../../src/components/elements/RButton.vue";
import {notify} from "../../../src/util/notify.js";

export default {
  name: "LoginTwoFactorOptionQR",
  components: {
    RButton,
    QrcodeVue,
  },
  props: {
    totpData: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    qrCodeValue() {
      if (this.totpData?.SetUpCode) {
        return `otpauth://totp/${this.totpData.UserName}@${window.location.host}?secret=${this.totpData.SetUpCode}&issuer=RentMagic`;
      }
      return null;
    },
  },
  watch: {
    totpData: {
      async handler() {
        this.$data.error =
          this.totpData == null ? this.translations.UnknownError : "";
      },
      immediate: true,
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.totpData.SetUpCode).then(
        () => {
          notify({
            message: this.translations.TwoFactorSetupAppCopyToClipboard,
            type: "success",
          });
        },
        (err) => {
          console.error("Could not copy text: ", err);
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.option-qr {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 17.6px;
    font-weight: 600;
  }
}

.downloads img {
  height: 75px;
}

.qrcode {
  --s: 30px; /* the size on the corner */
  --t: 3px; /* the thickness of the border */
  --g: 20px; /* the gap between the border and image */

  padding: calc(var(--g) + var(--t));
  outline: var(--t) solid rgba(242, 101, 34, 0.85); /* the color here */
  outline-offset: calc(-1 * var(--t));
  mask: conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 /
      calc(100% - var(--s)) calc(100% - var(--s)),
    linear-gradient(#000 0 0) content-box;
  border-radius: 7px;
}
</style>
