import store from "../../state/store.js";

export default function processComponentActions(actions) {
  const windowId = this.$store.getters["windows/activeWindowId"];
  let storeName = this.$store.getters["windows/windowType"](windowId);

  if (store.getters["dialog/show"]) {
    storeName = "dialog";
  }

  for (const action of actions) {
    this.$store.dispatch(`${storeName}/${action.Function}`, {
      data: action.Data,
      windowId,
    });
  }
}
