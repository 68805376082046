import {api} from "./api";
import store from "../../state/store";

/**
 * Adds a new row to the datagrid with given windowId.
 * @param requestData {Object}
 * @param requestData.subject {String}
 * @param requestData.criteria {Array}
 * @param requestData.rows {Array}
 * @param requestData.search {String}
 * @param requestData.windowId {String}
 * @returns {Promise<Object>}
 */
export async function newRow(requestData) {
  return await api.post(`/v2/datagrid/new`, requestData.criteria, {
    params: {subject: requestData.subject},
  });
}

/**
 * Adds a new row to the datagrid with given windowId.
 * @param requestData {Object}
 * @param requestData.subject {String}
 * @param requestData.criteria {Array}
 * @param requestData.rows {Array}
 * @param requestData.search {String}
 * @param requestData.windowId {String}
 * @returns {Promise<Object>}
 */
export async function newScanRow(requestData) {
  return await api.post(
    `/v2/datagrid/scan`,
    {
      criteria: requestData.criteria,
      rows: requestData.rows,
    },
    {
      windowId: requestData.windowId,
      params: {
        subject: requestData.subject,
        search: requestData.search,
        warehouse: store.state.activeWarehouse,
      },
    },
  );
}

/**
 * Saves the datagrid table with given windowId.
 * @param requestData {Object}
 * @param requestData.subject {String}
 * @param requestData.data {Array}
 * @param requestData.windowId {String}
 * @returns {Promise<Object>}
 */
export async function saveTable(requestData) {
  return await api.post(`/v2/datagrid/save`, requestData.data, {
    windowId: requestData.windowId,
    params: {
      subject: requestData.subject,
      warehouse: store.state.activeWarehouse,
    },
  });
}

/**
 * Changes the datagrid table row with given windowId.
 * @param requestData {Object}
 * @param requestData.subject {String}
 * @param requestData.data {Array}
 * @param requestData.windowId {String}
 * @returns {Promise<Object>}
 */
export async function changeRow(requestData) {
  return await api.post(`/v2/datagrid/change`, requestData.data, {
    windowId: requestData.windowId,
    params: {subject: requestData.subject},
  });
}
