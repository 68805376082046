var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login cover v-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"box pull-center"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"logo"},[_c('span',{class:[
              'logo-icon',
              'infodatek-icon',
              'infodatek-icon-' + (_vm.session.product || '').toLowerCase(),
            ]}),_c('br'),_c('span',{staticClass:"logo-text"},[_vm._v(_vm._s(_vm.session.product))])]),_c('div',{staticClass:"form-element"},[_c('select',{attrs:{"name":"language","autofocus":""},on:{"change":function($event){return _vm.session.switchLanguage($event.target.value)}}},_vm._l((_vm.session.languages),function(lang){return _c('option',{key:lang.ID,domProps:{"value":lang.ID,"selected":lang.ID == _vm.session.language}},[_vm._v(" "+_vm._s(lang.Name)+" ")])}),0)]),(_vm.error)?_c('div',{staticClass:"error-box"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('div',[_c('div',{staticClass:"form-element"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],attrs:{"type":"text","name":"username","required":"","autocomplete":"username","placeholder":_vm.session.translations.UserName},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}})]),_c('div',{staticClass:"form-element with-side"},[_c('div',{class:{
                'show-password': true,
                'material-icons': true,
              }},[_vm._v("  ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","name":"password","autocomplete":"current-password","required":"","placeholder":_vm.translations.Password},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_c('div',{staticClass:"form-element"},[_c('button',{staticClass:"button button-default login-button",attrs:{"name":"login","type":"submit"}},[_vm._v(" "+_vm._s(_vm.translations.LogIn)+" ")])]),(_vm.welcomeInfo.SSOConfigured)?_c('div',{staticClass:"form-element"},[_c('a',{staticClass:"button button-default login-button",attrs:{"name":"logingooglesso","type":"submit","href":_vm.welcomeInfo.SSOUrl}},[_vm._v(" "+_vm._s(_vm.welcomeInfo.SSOTitle)+" ")])]):_vm._e(),_c('div',{staticClass:"form-element"},[_c('button',{staticClass:"button button-menu password-lost-button",on:{"click":_vm.showRequestResetPassword}},[_vm._v(" "+_vm._s(_vm.translations.ForgotPassword)+" ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }