
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/timeline.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"content\" style=\"height: 100%\">\n\t<form class=\"form-view grid row\">\n\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t<label class=\"form-field\">\n\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"StartDate"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t<div class=\"form-input required-field date-picker\" data-replace=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"customData")),"startDate"), env.opts.autoescape);
output += "\" data-type=\"Date\">\n\t\t\t\t\t<input type=\"text\" name=\"StartDate\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"customData")),"startDate"), env.opts.autoescape);
output += "\" class=\"editable-text no-field date\">\n\t\t\t\t</div>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t<label class=\"form-field\">\n\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"EndDate"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t<div class=\"form-input required-field date-picker\" data-replace=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"customData")),"endDate"), env.opts.autoescape);
output += "\" data-type=\"Date\">\n\t\t\t\t\t<input type=\"text\" name=\"EndDate\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"customData")),"endDate"), env.opts.autoescape);
output += "\" class=\"editable-text no-field date\">\n\t\t\t\t</div>\n\t\t\t</label>\n\t\t</div>\n\n\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"customData")),"searchAvailable")) {
output += "\n\t\t<div class=\"col col-xs-12 col-sm-6 col-md-3\">\n\t\t\t<label class=\"form-field\">\n\t\t\t\t<div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ModalDayPlannerSearchTitle"), env.opts.autoescape);
output += ":</div>\n\t\t\t\t<div class=\"form-input\" data-type=\"String\">\n\t\t\t\t\t<input type=\"text\" name=\"SearchTerm\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"customData")),"searchTerm"), env.opts.autoescape);
output += "\" class=\"editable-text\">\n\t\t\t\t</div>\n\t\t\t</label>\n\t\t</div>\n\t\t";
;
}
output += "\n\n\t</form>\n\t<div class=\"row\">\n\t\t<div class=\"col col-12\">\n\t\t\t<div class=\"chart\"></div>\n\t\t</div>\n\t</div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/timeline.html", ctx);
          }
        
          return nunjucks.render("views/content/timeline.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/timeline.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        