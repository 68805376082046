
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/dateview.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"content\">\n    <div class=\"calendar-view fill dateview\">\n        ";
frame = frame.push();
var t_3 = [["Juli",31],["Augustus",31],["September",30]];
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("month", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n        <div class=\"month\">\n            <div class=\"month-name\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((t_4),0), env.opts.autoescape);
output += "\n            </div>\n\n            <div class=\"days\">\n            ";
frame = frame.push();
var t_7 = (lineno = 13, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [1,runtime.memberLookup((t_4),1) + 1]));
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("day", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n            <div class=\"day-state\">\n                <div class=\"day-name\">";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "</div>\n                <div class=\"day-status\">\n                    <div class=\"status\">3</div>\n                </div>\n                \n            </div>\n            ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n\n        </div>\n        ";
;
}
}
frame = frame.pop();
output += "\n    </div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/dateview.html", ctx);
          }
        
          return nunjucks.render("views/content/dateview.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/dateview.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        