import $ from "jquery";
import "bootstrap4-notify";
import Hook from "../hook";
import modalContentTemplate from "../../views/content/modals/modalGeneric.html";
import ModalImage from "../hooks/Modals/modalImage";
import {newVueModal} from "./vueModalInjector";
import {notify} from "../util/notify";
import {generateID} from "../util/generateID";

window.mailTemplates = {};
window.mailToAddresses = [];
window.mailTemplate = "";

/**
 * Modal class contains:
 * - modal instances (objects).
 * - listeners for modal related behaviors.
 * - listeners for modal view specific behaviors.
 * - views in modals usually are fully custom, and therefore are not always dynamic
 */
export default class modal {
  constructor() {
    let modalImageInstance = new ModalImage();
    modalImageInstance.registerEventListeners();

    /**
     * beforeMessage hook
     *
     * Currently contains a series of conditionals based on event types.
     * Each event type (could) result in showing a different modal content
     * TODO: make less messy
     */
    Hook.register("beforeMessage", this, async (window, event) => {
      let modalTitle = "";
      let modalSize = "xl";

      /**
       * Register listeners for Modal Images
       */

      if (
        event.args &&
        event.args.fields &&
        event.args.fields[0].Name != "MailSendDialog"
      ) {
        modalSize = "sm";
      }

      if (
        event.args &&
        event.args.fields &&
        window.session.translations.ModalSendMailTitle
      ) {
        modalTitle = `${event.args.fields[4]} ${window.session.translations.ModalSendMailTitle}`;
      }

      const html = await modalContentTemplate(event);

      const modalBodyID = generateID(5);
      if (modalTitle != "") {
        event.form = await newVueModal({
          randomID: generateID(5),
          modalBodyID,
          modalTitle,
          modalSize,
          html,
          event,
          actionName: window.lastActionArgs[0],
          selectionInfo: window.input,
        });
      }

      if (
        event.type === "error" &&
        event.type === "warning" &&
        event.args &&
        event.args.fields
      ) {
        notify({message: event.message, type: event.type});
      }
    });
  }

  /**
   * emailValidation
   *
   * Validates the provided email address using a Regex match
   * @param {string} email  An email address to validate on its validity
   * @returns {boolean} True or false
   */
  emailValidation(email) {
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  /**
   * notify
   *
   * Sends a message in the model when the element exists
   * @param {string} message The message displayed in the element
   * @returns {void}
   */
  notify(message) {
    $(document).find("#notifyDialogContent").html(message);
    $(document).find("#notifyDialog").show();
  }
}
