import {alertPopup} from "../../interface/alertPopup/alertPopup.js";

export default async function confirm(
  text,
  responseOnConfirm,
  responseOnCancel,
) {
  try {
    let confirmationObject = {
      text: text,
      icon: "warning",
      dangerMode: true, // setting dangerMode to true makes the Cancel button bigger
      buttons: [global.session.translations.Cancel, true],
    };

    if (!(await alertPopup(confirmationObject))) {
      if (responseOnCancel) {
        this.handleActionResponse(responseOnCancel);
      }
      return;
    }
  } catch (error) {
    console.error(error);
  }

  if (responseOnConfirm) {
    this.handleActionResponse(responseOnConfirm);
  }
}
