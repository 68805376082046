
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("./inputField.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/form/formElement.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/content/form/inputField.html", false, "views/content/form/formElement.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("inputField", t_1);
output += "\n";
var macro_t_4 = runtime.makeMacro(
["data", "col", "rowIndex", "window"], 
[], 
function (l_data, l_col, l_rowIndex, l_window, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", l_data);
frame.set("col", l_col);
frame.set("rowIndex", l_rowIndex);
frame.set("window", l_window);
var t_5 = "";t_5 += "\n  ";
var t_6;
t_6 = null;
frame.set("coltype", t_6, true);
if(frame.topLevel) {
context.setVariable("coltype", t_6);
}
if(frame.topLevel) {
context.addExport("coltype", t_6);
}
t_5 += "\n  ";
var t_7;
t_7 = null;
frame.set("value", t_7, true);
if(frame.topLevel) {
context.setVariable("value", t_7);
}
if(frame.topLevel) {
context.addExport("value", t_7);
}
t_5 += "\n  ";
var t_8;
t_8 = null;
frame.set("cell", t_8, true);
if(frame.topLevel) {
context.setVariable("cell", t_8);
}
if(frame.topLevel) {
context.addExport("cell", t_8);
}
t_5 += "\n  ";
var t_9;
t_9 = runtime.memberLookup((l_col),"Initial");
frame.set("initial", t_9, true);
if(frame.topLevel) {
context.setVariable("initial", t_9);
}
if(frame.topLevel) {
context.addExport("initial", t_9);
}
t_5 += "\n  ";
var t_10;
t_10 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("index", t_10, true);
if(frame.topLevel) {
context.setVariable("index", t_10);
}
if(frame.topLevel) {
context.addExport("index", t_10);
}
t_5 += "\n\n  ";
var t_11;
t_11 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Table")),"identifiedRows")),l_rowIndex);
frame.set("cell", t_11, true);
if(frame.topLevel) {
context.setVariable("cell", t_11);
}
if(frame.topLevel) {
context.addExport("cell", t_11);
}
t_5 += "\n  ";
l_col = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cell")),"Column");
frame.set("col", l_col, true);
if(frame.topLevel) {
context.setVariable("col", l_col);
}
if(frame.topLevel) {
context.addExport("col", l_col);
}
t_5 += "\n  ";
var t_12;
t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cell")),"Value");
frame.set("value", t_12, true);
if(frame.topLevel) {
context.setVariable("value", t_12);
}
if(frame.topLevel) {
context.addExport("value", t_12);
}
t_5 += "\n  ";
var t_13;
t_13 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cell")),"Initial");
frame.set("initial", t_13, true);
if(frame.topLevel) {
context.setVariable("initial", t_13);
}
if(frame.topLevel) {
context.addExport("initial", t_13);
}
t_5 += "\n  ";
var t_14;
t_14 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cell")),"Type") || runtime.memberLookup((l_col),"Type");
frame.set("coltype", t_14, true);
if(frame.topLevel) {
context.setVariable("coltype", t_14);
}
if(frame.topLevel) {
context.addExport("coltype", t_14);
}
t_5 += "\n  ";
var t_15;
t_15 = runtime.memberLookup((l_col),"ColumnOffSet");
frame.set("colOffset", t_15, true);
if(frame.topLevel) {
context.setVariable("colOffset", t_15);
}
if(frame.topLevel) {
context.addExport("colOffset", t_15);
}
t_5 += "\n  ";
var t_16;
t_16 = runtime.memberLookup((l_col),"ColumnWidth");
frame.set("colWidth", t_16, true);
if(frame.topLevel) {
context.setVariable("colWidth", t_16);
}
if(frame.topLevel) {
context.addExport("colWidth", t_16);
}
t_5 += "\n\n  <div class=\"col-xs-12 col-md-";
t_5 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "colWidth")?runtime.contextOrFrameLookup(context, frame, "colWidth"):"4"), env.opts.autoescape);
t_5 += "\">\n    <label class=\"form-field ";
t_5 += runtime.suppressValue((runtime.memberLookup((l_col),"Editor") == "ckeditor"?"fluid-height":""), env.opts.autoescape);
t_5 += "\">\n      ";
if(runtime.memberLookup((l_col),"Editor") == "ckeditor") {
t_5 += "\n      </label>\n    ";
;
}
t_5 += "\n\n    <div class=\"form-label flex ";
t_5 += runtime.suppressValue((runtime.memberLookup((l_col),"IsPrimary")?" primary":""), env.opts.autoescape);
t_5 += "  ";
t_5 += runtime.suppressValue((runtime.memberLookup((l_col),"Editor") == "ckeditor"?"ml-1":""), env.opts.autoescape);
t_5 += "\" title=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_col),"Title"), env.opts.autoescape);
t_5 += "\">\n      <div class=\"overflow-hidden text-ellipsis\">\n        ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_col),"Title")), env.opts.autoescape);
t_5 += ":\n      </div>\n\n      ";
if(runtime.memberLookup((l_col),"IsRequired") && runtime.contextOrFrameLookup(context, frame, "coltype") != "Boolean") {
t_5 += "\n        <span class=\"required-indicator ml-2 w-10\" title=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
t_5 += "\">*</span>\n      ";
;
}
t_5 += "\n      \n\n      ";
if(runtime.memberLookup((l_col),"FieldDescription") != null) {
t_5 += "\n        <a class=\"hint-button\" data-window-event=\"showHint:";
t_5 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_5 += "\" >(?)</a>\n      ";
;
}
t_5 += "\n    </div>\n\n    ";
if((runtime.contextOrFrameLookup(context, frame, "coltype") != "text")) {
t_5 += "\n      <div class=\"form-input ";
t_5 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "coltype") == "Money"?"money":""), env.opts.autoescape);
t_5 += "\">\n      ";
if((runtime.memberLookup((l_col),"Editor") == "ckeditor" && runtime.memberLookup((l_col),"IsReadOnly"))) {
t_5 += "\n        <div class=\"alert alert-light\" role=\"alert\">\n          ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "value")), env.opts.autoescape);
t_5 += "\n        </div>\n      ";
;
}
else {
t_5 += "\n          ";
t_5 += runtime.suppressValue((lineno = 46, colno = 40, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "inputField")),"createInputField"), "inputField[\"createInputField\"]", context, [runtime.memberLookup((l_window),"session"),l_col,runtime.contextOrFrameLookup(context, frame, "coltype"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "initial"),runtime.contextOrFrameLookup(context, frame, "index"),runtime.contextOrFrameLookup(context, frame, "cell"),l_window])), env.opts.autoescape);
t_5 += "\n      ";
;
}
t_5 += "\n        <small id=\"emailHelp\" class=\"form-text text-muted whitespace-normal ";
t_5 += runtime.suppressValue((runtime.memberLookup((l_col),"Comment") == null?"d-none":""), env.opts.autoescape);
t_5 += "\">\n          <i class=\"fas fa-info\"></i>\n          ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_col),"Comment")), env.opts.autoescape);
t_5 += "\n        </small>\n      </div>\n    ";
;
}
else {
t_5 += "\n      <div class=\"form-group\">\n        ";
t_5 += runtime.suppressValue((lineno = 55, colno = 38, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "inputField")),"createInputField"), "inputField[\"createInputField\"]", context, [runtime.memberLookup((l_window),"session"),l_col,runtime.contextOrFrameLookup(context, frame, "coltype"),runtime.contextOrFrameLookup(context, frame, "value"),runtime.contextOrFrameLookup(context, frame, "initial"),runtime.contextOrFrameLookup(context, frame, "index"),runtime.contextOrFrameLookup(context, frame, "cell"),l_window])), env.opts.autoescape);
t_5 += "\n        <small id=\"emailHelp\" class=\"form-text text-muted whitespace-normal ";
t_5 += runtime.suppressValue((runtime.memberLookup((l_col),"Comment") == null?"d-none":""), env.opts.autoescape);
t_5 += "\">\n          <i class=\"fas fa-info\"></i>\n          ";
t_5 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((l_col),"Comment")), env.opts.autoescape);
t_5 += "\n        </small>\n      </div>\n    ";
;
}
t_5 += "\n  </label>\n</div>\n\n  ";
if(runtime.contextOrFrameLookup(context, frame, "colOffset") > 0) {
t_5 += "\n    <div class=\"col-xs-12 col-md-";
t_5 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "colOffset"), env.opts.autoescape);
t_5 += "\"></div>\n  ";
;
}
t_5 += "\n\n  ";
if(runtime.contextOrFrameLookup(context, frame, "colOffset") === null && runtime.contextOrFrameLookup(context, frame, "colWidth")) {
t_5 += "\n    <div class=\"d-none d-md-block col-md-6\"></div>\n  ";
;
}
t_5 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("createFormElement");
context.setVariable("createFormElement", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/form/formElement.html", ctx);
          }
        
          return nunjucks.render("views/content/form/formElement.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/form/formElement.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        