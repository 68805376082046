import $ from "jquery";

/**
 * Window drag logic, not used if kiosk mode is phased out
 * @deprecated
 * @property {Array} callbacks - Array of callbacks
 * @property {Element} element - Element reference
 * @property {boolean} active - If active
 * @property {boolean} enabled - If enabled
 * @property {number} startX - Starting x position
 * @property {number} startY - Starting y position
 * @property {number} x - Current x position
 * @property {number} y - Current y position
 */
export default class Drag {
  callbacks = [];
  element = null;
  active = false;
  enabled = true;
  startX = 0;
  startY = 0;
  x = null;
  y = null;

  /**
   * @param {function | null} calcStart - Calculation starting function
   * @param {*} moveTarget - Target
   * @param {*} handleSelector - Handle CSS selector
   * @param {*} targetSelector - Target CSS selector
   * @param {*} exact - Should target match handle selector
   */
  constructor(calcStart, moveTarget, handleSelector, targetSelector, exact) {
    $(document).on("mousedown", handleSelector, (e) => {
      if (!this.enabled) {
        return;
      }

      if (exact && !$(e.target).is(handleSelector)) {
        return;
      }

      e.preventDefault();

      this.element = e.target;
      this.active = true;
      let scale = 1 / ($("body").css("zoom") || 1);
      this.x = e.pageX * scale;
      this.y = e.pageY * scale;

      let target =
        typeof targetSelector == "function"
          ? targetSelector(e.target)
          : $(targetSelector).get(0);

      if (typeof calcStart == "function") {
        [this.startX, this.startY] = calcStart.call(this, target);
      }
    });

    $(document).on("mousemove", (e) => {
      if (!this.enabled || !e.which) {
        this.active = false;
      }

      if (!this.active) {
        return;
      }

      let scale = 1 / ($("body").css("zoom") || 1);
      let x = this.startX + e.pageX * scale - this.x;
      let y = this.startY + e.pageY * scale - this.y;

      let target =
        typeof targetSelector == "function"
          ? targetSelector(this.element)
          : $(targetSelector).get(0);

      moveTarget.call(this, target, x, y);

      for (let cb of this.callbacks) {
        setTimeout(() => cb(target));
      }
    });

    $(document).on("mouseup", () => {
      this.active = false;
    });
  }

  /**
   * Background
   * @param {Array} args - arguments
   * @returns {void}
   */
  static background(...args) {
    return new Drag(
      function calcStart(target) {
        return $(target)
          .css("background-position")
          .split(" ")
          .map((s) => parseInt(s, 10) || 0);
      },
      function moveTarget(target, x, y) {
        $(target).css("background-position", `${x}px ${y}px`);
      },
      ...args,
    );
  }

  /**
   * Resize dragging
   * @param {Array} args - arguments
   * @returns {void}
   */
  static resize(...args) {
    return new Drag(
      function calcStart(target) {
        return [$(target).width(), $(target).height()];
      },
      function moveTarget(target, w, h) {
        let finalMode = $(this.element).data("resize-mode");

        if (finalMode === "width" || finalMode === "both") {
          $(target).css("width", `${w}px`);
        }

        if (finalMode === "height" || finalMode === "both") {
          $(target).css("height", `${h}px`);
        }
      },
      ...args,
    );
  }

  /**
   * Absolute
   * @param {Array} args - arguments
   * @returns {void}
   */
  static absolute(...args) {
    return new Drag(
      function calcStart(target) {
        return [target.offsetLeft, target.offsetTop];
      },
      function moveTarget(target, x, y) {
        $(target).css({
          left: `${x}px`,
          top: `${y}px`,
        });
      },
      ...args,
    );
  }

  /**
   * Add callback
   * @param {function} fn - Callback function
   * @returns {void}
   */
  callback(fn) {
    this.callbacks.push(fn);
    return this;
  }
}
