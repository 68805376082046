<template>
  <div class="login cover v-center">
    <div class="container">
      <div class="box pull-center">
        <form @submit.prevent="submit">
          <div class="logo">
            <span
              :class="[
                'logo-icon',
                'infodatek-icon',
                'infodatek-icon-' + (session.product || '').toLowerCase(),
              ]"
            ></span
            ><br />
            <span class="logo-text">{{ session.product }}</span>
          </div>
          <div class="flex justify-start mb-8">
            <strong class="text-lg">
              {{ translations.BackUpCodeUsedTitle }}
            </strong>
          </div>
          <div v-if="error" class="error-box">
            {{ error }}
          </div>
          <div class="text-center mb-12">
            <p>
              {{ translations.BackUpCodeUsedDescription }}
            </p>
          </div>
          <div>
            <div class="form-element">
              <button
                name="login"
                class="button button-default login-button"
                type="submit"
                @click="resetTOTP()"
              >
                {{ translations.Reset }}
              </button>
            </div>
            <div class="form-element">
              <button
                name="login"
                class="button button-default login-button"
                type="submit"
                @click="skip()"
              >
                {{ translations.Continue }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import postResetTOTP from "@/services/totp/postResetTOTP";

export default {
  name: "LoginAfterBackupCode",
  props: {
    session: {
      type: Object,
      default: () => {},
    },
    loginToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      totpCode: null,
      error: null,
      successMessage: "",
      verificationToken: "",
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  methods: {
    async resetTOTP() {
      await postResetTOTP();

      this.$emit("showComponent", "loginTwoFactorSetup");
    },
    skip() {
      this.$emit("showComponent", "loginSessionList");
    },
  },
};
</script>

<style scoped></style>
