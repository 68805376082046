<template>
  <div class="login cover v-center">
    <div class="container">
      <div class="box pull-center">
        <form @submit.prevent="submit">
          <div class="logo">
            <span
              :class="[
                'logo-icon',
                'infodatek-icon',
                'infodatek-icon-' + (session.product || '').toLowerCase(),
              ]"
            ></span
            ><br />
            <span class="logo-text">{{ session.product }}</span>
          </div>
          <div>
            <div class="form-element with-side">
              <div class="show-password material-icons">&#xe8f4;</div>
              <input
                v-model="password"
                type="password"
                name="password"
                :placeholder="translations.Password"
              />
            </div>

            <div class="form-element with-side">
              <div class="show-password material-icons">&#xe8f4;</div>
              <input
                v-model="confirmPassword"
                type="password"
                name="confirmPassword"
                :placeholder="translations.ConfirmPassword"
              />
            </div>

            <div class="form-element">
              <button
                class="button button-default password-reset-button"
                type="submit"
              >
                {{ translations.ChangePassword }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import postValidateResetPasswordCode from "@/services/authentication/postValidateResetPasswordCode.js";
import postResetPassword from "@/services/authentication/postResetPassword.js";

export default {
  name: "LoginResetPassword",
  props: {
    session: {
      type: Object,
      default: () => {},
    },
    loginToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      resetData: {
        userResetCode: null,
        userID: null,
      },
      password: null,
      confirmPassword: null,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  created: async function () {
    this.readResetData();

    await this.validateResetPasswordCode();
  },
  methods: {
    readResetData() {
      const url = location.hash.substring(1);

      if (url !== "" && url.includes("userResetCode")) {
        this.$data.resetData = JSON.parse(
          '{"' +
            decodeURI(url)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}',
        );
      }

      location.hash = "";
    },
    async validateResetPasswordCode() {
      const response = await postValidateResetPasswordCode({
        code: this.$data.resetData.userResetCode,
        userID: this.$data.resetData.userID,
      });

      if (response.status == 204) {
        return;
      } else if (response.status == 200) {
        this.$emit("showComponent", "loginPage", response.data.Error);
      } else {
        this.$emit(
          "showComponent",
          "loginPage",
          this.translations.UnknownError,
        );
      }
    },
    async submit() {
      this.$data.error = "";

      if (this.$data.password != this.$data.confirmPassword) {
        this.$data.error = this.translations.ExceptionPasswordMismatch;
      }

      const response = await postResetPassword({
        code: this.$data.resetData.userResetCode,
        userID: this.$data.resetData.userID,
        password: this.$data.password,
      });

      if (response.status == 200) {
        this.$emit(
          "showComponent",
          "loginPage",
          response.data.Message ?? response.data.Error,
        );
      } else {
        this.$emit(
          "showComponent",
          "loginPage",
          this.translations.UnknownError,
        );
      }
    },
  },
};
</script>

<style scoped></style>
