
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/dynamicdashboard.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"container-fluid\">\n    <div class=\"page-header mt-4\">        \n        <h1>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"Title"), env.opts.autoescape);
output += "</h1>\n        <hr>\n    </div>\n    <div class=\"row\">\n        ";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"DashboardTiles");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("dashboardTile", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "        \n        <div class=\"col col-12 col-md-6 col-lg-4 mb-4\" \n            ";
if(runtime.memberLookup((t_4),"Url")) {
output += " onclick=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"Url"), env.opts.autoescape);
output += "\" ";
;
}
output += ">\n                <div \n                ";
if(runtime.memberLookup((t_4),"Highlighted")) {
output += " \n                    class=\"card ";
output += runtime.suppressValue((runtime.memberLookup((t_4),"MenuID")?"panel-dashboard-pointer":""), env.opts.autoescape);
output += " card-dashboard card-primary ";
output += runtime.suppressValue((runtime.memberLookup((t_4),"Url")?"disabled":""), env.opts.autoescape);
output += "\" \n                ";
;
}
else {
output += " \n                    class=\"card ";
output += runtime.suppressValue((runtime.memberLookup((t_4),"MenuID")?"panel-dashboard-pointer":""), env.opts.autoescape);
output += " card-dashboard card-default ";
output += runtime.suppressValue((runtime.memberLookup((t_4),"Url")?"disabled":""), env.opts.autoescape);
output += "\" \n                ";
;
}
output += "\n                ";
if(runtime.memberLookup((t_4),"MenuID")) {
output += " \n                    data-menu-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"MenuID"), env.opts.autoescape);
output += "\" \n                ";
;
}
output += ">\n                ";
if(runtime.memberLookup((t_4),"Highlighted")) {
output += " \n                    <div class=\"card-body bg-primary text-white\">\n                ";
;
}
else {
output += "\n                    <div class=\"card-body bg-secondary \">\n                ";
;
}
output += "\n                    <div class=\"row align-items-center\">\n                        <div class=\"col col-2\">\n                            <i class=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"Icon"), env.opts.autoescape);
output += " fa-3x\" aria-hidden=\"true\"></i>\n                        </div>\n                        <div class=\"col col-4 text-right\">\n                            <h1>";
output += runtime.suppressValue(runtime.memberLookup((t_4),"Amount"), env.opts.autoescape);
output += "</h1>\n                        </div>\n                        <div class=\"col col-6\">\n                            <h3 class=\"text-right\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"Title"), env.opts.autoescape);
output += "</h3>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        ";
;
}
}
frame = frame.pop();
output += "\n    </div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/dynamicdashboard.html", ctx);
          }
        
          return nunjucks.render("views/content/dynamicdashboard.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/dynamicdashboard.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        