import Hook from "@/hook";
import {dirtyCheckWithPrompt} from "@/interface/window/dirty";
import store from "../../../state/store";

/**
 * Close and remove window and optioinally the parent object
 * @see {@link Canvas}
 * @see {@link Session}
 * @param {boolean} shouldConfirm - If confirmation is needed to close the window
 * @param {boolean} keepTabSelection - If the window is being reloaded
 * @param {boolean} tabDeleted - If the tab reference is already gone
 * @returns {void}
 */
export default async function disposeParent(
  shouldConfirm = true,
  keepTabSelection = false,
  tabDeleted = false,
) {
  // Run hookable event
  Hook.run("dispose", this, async (e) => {
    try {
      if (
        shouldConfirm === true &&
        (await dirtyCheckWithPrompt(this)) === false
      ) {
        e.cancel = true;
        return;
      }
    } catch (error) {
      console.error(error);
    }

    // If we're working from a tab remove parent window to
    if (this.parent) {
      // remove combobox references
      this.parent.removeComboboxReferences();

      // delete reference from session
      delete this.parent.session.windows[this.parent.id];

      // remove self from session tab
      let index = this.parent.session.tabs.indexOf(this.parent);
      if (index > -1 && !tabDeleted) {
        store.dispatch("removeTabByIndex", index);
      }
    }

    // remove combobox references
    this.removeComboboxReferences();

    // delete reference from session
    delete this.session.windows[this.id];

    // remove self from session tab
    let index = this.session.tabs.indexOf(this);
    if (index > -1 && !tabDeleted) {
      store.dispatch("removeTabByIndex", index);
    }

    // If we are the active window
    if (
      this.session.activeWindow === this ||
      (this.session.activeWindow.sub &&
        this.session.activeWindow.sub.window === this)
    ) {
      // Focus a new window

      this.session.activeWindow = null;

      let newIndex = Math.max(index - 1, 0);
      let newWindow = this.session.tabs[newIndex];

      if (newWindow) {
        newWindow.focus();
      }
    }
    store.commit("updateWindow");
  });
}
