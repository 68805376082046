
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/content.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["type", "text"], 
[], 
function (l_type, l_text, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("type", l_type);
frame.set("text", l_text);
var t_2 = "";if(l_type == "text") {
t_2 += "\n    ";
t_2 += runtime.suppressValue(l_text, env.opts.autoescape);
t_2 += "\n  ";
;
}
t_2 += "\n\n  ";
if(l_type == "info") {
t_2 += "\n    <div class=\"alert alert-info\" role=\"alert\">\n      ";
t_2 += runtime.suppressValue(l_text, env.opts.autoescape);
t_2 += "\n    </div>\n  ";
;
}
t_2 += "\n\n  ";
if(l_type == "warning") {
t_2 += "\n    <div class=\"alert alert-warning\" role=\"alert\">\n      ";
t_2 += runtime.suppressValue(l_text, env.opts.autoescape);
t_2 += "\n    </div>\n  ";
;
}
t_2 += "\n\n  ";
if(l_type == "danger") {
t_2 += "\n    <div class=\"alert alert-danger\" role=\"alert\">\n      ";
t_2 += runtime.suppressValue(l_text, env.opts.autoescape);
t_2 += "\n    </div>\n  ";
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("constructContent");
context.setVariable("constructContent", macro_t_1);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/content.html", ctx);
          }
        
          return nunjucks.render("views/content/content.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/content.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        