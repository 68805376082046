<template>
  <div
    :class="[
      'relative',
      'flex-col',
      'w-full',
      'px-[15px]',
      'min-h-[1px]',
      'md:grow-0',
      'md:shrink-0',
      computedLayoutClasses,
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
import Decimal from "decimal.js";

export default {
  name: "RCol",
  props: {
    cols: {
      type: Number,
      default: 12,
    },
    sm: {
      type: Number,
      default: null,
    },
    md: {
      type: Number,
      default: null,
    },
    lg: {
      type: Number,
      default: null,
    },
    xl: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      layoutClasses: "",
    };
  },
  computed: {
    componentProps() {
      return Object.keys(this.$props);
    },
    computedLayoutClasses() {
      this.renderLayoutClasses();
      return this.layoutClasses;
    },
  },
  methods: {
    calculateCols(cols) {
      return new Decimal((100 / 12) * cols).d[0];
    },
    renderLayoutClasses() {
      for (const prop of this.componentProps) {
        if (prop == "cols") {
          this.layoutClasses += `max-w-[${this.calculateCols(
            this[prop],
          )}%] basis-[${this.calculateCols(this[prop])}%] `;
          continue;
        }

        if (this[prop]) {
          this.layoutClasses += `${prop}:max-w-[${this.calculateCols(
            this[prop],
          )}%] ${prop}:basis-[${this.calculateCols(this[prop])}%] `;
        }
      }
    },
  },
};

// // Tailwind purge prevention

// // For Max-width
//   "sm:max-w-[100%]",
//   "sm:max-w-[91%]",
//   "sm:max-w-[83%]",
//   "sm:max-w-[75%]",
//   "sm:max-w-[58%]",
//   "sm:max-w-[66%]",
//   "sm:max-w-[50%]",
//   "sm:max-w-[41%]",
//   "sm:max-w-[33%]",
//   "sm:max-w-[25%]",
//   "sm:max-w-[16%]",
//   "sm:max-w-[8%]",
//   "sm:max-w-[0%]",
//   "md:max-w-[100%]",
//   "md:max-w-[91%]",
//   "md:max-w-[83%]",
//   "md:max-w-[75%]",
//   "md:max-w-[58%]",
//   "md:max-w-[66%]",
//   "md:max-w-[50%]",
//   "md:max-w-[41%]",
//   "md:max-w-[33%]",
//   "md:max-w-[25%]",
//   "md:max-w-[16%]",
//   "md:max-w-[8%]",
//   "md:max-w-[0%]",
//   "lg:max-w-[100%]",
//   "lg:max-w-[91%]",
//   "lg:max-w-[83%]",
//   "lg:max-w-[75%]",
//   "lg:max-w-[58%]",
//   "lg:max-w-[66%]",
//   "lg:max-w-[50%]",
//   "lg:max-w-[41%]",
//   "lg:max-w-[33%]",
//   "lg:max-w-[25%]",
//   "lg:max-w-[16%]",
//   "lg:max-w-[8%]",
//   "lg:max-w-[0%]",
//   "xl:max-w-[100%]",
//   "xl:max-w-[91%]",
//   "xl:max-w-[83%]",
//   "xl:max-w-[75%]",
//   "xl:max-w-[58%]",
//   "xl:max-w-[66%]",
//   "xl:max-w-[50%]",
//   "xl:max-w-[41%]",
//   "xl:max-w-[33%]",
//   "xl:max-w-[25%]",
//   "xl:max-w-[16%]",
//   "xl:max-w-[8%]",
//   "xl:max-w-[0%]",

//   // For flex-basis
//   "sm:flex-basis-[100%]",
//   "sm:flex-basis-[83%]",
//   "sm:flex-basis-[91%]",
//   "sm:flex-basis-[75%]",
//   "sm:flex-basis-[58%]",
//   "sm:flex-basis-[66%]",
//   "sm:flex-basis-[50%]",
//   "sm:flex-basis-[41%]",
//   "sm:flex-basis-[33%]",
//   "sm:flex-basis-[25%]",
//   "sm:flex-basis-[16%]",
//   "sm:flex-basis-[8%]",
//   "sm:flex-basis-[0%]",
//   "md:flex-basis-[100%]",
//   "md:flex-basis-[91%]",
//   "md:flex-basis-[83%]",
//   "md:flex-basis-[75%]",
//   "md:flex-basis-[58%]",
//   "md:flex-basis-[66%]",
//   "md:flex-basis-[50%]",
//   "md:flex-basis-[41%]",
//   "md:flex-basis-[33%]",
//   "md:flex-basis-[25%]",
//   "md:flex-basis-[16%]",
//   "md:flex-basis-[8%]",
//   "md:flex-basis-[0%]",
//   "lg:flex-basis-[100%]",
//   "lg:flex-basis-[91%]",
//   "lg:flex-basis-[83%]",
//   "lg:flex-basis-[75%]",
//   "lg:flex-basis-[58%]",
//   "lg:flex-basis-[66%]",
//   "lg:flex-basis-[50%]",
//   "lg:flex-basis-[41%]",
//   "lg:flex-basis-[33%]",
//   "lg:flex-basis-[25%]",
//   "lg:flex-basis-[16%]",
//   "lg:flex-basis-[8%]",
//   "lg:flex-basis-[0%]",
//   "xl:flex-basis-[100%]",
//   "xl:flex-basis-[91%]",
//   "xl:flex-basis-[83%]",
//   "xl:flex-basis-[75%]",
//   "xl:flex-basis-[58%]",
//   "xl:flex-basis-[66%]",
//   "xl:flex-basis-[50%]",
//   "xl:flex-basis-[41%]",
//   "xl:flex-basis-[33%]",
//   "xl:flex-basis-[25%]",
//   "xl:flex-basis-[16%]",
//   "xl:flex-basis-[8%]",
//   "xl:flex-basis-[0%]",
</script>
