import {api} from "./api";

export async function resetAll() {
  const result = await api.patch(`/v2/maintenance/reset`);

  return result;
}

export async function resetTranslations() {
  const result = await api.patch(`/v2/maintenance/reset/translations`);

  return result;
}

export async function resetSettings(){
  const result = await api.patch(`/v2/maintenance/reset/translations`);

  return result;
}

export async function resetRoles() {
  const result = await api.patch(`/v2/maintenance/reset/translations`);

  return result;
}

export async function resetColumns() {
  const result = await api.patch(`/v2/maintenance/reset/translations`);

  return result;
}
