/**
 * Remove combobox references
 * @returns {void}
 */
export default function removeComboboxReferences() {
  // If there are rows
  if (
    this.output &&
    this.output.Table &&
    this.output.Table.Rows &&
    this.output.Table.Rows.length
  ) {
    this.output.Table.Rows.forEach((row) => {
      // remove each row's combobox
      row.forEach((column) => {
        if (column.Combobox) {
          column.Combobox.remove();
        }
      });
    });
  }
}
