import $ from "jquery";

/**
 * Create window based on criteria
 * @param {string} tableName - Table name
 * @param {Object} rowCriteria - Row critiria object
 * @deprecated
 * @returns {Promise} To create window
 */
export default async function openCriteria(tableName, prefix, rowCriteria) {
  $("body").addClass("avoid-clicks");

  await this.session.openWindow({
    Subject: tableName,
    Prefix: prefix ?? "Single",
    Criteria: [rowCriteria],
  });
  $("body").removeClass("avoid-clicks");
}
