/**
 * Get Columns
 * @returns {Array} List of searchable Columns
 */
export default function getSearchColumns() {
  return (
    (this.output &&
      this.output.Table &&
      this.output.Table.Columns.filter(
        (x) =>
          x.CanSortOn &&
          ["String", "Int", "Decimal", "Money", "DateTime", "Date"].indexOf(
            x.Type,
          ) >= 0,
      )) ||
    []
  );
}
