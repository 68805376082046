<template>
  <h4>
    <span
      class="rounded-md"
      :class="titleClasses"
      :style="titleStyling"
      v-html="title"
    />
    <small>{{ subTitle }}</small>
  </h4>
</template>

<script>
import {getCachedRowColorOrComputeAndStore} from "@/functions/styling/colors/getCachedRowColorOrComputeAndStore";
import getContrastingTextColorByHex from "@/util/getContrastingTextColorByHex";
import {getSettings} from "@/util/getSettings";

export default {
  name: "CanvasTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    window: {
      type: Object,
      required: true,
    },
    subWindow: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      settings: {},
    };
  },
  computed: {
    titleBackgroundColor() {
      return this.getWindowTitleColor({
        window: this.window,
        subWindow: this.subWindow,
      });
    },
    titleTextColor() {
      return getContrastingTextColorByHex(this.titleBackgroundColor);
    },
    titleStyling() {
      if (!this.titleBackgroundColor) return {};
      if (this.titleBackgroundColor === "inherit") return {};
      return {
        "background-color": this.titleBackgroundColor,
        color: this.titleTextColor,
      };
    },
    titleClasses() {
      if (this.titleBackgroundColor) {
        return {
          "rounded-md px-2 py-1": this.titleBackgroundColor,
        };
      }
      return {};
    },
  },
  created() {
    this.settings = getSettings();
  },
  methods: {
    getWindowTitleColor({window, subWindow}) {
      if (this.settings.InterfaceColorIntensity !== "prominent") return null;

      const windowOutput = window?.output;
      const activeWindowOutput = subWindow?.output ?? window?.output;

      if (!windowOutput) return null;

      let row = activeWindowOutput.Table?.Rows[0];

      if (activeWindowOutput?.Request?.Prefix != "Single") {
        row = null;
      }

      const {Criteria, Subject} = window.output.Request;
      if (!Subject) return null;

      return getCachedRowColorOrComputeAndStore({
        row,
        windowOutput: activeWindowOutput,
        windowId: window?.id
      });
    },
  },
};
</script>
