// initial state
// shape: [{ user, properties }]
const state = () => ({});

// getters
const getters = {
  lanes: (state, getters, rootState, rootGetters) => (windowId) => {
    const window = rootGetters["windows/getWindow"](windowId);

    return window.output?.Data?.Lanes ?? {};
  },
  tasks: (state, getters) => (windowId) => {
    const lanes = getters["lanes"](windowId);

    const cards = Object.values(lanes).reduce((acc, lane) => {
      return [...acc, ...lane.Cards];
    }, []);

    const tasks = cards.reduce((acc, card) => {
      return [...acc, ...card.Tasks];
    }, []);

    return tasks ?? [];
  },
  resources: (state, getters) => (windowId) => {
    const resources = [];
    // loop through all tasks and add all distinct resources in task.Resources to resources
    getters["tasks"](windowId).forEach((task) => {
      task.Resources.forEach((resource) => {
        if (!resources.includes(resource)) {
          resources.push(resource);
        }
      });
    });

    return resources ?? [];
  },
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
