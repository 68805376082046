import Hook from "@/hook";

/**
 * Show message to user, get's overruled somewhere
 * @see {@link Canvas}
 * @param {string} type - message type(warning/succes/error/..)
 * @param {string} message - message text
 * @param {*} args - arguments
 * @returns {void | Object} Message output
 */
export default function message(
  type,
  message,
  args = null,
  content,
  size,
  dialogProperties,
) {
  let simple = type === "warning" || type === "success";
  let event = {
    type,
    message,
    args,
    simple,
    window: this,
    content,
    size,
    dialogProperties,
  };

  return Hook.run(
    "message",
    this,
    () => {
      // Should not be reached
      //
    },
    event,
  );
}
