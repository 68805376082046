import $ from "jquery";

export default class Tutorial {
  constructor(session) {
    this.session = session;

    this.activeTutorial = false;
    this.currentTutorialStep = null;

    this.init(document);
  }

  /**
   * Create event listeners, initialise component
   * @param {document} doc - Page document
   */
  init(doc) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var tutorial = this;

    // Toggle tutorial
    $(doc).on("click", ".hide-tutorial", () => {
      tutorial.hideTutorial();
    });

    // Close tutorial
    $(doc).on("click", ".close-tutorial", () => {
      tutorial.closeTutorial();
    });
  }

  hideTutorial() {
    $(".tutorial").toggleClass("hidden");

    $(".hide-tutorial").empty();

    if ($(".tutorial").hasClass("hidden")) {
      $(".hide-tutorial").append("<i class='fas fa-chevron-left'></i>");
    } else {
      $(".hide-tutorial").append("<i class='fas fa-chevron-right'></i>");
    }
  }

  async closeTutorial() {
    await this.session.request(
      "/Public/WebServices/AppWebServices.asmx/FinishTutorial",
      null,
    );

    $(".tutorial").addClass("closed");

    this.enableAllMenuNodes();

    this.activeTutorial = false;
    this.currentTutorialStep = null;
    this.tutorial = null;
  }

  /**
   * This method resets the tutorial.
   */
  async resetTutorial() {
    await this.session.request(
      "/Public/WebServices/AppWebServices.asmx/ResetTutorial",
      null,
    );

    if (this.tutorial != null) {
      this.tutorial.forEach((x) => (x.Passed = false));
    }

    this.startTutorial();
  }

  async startTutorial() {
    this.activeTutorial = true;

    $(".tutorial").removeClass("closed");

    if (!this.tutorial) {
      // perform request
      this.tutorial = await this.session.request(
        "/Public/WebServices/AppWebServices.asmx/GetTutorial?LangID=" +
          this.session.language,
        null,
      );
    }

    this.disableAllMenuNodes();

    let arPassedSteps = this.tutorial
      .filter((x) => x.Passed)
      .sort((x) => x.StepID);

    for (let i = 0; i < arPassedSteps.length; i++) {
      this.enableMenuNode(arPassedSteps[i].MenuID);
    }

    this.showNextTutorialStep();
  }

  /**
   * Checks if the has an active tutorial
   */
  checkTutorialMode() {
    if (this.session.settings.ActiveTutorial) {
      this.startTutorial();
    }
  }

  /**
   * This method is called when a menu node is opened.
   * @param {string} id - The id of the clicked menu node.
   */
  openMenuNodeNotifioncation(id) {
    if (this.activeTutorial) {
      if (id != null && this.currentTutorialStep != null) {
        if (this.currentTutorialStep.MenuID == id) {
          this.currentTutorialStep.Passed = true;

          this.showNextTutorialStep();
        }
      }
    }
  }

  /**
   * This method shows the next tutorial step.
   */
  showNextTutorialStep() {
    let arStepsToDo = this.tutorial
      .filter((x) => !x.Passed)
      .sort(function (a, b) {
        return a.StepID > b.StepID;
      });

    if (arStepsToDo.length > 0) {
      if (this.currentTutorialStep != null) {
        let opts = {
          stepID: this.currentTutorialStep.StepID,
        };

        this.session.request(
          "/Public/WebServices/AppWebServices.asmx/PassTutorial",
          opts,
        );
      }

      this.currentTutorialStep = arStepsToDo[0];

      // make the tutorial iframe visible and set the src
      var tutorial = $("#tutorial");

      if (tutorial.length > 0) {
        tutorial.show();
        tutorial.attr("src", this.currentTutorialStep.CompleteURL);
      }

      // enable the needed menu node
      this.enableMenuNode(this.currentTutorialStep.MenuID);
    }
  }

  /**
   * This method enables the menu node for the given id, and all parent nodes.
   * @param {string} id The id of the menu node to enable.
   */
  enableMenuNode(id) {
    if (id != null && $(".aside").length > 0) {
      var menuNode = $("[data-menu-id='" + id + "'");

      if (menuNode != null && menuNode.length > 0) {
        while (!menuNode.parent().hasClass("aside")) {
          if (menuNode.hasClass("disabled")) {
            menuNode.removeClass("disabled");
          }

          menuNode = menuNode.parent();
        }
      }
    }
  }

  /**
   * This method enables all menu nodes.
   */
  enableAllMenuNodes() {
    $(".aside").find(".branch").removeClass("disabled");

    $(".aside").find(".stick").removeClass("disabled");
  }

  /**
   * This method disables all menu nodes.
   */
  disableAllMenuNodes() {
    $(".aside").find(".branch").addClass("disabled");

    $(".aside").find(".stick").addClass("disabled");
  }
}
