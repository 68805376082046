export const setFormFieldVisuallyDirty = ({fieldName, window, dirty}) => {
  const $fieldElement = $(window.element).find(`[name="${fieldName}"]`);

  $fieldElement.closest(".form-field").toggleClass("dirty", dirty);
  $fieldElement
    .closest(".table-row")
    .toggleClass("dirty", dirty)
    .find(".selection-box input")
    .prop("checked", dirty)
    .change();
};
