import dayjs from "dayjs";

export const convertFourNumbersToDate = (value) => {
  let newValue = value;
  if (newValue.length === 4) {
    // if 3th and 4th number is above 12 return
    if (parseInt(newValue.charAt(2) + newValue.charAt(3)) > 12) return newValue;
    // add - after second number
    newValue = newValue.slice(0, 2) + "-" + newValue.slice(2, 5);
    newValue = newValue + "-" + dayjs().format("YYYY");
  }
  return newValue;
};
