
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        
        
        
        
        
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
        const __nunjucks_template_import__dep_1 = require("../elements/fileIcon.html");
        
        const __nunjucks_template_import__dep_2 = require("./form.html");
        
        const __nunjucks_template_import__dep_3 = require("../elements/plusminusinput.html");
        
        const __nunjucks_template_import__dep_4 = require("../elements/vueEditor.html");
        
        const __nunjucks_template_import__dep_5 = require("./table/tableBody.html");
        
        const __nunjucks_template_import__dep_6 = require("./table/cellStatusIcon.html");
        
        const __nunjucks_template_import__dep_7 = require("./table/branch.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_6.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_7.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_6.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_7.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_6.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_7.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_6.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_7.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_2.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_3.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_4.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_5.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_6.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_7.__nunjucks_module_dependencies__.assets};
    
    
            const __nunjucks_template_import__global_stringStartsWith = require("../../src/util/stringStartsWith.js");
            __nunjucks_module_dependencies__.globals['stringStartsWith'] = {
                module: __nunjucks_template_import__global_stringStartsWith && __nunjucks_template_import__global_stringStartsWith.default || __nunjucks_template_import__global_stringStartsWith
            };
            const __nunjucks_template_import__global_getSettings = require("../../src/functions/nunjucks/getSettings.js");
            __nunjucks_module_dependencies__.globals['getSettings'] = {
                module: __nunjucks_template_import__global_getSettings && __nunjucks_template_import__global_getSettings.default || __nunjucks_template_import__global_getSettings
            };
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/table.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/elements/combobox.html", false, "views/content/table.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "\n";
env.getTemplate("views/elements/fileIcon.html", false, "views/content/table.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("fileIcon", t_4);
output += "\n";
env.getTemplate("views/content/form.html", false, "views/content/table.html", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
context.setVariable("form", t_7);
output += "\n";
env.getTemplate("views/elements/plusminusinput.html", false, "views/content/table.html", false, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
t_10.getExported(function(t_12,t_10) {
if(t_12) { cb(t_12); return; }
context.setVariable("plusminusinput", t_10);
output += "\n";
env.getTemplate("views/elements/vueEditor.html", false, "views/content/table.html", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
t_13.getExported(function(t_15,t_13) {
if(t_15) { cb(t_15); return; }
context.setVariable("vue", t_13);
output += "\n";
env.getTemplate("views/content/table/tableBody.html", false, "views/content/table.html", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
context.setVariable("tableBody", t_16);
output += "\n";
env.getTemplate("views/content/table/cellStatusIcon.html", false, "views/content/table.html", false, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
t_19.getExported(function(t_21,t_19) {
if(t_21) { cb(t_21); return; }
context.setVariable("cellStatusIcon", t_19);
output += "\n";
env.getTemplate("views/content/table/branch.html", false, "views/content/table.html", false, function(t_23,t_22) {
if(t_23) { cb(t_23); return; }
t_22.getExported(function(t_24,t_22) {
if(t_24) { cb(t_24); return; }
context.setVariable("branch", t_22);
output += "\n\n";
output += "\n\n\n\n\n\n\n";
var macro_t_25 = runtime.makeMacro(
["col", "columnindex", "index", "colval", "window", "cell", "allowEdit", "rowColor", "metadata"], 
[], 
function (l_col, l_columnindex, l_index, l_colval, l_window, l_cell, l_allowEdit, l_rowColor, l_metadata, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("col", l_col);
frame.set("columnindex", l_columnindex);
frame.set("index", l_index);
frame.set("colval", l_colval);
frame.set("window", l_window);
frame.set("cell", l_cell);
frame.set("allowEdit", l_allowEdit);
frame.set("rowColor", l_rowColor);
frame.set("metadata", l_metadata);
var t_26 = "";if(runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Status")) {
t_26 += "\n\t\t";
var t_27;
t_27 = (lineno = 39, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cellStatusIcon")),"render"), "cellStatusIcon[\"render\"]", context, [runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Status")]));
frame.set("cellStatus", t_27, true);
if(frame.topLevel) {
context.setVariable("cellStatus", t_27);
}
if(frame.topLevel) {
context.addExport("cellStatus", t_27);
}
t_26 += "\n\t";
;
}
t_26 += "\n\t";
var t_28;
t_28 = (runtime.memberLookup((l_col),"Editor")?(lineno = 41, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "stringStartsWith"), "stringStartsWith", context, [runtime.memberLookup((l_col),"Editor"),"vue"])):"");
frame.set("vueEditor", t_28, true);
if(frame.topLevel) {
context.setVariable("vueEditor", t_28);
}
if(frame.topLevel) {
context.addExport("vueEditor", t_28);
}
t_26 += "\n\n\t";
if(runtime.memberLookup((l_col),"Editor") == "link" && runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Description")) {
t_26 += "\n\t\t<div class=\"case-0 table-cell field ";
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_26 += "\" data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "[]\" data-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\"\n\t\t\t\t";
if(runtime.memberLookup((l_col),"Width")) {
t_26 += "\n\t\t\t\t\tstyle=\"width: ";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Width"), env.opts.autoescape);
t_26 += "\"\n\t\t\t\t";
;
}
t_26 += "\n\t\t\t>\n\t\t\t";
var t_29;
t_29 = /^http([s]?):\/\/.*/;
frame.set("regExp", t_29, true);
if(frame.topLevel) {
context.setVariable("regExp", t_29);
}
if(frame.topLevel) {
context.addExport("regExp", t_29);
}
t_26 += "\n\t\t\t";
if((lineno = 50, colno = 20, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "regExp")),"test"), "regExp[\"test\"]", context, [l_colval]))) {
t_26 += "\n\t\t\t\t<a href=\"";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "\" target=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Type"), env.opts.autoescape);
t_26 += "\">";
t_26 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Description"), env.opts.autoescape);
t_26 += "</a>\n\t\t\t";
;
}
else {
t_26 += "\n\t\t\t\t<a href=\"//";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "\" target=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Type"), env.opts.autoescape);
t_26 += "\">";
t_26 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"Description"), env.opts.autoescape);
t_26 += "</a>\n\t\t\t";
;
}
t_26 += "\n\t\t</div>\n\t\t";
t_26 += "\n\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "vueEditor")) {
t_26 += "\n\t\t<div class=\"table-cell field\" data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" col-type=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Editor"), env.opts.autoescape);
t_26 += "\">\n\t\t    ";
t_26 += runtime.suppressValue((lineno = 59, colno = 28, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vue")),"generateElement"), "vue[\"generateElement\"]", context, [l_colval,runtime.memberLookup((l_col),"Name"),l_index,runtime.memberLookup((l_col),"Editor")])), env.opts.autoescape);
t_26 += "\n\t\t</div>\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") === "hidden") {
t_26 += "\n\t\t<div class=\"table-cell field d-none\" col-type=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Editor"), env.opts.autoescape);
t_26 += "\" data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" data-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\">\n\t\t\t\t";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "\n\t\t</div>\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") === "ref") {
t_26 += "\n        ";
var t_30;
t_30 = (l_rowColor != "inherit" && l_colval?"table-cell field field-ref":"table-cell field");
frame.set("classString", t_30, true);
if(frame.topLevel) {
context.setVariable("classString", t_30);
}
if(frame.topLevel) {
context.addExport("classString", t_30);
}
t_26 += "\n\t\t<div class=\"";
t_26 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classString"), env.opts.autoescape);
t_26 += "\" data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" data-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\">\n\t\t\t<span class=\"col-ref text-primary cursor-pointer underline\" data-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\">";
if(runtime.memberLookup((runtime.memberLookup((l_col),"Dropdown")),"Items")) {
t_26 += runtime.suppressValue((lineno = 70, colno = 27, runtime.callWrap(runtime.memberLookup((l_col),"getDropdownText"), "col[\"getDropdownText\"]", context, [l_colval])), env.opts.autoescape);
;
}
else {
if(l_cell && runtime.memberLookup((l_cell),"Initial") && runtime.memberLookup((runtime.memberLookup((l_cell),"Initial")),"Description")) {
t_26 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_cell),"Initial")),"Description"), env.opts.autoescape);
;
}
else {
if(runtime.memberLookup((l_col),"Html")) {
t_26 += runtime.suppressValue(env.getFilter("safe").call(context, l_colval), env.opts.autoescape);
;
}
else {
t_26 += "\n\t\t\t\t\t";
if((runtime.memberLookup((l_col),"Type") === "DateTime" || runtime.memberLookup((l_col),"Type") === "Date") && l_colval) {
t_26 += "\n\t\t\t\t\t\t<span>";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "</span>\n\t\t\t\t\t";
;
}
else {
t_26 += "\n\t\t\t\t\t\t";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "\n\t\t\t\t\t";
;
}
;
}
;
}
;
}
t_26 += "</span>\n\t\t</div>\n\t";
;
}
else {
if((runtime.memberLookup((l_metadata),"Label") && runtime.memberLookup((l_col),"Name") == "Label")) {
t_26 += "\n\t\t<div class=\"table-cell field\" data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" col-type=\"label\">\n\t\t\t";
var t_31;
t_31 = runtime.memberLookup((runtime.memberLookup((l_metadata),"Label")),"JSONValue");
frame.set("labels", t_31, true);
if(frame.topLevel) {
context.setVariable("labels", t_31);
}
if(frame.topLevel) {
context.addExport("labels", t_31);
}
t_26 += "\n\n\t\t";
frame = frame.push();
var t_34 = runtime.contextOrFrameLookup(context, frame, "labels");
if(t_34) {t_34 = runtime.fromIterator(t_34);
var t_32;
if(runtime.isArray(t_34)) {
var t_33 = t_34.length;
for(t_32=0; t_32 < t_34.length; t_32++) {
var t_35 = t_34[t_32][0];
frame.set("[object Object]", t_34[t_32][0]);
var t_36 = t_34[t_32][1];
frame.set("[object Object]", t_34[t_32][1]);
frame.set("loop.index", t_32 + 1);
frame.set("loop.index0", t_32);
frame.set("loop.revindex", t_33 - t_32);
frame.set("loop.revindex0", t_33 - t_32 - 1);
frame.set("loop.first", t_32 === 0);
frame.set("loop.last", t_32 === t_33 - 1);
frame.set("loop.length", t_33);
t_26 += "\n\t\t\t<span label-id=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((t_36),"LabelID"), env.opts.autoescape);
t_26 += "\" class=\"badge badge-secondary\" title=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((t_36),"Description"), env.opts.autoescape);
t_26 += "\" label=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((t_36),"Description"), env.opts.autoescape);
t_26 += "\" style=\"background-color:";
t_26 += runtime.suppressValue(runtime.memberLookup((t_36),"Color"), env.opts.autoescape);
t_26 += "; color:";
t_26 += runtime.suppressValue(runtime.memberLookup((t_36),"TextColor"), env.opts.autoescape);
t_26 += "\">";
t_26 += runtime.suppressValue(runtime.memberLookup((t_36),"Name"), env.opts.autoescape);
t_26 += "</span>\n\t\t";
;
}
} else {
t_32 = -1;
var t_33 = runtime.keys(t_34).length;
for(var t_37 in t_34) {
t_32++;
var t_38 = t_34[t_37];
frame.set("index", t_37);
frame.set("label", t_38);
frame.set("loop.index", t_32 + 1);
frame.set("loop.index0", t_32);
frame.set("loop.revindex", t_33 - t_32);
frame.set("loop.revindex0", t_33 - t_32 - 1);
frame.set("loop.first", t_32 === 0);
frame.set("loop.last", t_32 === t_33 - 1);
frame.set("loop.length", t_33);
t_26 += "\n\t\t\t<span label-id=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((t_38),"LabelID"), env.opts.autoescape);
t_26 += "\" class=\"badge badge-secondary\" title=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((t_38),"Description"), env.opts.autoescape);
t_26 += "\" label=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((t_38),"Description"), env.opts.autoescape);
t_26 += "\" style=\"background-color:";
t_26 += runtime.suppressValue(runtime.memberLookup((t_38),"Color"), env.opts.autoescape);
t_26 += "; color:";
t_26 += runtime.suppressValue(runtime.memberLookup((t_38),"TextColor"), env.opts.autoescape);
t_26 += "\">";
t_26 += runtime.suppressValue(runtime.memberLookup((t_38),"Name"), env.opts.autoescape);
t_26 += "</span>\n\t\t";
;
}
}
}
frame = frame.pop();
t_26 += "\n\t\t</div>\n\t";
t_26 += "\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"Name") == "ImageUrl") {
t_26 += "\n\t\t<div class=\"case-1 table-cell field ";
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_26 += "\" data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" data-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\" col-type=\"imageURL\">\n\t\t\t";
if(l_colval) {
t_26 += "\n\t\t\t\t<span img-url=\"";
t_26 += runtime.suppressValue(env.getFilter("safe").call(context, l_colval), env.opts.autoescape);
t_26 += "\" class=\"material-icons image\">&#xe3f4;</span>\n\t\t\t";
;
}
t_26 += "\n\t\t\t";
t_26 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_26 += "\n\t\t</div>\n\t";
t_26 += "\n\t";
;
}
else {
if((runtime.memberLookup((l_window),"bulkedit") || l_allowEdit) && runtime.memberLookup((l_col),"Type") == "Boolean") {
t_26 += "\n\t\t<div class=\"case-2 table-cell field checkbox";
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?" " + runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_26 += "\" data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" data-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\">\n\t\t\t<input name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "[";
t_26 += runtime.suppressValue(l_columnindex, env.opts.autoescape);
t_26 += "]\" type=\"checkbox\" title=\"";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "\" ";
t_26 += runtime.suppressValue((l_colval?"checked":""), env.opts.autoescape);
t_26 += " ";
if(runtime.memberLookup((l_col),"BaseType") != runtime.memberLookup((l_col),"Type")) {
t_26 += " class=\"pull-left\"";
;
}
t_26 += ">\n\t\t\t";
t_26 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_26 += "\n\t\t</div>\n\t";
t_26 += "\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"Type") == "Boolean") {
t_26 += "\n\t\t<div class=\"case-3 table-cell field checkbox";
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?" " + runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_26 += "\" data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" data-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\">\n\t\t\t<input disabled type=\"checkbox\" title=\"";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "\" ";
t_26 += runtime.suppressValue((l_colval?"checked":""), env.opts.autoescape);
t_26 += " ";
if(runtime.memberLookup((l_col),"BaseType") != runtime.memberLookup((l_col),"Type")) {
t_26 += " class=\"pull-left\"";
;
}
t_26 += ">\n\t\t\t";
t_26 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_26 += "\n\t\t</div>\n\n\t";
;
}
else {
if(runtime.memberLookup((l_col),"Editor") == "plusminusinput") {
t_26 += "\n\t\t";
t_26 += runtime.suppressValue((lineno = 114, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "plusminusinput")),"constructPlusMinusInput"), "plusminusinput[\"constructPlusMinusInput\"]", context, [l_col,"",l_index,l_colval,runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"MinNumber"),runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"MaxNumber"),"table-cell field"])), env.opts.autoescape);
;
}
else {
if((runtime.memberLookup((l_window),"bulkedit") || l_allowEdit) && (runtime.memberLookup((l_col),"Type") == "Date" || runtime.memberLookup((l_col),"Type") == "DateTime" || runtime.memberLookup((l_col),"Type") == "Time")) {
t_26 += "\n\t\t<div class=\"case-3 table-cell date-picker\"  data-replace=\"";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "\" data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" data-type=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Type"), env.opts.autoescape);
t_26 += "\">\n\t\t\t<span class=\"float-right\">";
t_26 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_26 += "</span>\n\t\t\t<div class=\"field date editable-text ";
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"Type") == "Money" || runtime.memberLookup((l_col),"Type") == "Decimal" || runtime.memberLookup((l_col),"Type") == "Int"?" number " + runtime.memberLookup((l_col),"Type"):""), env.opts.autoescape);
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?" " + runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_26 += "\"\n\t\t\t\tdata-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\" ";
t_26 += runtime.suppressValue((!runtime.memberLookup((l_col),"IsReadOnly")?"contenteditable=true":""), env.opts.autoescape);
t_26 += " name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "[";
t_26 += runtime.suppressValue(l_columnindex, env.opts.autoescape);
t_26 += "]\">";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "\n\t\t\t</div>\n\t\t</div>\n\t";
t_26 += "\n\t";
;
}
else {
if((runtime.memberLookup((l_window),"bulkedit") || l_allowEdit) && !(runtime.memberLookup((l_col),"IsRequired") && runtime.memberLookup((l_col),"IsPrimaryKey") && l_cell) && runtime.memberLookup((l_cell),"Combobox")) {
t_26 += "\n\t\t<div class=\"case-4 table-cell field";
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?" " + runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_26 += "\" data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" data-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\"\n\t\t\t";
if(runtime.memberLookup((l_col),"Width")) {
t_26 += "\n\t\t\t\tstyle=\"min-width: ";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Width"), env.opts.autoescape);
t_26 += "; width: ";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Width"), env.opts.autoescape);
t_26 += "\"\n\t\t\t";
;
}
t_26 += "\n\t\t>\n\t\t\t\t";
if(runtime.memberLookup((l_col),"Name") == "DimensionID-3") {
t_26 += "\n\t\t\t\t  ";
t_26 += runtime.suppressValue((lineno = 130, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "combo")),"constructTableRecordCombobox"), "combo[\"constructTableRecordCombobox\"]", context, [runtime.memberLookup((l_window),"session"),runtime.memberLookup((l_cell),"Combobox"),l_index,runtime.memberLookup((l_col),"Name") == "DimensionID-3"])), env.opts.autoescape);
t_26 += " ";
t_26 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_26 += "\n\t\t\t";
;
}
else {
t_26 += "\n\t\t\t\t";
t_26 += runtime.suppressValue((lineno = 132, colno = 30, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "combo")),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((l_window),"session"),runtime.memberLookup((l_cell),"Combobox"),runtime.memberLookup((l_col),"Width")])), env.opts.autoescape);
t_26 += " ";
t_26 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_26 += "\n\t\t\t";
;
}
t_26 += "\n\t\t</div>\n\t";
t_26 += "\n\t";
;
}
else {
if((runtime.memberLookup((l_window),"bulkedit") || l_allowEdit)) {
t_26 += "\n\t\t<div data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" class=\"case-5 table-cell field\n\t\t\t";
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"IsReadOnly") == false?" editable-text ":""), env.opts.autoescape);
t_26 += "\n\t\t\t";
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"Type") == "Money" || runtime.memberLookup((l_col),"Type") == "Decimal" || runtime.memberLookup((l_col),"Type") == "Int" || runtime.memberLookup((l_col),"Editor") == "number"?" number " + runtime.memberLookup((l_col),"Type"):""), env.opts.autoescape);
t_26 += "\n\t\t\t";
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?" " + runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_26 += "\"\n\t\t\t";
t_26 += "\n\t\t\t";
if(runtime.memberLookup((l_col),"IsReadOnly")) {
t_26 += "\n\t\t\t\tcontenteditable=\"false\"\n\t\t\t";
;
}
else {
t_26 += "\n\t\t\t\tcontenteditable=\"true\"\n\t\t\t";
;
}
t_26 += "\n\t\t\tname=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "[";
t_26 += runtime.suppressValue(l_columnindex, env.opts.autoescape);
t_26 += "]\"\n\t\t\tdata-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\"\n\t\t\t";
if(runtime.memberLookup((l_col),"MinNumber")) {
t_26 += "\n\t\t\t\tmin=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"MinNumber"), env.opts.autoescape);
t_26 += "\"\n\t\t\t";
;
}
t_26 += "\n\t\t\t";
if(runtime.memberLookup((l_col),"MaxNumber")) {
t_26 += "\n\t\t\t\tmax=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"MaxNumber"), env.opts.autoescape);
t_26 += "\"\n\t\t\t";
;
}
t_26 += "\n\t\t\t";
if(runtime.memberLookup((l_col),"Width")) {
t_26 += "\n\t\t\t\tstyle=\"width: ";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Width"), env.opts.autoescape);
t_26 += "\"\n\t\t\t";
;
}
t_26 += "\n\t\t>\n\t\t\t";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "\n\t\t</div>";
;
}
else {
t_26 += "\n\t\t<div data-name=\"";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_26 += "\" style=\"background-color:";
t_26 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "prominentRowColor"), env.opts.autoescape);
t_26 += ";\" class=\"case-6 table-cell field ";
t_26 += runtime.suppressValue("table-cell-" + runtime.memberLookup((l_col),"Type"), env.opts.autoescape);
t_26 += " ";
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"Type") == "Money" || runtime.memberLookup((l_col),"Type") == "Decimal"?"number " + runtime.memberLookup((l_col),"Type"):""), env.opts.autoescape);
t_26 += runtime.suppressValue((runtime.memberLookup((l_col),"CustomClass")?runtime.memberLookup((l_col),"CustomClass"):""), env.opts.autoescape);
t_26 += "\" data-field-index=\"";
t_26 += runtime.suppressValue(l_index, env.opts.autoescape);
t_26 += "\"\n\n\t\t\t";
if(runtime.memberLookup((l_col),"Width")) {
t_26 += "\n\t\t\t\tstyle=\"min-width: ";
t_26 += runtime.suppressValue(runtime.memberLookup((l_col),"Width"), env.opts.autoescape);
t_26 += "\"\n\t\t\t";
;
}
t_26 += ">";
if(runtime.contextOrFrameLookup(context, frame, "rowColorSubtle")) {
t_26 += "<div class=\"inline-flex items-center \">\n\t\t\t\t\t<div class=\"w-2 h-2 rounded-full inline-block\" style=\"background-color: ";
t_26 += runtime.suppressValue(l_rowColor, env.opts.autoescape);
t_26 += "\"></div>\n\t\t\t\t</div>";
;
}
if((runtime.memberLookup((l_col),"Editor") === "number")) {
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((l_col),"Dropdown")),"Items")) {
t_26 += runtime.suppressValue((lineno = 178, colno = 26, runtime.callWrap(runtime.memberLookup((l_col),"getDropdownText"), "col[\"getDropdownText\"]", context, [l_colval])), env.opts.autoescape);
;
}
else {
if(l_cell && runtime.memberLookup((l_cell),"Initial") && runtime.memberLookup((runtime.memberLookup((l_cell),"Initial")),"Description")) {
t_26 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_cell),"Initial")),"Description"), env.opts.autoescape);
;
}
else {
if(runtime.memberLookup((l_col),"Html")) {
t_26 += runtime.suppressValue(env.getFilter("safe").call(context, l_colval), env.opts.autoescape);
;
}
else {
t_26 += "\n\t\t\t\t";
if((runtime.memberLookup((l_col),"Type") === "DateTime" || runtime.memberLookup((l_col),"Type") === "Date") && l_colval) {
t_26 += "\n\t\t\t\t\t<span class=\"";
t_26 += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"CustomReadOnlyDateClass")?runtime.memberLookup((runtime.memberLookup((l_metadata),runtime.memberLookup((l_col),"Name"))),"CustomReadOnlyDateClass"):""), env.opts.autoescape);
t_26 += "\">";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "</span>\n\t\t\t\t";
;
}
else {
if((runtime.memberLookup((l_col),"Type") === "Decimal" || runtime.memberLookup((l_col),"Type") === "Money")) {
t_26 += "\n                    ";
l_colval = (lineno = 187, colno = 72, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "templateFunctions")),"parseStringToFloat"), "templateFunctions[\"parseStringToFloat\"]", context, [l_colval]));
frame.set("colval", l_colval, true);
if(frame.topLevel) {
context.setVariable("colval", l_colval);
}
if(frame.topLevel) {
context.addExport("colval", l_colval);
}
t_26 += "\n\t\t\t\t\t    ";
t_26 += runtime.suppressValue((lineno = 188, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "templateFunctions")),"formatDecimal"), "templateFunctions[\"formatDecimal\"]", context, [l_colval,runtime.memberLookup((l_col),"NumericScale")])), env.opts.autoescape);
t_26 += "\n                    ";
;
}
else {
t_26 += "\n                        ";
t_26 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_26 += "\n                    ";
;
}
;
}
;
}
;
}
;
}
;
}
t_26 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "cellStatus"), env.opts.autoescape);
t_26 += "\n\t\t</div>";
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
frame = callerFrame;
return new runtime.SafeString(t_26);
});
context.addExport("setColumnCell");
context.setVariable("setColumnCell", macro_t_25);
output += "\n\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true) {
output += "\n\t<div class=\"content row\" style=\"\n\t\t\tmargin-left: 0px;\n\t\t\talign-items: baseline;\n\t\">\n";
;
}
else {
output += "\n\t<div class=\"content\">\n";
;
}
output += "\n\n  ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent") !== null) {
output += "\n\t  <div class=\"table-view pl-0 col-10 ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")?"unsortable":""), env.opts.autoescape);
output += "\">\n  ";
;
}
else {
output += "\n    <div class=\"table-view ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")?"unsortable":""), env.opts.autoescape);
output += "\">\n  ";
;
}
output += "\n\t\t";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("header"))(env, context, frame, runtime, function(t_40,t_39) {
if(t_40) { cb(t_40); return; }
output += t_39;
output += "\n\n\t\t";
if((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Table")),"Rows")),"length") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Table")),"Columns")),"length")) {
output += "\n\t\t\t<form class=\"table-container\">\n\t\t\t\t<div class=\"table-index sticky-div\">\n\t\t\t\t\t<div class=\"table-row\">\n\n\t\t\t\t\t\t";
if(!runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Options")),"DisableSelection")) {
output += "\n\t\t\t\t\t\t\t<label style=\"padding-left: 5px;\" class=\"table-cell checkbox selection-box\"><div class=\"ellipsis\"><input type=\"checkbox\" title=\"Select all\"></div></label>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t";
frame = frame.push();
var t_43 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Table")),"Columns");
if(t_43) {t_43 = runtime.fromIterator(t_43);
var t_42 = t_43.length;
for(var t_41=0; t_41 < t_43.length; t_41++) {
var t_44 = t_43[t_41];
frame.set("col", t_44);
frame.set("loop.index", t_41 + 1);
frame.set("loop.index0", t_41);
frame.set("loop.revindex", t_42 - t_41);
frame.set("loop.revindex0", t_42 - t_41 - 1);
frame.set("loop.first", t_41 === 0);
frame.set("loop.last", t_41 === t_42 - 1);
frame.set("loop.length", t_42);
output += "\n\t\t\t\t\t\t\t<div class=\"table-cell ";
output += runtime.suppressValue((runtime.memberLookup((t_44),"Type") == "Money" || runtime.memberLookup((t_44),"Type") == "Decimal"?"number " + runtime.memberLookup((t_44),"Type"):""), env.opts.autoescape);
output += "\" data-window-event=\"toggle-column-sorting:";
output += runtime.suppressValue(runtime.memberLookup((t_44),"Name"), env.opts.autoescape);
output += "\" title=\"";
output += runtime.suppressValue(runtime.memberLookup((t_44),"Title"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t<div class=\"ellipsis\">";
if(runtime.memberLookup((t_44),"OrderType")) {
output += "<span class=\"material-icons\">";
output += runtime.suppressValue((runtime.memberLookup((t_44),"OrderType") == "DESC"?(env.getFilter("safe").call(context, "&#xe5c5;")):(env.getFilter("safe").call(context, "&#xe5c7;"))), env.opts.autoescape);
output += "</span>";
;
}
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_44),"Title")), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t\t\t</div>";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t";
var t_45;
t_45 = (lineno = 333, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "getSettings"), "getSettings", context, []));
frame.set("settings", t_45, true);
if(frame.topLevel) {
context.setVariable("settings", t_45);
}
if(frame.topLevel) {
context.addExport("settings", t_45);
}
output += "\n\t\t\t<div class=\"table-body ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")?"bulkedit":""), env.opts.autoescape);
output += "\">\n\t\t\t\t";
output += runtime.suppressValue((lineno = 335, colno = 32, runtime.callWrap(runtime.memberLookup((t_16),"renderTableBody"), "tableBody[\"renderTableBody\"]", context, [runtime.contextOrFrameLookup(context, frame, "data"),runtime.contextOrFrameLookup(context, frame, "window"),runtime.contextOrFrameLookup(context, frame, "settings"),runtime.contextOrFrameLookup(context, frame, "getRowColorMarking"),macro_t_25])), env.opts.autoescape);
output += "\n\t\t\t</div>\n\n\t\t</form>\n\t\t";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Table")),"Columns")),"length") == 0) {
output += "\n\t\t\t<p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"NoColumnsFound"), env.opts.autoescape);
output += "</p>\n\t\t";
;
}
;
}
output += "\n\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit") !== true && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Options")),"ShowPagination") == true && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Totals")),"RowCount")),"Value")) {
output += "<div class=\"table-footer clearfix sticky-div stick-to-bottom\">\n\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination") && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "<ul class=\"pagination\">\n\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"first") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"first")?"disabled":""), env.opts.autoescape);
output += ">&#xe045;</li>\n\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"prev") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"prev")?"disabled":""), env.opts.autoescape);
output += ">&#xe314;</li>\n\t\t\t\t\t\t";
frame = frame.push();
var t_48 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"sequence");
if(t_48) {t_48 = runtime.fromIterator(t_48);
var t_47 = t_48.length;
for(var t_46=0; t_46 < t_48.length; t_46++) {
var t_49 = t_48[t_46];
frame.set("item", t_49);
frame.set("loop.index", t_46 + 1);
frame.set("loop.index0", t_46);
frame.set("loop.revindex", t_47 - t_46);
frame.set("loop.revindex0", t_47 - t_46 - 1);
frame.set("loop.first", t_46 === 0);
frame.set("loop.last", t_46 === t_47 - 1);
frame.set("loop.length", t_47);
output += "<li class=\"page ";
output += runtime.suppressValue((runtime.memberLookup((t_49),"current")?"current":""), env.opts.autoescape);
output += "\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((t_49),"page"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_49),"page"), env.opts.autoescape);
output += "</li>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"next") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"next")?"disabled":""), env.opts.autoescape);
output += ">&#xe315;</li>\n\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"last") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"last")?"disabled":""), env.opts.autoescape);
output += ">&#xe044;</li>\n\t\t\t\t\t</ul>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Totals") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Totals")),"length") > 0) {
output += "\n\t\t\t\t\t<div class=\"pull-right\">\n\t\t\t\t\t";
frame = frame.push();
var t_52 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Totals");
if(t_52) {t_52 = runtime.fromIterator(t_52);
var t_51 = t_52.length;
for(var t_50=0; t_50 < t_52.length; t_50++) {
var t_53 = t_52[t_50];
frame.set("col", t_53);
frame.set("loop.index", t_50 + 1);
frame.set("loop.index0", t_50);
frame.set("loop.revindex", t_51 - t_50);
frame.set("loop.revindex0", t_51 - t_50 - 1);
frame.set("loop.first", t_50 === 0);
frame.set("loop.last", t_50 === t_51 - 1);
frame.set("loop.length", t_51);
output += "<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_53),"Title"), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((t_53),"Value"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</div>";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t<div class=\"fake-button button button-menu no-hover no-action\">\n\t\t\t\t\t";
if(!runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Options")),"DisableSelection")) {
output += "\n\t\t\t\t\t\t<span data-select-count>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"selection")),"length"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t/\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t<span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Totals")),"RowCount")),"Value"), env.opts.autoescape);
output += "</span>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\t</div>\n\n\n\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"settings")),"AlwaysShowPrimarySideBarItems") == true && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"parent") !== null || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "windows")),"sub")) {
output += "\n\t\t<div sidebar></div>\n\t";
;
}
output += "\n\n\n</div>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_header(env, context, frame, runtime, cb) {
var lineno = 212;
var colno = 5;
var output = "";
try {
var frame = frame.push(true);
output += "\n\t\t\t";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "\n\t\t\t\t<div class=\"table-form w-100 sticky-div\">\n\t\t\t\t\t\n\t\t\t\t<div class=\"flex w-100\">\n\t\t\t\t\t<form class=\"search-bar mb-2\">\n\t\t\t\t\t\t<div class=\"search-box \">\n\t\t\t\t\t\t\t<div class=\"flex flex-wrap w-100\">\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_56 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"filters")),"date");
if(t_56) {t_56 = runtime.fromIterator(t_56);
var t_55 = t_56.length;
for(var t_54=0; t_54 < t_56.length; t_54++) {
var t_57 = t_56[t_54];
frame.set("filter", t_57);
frame.set("loop.index", t_54 + 1);
frame.set("loop.index0", t_54);
frame.set("loop.revindex", t_55 - t_54);
frame.set("loop.revindex0", t_55 - t_54 - 1);
frame.set("loop.first", t_54 === 0);
frame.set("loop.last", t_54 === t_55 - 1);
frame.set("loop.length", t_55);
if(runtime.memberLookup((t_57),"value")) {
output += "<span class=\"filter\" data-window-filter-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" data-window-event=\"remove-date-query:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t<span class=\"close material-icons\" style=\"font-size:inherit;\">&#xe5cd;</span>\n\t\t\t\t\t\t\t\t\t\t\t<span>";
output += runtime.suppressValue(runtime.memberLookup((t_57),"text"), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((t_57),"value"), env.opts.autoescape);
output += "</span>\n\n\t\t\t\t\t\t\t\t\t\t</span>\n\t\t\t\t\t\t\t\t\t\t";
;
}
else {
output += "<div class=\"dropdown filter-option date-picker\" data-type=\"Date\" data-replace>\n\t\t\t\t\t\t\t\t\t\t\t<button class=\"toggle-menu button button-menu\">\n\t\t\t\t\t\t\t\t\t\t\t\t<span>";
output += runtime.suppressValue(runtime.memberLookup((t_57),"text"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t\t\t\t\t\t<span class=\"material-icons\" style=\"font-size:inherit;\">&#xe5c5;</span>\n\t\t\t\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t\t\t\t\t<input type=\"hidden\" class=\"date\" data-index=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t</div>";
;
}
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\n\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Filters")) {
output += "\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_60 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Filters");
if(t_60) {t_60 = runtime.fromIterator(t_60);
var t_59 = t_60.length;
for(var t_58=0; t_58 < t_60.length; t_58++) {
var t_61 = t_60[t_58];
frame.set("filter", t_61);
frame.set("loop.index", t_58 + 1);
frame.set("loop.index0", t_58);
frame.set("loop.revindex", t_59 - t_58);
frame.set("loop.revindex0", t_59 - t_58 - 1);
frame.set("loop.first", t_58 === 0);
frame.set("loop.last", t_58 === t_59 - 1);
frame.set("loop.length", t_59);
if(runtime.memberLookup((t_61),"Type") == "List") {
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"filter-option right\">\n\t\t\t\t\t\t\t\t\t\t<select data-filter-target=\"";
output += runtime.suppressValue(runtime.memberLookup((t_61),"Target"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_61),"Options")),"length") > 0 && runtime.memberLookup((t_61),"Title")) {
output += "\n\t\t\t\t\t\t\t\t\t\t\t\t<option disabled ";
output += runtime.suppressValue((runtime.memberLookup((t_61),"Selected") < 0?"selected":""), env.opts.autoescape);
output += " value=\"\">";
output += runtime.suppressValue((runtime.memberLookup((t_61),"Title")?runtime.memberLookup((t_61),"Title") + " filter":"Filter"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_64 = runtime.memberLookup((t_61),"Options");
if(t_64) {t_64 = runtime.fromIterator(t_64);
var t_63 = t_64.length;
for(var t_62=0; t_62 < t_64.length; t_62++) {
var t_65 = t_64[t_62];
frame.set("item", t_65);
frame.set("loop.index", t_62 + 1);
frame.set("loop.index0", t_62);
frame.set("loop.revindex", t_63 - t_62);
frame.set("loop.revindex0", t_63 - t_62 - 1);
frame.set("loop.first", t_62 === 0);
frame.set("loop.last", t_62 === t_63 - 1);
frame.set("loop.length", t_63);
output += "\n\t\t\t\t\t\t\t\t\t\t\t<option ";
output += runtime.suppressValue((runtime.memberLookup((t_61),"Selected") == runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")?"selected":""), env.opts.autoescape);
output += " value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_65),"Key"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t";
;
}
if(runtime.memberLookup((t_61),"Type") == "Treeview") {
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"dropdown right filter-option\">\n\t\t\t\t\t\t\t\t\t\t<div class=\"button button-menu fake-button toggle-menu\">\n\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_61),"text"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t<div class=\"menu hide tree-view white\">\n\t\t\t\t\t\t\t\t\t\t\t";
output += runtime.suppressValue((lineno = 259, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "makeBranch"), "makeBranch", context, [t_61,runtime.memberLookup((t_61),"list")])), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t";
;
}
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\n\n\n\n\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Data") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Data")),"Search") && runtime.memberLookup(((lineno = 270, colno = 88, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"getSearchColumns"), "window[\"getSearchColumns\"]", context, []))),"length") > 0) {
output += "\n\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_68 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Data")),"Search");
if(t_68) {t_68 = runtime.fromIterator(t_68);
var t_67 = t_68.length;
for(var t_66=0; t_66 < t_68.length; t_66++) {
var t_69 = t_68[t_66];
frame.set("searchObj", t_69);
frame.set("loop.index", t_66 + 1);
frame.set("loop.index0", t_66);
frame.set("loop.revindex", t_67 - t_66);
frame.set("loop.revindex0", t_67 - t_66 - 1);
frame.set("loop.first", t_66 === 0);
frame.set("loop.last", t_66 === t_67 - 1);
frame.set("loop.length", t_67);
output += "\n\t\t\t\t\t\t\t\t\t\t";
var t_70;
t_70 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("offset", t_70, true);
if(frame.topLevel) {
context.setVariable("offset", t_70);
}
if(frame.topLevel) {
context.addExport("offset", t_70);
}
output += "\n\t\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_73 = t_69;
if(t_73) {t_73 = runtime.fromIterator(t_73);
var t_71;
if(runtime.isArray(t_73)) {
var t_72 = t_73.length;
for(t_71=0; t_71 < t_73.length; t_71++) {
var t_74 = t_73[t_71][0];
frame.set("[object Object]", t_73[t_71][0]);
var t_75 = t_73[t_71][1];
frame.set("[object Object]", t_73[t_71][1]);
frame.set("loop.index", t_71 + 1);
frame.set("loop.index0", t_71);
frame.set("loop.revindex", t_72 - t_71);
frame.set("loop.revindex0", t_72 - t_71 - 1);
frame.set("loop.first", t_71 === 0);
frame.set("loop.last", t_71 === t_72 - 1);
frame.set("loop.length", t_72);
output += "\n\t\t\t\t\t\t\t\t\t\t\t<div class=\"filter\" data-window-filter-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "offset"), env.opts.autoescape);
output += "\" data-window-event=\"remove-text-query:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "offset"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t\t<span class=\"close material-icons\" style=\"font-size:inherit;\">&#xe5cd;</span>\n\t\t\t\t\t\t\t\t\t\t\t\t<span>";
output += runtime.suppressValue(t_75, env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t";
;
}
} else {
t_71 = -1;
var t_72 = runtime.keys(t_73).length;
for(var t_76 in t_73) {
t_71++;
var t_77 = t_73[t_76];
frame.set("columnSelection", t_76);
frame.set("searchTerm", t_77);
frame.set("loop.index", t_71 + 1);
frame.set("loop.index0", t_71);
frame.set("loop.revindex", t_72 - t_71);
frame.set("loop.revindex0", t_72 - t_71 - 1);
frame.set("loop.first", t_71 === 0);
frame.set("loop.last", t_71 === t_72 - 1);
frame.set("loop.length", t_72);
output += "\n\t\t\t\t\t\t\t\t\t\t\t<div class=\"filter\" data-window-filter-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "offset"), env.opts.autoescape);
output += "\" data-window-event=\"remove-text-query:";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "offset"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t\t\t\t\t\t\t<span class=\"close material-icons\" style=\"font-size:inherit;\">&#xe5cd;</span>\n\t\t\t\t\t\t\t\t\t\t\t\t<span>";
output += runtime.suppressValue(t_77, env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t";
;
}
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\n\t\t\t\t\t\t\t\t\t<div class=\"ml-1 search-input-container flex-1 min-w-[200px]\" style=\"flex: 1;\">\n\t\t\t\t\t\t\t\t\t\t<input class=\"input ignore-next ignore-reset\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"PlaceholderSearch"), env.opts.autoescape);
output += "\" >\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</form>\n\t\t\t\t\t<div class=\"pt-1 flex\">\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination") && !runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")) {
output += "<div>\n\t\t\t\t\t\t\t<ul class=\"pagination  ml-3\">\n\t\t\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"first") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"first")?"disabled":""), env.opts.autoescape);
output += ">&#xe045;</li>\n\t\t\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"prev") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"prev")?"disabled":""), env.opts.autoescape);
output += ">&#xe314;</li>\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_80 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"sequence");
if(t_80) {t_80 = runtime.fromIterator(t_80);
var t_79 = t_80.length;
for(var t_78=0; t_78 < t_80.length; t_78++) {
var t_81 = t_80[t_78];
frame.set("item", t_81);
frame.set("loop.index", t_78 + 1);
frame.set("loop.index0", t_78);
frame.set("loop.revindex", t_79 - t_78);
frame.set("loop.revindex0", t_79 - t_78 - 1);
frame.set("loop.first", t_78 === 0);
frame.set("loop.last", t_78 === t_79 - 1);
frame.set("loop.length", t_79);
output += "<li class=\"page ";
output += runtime.suppressValue((runtime.memberLookup((t_81),"current")?"current":""), env.opts.autoescape);
output += "\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((t_81),"page"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_81),"page"), env.opts.autoescape);
output += "</li>\n\t\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"next") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"next")?"disabled":""), env.opts.autoescape);
output += ">&#xe315;</li>\n\t\t\t\t\t\t\t\t<li class=\"button button-menu material-icons\" data-window-event=\"page:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"last") || "", env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pagination")),"last")?"disabled":""), env.opts.autoescape);
output += ">&#xe044;</li>\n\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t<select class=\"number-of ml-2\">\n\t\t\t\t\t\t\t";
frame = frame.push();
var t_84 = [15,25,50,100,200,500];
if(t_84) {t_84 = runtime.fromIterator(t_84);
var t_83 = t_84.length;
for(var t_82=0; t_82 < t_84.length; t_82++) {
var t_85 = t_84[t_82];
frame.set("i", t_85);
frame.set("loop.index", t_82 + 1);
frame.set("loop.index0", t_82);
frame.set("loop.revindex", t_83 - t_82);
frame.set("loop.revindex0", t_83 - t_82 - 1);
frame.set("loop.first", t_82 === 0);
frame.set("loop.last", t_82 === t_83 - 1);
frame.set("loop.length", t_83);
output += "\n\t\t\t\t\t\t\t\t<option ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Request")),"Data") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Request")),"Data")),"PageSize") == t_85?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(t_85, env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t</div>\n\n\t\t\t";
;
}
output += "\n\n\t\t";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_header: b_header,
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/table.html", ctx);
          }
        
          return nunjucks.render("views/content/table.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/table.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        