/**
 * Clear html thoroughly
 * @returns {void}
 */
export default function clearHTML() {
  if (!this.element) {
    return;
  }

  if (this.element.firstChild) {
    while (this.element.children.length > 0) {
      this.element.removeChild(this.element.children[0]);
    }
  }

  this.element.innerHTML = "";
}
