/**
 * Remove text query
 * @param {*} id - Search id
 * @returns {Promise} To re-render
 */
export default async function removeTextQuery(id) {
  if (!this.input.Data.Search.length) {
    return;
  }

  // let textFilter = this.input.Data.Search[id]
  this.input.Data.Search.splice(id, 1);
  // $(this.element)
  //   .find(`.filter[data-window-filter-id='${id}']`)
  //   .remove()

  await this.reload();
}
