import {nonActionAxiosInstance} from "../nonActionAxiosInstance.js";
import store from "../../../state/store.js";

export const getPostbackValues = async ({window, values}) => {
  const postbackValues = await nonActionAxiosInstance.post(
    "/Admin/WebServices/CoreWebServices.asmx/PostbackValues",
    {
      request: window.input,
      values: values,
      previousValues: window.previousPostbackValues,
    },
    {
      params: {
        warehouse: store.state.activeWarehouse,
      },
    },
  );

  window.previousPostbackValues = values;

  return postbackValues;
};
