<template>
  <div>
    <timeline
      ref="timeline"
      :groups="groups"
      :items="items"
      :options="options"
      :style="{'min-height': minHeight}"
      class="item-availability-timeline"
      style="position: relative"
      @click="handleSegmentClick($event)"
      @rangechanged="handleTimeChanged"
    />
  </div>
</template>
<script>
import {Timeline} from "vue2vis";

export default {
  name: "ItemAvailabilityPlanner",
  components: {
    Timeline,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    minHeight: {
      type: String,
      required: false,
      default: "600px",
    },
    groups: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    options: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      rangeChangedTimestamp: null,
    };
  },
  methods: {
    handleTimeChanged() {
      this.rangeChangedTimestamp = new Date().getTime();
    },
    handleSegmentClick(properties) {
      const segment = this.items[properties.item];

      // ensure the user is not currently dragging
      if (
        this.rangeChangedTimestamp !== null &&
        new Date().getTime() - this.rangeChangedTimestamp < 800
      ) {
        return;
      }

      if (properties.item !== null) {
        this.$emit("segment-click", {
          item: properties.item,
          segment: segment,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.item-availability-timeline {
  .vis-time-axis .vis-grid.vis-odd {
    background: #f5f5f5;
  }

  .vis-group {
    height: 28px !important;
  }

  .vis-label {
    height: 28px !important;

    .btn {
      height: 22px;
      transform: translateY(-15.5%);
      border-radius: 1px !important;
    }

    .btn i {
      transform: translateY(-14%);
    }
  }

  .vis-item .vis-item-content {
    padding: 0px 0px 0px 4px !important;
    word-break: normal;
    overflow-wrap: normal;
  }
}
</style>
