import store from "../../../state/store";

export default async function reloadSub(output) {
  this.input.Criteria = [];
  if (output) {
    this.input.Criteria = output.Request.Criteria;
  }
  this.fetch();
  store.commit("updateWindow");
}
