<template>
  <div class="login cover v-center">
    <div class="container">
      <div class="box pull-center">
        <form @submit.prevent="submit">
          <div class="logo">
            <span
              :class="[
                'logo-icon',
                'infodatek-icon',
                'infodatek-icon-' + (session.product || '').toLowerCase(),
              ]"
            ></span
            ><br />
            <span class="logo-text">{{ session.product }}</span>
          </div>

          <div v-if="error" class="error-box mb-4">
            {{ error }}
          </div>

          <div class="mb-4">
            <p>
              {{ translations.TwoFactorLoginEnterMFACode }}
            </p>
          </div>

          <div class="form-element font-medium flex justify-center">
            <input
              id="input-1"
              v-model="totpCode"
              class="inputCode h-[40px] w-full text-center rounded tracking-widest !text-lg"
              maxlength="8"
              minlength="6"
              type="text"
              :placeholder="translations.EnterCode"
              required
              @input="handleInput"
            />
          </div>

          <div class="form-element mt-4">
            <RButton
              :variant="'primary'"
              :override-classes="'w-full mx-0'"
              @click="submit"
            >
              {{ translations.LogIn }}
            </RButton>
            <RButton
              :variant="'secondary'"
              :override-classes="'w-full mt-2 mx-0'"
              @click.stop="showLogin()"
            >
              {{ translations.Back }}
            </RButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import postMembershipLoginVerifyTOTP from "../../src/services/totp/postMembershipLoginVerifyTOTP.js";
import RButton from "../../src/components/elements/RButton.vue";

export default {
  name: "LoginMFA",
  components: {RButton},
  props: {
    session: {
      type: Object,
      default: () => {},
    },
    loginToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      totpCode: null,
      error: null,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  methods: {
    async submit() {
      this.$data.error = null;
      let result = await postMembershipLoginVerifyTOTP({
        loginToken: this.$props.loginToken,
        totpCode: this.$data.totpCode,
      });

      if (result.status == 401) {
        this.$emit(
          "showComponent",
          "loginPage",
          this.translations.LoginTokenExpired,
        );
        return;
      }

      switch (result.data.Status) {
        case "Success":
          this.$emit("setBearerToken", result.data.Token);

          if (result.data.BackUpCodeUsed) {
            this.$emit("showComponent", "loginAfterBackupCode");
          } else {
            this.$emit("showComponent", "loginSessionList");
          }
          break;
        default:
          this.$data.error = result.data.Error;
          break;
      }
    },
    handleInput() {
      if (this.totpCode.length > 5 && !isNaN(parseFloat(this.totpCode)))
        this.submit();
    },
    showLogin() {
      this.$emit("showComponent", "loginPage");
    },
  },
};
</script>

<style scoped>
.inputCode {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.inputCode:focus {
  color: #495057;
  border-color: #f9b99a;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 101, 34, 0.25);
}

input::placeholder {
  color: #999;
  opacity: 0.7;
  letter-spacing: normal;
  font-size: 13.2px;
}
</style>
