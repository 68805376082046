import Hook from "../hook";
import shortid from "shortid";

/**
 * Window object
 * @property {string} id - Window Identifier
 * @property {Window} parent - Parent window
 * @property {Object} sub - Sub window object
 * @property {Session} session - Current session
 * @property {boolean} loading - If the window is loading
 * @property {Element} element - window html element
 * @property {Object} input - Server Input object
 * @property {Object} output - Server output object
 * @property {Object} output.Request - Request object
 * @property {Object} output.Data - Data object
 * @property {number} counter - counter
 * @property {Object} lastSuccessInput - Last succesful input values
 * @property {Object} actionInputArgs - (Dialogue)Action input
 * @property {*} lastActionArgs - Last succesful action input values
 * @property {boolean} waitingForReload - Windows is waiting for reload
 * @property {*} error - Window error
 * @property {boolean} empty - Window is empty
 * @property {Object} options - Window logic options
 * @property {boolean} options.customKeyEvents - Ignore normal key events like escape, enter etc.
 * @property {boolean} options.noNewLines - Do not add new line to bulkedit
 * @property {boolean} options.autoFocus - Autofocus first input
 * @property {boolean} options.noReset - Allow reset
 * @property {Object} renderOptions - Window render options
 * @property {boolean} renderOptions.actionbar - Show action bar
 * @property {boolean} renderOptions.customButtons - Use custom buttons in bulkedit mode
 * @property {boolean} renderOptions.overlay - Cover the whole form(also cover subforms)
 * @property {boolean} renderOptions.inactive - Cover content
 * @property {Object} filters - Window filters
 * @property {*} bulkedit - Window is in bulkedit mode
 * @property {Array} selection - List of selection
 * @property {Array} tabs - List of tabs
 * @property {string | null} customTemplateName - (optional) Custom template name
 * @property {string | null} customTitle - (optional) Custom title
 * @property {Object} customData - (optional) custom Data
 * @property {boolean} customInit - (optional) custum Init function check
 */
export default class Window {
  id = null;
  element = null;
  parent = null;
  sub = null;
  session = null;
  loading = true;
  dateTimeCreated = null;
  subWindow = false;
  vueColumns = {};

  input = {};

  output = {
    Request: {},
    Data: {},
  };

  previousPostbackValues = null;

  counter = 0;
  lastSuccessInput = null;
  actionInputArgs = {};
  lastActionArgs = null;
  waitingForReload = false;
  error = null;
  empty = false;

  options = {
    customKeyEvents: false,
    noNewLines: false,
    autoFocus: true,
    noReset: false,
  };

  renderOptions = {
    actionbar: true,
    customButtons: false,
    overlay: false,
    inactive: false,
  };

  // table properties
  filters = {};

  bulkedit = false;
  selection = [];
  tabs = [];

  // custom variables
  customTemplateName = null;
  customTitle = null;
  customData = {};
  customInit = false;

  // set extra data to window send in responses by server
  extraData = null;

  /**
   * Window constructor
   * @param {Session} session - Session
   * @param {Window} parent - Parent window
   */
  constructor(session, parent = null) {
    Object.assign(this, require("./window-actions")());

    this.parent = parent || null;
    this.session = session;
    this.dayjs = require("dayjs");
    const weekOfYear = require("dayjs/plugin/weekOfYear");
    this.dayjs.extend(weekOfYear);
    this.dayjs.locale(global.session.language);
    this.dateTimeCreated = this.dayjs().format("YYYY-MM-DD HH:mm:ss");
    if (global.session.language === "nl") import("dayjs/locale/nl");
    if (global.session.language === "fr") import("dayjs/locale/fr");
    if (global.session.language === "de") import("dayjs/locale/de");

    // set ID
    this.id = shortid.generate();

    // Register window in session and run create event
    if (this.session !== null) {
      this.session.windows[this.id] = this;
      Hook.run("create", this);
    }

    return this;
  }
}
