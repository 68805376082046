import {formRowIsDirty} from "../../functions/form/formRowIsDirty.js";

/**
 * Check if dirty values exists
 * @returns {boolean} True if the window is dirty
 */
export default function isDirty() {
  const formWindow = this.sub?.window ?? this;

  if (!formWindow || formWindow.output.Request.Prefix === "Multi") return false;

  const originalRow = formWindow.originalFormRow;
  const newRow = formWindow.output?.Table?.Rows[0];

  if (originalRow && newRow) return formRowIsDirty({originalRow, newRow});

  if (this.bulkedit) {
    return true;
  }

  if (this.output != null && this.output.Table != null) {
    for (let row of this.output.Table.Rows) {
      if (row.filter((cell) => cell.IsDirty).length > 0) {
        return true;
      }
    }
  }

  if (this.dirty) return true;
}
