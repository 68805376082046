import store from "../../state/store.js";
import {capitalizeFirstCharInString} from "./capitalizeFirstCharInString.js";

export const getBase64SoundFromSettings = ({sound}) => {
  const settings = store.state.settings;
  const setting =
    settings[`Sound${capitalizeFirstCharInString({string: sound})}`];
  const parsedSetting = JSON.parse(setting);

  if (!parsedSetting?.Base64File) return;

  return parsedSetting.Base64File;
};
