// Check whether there is a change in the number of columns in a section,
// to force a re-render of the form.

export const hasSectionColumnsShift = ({newColumns, newSections, window}) => {
  const oldVisibleColumns = window.output?.Table?.Columns.filter(
    (column) => column.IsVisible,
  ).map((column) => column.Name);

  const newVisibleColumns = Object.keys(newColumns).filter(
    (column) => newColumns[column].IsVisible,
  );

  const allNewInOld = newVisibleColumns.every((column) =>
    oldVisibleColumns.includes(column),
  );

  if (oldVisibleColumns.length !== newVisibleColumns.length || !allNewInOld)
    return true;

  const oldSections = window.output?.Data?.Sections;
  for (const section in oldSections) {
    if (
      !newSections[section]?.Columns ||
      newSections[section]?.Columns.length !==
        oldSections[section]?.Columns.length
    ) {
      return true;
    }
  }

  return false;
};
