import dayjs from "dayjs";

export const convertTwoNumbersToDate = (value) => {
  let newValue = value;
  if (newValue.length === 2) {
    // check if newValue is a day longer then the current month
    if (parseInt(newValue) > dayjs().daysInMonth()) return newValue;

    // add - to newValue appended by month in MM and year in YYYY
    newValue =
      newValue + "-" + dayjs().format("MM") + "-" + dayjs().format("YYYY");
  }
  return newValue;
};
