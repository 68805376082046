<template>
  <span>
    <span v-for="(value, index) in parsedValue" :key="value.Description">
      <span v-if="index > 0"> / </span
      ><strong :class="`text-${value.Type}`" :title="value.Description">{{
        value.Text
      }}</strong>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
  },
  computed: {
    parsedValue: function () {
      if (!this.value) return null;
      return JSON.parse(this.value);
    },
  },
};
</script>
