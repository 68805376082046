export const getIconClasses = ({iconAdditionalClasses, iconType}) => {
  const baseClasses = `${iconAdditionalClasses} text-7xl`;
  const colorMap = {
    success: "text-green-500",
    primary: "text-yellow",
    warning: "text-yellow",
    danger: "text-red-500",
    error: "text-red-500",
    info: "text-blue-400",
  };

  return `${baseClasses} ${colorMap[iconType] || "text-blue-400"}`;
};
