<template>
  <div class="w-100">
    <date-range-picker
      ref="picker"
      v-model="dateRange"
      class="float-right"
      :locale-data="dataFormat"
      :date-format="dateFormat"
      :show-dropdowns="true"
      :auto-apply="true"
      :time-picker="false"
      :ranges="false"
      :single-date-picker="true"
      opens="right"
      @change-month="updateClosingDays($event)"
    >
    </date-range-picker>

    <date-field
      :date="exposedStartDate"
      :show-time="showTime"
      :original-value="value"
      :readonly="readonly"
      :required="metadata.IsRequired"
      vue-input
      :random-i-d="randomID"
      :disabled-dates="disabledDates"
      @change="updateStartDate($event)"
      @keyup.enter.native="togglePicker()"
    >
      <r-button
        v-if="readonly != 'true'"
        class="z-10 ml-[-35px]"
        variant="link"
        @mousedown="togglePicker()"
        ><i class="fas fa-calendar-alt text-dark"></i></r-button
    ></date-field>

    <input
      :id="randomID"
      v-model="exposedStartDate"
      vue-input
      :name="name"
      vue-date-time-field
      hidden
    />
  </div>
</template>

<script>
import DateRangePicker from "./calendar/DateRangePicker.vue";

import dayjs from "dayjs";
import generateRandomID from "../../src/util/generateRandomID";
import dateField from "./dateField.vue";
import {getColumnMetaDataFromWindowId} from "@/functions/window/getColumnMetaDataFromWindowId";
import {nonActionAxiosInstance} from "../../src/services/nonActionAxiosInstance";
import RButton from "../../src/components/elements/RButton.vue";
import {getWarehouseIdFromFormRow} from "../../src/functions/form/getWarehouseIdFromFormRow.js";

export default {
  name: "DateTime",
  components: {"date-range-picker": DateRangePicker, dateField, RButton},
  props: {
    providedValue: {
      type: String,
      required: true,
    },
    today: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    postback: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterOnOpeningsDay: {
      type: String,
      required: false,
      default: "false",
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: String,
      required: false,
    },
    windowId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dateRange: {
        startDate: "",
        endDate: "",
      },
      exposedStartDate: "",
      disabledDates: [],
      checkedDates: [],
      metadata: {},
      value: "",
    };
  },
  computed: {
    startDate: function () {
      if (this.dateRange.startDate) {
        return dayjs(this.dateRange.startDate).format("DD-MM-YYYY");
      }
      return "";
    },
    randomID: function () {
      return generateRandomID(8);
    },
    translations: function () {
      return this.$store.state.translations;
    },
    dataFormat: function () {
      return {
        direction: "ltr",
        format: "dd-mm-yyyy",
        separator: " - ",
        changed: false,
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: [
          this.translations["calendar-sun"],
          this.translations["calendar-mon"],
          this.translations["calendar-tue"],
          this.translations["calendar-wed"],
          this.translations["calendar-thu"],
          this.translations["calendar-fri"],
          this.translations["calendar-sat"],
        ],
        monthNames: [
          this.translations["calendar-jan"],
          this.translations["calendar-feb"],
          this.translations["calendar-mar"],
          this.translations["calendar-apr"],
          this.translations["calendar-may"],
          this.translations["calendar-jun"],
          this.translations["calendar-jul"],
          this.translations["calendar-aug"],
          this.translations["calendar-sep"],
          this.translations["calendar-oct"],
          this.translations["calendar-nov"],
          this.translations["calendar-dec"],
        ],
        firstDay: 0,
      };
    },
  },
  watch: {
    dateRange() {
      this.changed = true;
      if (
        dayjs(this.dateRange.startDate).format("DD-MM-YYYY") !== "Invalid Date"
      ) {
        this.exposedStartDate = dayjs(this.dateRange.startDate).format(
          "DD-MM-YYYY",
        );
      }
    },
    startDate() {
      if (this.changed) {
        this.processChange();
        let $this = this;
        $.when($(document).find(`#${this.randomID}`).trigger("change")).done(
          function () {
            if ($this.postback == true) {
              $this.runPostback();
            }
            (
              global.session.activeWindow?.sub?.window ??
              global.session.activeWindow
            ).dirty = true;
            return;
          },
        );
      }
      this.changed = true;
    },
  },
  created() {
    this.value = this.providedValue;
    let value = this.value;
    if (!this.value && this.today) {
      value = dayjs().format("DD-MM-YYYY");
    }

    if (value) {
      this.dateRange.startDate = dayjs(value, "DD-MM-YYYY").format(
        "YYYY-MM-DD",
      );
      this.updateClosingDays(this.dateRange.startDate);
    } else {
      this.updateClosingDays();
    }

    if (dayjs(value, "DD-MM-YYYY").format("DD-MM-YYYY") !== "Invalid Date") {
      this.exposedStartDate = dayjs(value, "DD-MM-YYYY").format("DD-MM-YYYY");
    } else if (dayjs(value).format("DD-MM-YYYY") !== "Invalid Date") {
      this.exposedStartDate = dayjs(value).format("DD-MM-YYYY");
    }

    this.metadata = getColumnMetaDataFromWindowId({
      windowId: this.windowId,
      columnName: this.name,
      session: global.session,
    });
  },
  methods: {
    updateStartDate(val) {
      this.changed = true;
      if (val === "" || dayjs(val).format("DD-MM-YYYY") === "Invalid Date") {
        this.dateRange.startDate = "";
        this.dateRange.endDate = "";
        this.exposedStartDate = "";
        return;
      }

      this.dateRange.startDate = dayjs(val).toDate();
      this.dateRange.endDate = dayjs(val).toDate();
      const formattedDate = dayjs(val).format("DD-MM-YYYY");

      if (formattedDate && formattedDate !== "Invalid Date") {
        this.exposedStartDate = "";
        this.exposedStartDate = formattedDate;
      }
    },
    togglePicker() {
      this.$refs.picker.openPicker();
    },
    processChange() {
      const values = {};

      const firstRow =
        this.$store.state.window.output?.FullTable?.Rows[0] ??
        this.$store.state.window.output.Sub?.Table?.Rows[0] ??
        Object.values(
          global.session.activeWindow?.customData?.rows?.[0].cells ?? {},
        );

      if (!firstRow.length > 0) {
        return [];
      }

      for (let cell of firstRow) {
        values[cell.Column.Name] = cell.NewValue;

        if (cell.Column.Name === this.name) {
          cell.NewValue = this.startDate;
        }
      }

      values[this.name] = this.startDate;

      return values;
    },
    runPostback() {
      const values = this.processChange();

      Promise.resolve(
        global.session.windows[this.windowId].postbackValues(values),
      );
    },
    dateFormat(classes, date) {
      if (this.disabledDates.length > 0) {
        for (const disabledDate of this.disabledDates) {
          if (dayjs(disabledDate).isSame(dayjs(date), "day")) {
            classes.disabled = true;
          }
        }
      }
      return classes;
    },
    updateClosingDays: async function (date) {
      if (this.filterOnOpeningsDay === "false") {
        return;
      }

      if (!date) {
        date = new Date();
      }

      if (this.checkedDates.includes(dayjs(date).format("YYYY-MM-DD"))) {
        return;
      }

      const start = dayjs(date).subtract("2", "month").format("YYYY-MM-DD");
      const end = dayjs(date).add("2", "month").format("YYYY-MM-DD");

      const relevantClosingDaysWarehouse = getWarehouseIdFromFormRow({
        windowId: this.windowId,
      });

      const disabledDates = await nonActionAxiosInstance.get(
        "/api/v2/closingdays",
        {
          params: {
            warehouse: relevantClosingDaysWarehouse,
            dateFrom: start,
            dateTo: end,
          },
        },
      );

      this.disabledDates = this.disabledDates.concat(disabledDates.data);

      this.checkedDates.push(dayjs(date).format("YYYY-MM-DD"));
    },
  },
};
</script>

<style></style>
