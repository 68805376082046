import {alertPopup} from "../../interface/alertPopup/alertPopup.js";
import {hasMissingRequiredField} from "./hasMissingRequiredField.js";

export function rowIsMissingRequiredFormFields({
  row,
  activeWindow,
  translations,
}) {
  const formSections = activeWindow.output.serverData.Sections;
  const formColumns = [];

  for (const section in formSections) {
    formColumns.push(...formSections[section]["Columns"]);
  }

  let missingFieldsString = "";
  let missingFieldsCount = 0;

  for (const field of row) {
    for (const formColumn of formColumns) {
      if (hasMissingRequiredField({formColumn, field, activeWindow})) {
        if (missingFieldsCount >= 1) {
          missingFieldsString = missingFieldsString.concat(
            ", " + field.Column.Title,
          );
        } else {
          missingFieldsString = field.Column.Title;
        }

        missingFieldsCount++;
      }
    }
  }

  if (missingFieldsCount > 0) {
    const message = translations.ExceptionRequiredFieldNotGiven.replace(
      "{}",
      missingFieldsString,
    );

    alertPopup({icon: "error", text: message});

    return true;
  }

  return false;
}
