export function setPostbackValues({rows}) {
  const changes = global.session.activeWindow.output.Data?.Changes;
  if (!changes) return {};

  let values = {};
  outerLoop: for (let rowcell of rows) {
    for (let change of changes) {
      if (Object.prototype.hasOwnProperty.call(change, rowcell.Column.Name)) {
        values[rowcell.Column.Name] = change[rowcell.Column.Name];
        continue outerLoop;
      }
    }
    values[rowcell.Column.Name] = rowcell.NewValue;
  }

  return values;
}
