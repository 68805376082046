<template>
  <div>
    <action-bar-actions
      v-if="simpleInterface === false && showActionBar === false"
      :window="window"
      :subWindowid="subWindow.id || null"
      :buttons="buttons"
      :subWindow="subWindow"
    />
  </div>
</template>

<script>
import ActionBarActions from "./actionBar/ActionBarHorizontalActions.vue";

export default {
  components: {ActionBarActions},
  computed: {
    window: function () {
      return this.$store.getters.window;
    },
    subWindow: function () {
      return this.$store.getters.subWindow;
    },
    windowOutput: function () {
      if (!this.window.output) return {};
      return this.window.output;
    },
    subWindowOutput: function () {
      if (!this.subWindow.output) return {};
      return this.subWindow.output;
    },
    windowButtons: function () {
      if (!this.window.output?.Buttons) return [];
      return this.window.output?.Buttons;
    },
    showActionBar() {
      return this.$store.getters.showActionBar;
    },
    subWindowButtons: function () {
      if (!this.subWindowOutput) return [];
      if (!this.subWindowOutput.Buttons) return [];
      return this.subWindowOutput.Buttons;
    },
    buttons: function () {
      if (this.subWindowButtons.length > 0) return this.subWindowButtons;
      if (this.windowButtons.length > 0) return this.windowButtons;
      return [];
    },
    simpleInterface: function () {
      return this.$store.getters.simpleInterface;
    },
  },
};
</script>

<style></style>
