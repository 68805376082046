
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/permissions.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"row form-permissions\">\n  <div class=\"col col-xs-12 col-sm-6 col-md-4\">\n    ";
if(!env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "custom"))) {
output += " ";
var t_1;
t_1 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data");
frame.set("custom", t_1, true);
if(frame.topLevel) {
context.setVariable("custom", t_1);
}
if(frame.topLevel) {
context.addExport("custom", t_1);
}
output += " ";
;
}
output += " ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"inputVars")) {
output += "\n    <input\n      type=\"hidden\"\n      name=\"tablename\"\n      value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"inputVars")),"tableName"), env.opts.autoescape);
output += "\"\n    />\n    <input type=\"hidden\" name=\"roleid\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"inputVars")),"roleID"), env.opts.autoescape);
output += "\" />\n    ";
;
}
output += "\n    <div class=\"content\">\n      <form data-form-id=\"FormPermissions\">\n        <div class=\"tree-view\">\n          ";
frame = frame.push();
var t_4 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"FormPermissionMenu");
if(t_4) {t_4 = runtime.fromIterator(t_4);
var t_3 = t_4.length;
for(var t_2=0; t_2 < t_4.length; t_2++) {
var t_5 = t_4[t_2];
frame.set("branch", t_5);
frame.set("loop.index", t_2 + 1);
frame.set("loop.index0", t_2);
frame.set("loop.revindex", t_3 - t_2);
frame.set("loop.revindex0", t_3 - t_2 - 1);
frame.set("loop.first", t_2 === 0);
frame.set("loop.last", t_2 === t_3 - 1);
frame.set("loop.length", t_3);
output += "\n          <div class=\"branch\">\n            <div class=\"branch-name\">";
output += runtime.suppressValue(runtime.memberLookup((t_5),"Text"), env.opts.autoescape);
output += "</div>\n            <div class=\"branches\">\n              ";
frame = frame.push();
var t_8 = runtime.memberLookup((t_5),"children");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("branch2", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\n              <div class='branch ";
output += runtime.suppressValue(((runtime.memberLookup((t_9),"Collapsed"))?"collapsed":""), env.opts.autoescape);
output += "'>\n                <div class=\"branch-name\">";
output += runtime.suppressValue(runtime.memberLookup((t_9),"Text"), env.opts.autoescape);
output += "</div>\n                <div class=\"branches\">\n                  ";
frame = frame.push();
var t_12 = runtime.memberLookup((t_9),"children");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("branch3", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
output += "\n                  <div class=\"stick\">\n                    <label class=\"table-cell field checkbox\">\n                      <input name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"ID"), env.opts.autoescape);
output += "\" type=\"checkbox\" ";
output += runtime.suppressValue(((runtime.memberLookup((t_13),"Available"))?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"IsReadOnly")?"disabled":""), env.opts.autoescape);
output += " ";
if(runtime.memberLookup((t_13),"IsReadOnly")) {
output += "\n                      style=\"visibility: hidden\" ";
;
}
output += ">\n                      <span\n                        data-window-event=\"selectPermissions:";
output += runtime.suppressValue(runtime.memberLookup((t_13),"TableName"), env.opts.autoescape);
output += ":";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"RoleID"), env.opts.autoescape);
output += ":";
output += runtime.suppressValue(runtime.memberLookup((t_13),"Text"), env.opts.autoescape);
output += ":true\"\n                        >";
output += runtime.suppressValue(runtime.memberLookup((t_13),"Text"), env.opts.autoescape);
output += "</span\n                      >\n                    </label>\n                  </div>\n                  ";
if(runtime.memberLookup((t_13),"children")) {
output += "\n                  <div class='branch ";
output += runtime.suppressValue(((runtime.memberLookup((t_13),"Collapsed"))?"collapsed":""), env.opts.autoescape);
output += "'>\n                    <div class=\"branch-name\">";
output += runtime.suppressValue(runtime.memberLookup((t_13),"Text"), env.opts.autoescape);
output += "</div>\n                    <div class=\"branches\">\n                      ";
frame = frame.push();
var t_16 = runtime.memberLookup((t_13),"children");
if(t_16) {t_16 = runtime.fromIterator(t_16);
var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("branch4", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
output += "\n                      <div class=\"stick\">\n                        <label class=\"table-cell\">\n                          ";
if(!runtime.memberLookup((t_17),"IsReadOnly")) {
output += " <input name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_17),"ID"), env.opts.autoescape);
output += "\" type=\"checkbox\" ";
output += runtime.suppressValue(((runtime.memberLookup((t_17),"Available"))?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"IsReadOnly")?"disabled":""), env.opts.autoescape);
output += "> ";
;
}
output += "\n                          <span\n                            data-window-event=\"selectPermissions:";
output += runtime.suppressValue(runtime.memberLookup((t_17),"TableName"), env.opts.autoescape);
output += ":";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"RoleID"), env.opts.autoescape);
output += ":";
output += runtime.suppressValue(runtime.memberLookup((t_17),"Text"), env.opts.autoescape);
output += ":true\"\n                            >";
output += runtime.suppressValue(runtime.memberLookup((t_17),"Text"), env.opts.autoescape);
output += "</span\n                          >\n                        </label>\n                      </div>\n                      ";
;
}
}
frame = frame.pop();
output += "\n                    </div>\n                  </div>\n                  ";
;
}
output += " ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n              </div>\n              ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n          </div>\n          ";
;
}
}
frame = frame.pop();
output += "\n        </div>\n      </form>\n    </div>\n  </div>\n  <div class=\"col-xs-12 col-md-8 col-sm-6 left-border\">\n    ";
if(!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ActionInfo")),"length") && !runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ColumnInfo")),"length") && !runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"TabInfo")),"length")) {
output += "\n    <!--Nothing to display, maybe some informative text-->\n    ";
;
}
else {
output += "\n    <div class=\"breadcrumbs\">\n      ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"previousPermissionScreen")) > 1) {
output += "\n      <button\n        class=\"btn btn-primary btn-sm text-white\"\n        data-window-event=\"openPreviousPermissionsScreen\"\n      >\n        <i class=\"fas fa-arrow-left\"></i>\n      </button>\n      ";
;
}
output += " ";
frame = frame.push();
var t_20 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"previousPermissionScreen");
if(t_20) {t_20 = runtime.fromIterator(t_20);
var t_19 = t_20.length;
for(var t_18=0; t_18 < t_20.length; t_18++) {
var t_21 = t_20[t_18];
frame.set("breadcrumb", t_21);
frame.set("loop.index", t_18 + 1);
frame.set("loop.index0", t_18);
frame.set("loop.revindex", t_19 - t_18);
frame.set("loop.revindex0", t_19 - t_18 - 1);
frame.set("loop.first", t_18 === 0);
frame.set("loop.last", t_18 === t_19 - 1);
frame.set("loop.length", t_19);
output += "\n      <div class=\"crumb\">";
output += runtime.suppressValue(runtime.memberLookup((t_21),"title"), env.opts.autoescape);
output += "</div>\n      ";
;
}
}
frame = frame.pop();
output += "\n    </div>\n    <div class=\"content\">\n      ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"TabInfo")) > 1) {
output += "\n      <h3>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Tabs"), env.opts.autoescape);
output += "</h3>\n      <form data-form-id=\"Tabs\">\n        <ul class=\"nav nav-tabs\">\n          ";
frame = frame.push();
var t_24 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"TabInfo");
if(t_24) {t_24 = runtime.fromIterator(t_24);
var t_23 = t_24.length;
for(var t_22=0; t_22 < t_24.length; t_22++) {
var t_25 = t_24[t_22];
frame.set("tab", t_25);
frame.set("loop.index", t_22 + 1);
frame.set("loop.index0", t_22);
frame.set("loop.revindex", t_23 - t_22);
frame.set("loop.revindex0", t_23 - t_22 - 1);
frame.set("loop.first", t_22 === 0);
frame.set("loop.last", t_22 === t_23 - 1);
frame.set("loop.length", t_23);
output += "\n          <li class=\"nav-item ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") == 1?" avoid-clicks":""), env.opts.autoescape);
output += "\">\n            <input type=\"checkbox\" style=\"margin-top:10px;\" class=\"ml-3\n            float-left\" name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_25),"ActionName"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") == 1?"disabled":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(((runtime.memberLookup((t_25),"Available") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") == 1)?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"IsReadOnly")?"disabled":""), env.opts.autoescape);
output += ">\n            <a\n              class=\"nav-link pl-4 ml-2 ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") == 1?"disabled active avoid-clicks":""), env.opts.autoescape);
output += "\"\n              data-window-event=\"selectPermissions:";
output += runtime.suppressValue(runtime.memberLookup((t_25),"ReferencedTableName"), env.opts.autoescape);
output += ":";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"RoleID"), env.opts.autoescape);
output += ":";
output += runtime.suppressValue(runtime.memberLookup((t_25),"ActionTitle"), env.opts.autoescape);
output += "\"\n              >";
output += runtime.suppressValue(runtime.memberLookup((t_25),"ActionTitle"), env.opts.autoescape);
output += "</a\n            >\n          </li>\n          ";
;
}
}
frame = frame.pop();
output += "\n        </ul>\n      </form>\n      ";
;
}
output += "\n      <h3>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Buttons"), env.opts.autoescape);
output += "</h3>\n\n      ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ActionInfo") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ActionInfo")),"length")) {
output += "\n      <form class=\"clearfix\" data-form-id=\"Actions\">\n        ";
frame = frame.push();
var t_28 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ActionInfo");
if(t_28) {t_28 = runtime.fromIterator(t_28);
var t_27 = t_28.length;
for(var t_26=0; t_26 < t_28.length; t_26++) {
var t_29 = t_28[t_26];
frame.set("x", t_29);
frame.set("loop.index", t_26 + 1);
frame.set("loop.index0", t_26);
frame.set("loop.revindex", t_27 - t_26);
frame.set("loop.revindex0", t_27 - t_26 - 1);
frame.set("loop.first", t_26 === 0);
frame.set("loop.last", t_26 === t_27 - 1);
frame.set("loop.length", t_27);
output += "\n        <div style=\"float: left\">\n          <label class=\"button button-menu check-button\"\n            ><input type=\"checkbox\" name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_29),"ActionName"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue(((runtime.memberLookup((t_29),"Available"))?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"IsReadOnly")?"disabled":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_29),"ActionTitle"), env.opts.autoescape);
output += "</label\n          >\n        </div>\n        ";
;
}
}
frame = frame.pop();
output += "\n      </form>\n      ";
;
}
output += "\n\n      <h3>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Columns"), env.opts.autoescape);
output += "</h3>\n      ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ColumnInfo") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ColumnInfo")),"length")) {
output += "\n      <div class=\"content\">\n        <form class=\"table-view bordered\" data-form-id=\"Columns\">\n          <div class=\"table-container\">\n            <div class=\"table-index\">\n              <div class=\"table-row\">\n                <div class=\"table-cell\">\n                  <div class=\"ellipsis\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Column"), env.opts.autoescape);
output += "\n                  </div>\n                </div>\n                <div\n                  class=\"table-cell checkbox\"\n                  title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ShowInMulti"), env.opts.autoescape);
output += "\"\n                  data-event-select-all-multi\n                >\n                  <div class=\"ellipsis\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ShowInMulti"), env.opts.autoescape);
output += "\n                  </div>\n                </div>\n                <div\n                  class=\"table-cell checkbox\"\n                  title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ShowInSingle"), env.opts.autoescape);
output += "\"\n                  data-event-select-all-single\n                >\n                  <div class=\"ellipsis\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ShowInSingle"), env.opts.autoescape);
output += "\n                  </div>\n                </div>\n                <div\n                  class=\"table-cell checkbox\"\n                  title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ShowInNew"), env.opts.autoescape);
output += "\"\n                  data-event-select-all-new\n                >\n                  <div class=\"ellipsis\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"ShowInNew"), env.opts.autoescape);
output += "\n                  </div>\n                </div>\n                <div\n                  class=\"table-cell checkbox\"\n                  title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Editable"), env.opts.autoescape);
output += "\"\n                  data-event-select-all-editable\n                >\n                  <div class=\"ellipsis\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Editable"), env.opts.autoescape);
output += "\n                  </div>\n                </div>\n              </div>\n            </div>\n            <div class=\"table-body\">\n              <div class=\"table-rows\">\n                ";
frame = frame.push();
var t_32 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"ColumnInfo");
if(t_32) {t_32 = runtime.fromIterator(t_32);
var t_31 = t_32.length;
for(var t_30=0; t_30 < t_32.length; t_30++) {
var t_33 = t_32[t_30];
frame.set("x", t_33);
frame.set("loop.index", t_30 + 1);
frame.set("loop.index0", t_30);
frame.set("loop.revindex", t_31 - t_30);
frame.set("loop.revindex0", t_31 - t_30 - 1);
frame.set("loop.first", t_30 === 0);
frame.set("loop.last", t_30 === t_31 - 1);
frame.set("loop.length", t_31);
output += "\n                <div class=\"table-row\">\n                  <div\n                    class=\"table-cell field\"\n                    data-window-event=\"toggle-column:";
output += runtime.suppressValue(runtime.memberLookup((t_33),"ColumnName"), env.opts.autoescape);
output += "\"\n                  >\n                    ";
output += runtime.suppressValue(runtime.memberLookup((t_33),"ColumnTitle"), env.opts.autoescape);
output += " ";
if(runtime.memberLookup((t_33),"IsRequired")) {
output += "\n                    <span\n                      class=\"text-danger\"\n                      title=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"RequiredField"), env.opts.autoescape);
output += "\"\n                      >*</span\n                    >\n                    ";
;
}
output += "\n                  </div>\n                  <label class=\"table-cell field checkbox\">\n                    ";
if(runtime.memberLookup((runtime.memberLookup((t_33),"Multi")),"IsVisible")) {
output += " <input multi-checkbox name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_33),"ColumnName"), env.opts.autoescape);
output += "[Multi]\" type=\"checkbox\" ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.memberLookup((t_33),"Multi")),"Value"))?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"IsReadOnly") || runtime.memberLookup((runtime.memberLookup((t_33),"Multi")),"IsReadOnly"))?"disabled":""), env.opts.autoescape);
output += "> ";
;
}
output += "\n                  </label>\n                  <label class=\"table-cell field checkbox\">\n                    ";
if(runtime.memberLookup((runtime.memberLookup((t_33),"Single")),"IsVisible")) {
output += " <input single-checkbox name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_33),"ColumnName"), env.opts.autoescape);
output += "[Single]\" type=\"checkbox\" ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.memberLookup((t_33),"Single")),"Value"))?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"IsReadOnly") || runtime.memberLookup((runtime.memberLookup((t_33),"Single")),"IsReadOnly"))?"disabled":""), env.opts.autoescape);
output += "> ";
;
}
output += "\n                  </label>\n\n                  <label class=\"table-cell field checkbox\">\n                    ";
if(runtime.memberLookup((runtime.memberLookup((t_33),"New")),"IsVisible")) {
output += " <input new-checkbox name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_33),"ColumnName"), env.opts.autoescape);
output += "[New]\" type=\"checkbox\" ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.memberLookup((t_33),"New")),"Value") || runtime.memberLookup((runtime.memberLookup((t_33),"New")),"IsReadOnly"))?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"IsReadOnly") || runtime.memberLookup((runtime.memberLookup((t_33),"New")),"IsReadOnly"))?"disabled":""), env.opts.autoescape);
output += "> ";
;
}
output += "\n                  </label>\n                  <label class=\"table-cell field checkbox\">\n                    ";
if(runtime.memberLookup((runtime.memberLookup((t_33),"Editable")),"IsVisible")) {
output += " <input editable-checkbox\n                    name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_33),"ColumnName"), env.opts.autoescape);
output += "[Editable]\" type=\"checkbox\" ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.memberLookup((t_33),"Editable")),"Value") || runtime.memberLookup((runtime.memberLookup((t_33),"Editable")),"IsReadOnly"))?"checked":""), env.opts.autoescape);
output += "\n                    ";
output += runtime.suppressValue(((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"IsReadOnly") || runtime.memberLookup((runtime.memberLookup((t_33),"Editable")),"IsReadOnly"))?"disabled":""), env.opts.autoescape);
output += "> ";
;
}
output += "\n                  </label>\n                </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n              </div>\n            </div>\n          </div>\n        </form>\n      </div>\n      ";
;
}
output += "\n    </div>\n    ";
;
}
output += "\n  </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/permissions.html", ctx);
          }
        
          return nunjucks.render("views/content/permissions.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/permissions.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        