
        
    const runtime = require("../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        const __nunjucks_template_import__dep_0 = require("./content/form.html");
        
        const __nunjucks_template_import__dep_1 = require("./content/form/inputField.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/setup.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/content/form.html", false, "views/setup.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("form", t_1);
output += "\n";
env.getTemplate("views/content/form/inputField.html", false, "views/setup.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("inputField", t_4);
output += "\n\n\n    <div class=\"box pull-center\">\n        <div class=\"aside-box\">\n            <div class=\"logo\">\n                <span class=\"logo-icon infodatek-icon infodatek-icon-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.contextOrFrameLookup(context, frame, "product")), env.opts.autoescape);
output += "\"></span>\n                <span class=\"logo-text\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"product"), env.opts.autoescape);
output += "</span>\n            </div>\n            <h3>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Installation"), env.opts.autoescape);
output += "</h3>\n            <ol class=\"step-list\">\n                ";
frame = frame.push();
var t_9 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"Menu");
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("stepname", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\n                    <li data-setup-step=\"set:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\" class=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") < runtime.contextOrFrameLookup(context, frame, "currentStep")?"clickable":""), env.opts.autoescape);
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") == runtime.contextOrFrameLookup(context, frame, "currentStep")?" active":""), env.opts.autoescape);
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") < runtime.contextOrFrameLookup(context, frame, "currentStep")?" done":""), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(t_10, env.opts.autoescape);
output += "</li>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </ol>\n        </div>\n        <div class=\"steps\">\n            ";
if(runtime.contextOrFrameLookup(context, frame, "loading")) {
output += "\n                <h2><div class=\"spinner float-right mt-3\"></div></h2>\n            </div>\n        ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "currentStep") != 0) {
output += "\n            <h2>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"Title"), env.opts.autoescape);
output += "</h2>\n            <div class=\"error-box\" style=\"display:none;\"></div>\n            <div class=\"step\" data-step=\"0\">\n                <div class=\"v-center\">\n                    <div>\n                        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"IsLast") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"LicenseInfo")) {
output += "\n                            ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"Text")), env.opts.autoescape);
output += "\n                        ";
;
}
else {
output += "\n                            <form data-step=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "currentStep"), env.opts.autoescape);
output += "\">\n                                <h3>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"Text"), env.opts.autoescape);
output += "</h3>\n                                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"IsLoading")) {
output += "\n                                    <div class=\"spinner pull-center\"></div>\n                                ";
;
}
output += "\n                                ";
frame = frame.push();
var t_13 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"Columns");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_11;
if(runtime.isArray(t_13)) {
var t_12 = t_13.length;
for(t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11][0];
frame.set("[object Object]", t_13[t_11][0]);
var t_15 = t_13[t_11][1];
frame.set("[object Object]", t_13[t_11][1]);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "\n                                    <div class=\"col\">\n                                        ";
if(runtime.memberLookup((t_15),"Name") == "Password") {
output += "\n                                            <ul class=\"password-requires\">\n                                                <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordText"), env.opts.autoescape);
output += "</span>\n                                                <li data-regex=\".{8,}\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordReq1"), env.opts.autoescape);
output += "</li>\n                                                <li data-regex=\"[a-z]\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordReq2"), env.opts.autoescape);
output += "</li>\n                                                <li data-regex=\"[A-Z]\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordReq3"), env.opts.autoescape);
output += "</li>\n                                                <li data-regex=\"[\\d]\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordReq4"), env.opts.autoescape);
output += "</li>\n                                                <li data-regex=\"[$@$!%*?&amp;]\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordReq5"), env.opts.autoescape);
output += "</li>\n                                            </ul>\n                                        ";
;
}
output += "\n                                        <label class=\"form-field\">\n                                            <div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_15),"Title"), env.opts.autoescape);
output += "</div>\n                                            <div class=\"form-input required-field ";
output += runtime.suppressValue((runtime.memberLookup((t_15),"Type") == " money"?" money":""), env.opts.autoescape);
output += "\"=\"Money\"}}\"\">\n                                                ";
output += runtime.suppressValue((lineno = 50, colno = 78, runtime.callWrap(runtime.memberLookup((t_4),"createInputField"), "inputField[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_15,runtime.memberLookup((t_15),"Type"),null,runtime.memberLookup((t_15),"Initial"),null,null,runtime.contextOrFrameLookup(context, frame, "window"),true])), env.opts.autoescape);
output += "\n                                            </div>\n                                        </label>\n                                    </div>\n                                ";
;
}
} else {
t_11 = -1;
var t_12 = runtime.keys(t_13).length;
for(var t_16 in t_13) {
t_11++;
var t_17 = t_13[t_16];
frame.set("key", t_16);
frame.set("col", t_17);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += "\n                                    <div class=\"col\">\n                                        ";
if(runtime.memberLookup((t_17),"Name") == "Password") {
output += "\n                                            <ul class=\"password-requires\">\n                                                <span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordText"), env.opts.autoescape);
output += "</span>\n                                                <li data-regex=\".{8,}\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordReq1"), env.opts.autoescape);
output += "</li>\n                                                <li data-regex=\"[a-z]\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordReq2"), env.opts.autoescape);
output += "</li>\n                                                <li data-regex=\"[A-Z]\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordReq3"), env.opts.autoescape);
output += "</li>\n                                                <li data-regex=\"[\\d]\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordReq4"), env.opts.autoescape);
output += "</li>\n                                                <li data-regex=\"[$@$!%*?&amp;]\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"InstallPasswordReq5"), env.opts.autoescape);
output += "</li>\n                                            </ul>\n                                        ";
;
}
output += "\n                                        <label class=\"form-field\">\n                                            <div class=\"form-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_17),"Title"), env.opts.autoescape);
output += "</div>\n                                            <div class=\"form-input required-field ";
output += runtime.suppressValue((runtime.memberLookup((t_17),"Type") == " money"?" money":""), env.opts.autoescape);
output += "\"=\"Money\"}}\"\">\n                                                ";
output += runtime.suppressValue((lineno = 50, colno = 78, runtime.callWrap(runtime.memberLookup((t_4),"createInputField"), "inputField[\"createInputField\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),t_17,runtime.memberLookup((t_17),"Type"),null,runtime.memberLookup((t_17),"Initial"),null,null,runtime.contextOrFrameLookup(context, frame, "window"),true])), env.opts.autoescape);
output += "\n                                            </div>\n                                        </label>\n                                    </div>\n                                ";
;
}
}
}
frame = frame.pop();
output += "\n                            </form>\n                        ";
;
}
output += "\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"actions\">\n                <button data-setup-step=\"rel:-1\" class=\"button button-menu pull-left clickable\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "currentStep") == 0?"disabled":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Previous"), env.opts.autoescape);
output += "</button>\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"LicenseInfo") && runtime.contextOrFrameLookup(context, frame, "showExpiredLicenseScreen")) {
output += "\n                    <button data-setup-step=\"set:4\" class=\"button button-default pull-right clickable\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Done"), env.opts.autoescape);
output += "</button>\n                ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"LicenseInfo")) {
output += "\n                    <button data-setup-step=\"rel:1\" class=\"button button-default pull-right clickable\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Next"), env.opts.autoescape);
output += "</button>\n                ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"IsLast")) {
output += "\n                    <button data-setup-step=\"rel:1\" class=\"button button-default pull-right clickable\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Done"), env.opts.autoescape);
output += "</button>\n                ";
;
}
else {
output += "\n                    <button data-setup-step=\"rel:1\" disabled=\"disabled\" class=\"button button-default pull-right\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "session")),"translations")),"Next"), env.opts.autoescape);
output += "</button>\n                ";
;
}
;
}
;
}
output += "\n            </div>\n        ";
;
}
else {
output += "\n            <h2>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"Title"), env.opts.autoescape);
output += "</h2>\n            <form class=\"setup-languages\" data-step=\"0\">\n                <input type=\"hidden\" name=\"LangID\">\n                ";
frame = frame.push();
var t_20 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "step")),"Columns")),"LangID")),"Dropdown")),"Items");
if(t_20) {t_20 = runtime.fromIterator(t_20);
var t_19 = t_20.length;
for(var t_18=0; t_18 < t_20.length; t_18++) {
var t_21 = t_20[t_18];
frame.set("lang", t_21);
frame.set("loop.index", t_18 + 1);
frame.set("loop.index0", t_18);
frame.set("loop.revindex", t_19 - t_18);
frame.set("loop.revindex0", t_19 - t_18 - 1);
frame.set("loop.first", t_18 === 0);
frame.set("loop.last", t_18 === t_19 - 1);
frame.set("loop.length", t_19);
output += "\n                    <div class=\"language\">\n                        <div class=\"flag-block\" data-select-language=\"";
output += runtime.suppressValue(runtime.memberLookup((t_21),"Value"), env.opts.autoescape);
output += "\">\n                            <img src=\"/App_Themes/images/flags/";
output += runtime.suppressValue(runtime.memberLookup((t_21),"Value"), env.opts.autoescape);
output += ".svg\" alt=\"";
output += runtime.suppressValue(runtime.memberLookup((t_21),"Key"), env.opts.autoescape);
output += "\">\n                            <div class=\"language-name\">";
output += runtime.suppressValue(runtime.memberLookup((t_21),"Text"), env.opts.autoescape);
output += "</div>\n                        </div>\n                    </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </form>\n        ";
;
}
;
}
output += "\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/setup.html", ctx);
          }
        
          return nunjucks.render("views/setup.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/setup.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        