/**
 * @deprecated
 * @todo what is this?
 * @param {string} name - Name
 * @param {Array} args - Array of arguments
 * @returns {Promise} Nothing
 */
export default async function filterAction(name, ...args) {
  //
}
