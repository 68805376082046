import Cookies from "js-cookie";

export function getPersistedWarehouse({userId}) {
  try {
    const warehouseString =
      localStorage.getItem(`warehouse-${userId}`) ??
      Cookies.get(`warehouse-${userId}`);
    return warehouseString ? JSON.parse(warehouseString) : null;
  } catch (e) {
    console.log(e);
  }
}
