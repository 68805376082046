export function stringContainsMoreThenOneLetter({value}) {
  const regex = /[a-z]/gi;
  const matches = value.match(regex);

  if (matches === null) {
    return false;
  }

  return matches.length > 1;
}
