/**
 * Reverse column sorting
 * @param {string} column - Column name
 * @returns {Promise} Promise to reload
 */
export default async function toggleColumnSorting(column) {
  if (this.bulkedit) {
    return;
  }

  let columnSpec = this.output.FullTable.Columns.filter(
    (x) => x.Name == column,
  ).pop();

  if (
    columnSpec == null ||
    !columnSpec.CanSortOn ||
    (columnSpec.Dropdown || {}).Items != null
  ) {
    return;
  }

  this.input.Data.SortReverse =
    this.input.Data.SortOn == column ? !this.input.Data.SortReverse : false;
  this.input.Data.SortOn = column;

  await this.reload();
}
