import Vue from "vue";
import manual from "../../views/interface/manual.vue";
import store from "../../state/store";

function drawVueManualModal({helpUrl, windowID}) {
  if (helpUrl) {
    window.manualVueInstance = new Vue({
      el: `#manual-${windowID}`,
      store,
      render: (h) =>
        h(manual, {
          props: {
            manualURL: `${helpUrl}`,
            translations: global.session.translations,
          },
        }),
    });
  }
}

export {drawVueManualModal};
