import $ from "jquery";
import formatter from "@/model/formatter";
import Hook from "@/hook";

/**
 * Save bulk edit
 * @returns {Promise} Promise
 */
export default async function saveBulk() {
  let _this = this;
  let uniChanges = [];

  // If we aren't in bulk mode, maybe our parent or sub are
  if (!this.bulkedit) {
    if (this.sub && this.sub.window && this.sub.window.bulkedit) {
      await this.sub.window.saveBulk();
    }

    if (this.parent && this.parent.bulkedit) {
      await this.parent.saveBulk();
    }

    return;
  }

  // get all rows
  let $tableRows = $(this.element).find(".table-row").toArray();

  // construct data and criteria for each row
  for (let tableRowEl of $tableRows) {
    let data = {};
    let criteria = null;
    let rowData = this.output.Data.Rows[$(tableRowEl).attr("data-row-index")];

    if (rowData != null) {
      criteria = {};
      // construct criteria
      for (let columnName in this.output.Data.Columns) {
        let column = this.output.Data.Columns[columnName];

        if (column.IsPrimaryKey) {
          criteria[columnName] = rowData[columnName];
        }
      }
    }

    // Get all values, sanitized
    $(tableRowEl)
      .find("[name]")
      .each(function forEachName() {
        let name = $(this).attr("name").split(/\[|\]/g).shift();
        let value = $(this).is("[contenteditable]")
          ? $(this).text()
          : $(this).val();

        let col =
          _this.output.Table.Rows[$(tableRowEl).attr("data-row-index")].Column;
        if (!col) {
          return;
        }

        value = formatter.serializeValue(col, value);
        data[name] = value;
      });

    let newValues = {};

    for (let columnName in data) {
      if (rowData == null || data[columnName] !== rowData[columnName]) {
        newValues[columnName] = data[columnName];
      }
    }

    let commitChanges = $(tableRowEl)
      .find("[data-commit-checkbox]")
      .prop("checked");

    if (commitChanges) {
      uniChanges.push({Criteria: criteria, Values: newValues});
    }
  }

  let opts = {
    changes: uniChanges,
    tableName: this.bulkedit,
  };

  await Hook.run("saveBulk", this, async () => {
    // save values
    let save = await this.session.request(
      "/Admin/WebServices/CoreWebServices.asmx/SaveBulk",
      opts,
    );

    this.bulkedit = false;
    this.session.updateRelatedWindows(this);
    await this.reload();
  });
}
