/**
 * Open new tab or window in the current browser
 * @param {string} url - url to load in the new tab/window
 * @param {boolean} setFocus - indication if the new tab/window should receive focus
 *  @returns {Promise} Promise
 */
export default async function openbrowserwindow(url, setFocus) {
  var win = window.open(url, "_blank");
  if (setFocus) {
    win.focus();
  }
}
