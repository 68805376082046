<template>
  <div
    class="date-field"
    :class="{'cursor-not-allowed': readonly === 'true' || readonly === true}"
  >
    <input
      :value="date"
      :class="{
        'cursor-not-allowed': readonly == 'true' || readonly === true,
        'text-[#888] !bg-[#eeeeee]':
          readonly === 'true' || readonly === true,
      }"
      :state="validData"
      maxlength="10"
      :readonly="readonly === 'true' || readonly === true"
      @change="handleChange"
    />
    <slot></slot>
  </div>
</template>

<script>
import {parseValueToDate} from "@/functions/date/parseValueToDate";
import dayjs from "dayjs";

export default {
  props: {
    date: {
      required: true,
      type: String,
    },
    originalValue: {
      required: false,
      type: String,
      default: function () {
        return "";
      },
    },
    disabledDates: {
      required: false,
      type: Array,
      default: function () {
        return [];
      },
    },
    required: {
      required: false,
      type: Boolean,
      default: true,
    },
    showTime: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: [String, Boolean],
      default: function () {
        return "false";
      },
    },
  },
  data() {
    return {
      validData: null,
    };
  },
  methods: {
    handleChange(event) {
      if (this.required === false && !event.target.value) {
        this.$emit("change", "");
        this.validData = null;
        return;
      }

      const parsedDate = parseValueToDate(event.target.value);

      if (this.disabledDates.includes(parsedDate)) {
        this.validData = false;
        this.$emit("change", dayjs(parsedDate));
      }

      this.$emit("change", dayjs(parsedDate).format("YYYY-MM-DD"));
      this.validData = false;
    },
  },
};
</script>
<style lang="scss">
.date-field {
  display: grid;
  grid-template-columns: 1fr 1px;
  width: 100%;
  input {
    width: 100%;
  }
  .btn {
    margin-left: -35px;
  }
}
</style>
