/**
 * Asynchronously fetches table color information from the server.
 *
 * @async
 * @function getTableColor
 * @returns {Promise<{
 *   Collection: Array.<{
 *     FullTableName: string,
 *     ColorPolicy: string,
 *     Color: string,
 *     TableColorCriteria: Array.<{
 *       ColumnName: string,
 *       Criteria: string,
 *       Color: string,
 *       Ranking: null,
 *     }>
 *   }>,
 *   Index: number,
 *   Size: number,
 *   Total: number,
 * }>} - Promise resolving with an object containing table color information. The object includes
 * a Collection array with the color rules for each table, an Index indicating the current index,
 * a Size indicating the number of items per page, and a Total indicating the total number of items.
 * @example
 * // Response object structure:
 * {
 *   "Collection": [
 *     {
 *       "FullTableName": "Rental.Order",
 *       "ColorPolicy": "SubtleColor",
 *       "Color": "#5778a2",
 *       "TableColorCriteria": [
 *         {
 *           "ColumnName": "CustomerID",
 *           "Criteria": "00003",
 *           "Color": "#28af82",
 *           "Ranking": null
 *         }
 *       ]
 *     }
 *   ],
 *   "Index": 1,
 *   "Size": 0,
 *   "Total": 1
 * }
 * @throws Will throw an error if the request fails.
 */
import {axiosInstance} from "@/services/axiosInstance";

export async function getTableColor() {
  //return null;
  return await axiosInstance.get("/api/v2/core/_tablecolor");
}
