/**
 * Cancel bulk edit
 * @returns {Promise} Promise
 */
export default async function cancelBulk() {
  if (
    this.selection.length &&
    !confirm(this.session.translations.WindowWarningOnClose)
  ) {
    return;
  }

  this.bulkedit = false;
  await this.render();
}
