const getters = {
  window: (state) => state.window,
  subWindow: (state) => state.subWindow,
  shortcuts: (state) => state.shortcuts,
  activeWindowID: (state) => state.activeWindowID,
  translations: (state) => state.translations,
  accessToken: (state) => state.accessToken,
  subMenuOpen: (state) => state.subMenuOpen,
  subMenuItems: (state) => state.subMenuItems,
  headless: (state) => state.headless,
  settings: (state) => state.settings,
  tabs: (state) => state.tabs,
  showMenuTitles: (state) => state.settings.TopLevelMenuDescription,
  simpleInterface: (state) => state.settings.SimpleInterface,
  showActionBar: (state) => state.settings.ShowActionBar,
  hideSidebar: (state) => state.hideActionBar,
  selectedWindow: (state) => {
    if (state.subWindow.output) {
      return state.subWindow;
    }
    return state.window;
  },
  selectedWindowType: (state) => {
    if (state.subWindow.output) {
      return "subWindow";
    }
    return "window";
  },
};

export default getters;
