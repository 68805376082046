import Cookies from "js-cookie";

export function setPersistedWarehouse({warehouse, userId}) {
  if (warehouse) {
    localStorage.setItem(`warehouse-${userId}`, JSON.stringify(warehouse));
    Cookies.set(`warehouse-${userId}`, JSON.stringify(warehouse), {
      sameSite: "None",
      secure: true,
    });
  }
}
