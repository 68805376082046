import {getWindowTemplateMapping} from "./getWindowTemplateMapping";

export const getWindowButtonsFromTemplateMapping = async ({window}) => {
  const windowTemplateMapping = getWindowTemplateMapping({window});
  if (windowTemplateMapping?.getWindowButtonsFunction) {
    return await windowTemplateMapping.getWindowButtonsFunction({window});
  }

  return [];
};
