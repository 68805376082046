import $ from "jquery";

export function hasMissingRequiredField({formColumn, field, activeWindow}) {
  const fieldElement = $(activeWindow.element).find(
    `input[name='${field?.Column?.Name}']`,
  );

  if (isDropdownAndPrefixNotNew({field, window: activeWindow})) return;

  if (
    formColumn?.Name === field?.Column?.Name &&
    formColumn?.IsRequired &&
    field?.Column?.IsRequired &&
    !formColumn?.IsReadOnly &&
    formColumn?.Type !== "Boolean" &&
    fieldElement?.val() === ""
  ) {
    return true;
  }
}

// Some dropdowns have their String value set on page load in a way you cant retrieve it from the DOM
// Dropdown fields which are required cannot be emptied and always contain a value so should not be checked outside of "New" forms
const isDropdownAndPrefixNotNew = ({field, window}) => {
  return (
    field.Column.Editor === "Select" && window.output.Request.Prefix !== "New"
  );
};
