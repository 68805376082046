import "bootstrap4-notify";

import {notify} from "../util/notify";

import {axiosInstance} from "@/services/axiosInstance";
import {logout} from "@/functions/session/logout"

export default class NotificationQueue {
  constructor(NotificationID) {
    this.requestUrl =
      "/Admin/WebServices/NotificationLogWebService.asmx/GetNotificationLogs";
    this.requestData = [];
    this.requestParameters = {};

    this.notificationID = 0;

    if (NotificationID) {
      this.notificationID = NotificationID;
    }
  }

  async render() {
    await this.getData();

    if (!this.requestData || this.requestData?.data?.Error) return;

    if (global.session.currentUser?.AcceptNotification) {
      this.drawNotifications();
    }
  }

  async getData() {
    const response = await axiosInstance.post(this.requestUrl, {
      id: this.notificationID,
    });

    if (response.status == 401) {
      logout(true, true, response);
    }
    else {
      this.requestData = response.data;

      if (this.requestData.NotificationLogs.length > 0) {
        this.notificationID =
          this.requestData.NotificationLogs[
            this.requestData.NotificationLogs.length - 1
          ].NotificationLogID;
      }
    }
  }

  drawNotifications() {
    let messageDelay = 0;
    if (
      window.session.currentUser &&
      window.session.currentUser.NotificationDismissed
    ) {
      messageDelay = 5000;
    }

    this.requestData.NotificationLogs.forEach((item) => {
      let message = window.session.translations["Notification" + item.Action];

      message = message.replace("[Username]", item.UserName);
      message = message.replace(
        "[Subject]",
        window.session.translations["Notification" + item.Subject],
      );
      message = message.replace("[KeyValues]", item.KeyValues);

      notify({message, messageDelay, type: "info"});
    });
  }

  getNotificationID() {
    return this.notificationID;
  }
}
