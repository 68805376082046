import {getOptions} from "../../fields/select-field/getOptions.js";

export const processColumnCriteria = async ({vueColumns, formRow, column}) => {
  const affectedFieldComponent =
    vueColumns[column.Name].$children[0].$children[0].$children[0];
  if (!affectedFieldComponent) return;

  const searchValue = affectedFieldComponent.formattedSelectValue?.Text;

  const newOptions = await getOptions({
    field: column,
    searchValue: searchValue ?? null,
    row: formRow.reduce((acc, field) => {
      acc[field.Column.Name] = field.NewValue ?? field.Value;
      return acc;
    }, {}),
  });

  const affectedRowCell = formRow.find(
    (cell) => cell.Column.Name === column.Name,
  );

  const newColumnOptionsIncludeSelectedValue = newOptions.some(
    (option) =>
      option.Value === affectedRowCell.NewValue ?? affectedRowCell.Value,
  );

  if (column.Type === "Date" || column.Editor === "date")
    return affectedFieldComponent.handleFieldInput("");

  if (!newColumnOptionsIncludeSelectedValue) {
    affectedFieldComponent.options = [];
    affectedFieldComponent.handleInput({
      Value: null,
    });
  }

  if (newColumnOptionsIncludeSelectedValue) {
    affectedFieldComponent.options = newOptions;
  }
};
