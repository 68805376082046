import {setPostbackValues} from "../../functions/window/setPostbackValues.js";
import store from "../../../state/store";

/**
 * Process a change in value
 * @param {number} row - Row id
 * @param {number} index - Column index
 * @param {*} val - Cell value
 * @returns {*} Returns cell if there was a change relative to the value in the database
 */
export default async function processChange(row, index, val) {
  let isNew = this.output.Request.Prefix == "New";

  if (this.output.Table.Rows.length < row) {
    return null;
  }

  let cell = this.output.Table.Rows[row][index];
  if (!cell) {
    return null;
  }
  // val = formatter.parseValue(cell.Column, val)

  // Value as it came from the server.
  let serverValue = (cell.Initial && cell.Initial.Value) || cell.Value;
  let triggerChange =
    cell.NewValue != val && !(cell.Value === null && val === "");

  if (
    (cell.Column.Type === "Boolean" || cell.Column.Editor === "Boolean") &&
    val === null
  )
    serverValue = false;

  // Dirty if: not equal the server value AND not equal the last new value.
  cell.IsDirty = serverValue !== val;
  cell.NewValue = val;

  // In the event of a date do not process change if the values are the same
  if (cell.NewValue === cell.Value && cell.Type === "Date") return;

  if (triggerChange && cell.Column.IsPostback) {
    store.state.loading = true;

    const values = setPostbackValues({rows: this.output.FullTable.Rows[row]});

    await this.postbackValues(values);

    store.state.loading = false;
  }

  return triggerChange && !isNew ? cell : null;
}
