import Combobox from "../interface/combobox.class";
import $ from "jquery";

import Hook from "../hook";

/** Tiles hook */
class TilesHook extends Hook {
  /**
   * Setup custom view
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    //test
    if (!window.output.Data || window.output.Data.Type != "tiles") {
      return;
    }

    if (window.output.Data.ScanBox != null) {
      window.customData.combobox = {
        scan: Combobox.new(null, {
          tableName: window.output.Data.ScanBox.TableName,
          columnName: window.output.Data.ScanBox.ColumnName,
          name: "scan",
        }),
      };

      window.customData.combobox.scan.specification.readOnly =
        window.output.Data.ScanIsDisabled;

      if (window.output.Data.ScanValue != null) {
        window.customData.combobox.scan.setInitialValues(
          window.output.Data.ScanValue.Text,
          window.output.Data.ScanValue.Value,
        );
      }
    }
  }

  /**
   * Custom view
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterRender(window) {
    if (!window.output.Data || window.output.Data.Type != "tiles") {
      return;
    }

    $(window.element).off();
    $(window.element).on("change", "[name=scan]", async function (e) {
      let val = $(this).val() || null;

      if (
        val == null ||
        (window.input.Criteria != null &&
          window.input.Criteria.length > 0 &&
          window.input.Criteria[0].ScanValue == val)
      ) {
        return;
      }

      window.input.Criteria = [
        {
          ScanValue: val,
        },
      ];

      await window.reload();
    });
  }
}

/** @ignore */
export default new TilesHook();
