module.exports = () => ({
  emit: require("./emit").default,
  setError: require("./setError").default,
  loadDefaultValues: require("./loadDefaultValues").default,
  event: require("./event").default,
  confirm: require("./confirm").default,
  disposeParent: require("./disposeParent").default,
  dispose: require("./dispose").default,
  disposeAll: require("./disposeAll").default,
  removeComboboxReferences: require("./removeComboboxReferences").default,
  title: require("./title").default,
  setTitle: require("./setTitle").default,
  setCounter: require("./setCounter").default,
  openRow: require("./openRow").default,
  process: require("./process").default,
  showHint: require("./showHint").default,
  reloadRelated: require("./reloadRelated").default,
  reload: require("./reload").default,
  reloadParent: require("./reloadParent").default,
  reset: require("./reset").default,
  getActiveSubWindow: require("./getActiveSubWindow").default,
  resetSubPropper: require("./resetSubPropper").default,
  resetSub: require("./resetSub").default,
  fetch: require("./fetch").default,
  loadSub: require("./loadSub").default,
  render: require("./render").default,
  swalMessage: require("./swalMessage").default,
  showNotification: require("./showNotification").default,
  message: require("./message").default,
  focusOrOpenNewWindow: require("./focusOrOpenNewWindow").default,
  focus: require("./focus").default,
  getColorByBgColor: require("./getColorByBgColor").default,
  page: require("./page").default,
  html: require("./html").default,
  pagesize: require("./pagesize").default,
  buildCriteria: require("./buildCriteria").default,
  toggleLoading: require("./toggleLoading").default,
  openbrowserwindow: require("./openbrowserwindow").default,
  opentabparent: require("./opentabparent").default,
  opentab: require("./opentab").default,
  openTab: require("./opentab2").default,
  insertWindow: require("./insertWindow").default,
  injectSub: require("./injectSub").default,
  updateParentWindow: require("./updateParentWindow").default,
  reloadSub: require("./reloadSub").default,
  replaceWindow: require("./replaceWindow").default,
  updateWindow: require("./updateWindow").default,
  action: require("./action.js").default,
  injectWindow: require("./updateWindow").default,
  buildCriteriaNew: require("./buildCriteriaNew").default,
  injectTab: require("./injectTab").default,
  handleActionResponse: require("./handleActionResponse").default,
  actionExcel: require("./actionExcel").default,
  actionDownloadTemplate: require("./actionDownloadTemplate").default,
  actionOpenExcelFileUpdate: require("./actionOpenExcelFileUpdate").default,
  actionOpenExcelFileInput: require("./actionOpenExcelFileInput").default,
  actionImportExcelFile: require("./actionImportExcelFile").default,
  actionBulk: require("./actionBulk").default,
  cancelBulk: require("./cancelBulk").default,
  saveBulk: require("./saveBulk").default,
  close: require("./dispose").default,
  actionDownloadFile: require("./actionDownloadFile").default,
  actionUploadFile: require("./actionUploadFile").default,
  open: require("./open").default,
  openCriteria: require("./openCriteria").default,
  removeTextQuery: require("./removeTextQuery").default,
  removeDateQuery: require("./removeDateQuery").default,
  filterAction: require("./filterAction").default,
  opentree: require("./opentree").default,
  openWindowFromTree: require("./openWindowFromTree").default,
  openref: require("./openref").default,
  resize: require("./resize").default,
  clearHTML: require("./clearHTML").default,
  toggleColumn: require("./toggleColumn").default,
  selectedFilterItem: require("./selectedFilterItem").default,
  processChange: require("./processChange").default,
  postbackValues: require("./postbackValues").default,
  applyChanges: require("./applyChanges").default,
  isDirty: require("./isDirty").default,
  isRelated: require("./isRelated").default,
  selectRow: require("./selectRow").default,
  isSelectedRow: require("./isSelectedRow").default,
  toggleColumnSorting: require("./toggleColumnSorting").default,
  download: require("./download").default,
  random: require("./random").default,
  selectPermissions: require("./selectPermissions").default,
  openPreviousPermissionsScreen: require("./openPreviousPermissionsScreen")
    .default,
  selectPermissionsSub: require("./selectPermissionsSub").default,
  savePermissions: require("./savePermissions").default,
  saveTreeView: require("./saveTreeView").default,
  cancelTreeView: require("./reset").default,
  setupCustomEnv: require("./setupCustomEnv").default,
  dialog: require("./dialog").default,
  openCategory: require("./openCategory").default,
  help: require("./help").default,
  openUrl: require("./openUrl").default,
  reloadWebpage: require("./reloadWebpage").default,
  getCssInfo: require("./getCssInfo").default,
  getSearchColumns: require("./getSearchColumns").default,
});
