export function disableComboboxFromVisibleInput(inputElement, disabled) {
  if (disabled) {
    $(inputElement).parent().addClass("disabled");
    $(inputElement)
      .parent()
      .find("[data-toggle-combobox='data-toggle-combobox']")
      .addClass("d-none");
  } else {
    $(inputElement).parent().removeClass("disabled");
    $(inputElement)
      .parent()
      .find("[data-toggle-combobox='data-toggle-combobox']")
      .removeClass("d-none");
  }
}
