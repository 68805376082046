import vueDateTimeTemplate from "../../views/elements/vueDateTime.vue";
import customParseFormat from "dayjs/plugin/customParseFormat";
import vueDateTemplate from "../../views/elements/vueDate.vue";
import FormField from "@/components/form/LegacyFormField.vue";
import store from "../../state/store";
import Vue from "vue";
import $ from "jquery";
import Hook from "../hook";
import dayjs from "dayjs";

import {extractSubWindowFromWindowObject} from "@/util/extractSubWindowFromWindowObject";
import {setOriginalFormRowOnWindow} from "../functions/form/setOriginalFormRowOnWindow.js";
import {parseBooleanAttribute} from "@/functions/parsing/parseBooleanAttribute";
import {getFormFieldColumn} from "../functions/form/getFormFieldColumn.js";
dayjs.extend(customParseFormat);

class FormHook extends Hook {
  async afterFocus(eventWindow) {
    bindVueElements(eventWindow);
    setOriginalFormRowOnWindow({window: eventWindow});
  }

  async afterRender(eventWindow) {
    bindVueElements(eventWindow);
  }

  async afterProcess(eventWindow) {
    bindVueElements(eventWindow);
    setOriginalFormRowOnWindow({window: eventWindow});
  }
}

function bindVueElements(eventWindow) {
  if (
    eventWindow.output.Request.Prefix == "Single" ||
    eventWindow.output.Request.Prefix == "New" ||
    eventWindow.output.Request.Prefix == "Preview" ||
    eventWindow.output.Request.Prefix == "Custom"
  ) {
    const vueFormFields = $(eventWindow.element).find("div [vue-form-field]");
    const relevantWindow =
      extractSubWindowFromWindowObject({window: eventWindow}) ?? eventWindow;

    for (const vueFormField of vueFormFields) {
      const vueFormFieldAttrs = vueFormField
        .getAttributeNames()
        .reduce((acc, name) => {
          return {...acc, [name]: vueFormField.getAttribute(name)};
        }, {});

      const vueFormFieldColumn = getFormFieldColumn({
        field: vueFormFieldAttrs,
        window: relevantWindow,
      });

      const vueInstance = new Vue({
        el: `#${vueFormField.id}`,
        store,
        render: (h) =>
          h(FormField, {
            props: {
              providedField: vueFormFieldAttrs,
              providedColumn: vueFormFieldColumn,
              windowId: eventWindow.id,
            },
          }),
      });

      relevantWindow.vueColumns = {
        ...relevantWindow.vueColumns,
        [$(vueFormField).attr("name")]: vueInstance,
      };
    }

    const colorInputFields = $('input[type="color"]');
    for (const colorpicker of colorInputFields) {
      if (!$(colorpicker).attr("value")) {
        $(colorpicker).val("#f26522");
      }
    }

    const calendarDateTimeFields = $(eventWindow.element).find(
      "div [data-editor-type='calendar-date-time']",
    );

    for (const calendarField of calendarDateTimeFields) {
      const vueInstance = new Vue({
        el: `#${calendarField.id}`,
        store,
        render: (h) =>
          h(vueDateTimeTemplate, {
            props: {
              windowId: $(calendarField).attr("window-id"),
              name: $(calendarField).attr("name"),
              index: $(calendarField).attr("index"),
              value: $(calendarField).attr("value"),
              today: $(calendarField).attr("today"),
              readonly: $(calendarField).attr("read-only"),
              showTime: $(calendarField).attr("show-time") === "true",
              filterOnOpeningsDay: $(calendarField).attr(
                "filter-on-openings-day",
              ),
              timePicker: $(calendarField).attr("time-picker"),
              postback: $(calendarField).attr("postback") == "true",
              required: parseBooleanAttribute(
                $(calendarField).attr("isRequired"),
              ),
            },
          }),
      });

      relevantWindow.vueColumns = {
        ...relevantWindow.vueColumns,
        [$(calendarField).attr("name")]: vueInstance,
      };
    }

    const calendarDateFields = $(eventWindow.element).find(
      "div [data-editor-type='calendar']",
    );

    for (const calendarField of calendarDateFields) {
      const vueInstance = new Vue({
        el: `#${calendarField.id}`,
        store,
        render: (h) =>
          h(vueDateTemplate, {
            props: {
              windowId: $(calendarField).attr("window-id"),
              name: $(calendarField).attr("name"),
              index: $(calendarField).attr("index"),
              providedValue: $(calendarField).attr("value"),
              today: $(calendarField).attr("today"),
              readonly: $(calendarField).attr("read-only"),
              showTime: $(calendarField).attr("show-time") === "true",
              filterOnOpeningsDay: $(calendarField).attr(
                "filter-on-openings-day",
              ),
              timePicker: $(calendarField).attr("time-picker"),
              postback: $(calendarField).attr("postback") == "true",
            },
          }),
      });

      relevantWindow.vueColumns = {
        ...relevantWindow.vueColumns,
        [$(calendarField).attr("name")]: vueInstance,
      };
    }

    if (
      eventWindow.output.Request.Prefix == "Single" ||
      eventWindow.output.Request.Prefix == "New" ||
      eventWindow.output.Request.Prefix == "Multi" ||
      eventWindow.output.Request.Prefix == "View"
    ) {
      const windowType = store.getters.activeWindow?.type ?? null;
      if (windowType !== "datagrid") {
        store.commit("windows/setWindow", {
          window: store.getters.selectedWindow,
          windowType: "form",
        });
      }
    }
  }
}

/** Date filter 'equals' hook */
class DateEqualsHook extends Hook {
  /**
   * Set correct filters
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    if (
      !window.output.Options ||
      window.output.Options.Filters_UseDate == null ||
      !window.output.Options.Filters_UseDate.endsWith ||
      !window.output.Options.Filters_UseDate.endsWith(" =")
    ) {
      return;
    }

    let filterTarget = window.output.Options.Filters_UseDate.slice(0, -2);
    let filterText = window.session.translations.Date;
    if (
      window.output.Request.Data.Filters.hasOwnProperty(
        window.output.Options.Filters_UseDate,
      )
    ) {
      filterText =
        window.session.translations.Date +
        ": " +
        window.output.Request.Data.Filters[
          window.output.Options.Filters_UseDate
        ];
    }

    window.filters = {
      usedate: filterTarget,
      date: [
        {
          text: filterText,
          suffix: "=",
          value: null,
        },
      ],
      other: [],
      selectedOthers: 0,
    };
  }
}

/** @ignore */
export default [
  new DateEqualsHook(),
  // new VWInvoiceProposalHook(),
  new FormHook(),
];
