export default function delayedCorrectTableHeaders(window) {
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function correctTableHeaders() {
    await sleep(500);

    global.session.canvas.correctTableHeaders(window);
  }
  correctTableHeaders();
}
