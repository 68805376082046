<template>
  <div
    v-if="active"
    :class="`relative !block mb-[1rem] py-[0.75rem] px-[1.25rem] !border-transparent rounded-md ${computedClasses}`"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  Name: "RAlert",
  props: {
    variant: {
      type: String,
      required: true,
      default: "primary",
    },
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      if (this.variant == "primary")
        return "!text-[#004085] !bg-[#cce5ff] !border-[#b8daff]";
      if (this.variant == "secondary")
        return "!text-[#383d41] !bg-[#e2e3e5] !border-[#d6d8db]";
      if (this.variant == "success")
        return "!text-[#155724] !bg-[#d4edda] !border-[#c3e6cb]";
      if (this.variant == "danger")
        return "!text-[#773c3c] !bg-[#fae3e3] !border-[#f8d8d8]";
      if (this.variant == "warning")
        return "!text-[#856404] !bg-[#fff3cd] !border-[#ffeeba]";
      if (this.variant == "info")
        return "!text-[#0c5460] !bg-[#d1ecf1] !border-[#bee5eb]";
    },
  },
};
</script>

<style></style>
