<template>
  <span refs="modal" class="mt-2">
    <a
      id="availabilityModalButton"
      variant="link"
      class="text-white button button-default"
      style="margin-top: 23px"
      @click="show = true"
      >{{ buttonText }}</a
    >

    <r-modal
      id="modal-1"
      v-model="show"
      size="xl"
      :show="show"
      :title="modalTitle"
      :no-close-on-backdrop="true"
      @hide="close()"
    >
      <div id="availabilityModalBody"></div>
      <template #modal-footer>
        <div class="w-100">
          <r-button
            class="float-right"
            :variant="'secondary'"
            @click="show = false"
            >{{ translations.Close }}</r-button
          >
        </div>
      </template>
    </r-modal>
  </span>
</template>

<script>
import RButton from "@/components/elements/RButton";
import RModal from "@/components/elements/RModal";

export default {
  components: {
    RButton,
    RModal,
  },
  data() {
    return {
      translations: window.session.translations,
      modalTitle: "",
      buttonText: "",
      modalBody: "",
      show: false,
    };
  },
  methods: {
    setButtonText(payload) {
      this.buttonText = payload;
    },
    setModalTitle(payload) {
      this.modalTitle = payload;
    },
    setModalBody(payload) {
      this.modalBody = payload;
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style>
.locked .modal {
  display: none !important;
}
</style>
