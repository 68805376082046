import {getTranslations} from "../functions/session/localstorage/getTranslations.js";
import Hook from "../hook";
import {alertPopup} from "../interface/alertPopup/alertPopup.js";

class PeriodCriteriaWarning extends Hook {
  async afterRender(window) {
    if (
      window.output.Request?.Subject == "Settings.Period" &&
      window.output.Request.Criteria[0]["PeriodID"] === "EntirePeriod"
    ) {
      window.dispose();
      const translations = getTranslations();
      await alertPopup({
        icon: "warning",
        text: translations.EntirePeriodWarning,
      });
    }
  }
}

/** @ignore */
export default new PeriodCriteriaWarning();
