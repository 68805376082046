const axios = require("axios");
import store from "../../state/store";

const nonActionAxiosInstance = axios.create({
  baseURL: "/"
});

nonActionAxiosInstance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${store.state.accessToken}`;
    config.headers.SessionID = store.state.sessionId;
    config.headers["Content-Language"] = store.state.language;
    config.headers["NormalizeEnums"] = true;
    
    return config;
  }
);

export {nonActionAxiosInstance};
