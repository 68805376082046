import {handleDatagridService} from "../../src/services/table/handleDatagridService.js";
// initial state
// shape: [{ user, properties }]
const state = () => ({});

// getters
const getters = {
  primaryKey: (state, getters, rootState, rootGetters) => (windowId) => {
    const columns = rootGetters["windows/columns"](windowId);
    const primaryKey = Object.values(columns).find(
      (column) => column.IsPrimaryKey,
    ).Name;
    return primaryKey;
  },
};

// actions
const actions = {
  async save({commit, rootState, rootGetters}) {
    await handleDatagridService({
      operation: "saveTable",
      requestData: {
        subject: rootGetters["windows/activeWindow"].output.Request.Subject,
        data: rootGetters["windows/changedRows"](),
        windowId: rootGetters["windows/activeWindowId"],
      },
    });

    commit(
      "windows/clearChangedRows",
      {
        windowId: this.windowId,
        rows: [],
      },
      {root: true},
    );

    rootState.loading = false;
  },
  addRows({commit, dispatch}, {windowId, data}) {
    // loop over rows and add them to the datagrid
    data.Rows.forEach((row) => {
      commit("windows/addRow", {windowId, row}, {root: true});
      dispatch("updateOrAddChangedRow", {windowId, data: {Row: row}});
    });
  },
  async emitRowChange({rootGetters, dispatch}, {windowId, row, meta, deleted}) {
    const subject = rootGetters["windows/subject"](windowId);

    const result = await handleDatagridService({
      operation: "changeRow",
      requestData: {
        subject,
        data: row,
        windowId: windowId ?? rootGetters["windows/activeWindowId"],
      },
    });

    const rowData = result.data?.Data;
    if (rowData) {
      dispatch("updateRow", {
        windowId: windowId ?? rootGetters["windows/activeWindowId"],
        data: {Row: rowData, deleted, meta},
      });
    }
  },
  updateRow({commit, getters, rootGetters, dispatch}, {data, windowId}) {
    const rows = rootGetters["windows/rows"](windowId);
    const primaryKeyValue = data.Row[getters.primaryKey(windowId)].Value;
    const rowIndex = rows.findIndex(
      (row) => row[getters.primaryKey(windowId)].Value === primaryKeyValue,
    );

    commit(
      "windows/updateRow",
      {windowId, row: data.Row, rowIndex},
      {root: true},
    );

    dispatch("updateOrAddChangedRow", {windowId, data});
  },
  markRowsChanged({commit, getters, rootGetters}, {windowId, rows}) {
    rows.forEach((targetRow) => {
      const primaryKey = getters.primaryKey(windowId);
      const primaryKeyValue = targetRow[primaryKey];
      const rowIndex = rows.findIndex(
        (row) => row[primaryKey] === primaryKeyValue,
      );

      const row = {
        Criteria: {},
        Values: {},
      };

      row.Criteria[primaryKey] = targetRow[primaryKey].Value;

      Object.entries(targetRow).forEach(([key, value]) => {
        row.Values[key] = value.Value;
      });

      commit("windows/addChangedRow", {windowId, row}, {root: true});
    });
  },
  updateOrAddChangedRow({commit, getters, rootGetters}, {data, windowId}) {
    const rows = rootGetters["windows/changedRows"](windowId);
    const primaryKey = getters.primaryKey(windowId);
    const primaryKeyValue = data.Row[primaryKey].Value;
    const rowIndex = rows.findIndex(
      (row) => row.Values[primaryKey] === primaryKeyValue,
    );

    const row = {
      Criteria: {},
      Values: {},
    };

    if (data.deleted) {
      row.Deleted = true;
    }

    if (data.meta) {
      row.meta = data.meta;
    }

    row.Criteria[primaryKey] = data.Row[primaryKey].Value;

    Object.entries(data.Row).forEach(([key, value]) => {
      row.Values[key] = value.Value;
    });

    if (rowIndex === -1) {
      commit("windows/addChangedRow", {windowId, row}, {root: true});
    } else {
      commit(
        "windows/updateChangedRow",
        {windowId, row, rowIndex},
        {root: true},
      );
    }
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
