import {getRowColorFromTableColorCriteria} from "./getRowColorFromTableColorCriteria";

export function getRowColor({colorRule, row}) {
  if (colorRule?.TableColorCriteria.length) {
    const rowColor = getRowColorFromTableColorCriteria({
      tableColorCriteria: colorRule.TableColorCriteria,
      row,
    });

    if (rowColor) return rowColor;
  }
  return "inherit";
}
