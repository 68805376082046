<template>
  <div class="w-100">
    <r-button
      :variant="'primary'"
      :size="'md'"
      class="float-right"
      @click="hideDialog"
    >
      {{ translations.Close }}
    </r-button>
  </div>
</template>

<script>
import RButton from "@/components/elements/RButton";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";

export default {
  components: {
    RButton,
  },
  name: "DialogCloseButton",
  methods: {
    hideDialog() {
      this.$store.commit("dialog/hideDialog");
    },
  },
  computed: {
    translations: function () {
      return getTranslations();
    },
  },
};
</script>

<style scoped></style>
