export const emitAlert = ({text, icon, dangerMode, buttons, size, resolve}) => {
  global.eventBus.$emit("alert", {
    markdown: text,
    icon,
    dangerMode,
    buttons,
    size,
    resolve,
  });
};
