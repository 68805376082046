import {alertPopup} from "../interface/alertPopup/alertPopup.js";

export async function prompt({text, confirmText, cancelText}) {
  const buttons = {
    confirm: {
      text: confirmText,
      variant: "primary",
      value: true,
    },
    decline: {
      text: cancelText,
      value: false,
      className: "btn-secondary hover:bg-grey-200",
    },
  };
  return await alertPopup({icon: "warning", text, buttons});
}
