import store from "../../state/store";

/**
 * Processes a legacy event from the old action based window system
 * @param actionString {string} Legacy function argument string seperated by :
 * @param windowId {string} Window ID
 * @returns * @returns {Promise<void>}
 */
export default async function (actionString, windowId) {
  const [functionName, action, actionName, actionValue, actionValueType] =
    actionString.split(/:/g);

  const importedModule = await import(`./legacy/${functionName}.js`);

  importedModule.default({
    action,
    actionName,
    actionValue,
    actionValueType,
    windowId:
      windowId ??
      store.getters.subWindow.id ??
      store.getters["windows/activeWindowId"],
  });
}
