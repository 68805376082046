
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
            const __nunjucks_template_import__global_getRowColorMarking = require("../../../src/functions/styling/colors/getRowColorMarking.js");
            __nunjucks_module_dependencies__.globals['getRowColorMarking'] = {
                module: __nunjucks_template_import__global_getRowColorMarking && __nunjucks_template_import__global_getRowColorMarking.default || __nunjucks_template_import__global_getRowColorMarking
            };
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/table/tableBody.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["data", "window", "settings", "getRowColorMarking", "setColumnCell"], 
[], 
function (l_data, l_window, l_settings, l_getRowColorMarking, l_setColumnCell, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", l_data);
frame.set("window", l_window);
frame.set("settings", l_settings);
frame.set("getRowColorMarking", l_getRowColorMarking);
frame.set("setColumnCell", l_setColumnCell);
var t_2 = "";t_2 += "\n<div class=\"table-body ";
t_2 += runtime.suppressValue((runtime.memberLookup((l_window),"bulkedit")?"bulkedit":""), env.opts.autoescape);
t_2 += "\">\n  <div class=\"table-rows\">\n    ";
frame = frame.push();
var t_5 = runtime.memberLookup((runtime.memberLookup((l_data),"Table")),"Rows");
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("row", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n    ";
var t_7;
t_7 = (lineno = 4, colno = 40, runtime.callWrap(l_getRowColorMarking, "getRowColorMarking", context, [{"table": runtime.memberLookup((l_data),"Table"),"row": t_6,"windowOutput": runtime.memberLookup((l_window),"output")}]));
frame.set("rowColor", t_7, true);
if(frame.topLevel) {
context.setVariable("rowColor", t_7);
}
if(frame.topLevel) {
context.addExport("rowColor", t_7);
}
t_2 += "\n    ";
var t_8;
t_8 = ((runtime.contextOrFrameLookup(context, frame, "rowColor") && runtime.memberLookup((l_settings),"InterfaceColorIntensity") == "prominent")?runtime.contextOrFrameLookup(context, frame, "rowColor"):"");
frame.set("prominentRowColor", t_8, true);
if(frame.topLevel) {
context.setVariable("prominentRowColor", t_8);
}
if(frame.topLevel) {
context.addExport("prominentRowColor", t_8);
}
t_2 += "\n\n    <div class=\"table-row ";
t_2 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "prominentRowColor")?"bg-[" + runtime.contextOrFrameLookup(context, frame, "prominentRowColor") + "]":""), env.opts.autoescape);
t_2 += "\" data-row-index=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
t_2 += "\" style=\"";
t_2 += runtime.suppressValue((lineno = 7, colno = 149, runtime.callWrap(runtime.memberLookup((l_window),"getCssInfo"), "window[\"getCssInfo\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")])), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "prominentRowColor")?"background-color:" + runtime.contextOrFrameLookup(context, frame, "prominentRowColor") + ";":""), env.opts.autoescape);
t_2 += "\">\n\n      ";
if(!runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"output")),"Options")),"DisableSelection")) {
t_2 += "\n      <label class=\"table-cell checkbox selection-box\"><input type=\"checkbox\" data-commit-checkbox=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
t_2 += "\" title=\"Select\"></label>\n      ";
;
}
t_2 += "\n\n      ";
if(runtime.contextOrFrameLookup(context, frame, "rowColor") && runtime.memberLookup((l_settings),"InterfaceColorIntensity") == "subtle") {
t_2 += "\n      <div class=\"table-cell table-cell-color-cell min-h-[27px]\" style=\"min-width:28px\">\n        <div class=\"inline-flex items-center h-[27px]\">\n          <div class=\"w-5 h-5 rounded-full inline-block\" style=\"background-color: ";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "rowColor"), env.opts.autoescape);
t_2 += "\"></div>\n        </div>\n      </div>\n      ";
;
}
var t_9;
t_9 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0");
frame.set("rowIndex", t_9, true);
if(frame.topLevel) {
context.setVariable("rowIndex", t_9);
}
if(frame.topLevel) {
context.addExport("rowIndex", t_9);
}
frame = frame.push();
var t_12 = t_6;
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("col", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
t_2 += "\n        ";
t_2 += runtime.suppressValue((lineno = 23, colno = 24, runtime.callWrap(l_setColumnCell, "setColumnCell", context, [runtime.memberLookup((t_13),"Column"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),runtime.memberLookup((t_13),"Value"),l_window,t_13,runtime.memberLookup((runtime.memberLookup((t_13),"Column")),"AllowEditOnTable"),runtime.contextOrFrameLookup(context, frame, "rowColor"),runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Data")),"Metadata")),runtime.contextOrFrameLookup(context, frame, "rowIndex"))])), env.opts.autoescape);
t_2 += "\n      ";
;
}
}
frame = frame.pop();
t_2 += "\n    </div>\n    ";
;
}
}
frame = frame.pop();
t_2 += "\n\n    ";
if(runtime.memberLookup((l_window),"bulkedit")) {
t_2 += "\n    <div class=\"table-row new-row\">\n      <label class=\"table-cell checkbox selection-box\"><input type=\"checkbox\" data-commit-checkbox=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
t_2 += "\" title=\"Select\"></label>\n      ";
frame = frame.push();
var t_16 = runtime.memberLookup((runtime.memberLookup((l_data),"Table")),"Columns");
if(t_16) {t_16 = runtime.fromIterator(t_16);
var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("col", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
t_2 += "\n      ";
t_2 += runtime.suppressValue((lineno = 32, colno = 22, runtime.callWrap(l_setColumnCell, "setColumnCell", context, [t_17,"",runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"),"",l_window,null,null,runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_data),"Data")),"Metadata")),runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"))])), env.opts.autoescape);
t_2 += "\n      ";
;
}
}
frame = frame.pop();
t_2 += "\n    </div>\n    ";
;
}
t_2 += "\n  </div>\n</div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("renderTableBody");
context.setVariable("renderTableBody", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/table/tableBody.html", ctx);
          }
        
          return nunjucks.render("views/content/table/tableBody.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/table/tableBody.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        