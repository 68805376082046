import {fieldContainsExtraCriteria} from "../fieldContainsExtraCriteria.js";
import {processColumnCriteria} from "../r-select/processColumnCriteria.js";

export const processLegacyFormFieldExtraCriteria = async ({
  changedColumn,
  window,
}) => {
  const formColumns = window.output.Table?.Columns;
  const vueColumns = window.vueColumns;
  const formRow = window.output.Table?.Rows[0];

  const changedFieldHasExtraCriteria = fieldContainsExtraCriteria({
    field: changedColumn,
  });

  // check if the changed column has extra criteria
  if (changedFieldHasExtraCriteria) {
    const extraCriteria = changedColumn.Dropdown.ExtraCriteria;

    // for every criterion, check if the row cell has a value
    for (const criterion in extraCriteria) {
      const rowCell = formRow.find(
        (cell) => cell.Column.Name === extraCriteria[criterion]?.FormColumnName,
      );

      if (!rowCell) continue;

      if (rowCell.NewValue ?? rowCell.Value) {
        await processColumnCriteria({
          vueColumns,
          formRow,
          column: formColumns.find((col) => col.Name === rowCell.Column.Name),
        });
      }
    }
  }

  // if the changed column does not have extra criteria, check if any other column has extra criteria that points to the changed column
  if (!changedFieldHasExtraCriteria) {
    // loop through each column with criteria and a value, and check if one of the criteria matches the changed column

    for (const column in formColumns) {
      const formColumn = formColumns[column];
      const columnHasCriteria = fieldContainsExtraCriteria({
        field: formColumn,
      });
      const columnCell = formRow.find(
        (cell) => cell.Column.Name === formColumn.Name,
      );
      const columnCellValue = columnCell.NewValue ?? columnCell.Value;

      // if the column has a matching criteria and a cell value, process the criteria
      if (columnHasCriteria && columnCellValue) {
        await processColumnCriteria({
          vueColumns,
          formRow,
          column: formColumn,
        });
      }
    }
  }
};
