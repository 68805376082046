export const getDataWebhooksNormalizeApiData = async ({response}) => {
  try {
    const {Collection} = response.data;

    const newCollection = Collection.map((item) => {
      const {EventID} = item;

      return {
        ...item,
        EventID: EventID.Key,
      };
    });

    return {
      ...response,
      data: {
        ...response.data,
        Collection: newCollection,
      },
    };
  } catch (error) {
    console.error(error);
  }
};
