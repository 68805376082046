//data-event-select-all-editable

import Hook from "../hook";

/** Return view */
class PermissionsHook extends Hook {
  /**
   * Setup variables
   * @param {Window} currentWindow - Window
   * @returns {Promise} Promise
   */
  async afterRender(currentWindow) {
    if (currentWindow?.output?.Data?.Type === "permissions") {
      // listen for click on attribute named [data-event-select-all-editable] with jquery

      // remove click listener for [data-event-select-all-editable]
      $(currentWindow.element).off("click", "[data-event-select-all-editable]");
      $(currentWindow.element).off("click", "[data-event-select-all-new]");
      $(currentWindow.element).off("click", "[data-event-select-all-multi]");
      $(currentWindow.element).off("click", "[data-event-select-all-single]");
      $(currentWindow.element).on(
        "click",
        "[data-event-select-all-editable]",
        function () {
          toggleColumnComboboxes(currentWindow, "[editable-checkbox]");
        },
      );

      $(currentWindow.element).on(
        "click",
        "[data-event-select-all-new]",
        function () {
          toggleColumnComboboxes(currentWindow, "[new-checkbox]");
        },
      );

      $(currentWindow.element).on(
        "click",
        "[data-event-select-all-multi]",
        function () {
          toggleColumnComboboxes(currentWindow, "[multi-checkbox]");
        },
      );

      $(currentWindow.element).on(
        "click",
        "[data-event-select-all-single]",
        function () {
          toggleColumnComboboxes(currentWindow, "[single-checkbox]");
        },
      );
    }
  }
}

function toggleColumnComboboxes(currentWindow, attribute) {
  // get all checkboxes with [editable] attribute in currentWindow.element
  const checkboxes = $(currentWindow.element).find(attribute).not("[disabled]");

  // if any of the checkboxes are checked in checkboxes

  if ($(checkboxes[0]).is(":checked")) {
    // loop through checkboxes and set each element checked to false except last element
    for (let i = 0; i < checkboxes.length; i++) {
      if (i === checkboxes.length - 1) {
        $(checkboxes[i]).prop("checked", true);
        $(checkboxes[i]).trigger("click");
      } else {
        $(checkboxes[i]).prop("checked", false);
      }
    }
  } else {
    for (let i = 0; i < checkboxes.length; i++) {
      if (i === checkboxes.length - 1) {
        $(checkboxes[i]).prop("checked", false);
        $(checkboxes[i]).trigger("click");
      } else {
        $(checkboxes[i]).prop("checked", true);
      }
    }
  }
}

/** @ignore */
export default new PermissionsHook();
