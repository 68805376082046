import formatter from "@/model/formatter";
import Combobox from "@/interface/combobox.class";

/**
 * Open dialog with user
 * @param {string} instructionText - Instruction text displayed in message
 * @param {Array} fields - Fields to show in dialog
 * @param {boolean} isStack - If is stack
 * @returns {Promise} Promise
 */
export default async function dialog(
  instructionText,
  fields = null,
  isStack,
  content,
  dialogProperties,
) {
  if (fields) {
    fields.forEach((f) => {
      if (f.Initial != null && f.Initial.Value != null) {
        f.Initial.Value = formatter.parseValue(f, f.Initial.Value);
      }

      if (f.DropdownItems) {
        f.Combobox = new Combobox();
        f.Combobox.populate(f.DropdownItems);
        f.Combobox.specification.name = f.Name;
        f.Combobox.specification.extraKeys = f.Dropdown?.ExtraKeys;
        f.Combobox.specification.passthroughValues =
          f.Dropdown?.PassthroughValues ?? null;
        if (
          f.Initial != null &&
          f.Initial.Value != null &&
          f.Initial.Text != null
        ) {
          f.Combobox.setInitialValues(f.Initial.Text, f.Initial.Value);
        }
      } else if (f.Dropdown) {
        f.Combobox = Combobox.new(null, {
          name: f.Dropdown.ColumnName,
          tableName: f.Dropdown.TableName,
          columnName: f.Dropdown.ColumnName,
        });
        if (
          f.Initial != null &&
          f.Initial.Value != null &&
          f.Initial.Text != null
        ) {
          f.Combobox.setInitialValues(
            f.Initial.Text ?? f.Initial.Value,
            f.Initial.Value,
          );
        }
        f.Combobox.specification.extraKeys = f.Dropdown?.ExtraKeys;
        f.Combobox.specification.passthroughValues =
          f.Dropdown?.PassthroughValues ?? null;
      }
    });
  }

  this.actionInputArgs = this.actionInputArgs || {};
  let formMessage = await this.message(
    "dialog",
    instructionText,
    {fields},
    content,
    null,
    dialogProperties,
  );
  let form = formMessage.form;

  if (form == null) {
    this.lastActionArgs = null;
    this.actionInputArgs = {};
    return;
  }

  for (let key in form) {
    let col = fields.filter(
      (x) => x.Name == key || x.Dropdown?.ColumnName == key,
    )[0];
    this.actionInputArgs[key] = formatter.serializeValue(col, form[key]);
  }

  const input = JSON.parse(
    JSON.stringify(
      global.session.activeWindow.sub?.window?.input ??
        global.session.activeWindow.input,
    ),
  );
  this.actionInputArgs.input = {
    input,
  };

  if (!isStack && this.lastActionArgs) {
    this.toggleLoading(true);
    await this.action(...this.lastActionArgs);
    this.toggleLoading(false);
  }
}
