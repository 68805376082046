export default function getStatusIcon(
  status,
  description,
) {
  let iconHtml = "";
  if (status === "success") {
    iconHtml = `<span  title="${description}" style="float: left;"><i class="fas fa-check text-success mr-1" ></i></span>`;
  }

  if (status === "warning") {
    iconHtml = `<span title="${description}" style="float: left;"><i class="fas fa-exclamation-triangle text-warning mr-1"></i></span>`;
  }

  if (status === "danger") {
    iconHtml = `<span  title="${description}" style="float: left;"><i class="fas fa-times text-danger mr-1"></i></span>`;
  }

  return iconHtml;
}
