import $ from "jquery";

/**
 * Signal session that there may be inconsistant data(race conditions)
 * @see {@link Session.updateRelatedWindows}
 * @returns {void}
 */
export default function reloadRelated(table) {
  $("body").addClass("avoid-clicks");
  this.session.updateRelatedWindows(this, table);
  $("body").removeClass("avoid-clicks");
}
