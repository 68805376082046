import $ from "jquery";
import {alertPopup} from "../../interface/alertPopup/alertPopup.js";

export default async function actionImportExcelFile(element, page) {
  let window = global.session.activeWindow;
  // let file = element.files[0];
  // let extension = file.substr((file.lastIndexOf('.') +1));
  let url =
    "/Admin/Pages/" +
    page +
    ".aspx?subject=" +
    encodeURIComponent(window.output.Request.Subject);

  for (const [key, value] of Object.entries(
    window.output.Request.Criteria[0],
  )) {
    url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  let filename = element.files[0].name;
  let extension = filename.substr(filename.lastIndexOf(".") + 1);
  let allowed_extensions = new Array("xls", "xlsx", "docb", "ods");

  if (
    filename &&
    filename.length > 0 &&
    allowed_extensions.includes(extension)
  ) {
    let data = new FormData();
    let file = element.files[0];
    data.append("file" + 0, file);
    $.ajax({
      type: "POST",
      url: url,
      contentType: false,
      processData: false,
      data: data,
      success: function (result) {
        if (result.Error) {
          let dbError = result.Error.Message;
          let friendlyUserError = dbError.substring(dbError.indexOf(":") + 1);
          alertPopup({icon: "warning", text: friendlyUserError});
        } else {
          window.handleActionResponse(result);
        }
      },
      error: function (error) {
        // alert('error!')
      },
    });
    this.reload();
    /*
    element.oninput = function() {
      window.actionImportExcelFile(this, page)
    }
    */
  } else {
    alertPopup({
      icon: "warning",
      text: this.session.translations.InvalidExcelFile,
    });
  }

  document.getElementById("ExcelFileCatcherInsert-" + window.id).value = null;
  document.getElementById("ExcelFileCatcherUpdate-" + window.id).value = null;
}
