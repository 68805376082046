import {api} from "@/services/api";

export async function fetchSettings({cache = false} = {}) {
  const cacheKey = "settingsData";

  // If caching is enabled, try to retrieve the cached data first
  if (cache) {
    const cachedData = sessionStorage.getItem(cacheKey);
    if (cachedData) {
      return JSON.parse(cachedData); // Return the cached data if it exists
    }
  }

  // Fetch new data if no cache is found or caching is not enabled
  const result = await api.get(`/v2/core/settings`);

  // If caching is enabled, save the new data to sessionStorage
  if (cache) {
    sessionStorage.setItem(cacheKey, JSON.stringify(result.data));
  }

  return result.data;
}
