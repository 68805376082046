import {setFormFieldVisuallyDirty} from "./setFormFieldVisuallyDirty.js";
import {formRowCellIsDirty} from "../formRowCellIsDirty.js";

export const processDirtyFormFields = ({window}) => {
  if (!window) return;

  const row =
    window.output.Table?.Rows?.[0] ?? window.sub?.window.output.Table.Rows?.[0];
  const originalRow =
    window.originalFormRow ?? window.sub?.window.originalFormRow;

  for (const cell in row) {
    const fieldName = row[cell].Column.Name;
    const dirty = formRowCellIsDirty({originalRow, rowCell: row[cell]});

    setFormFieldVisuallyDirty({
      fieldName,
      dirty,
      window,
    });
  }
};
