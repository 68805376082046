import $ from "jquery";
import Hook from "../hook";

class ItemFieldHook extends Hook {
  async afterRender(window) {
    // Disable IsRequired checkbox if chosen Type is Checkbox (a 'required' checkbox does not make much sense, also '*' is not shown on a checkbox)
    if (
      window.output.Request.Subject == "Rental.ItemGroupFieldDefinition" &&
      window.output.Request.Prefix == "New"
    ) {
      let $inputItemFieldType = $("[name='Type']", window.element);
      let $inputIsRequired = $("[name='IsRequired']", window.element);

      $inputItemFieldType.off("change");
      $inputItemFieldType
        .change(() => {
          let itemTypeVal = $inputItemFieldType.val();
          $inputIsRequired.prop("disabled", itemTypeVal == "Boolean");
          if (itemTypeVal == "Boolean") {
            $inputIsRequired.prop("checked", false);
          }
        })
        .change();
    }
  }
}

/** @ignore */
export default new ItemFieldHook();
