import arrayToTree from "array-to-tree";

/**
 * Opens previous permissions screen based on saved history of window object
 */
export default async function openPreviousPermissionsScreen() {
  if (this.session.previousPermissionScreen.length < 1) return;

  this.session.previousPermissionScreen.splice(-1, 1);

  const previousPermissionScreen =
    this.session.previousPermissionScreen[
      this.session.previousPermissionScreen.length - 1
    ];

  let url =
    "/Admin/WebServices/MembershipWebServices.asmx/GetRolePermissionData";
  let input = {
    tableName: previousPermissionScreen.tableName,
    roleID: previousPermissionScreen.roleID,
  };
  // Fixed strange browser bug, uncheck all checkboxes before reloading data.
  // Firefox & Chrome are remembering Checked action buttons.
  //$("input:checkbox", window.element).removeAttr("checked")

  let output = await this.session.request(url, input);
  output.FormPermissionMenu = arrayToTree(output.FormPermissionMenu, {
    parentProperty: "ParentID",
    customID: "ID",
  });

  output.inputVars = input;
  //output.breadCrumbs = [].concat(breadcrumbs.split(/;/g))

  this.setupCustomEnv("permissions", output, true);
}
