
        
    const runtime = require("../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/modals/body/modalBodyLoadAnimation.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n<div class=\"container-fluid\">\n\t<h1 class=\"text-center mt-3\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ResultsLoading"), env.opts.autoescape);
output += "</h1>\n\t<div class=\"loading-spinner mt-5 mb-5\">\n\t\t<div class=\"loading-dot dot-0\"></div>\n\t\n\t\t<div class=\"loading-dot dot-1\"></div>\n\t\n\t\t<div class=\"loading-dot dot-2\"></div>\n\t\n\t\t<div class=\"loading-dot dot-3\"></div>\n\t\n\t\t<div class=\"loading-dot dot-4\"></div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/modals/body/modalBodyLoadAnimation.html", ctx);
          }
        
          return nunjucks.render("views/content/modals/body/modalBodyLoadAnimation.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/modals/body/modalBodyLoadAnimation.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        