import Hook from "@/hook";
import store from "../../../state/store";
import openref from "./openref";

/**
 * Open table row in new window
 * @param {string} id - Row id
 * @param {string} fieldIndex - Optional field (column) index (when a specific linked column is clicked)
 * @param {string} subject - Provide your subject manually
 * @param {string} key - Key to be provided manually when not using a standard table
 * @param {string} keyReference - KeyReference to be provided manually when not using a standard table
 * @returns {void}
 */
export default function openRow(id, fieldIndex, subject, key, keyReference) {
  // run hookable function
  // Set input of new window
  let input = {};
  let column = null;
  input.Subject = this.input.Subject;
  input.Prefix = "Single";
  input.Criteria = {};

  if (subject) {
    input.Subject = subject;
  }

  if (fieldIndex) {
    column = this.output.Table.Columns[fieldIndex];
  }

  if (keyReference) {
    // if key is provided manually (when not using a standard table) create criteria from key
    input.Criteria = [{[keyReference]: key}];
  } else if (column && column.Dropdown && column.Dropdown.OpenRef) {
    let columnValue = this.output.FullTable.Rows[id].find((cell) => {
      return cell.Column.Name === column.Name;
    }).Value;

    openref.call(this, column.Name, columnValue);

    return;
  } else if (this.output.FullTable.Rows[id]) {
    // build criteria based on the selected rowID
    input.Criteria = [this.buildCriteriaNew(this.output.FullTable.Rows[id])];
  }

  // temporaraly store the clicked rowID in input.rowID for hooks
  input.rowID = id;

  // Cancel if composition ID is NULL
  if (input.Criteria.length && input.Criteria[0].CompositionID === null) return;

  return Hook.run(
    "openrow",
    this,
    async () => {
      // remove temporariy rowID
      delete input.rowID;

      // Create the new window
      await this.session.openWindow(input);

      store.commit("updateWindow");
    },
    input,
  );
}
