export const transformButtons = (btns) => {
  if (typeof btns === "object" && !Array.isArray(btns)) {
    return Object.entries(btns).map(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        return {...value, value: value.value !== undefined ? value.value : key};
      }
      return {text: value, value: key};
    });
  }

  if (Array.isArray(btns) && typeof btns[0] !== "object") {
    return [
      {text: btns[0], value: null},
      {text: "Ok", value: true, variant: "primary"},
    ];
  }

  return btns;
};
