import store from "../../../state/store";
import $ from "jquery";

export default async function injectTab(output) {
  if (this.bulkedit) {
    if (!this.dirtyCheckWithPrompt(window)) return;
  }

  await this.toggleLoading(true);

  // process and render
  await this.process(output, true);
  await this.toggleLoading(false);

  await this.render();
  store.commit("updateWindow");
  $(this.element).removeClass("dirty");

  return;
}
