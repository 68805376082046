import {saveWindowData} from "@/services/datagrid";
import getMetadata from "@/services/metadata/getMetaData";

// initial state
// shape: [{ user, properties }]
const state = () => ({
  windows: {},
});

// getters
const getters = {
  getWindow: (state, getters) => (windowId) => {
    return state.windows[windowId ?? getters.activeWindowId] ?? undefined;
  },
  getWindowForm: (state, getters) => (windowId) => {
    return (
      state.windows[windowId ?? getters.activeWindowId]?.output?.Form ??
      undefined
    );
  },
  activeWindowForm: (state, getters) => {
    return state.windows[getters.activeWindowId]?.output?.Form ?? undefined;
  },
  getWindowData: (state, getters) => (windowId) => {
    return (
      state.windows[windowId ?? getters.activeWindowId]?.output?.Data ??
      undefined
    );
  },

  activeWindow: (state, getters) => {
    return state.windows[getters.activeWindowId] ?? undefined;
  },
  rows: (state) => (windowId) => {
    return (
      state.windows[windowId ?? getters.activeWindowId]?.output?.Data?.Rows ??
      undefined
    );
  },
  changedRows: (state, getters) => (windowId) => {
    return state.windows[windowId ?? getters.activeWindowId]?.changedRows;
  },
  columns: (state, getters) => (windowId) => {
    return (
      state.windows[windowId ?? getters.activeWindowId]?.output?.Data
        ?.Columns ?? undefined
    );
  },
  subject: (state, getters) => (windowId) => {
    return state.windows[windowId ?? getters.activeWindowId].output.Request
      .Subject;
  },
  activeWindowId: (state, getters, rootState) => {
    if (rootState.subWindow?.id) return rootState.subWindow.id;
    return rootState.window?.id;
  },
  windowType: (state) => (windowId) => {
    return state.windows[windowId ?? getters.activeWindowId]?.type ?? undefined;
  },
  windowReloadIteration: (state) => (windowId) => {
    return (
      state.windows[windowId ?? getters.activeWindowId]?.reloadIteration ?? 0
    );
  },
};

// actions
const actions = {
  async fetchMetadata({state, commit}, {windowId}) {
    const window = state.windows[windowId];

    const metadata = await getMetadata({
      table: window.output.Data.Subject,
      prefix: window.output.Request.Prefix,
      criteria: window.output.Request.Criteria,
    });

    commit("setWindowMetadata", {windowId, metadata});
  },
  async save({state}, {windowId}) {
    console.log({windowId});
  },
  reloadWindow(state, {windowId}) {
    global.session.windows[windowId].reload();
  },
  async saveWindowData({state, rootState, rootGetters}, {windowId}) {
    const rows = rootState[rootGetters.selectedWindowType].changedRows;
    const filteredRows = [];
    const activeWindow = state.windows[windowId];

    for (const row of rows) {
      if (row.Criteria[this.primaryKey.Name].toString().startsWith("NEW")) {
        filteredRows.push({Criteria: {}, Values: row.Values});
        continue;
      }
      filteredRows.push(row);
    }

    await saveWindowData({
      table: {
        subject: rootGetters.selectedWindow.output.Request.Subject,
        rows: filteredRows ?? [],
      },
      form: {
        subject: rootGetters.selectedWindow.output.Request.Subject,
        data: {
          criteria: activeWindow.output.Request.Criteria,
          values: activeWindow.output.Form.Values,
        },
      },
    });

    rootState[rootGetters.selectedWindowType].changedRows = [];

    rootState.loading = false;
    global.session.activeWindow.reset();
  },
};

// mutations
const mutations = {
  increaseReloadIteration(state, {windowId}) {
    const window = {...state.windows[windowId]};
    window.reloadIteration++;

    state.windows = {
      ...state.windows,
      [windowId]: window,
    };
  },
  updateRow(state, {windowId, rowIndex, row}) {
    state.windows[windowId].rows.splice(rowIndex, 1, row);
  },
  updateChangedRow(state, {windowId, rowIndex, row}) {
    state.windows[windowId].changedRows.splice(rowIndex, 1, row);
  },
  addChangedRow(state, {windowId, row}) {
    state.windows[windowId].changedRows.push(row);
  },
  addRow(state, {windowId, row}) {
    state.windows[windowId].rows.unshift(row);
  },
  clearChangedRows(state, {windowId}) {
    if (!windowId) return;
    state.windows[windowId]?.changedRows?.splice(
      0,
      state.windows[windowId]?.changedRows?.length,
    );
  },
  setWindow(state, {window, windowType}) {
    state.windows[window.id] = {
      ...window,
      changedRows: [],
      type: windowType,
      rows: window.output?.Data?.Rows,
      columns: window.output?.Data?.Columns,
      reloadIteration: 0,
      vueTemplate: window.vueTemplate,
    };
  },
  setWindowOutput(state, {windowId, output}) {
    const window = {...state.windows[windowId]};
    window.output = output;

    state.windows = {
      ...state.windows,
      [windowId]: window,
    };
  },
  setWindowMetadata(state, {windowId, metadata}) {
    const window = {...state.windows[windowId]};
    window.metadata = metadata;

    state.windows = {
      ...state.windows,
      [windowId]: window,
    };
  },
  setWindowFormValue(state, {fieldName, value, windowID}) {
    const form = state.windows[windowID].output.Form;

    if (!form.Values) form.Values = {};

    form.Values[fieldName] = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
