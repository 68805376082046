/**
 * Insert a sub (open a tab) in the active window (parent of active window if applicable)
 *
 * @param {Object} output
 * @memberof Window
 */
export default async function openTab(output) {
  // check if we need to show a confirmation
  if (output.NoDirtyCheck) {
    global.skipDirty = output.FullTableName;
  }
  global.session.activeWindow.action(
    output.ActionName,
    null,
    output.WebService,
    output.WebMethod,
  );
}
