import {transformButtons} from "./transformButtons";

export const processButtons = (btns) => {
  if (Array.isArray(btns) && typeof btns[0] === "object") {
    return btns;
  }
  if (!btns) {
    return [
      {
        text: "Ok",
        value: true,
        variant: "primary",
      },
    ];
  }
  return transformButtons(btns);
};
