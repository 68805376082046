import {api} from "./api";

export async function getExcelTemplate({fullTableName, criteria}) {
  const result = await api.get(`/v2/excel/template`, {
    params: {fullTableName},
    ...criteria?.[0]
  });

  return result.data;
}

export async function importExcelTemplate({
  formData,
  insert,
  update,
  fullTableName,
  criteria
}) {
  const result = await api.post(`/v2/excel/import`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      insert,
      update,
      fullTableName,
      ...criteria?.[0]
    },
  });

  return result.data;
}
