/**
 * Set custum values
 * @param {string} templateName - Custom template name
 * @param {Object} customData - Custom data
 * @param {boolean} keepInput - Keep input
 * @returns {void}
 */
export default function setupCustomEnv(templateName, customData, keepInput) {
  this.customTemplateName = templateName;
  this.customData = customData;
  this.input = keepInput ? this.input : null;
  this.render();
}
