import {getSettings} from "@/util/getSettings";

export function formatDecimal(decimal, decimalPlaces) {
  const {DecimalSeparator} = getSettings();

  if (decimal == null) {
    return "";
  }

  if (typeof decimal != "number") {
    throw new Error(`Decimal must be a number: ${decimal}`);
  }

  if (isNaN(decimal)) {
    return "";
  }

  if (DecimalSeparator === "Comma") {
    return decimal.toFixed(decimalPlaces ?? 2).replace(".", ",");
  }

  if (DecimalSeparator === "Point") {
    return decimal.toFixed(decimalPlaces ?? 2).replace(",", ".");
  }
}
