
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/elements/horizontalCalender.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"table-view ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"bulkedit")?" unsortable":""), env.opts.autoescape);
output += "\">\n\n    <div class=\"row no-gutters horizontal-agenda\">\n        <div class=\"col-12\">\n            <div>\n                <div class=\"table-container\" style=\"overflow: hidden auto;\">\n                    ";
var t_1;
t_1 = 0;
frame.set("rowIndex", t_1, true);
if(frame.topLevel) {
context.setVariable("rowIndex", t_1);
}
if(frame.topLevel) {
context.addExport("rowIndex", t_1);
}
output += "\n                    ";
frame = frame.push();
var t_4 = env.getFilter("groupby").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Rows"),"DueDateRaw");
if(t_4) {t_4 = runtime.fromIterator(t_4);
var t_2;
if(runtime.isArray(t_4)) {
var t_3 = t_4.length;
for(t_2=0; t_2 < t_4.length; t_2++) {
var t_5 = t_4[t_2][0];
frame.set("[object Object]", t_4[t_2][0]);
var t_6 = t_4[t_2][1];
frame.set("[object Object]", t_4[t_2][1]);
frame.set("loop.index", t_2 + 1);
frame.set("loop.index0", t_2);
frame.set("loop.revindex", t_3 - t_2);
frame.set("loop.revindex0", t_3 - t_2 - 1);
frame.set("loop.first", t_2 === 0);
frame.set("loop.last", t_2 === t_3 - 1);
frame.set("loop.length", t_3);
output += "\n                    ";
if((lineno = 10, colno = 27, runtime.callWrap(runtime.memberLookup(((lineno = 9, colno = 26, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_5]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["x"])) < (lineno = 10, colno = 62, runtime.callWrap(runtime.memberLookup(((lineno = 10, colno = 48, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [runtime.contextOrFrameLookup(context, frame, "date")]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["x"]))) {
output += " \n                    <table class=\"table table-hover table-striped table-danger table-bordered mb-0\">\n                        ";
;
}
else {
output += "\n                        <table class=\"table table-hover table-striped table-bordered mb-0\">\n                            ";
;
}
output += "\n                            <tbody>\n                                <tr class=\"table-active bg-secondary\">\n                                    <td class=\"table-active\" colspan=\"4\">\n                                        <strong>\n                                            <a>\n                                                <i class=\"fas fa-calendar-day\"></i>\n                                                ";
output += runtime.suppressValue((lineno = 21, colno = 75, runtime.callWrap(runtime.memberLookup(((lineno = 21, colno = 63, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_5]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["dddd DD MMMM YYYY"])), env.opts.autoescape);
output += "</a>\n                                        </strong>\n                                        <strong class=\"float-right\">\n                                            <a>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Week"), env.opts.autoescape);
output += "\n                                                ";
output += runtime.suppressValue((lineno = 25, colno = 73, runtime.callWrap(runtime.memberLookup(((lineno = 25, colno = 63, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_5]))),"week"), "the return value of (window[\"dayjs\"])[\"week\"]", context, [])), env.opts.autoescape);
output += "</a>\n                                        </strong>\n                                    </td>\n                                </tr>\n                                ";
frame = frame.push();
var t_9 = t_6;
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("item", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\n                                <tr data-row-index=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "rowIndex"), env.opts.autoescape);
output += "\" data-subject=\"";
output += runtime.suppressValue(runtime.memberLookup((t_10),"Subject"), env.opts.autoescape);
output += "\"\n                                    data-key=\"";
output += runtime.suppressValue(runtime.memberLookup((t_10),"ID"), env.opts.autoescape);
output += "\" data-keyreference=\"";
output += runtime.suppressValue(runtime.memberLookup((t_10),"KeyReference"), env.opts.autoescape);
output += "\">\n                                    <td width=\"160px\" class=\"text-center\">\n                                        <!-- Type 1 Outgoing order -->\n                                        ";
if(runtime.memberLookup((t_10),"TaskDescription") == "IncomingItem") {
output += "\n                                        <span class=\"badge badge-info\">\n                                            <i class=\"fas fa-arrow-down\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_10),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_10),"TaskDescription") == "OutgoingItem") {
output += "\n                                        <span class=\"badge badge-success\">\n                                            <i class=\"fas fa-arrow-up\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_10),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_10),"TaskDescription") == "OutgoingItem") {
output += "\n                                        <span class=\"badge badge-secondary\">\n                                            <i class=\"fas fa-dolly\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.contextOrFrameLookup(context, frame, "stocktransfer")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_10),"TaskDescription") == "Invoice") {
output += "\n                                        <span class=\"badge badge-secondary\">\n                                            <i class=\"fas fa-file-invoice\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_10),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
output += "\n                                        <span class=\"badge badge-secondary\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_10),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
;
}
;
}
;
}
output += "\n                                    </td>\n                                    <td width=\"40px;\" class=\"text-center\">";
output += runtime.suppressValue(runtime.memberLookup((t_10),"Amount"), env.opts.autoescape);
output += "</td>\n                                    <td>\n                                        <a>\n                                            <strong>";
output += runtime.suppressValue(runtime.memberLookup((t_10),"ID"), env.opts.autoescape);
output += "\n                                            </strong>- ";
output += runtime.suppressValue(runtime.memberLookup((t_10),"CustomerID"), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((t_10),"CustomerCompanyName"), env.opts.autoescape);
output += "\n                                            <i>";
output += runtime.suppressValue(runtime.memberLookup((t_10),"Reference"), env.opts.autoescape);
output += "\n                                                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_10),"OtherDescription")), env.opts.autoescape);
output += "</i>\n                                            <span class=\"float-right\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Metadata")),runtime.contextOrFrameLookup(context, frame, "rowIndex"))),"UserModified")),"Description"), env.opts.autoescape);
output += "</span></a>\n                                    </td>\n                                </tr>\n                                ";
var t_11;
t_11 = runtime.contextOrFrameLookup(context, frame, "rowIndex") + 1;
frame.set("rowIndex", t_11, true);
if(frame.topLevel) {
context.setVariable("rowIndex", t_11);
}
if(frame.topLevel) {
context.addExport("rowIndex", t_11);
}
output += "\n                                ";
;
}
}
frame = frame.pop();
output += "\n                            </tbody>\n                        </table>\n                        ";
;
}
} else {
t_2 = -1;
var t_3 = runtime.keys(t_4).length;
for(var t_12 in t_4) {
t_2++;
var t_13 = t_4[t_12];
frame.set("day", t_12);
frame.set("row", t_13);
frame.set("loop.index", t_2 + 1);
frame.set("loop.index0", t_2);
frame.set("loop.revindex", t_3 - t_2);
frame.set("loop.revindex0", t_3 - t_2 - 1);
frame.set("loop.first", t_2 === 0);
frame.set("loop.last", t_2 === t_3 - 1);
frame.set("loop.length", t_3);
output += "\n                    ";
if((lineno = 10, colno = 27, runtime.callWrap(runtime.memberLookup(((lineno = 9, colno = 26, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_12]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["x"])) < (lineno = 10, colno = 62, runtime.callWrap(runtime.memberLookup(((lineno = 10, colno = 48, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [runtime.contextOrFrameLookup(context, frame, "date")]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["x"]))) {
output += " \n                    <table class=\"table table-hover table-striped table-danger table-bordered mb-0\">\n                        ";
;
}
else {
output += "\n                        <table class=\"table table-hover table-striped table-bordered mb-0\">\n                            ";
;
}
output += "\n                            <tbody>\n                                <tr class=\"table-active bg-secondary\">\n                                    <td class=\"table-active\" colspan=\"4\">\n                                        <strong>\n                                            <a>\n                                                <i class=\"fas fa-calendar-day\"></i>\n                                                ";
output += runtime.suppressValue((lineno = 21, colno = 75, runtime.callWrap(runtime.memberLookup(((lineno = 21, colno = 63, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_12]))),"format"), "the return value of (window[\"dayjs\"])[\"format\"]", context, ["dddd DD MMMM YYYY"])), env.opts.autoescape);
output += "</a>\n                                        </strong>\n                                        <strong class=\"float-right\">\n                                            <a>";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Week"), env.opts.autoescape);
output += "\n                                                ";
output += runtime.suppressValue((lineno = 25, colno = 73, runtime.callWrap(runtime.memberLookup(((lineno = 25, colno = 63, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"dayjs"), "window[\"dayjs\"]", context, [t_12]))),"week"), "the return value of (window[\"dayjs\"])[\"week\"]", context, [])), env.opts.autoescape);
output += "</a>\n                                        </strong>\n                                    </td>\n                                </tr>\n                                ";
frame = frame.push();
var t_16 = t_13;
if(t_16) {t_16 = runtime.fromIterator(t_16);
var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("item", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
output += "\n                                <tr data-row-index=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "rowIndex"), env.opts.autoescape);
output += "\" data-subject=\"";
output += runtime.suppressValue(runtime.memberLookup((t_17),"Subject"), env.opts.autoescape);
output += "\"\n                                    data-key=\"";
output += runtime.suppressValue(runtime.memberLookup((t_17),"ID"), env.opts.autoescape);
output += "\" data-keyreference=\"";
output += runtime.suppressValue(runtime.memberLookup((t_17),"KeyReference"), env.opts.autoescape);
output += "\">\n                                    <td width=\"160px\" class=\"text-center\">\n                                        <!-- Type 1 Outgoing order -->\n                                        ";
if(runtime.memberLookup((t_17),"TaskDescription") == "IncomingItem") {
output += "\n                                        <span class=\"badge badge-info\">\n                                            <i class=\"fas fa-arrow-down\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_17),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_17),"TaskDescription") == "OutgoingItem") {
output += "\n                                        <span class=\"badge badge-success\">\n                                            <i class=\"fas fa-arrow-up\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_17),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_17),"TaskDescription") == "OutgoingItem") {
output += "\n                                        <span class=\"badge badge-secondary\">\n                                            <i class=\"fas fa-dolly\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.contextOrFrameLookup(context, frame, "stocktransfer")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
if(runtime.memberLookup((t_17),"TaskDescription") == "Invoice") {
output += "\n                                        <span class=\"badge badge-secondary\">\n                                            <i class=\"fas fa-file-invoice\"></i>\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_17),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
else {
output += "\n                                        <span class=\"badge badge-secondary\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),runtime.memberLookup((t_17),"TaskDescription")), env.opts.autoescape);
output += "</span>\n                                        ";
;
}
;
}
;
}
;
}
output += "\n                                    </td>\n                                    <td width=\"40px;\" class=\"text-center\">";
output += runtime.suppressValue(runtime.memberLookup((t_17),"Amount"), env.opts.autoescape);
output += "</td>\n                                    <td>\n                                        <a>\n                                            <strong>";
output += runtime.suppressValue(runtime.memberLookup((t_17),"ID"), env.opts.autoescape);
output += "\n                                            </strong>- ";
output += runtime.suppressValue(runtime.memberLookup((t_17),"CustomerID"), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((t_17),"CustomerCompanyName"), env.opts.autoescape);
output += "\n                                            <i>";
output += runtime.suppressValue(runtime.memberLookup((t_17),"Reference"), env.opts.autoescape);
output += "\n                                                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_17),"OtherDescription")), env.opts.autoescape);
output += "</i>\n                                            <span class=\"float-right\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"Metadata")),runtime.contextOrFrameLookup(context, frame, "rowIndex"))),"UserModified")),"Description"), env.opts.autoescape);
output += "</span></a>\n                                    </td>\n                                </tr>\n                                ";
var t_18;
t_18 = runtime.contextOrFrameLookup(context, frame, "rowIndex") + 1;
frame.set("rowIndex", t_18, true);
if(frame.topLevel) {
context.setVariable("rowIndex", t_18);
}
if(frame.topLevel) {
context.addExport("rowIndex", t_18);
}
output += "\n                                ";
;
}
}
frame = frame.pop();
output += "\n                            </tbody>\n                        </table>\n                        ";
;
}
}
}
frame = frame.pop();
output += "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/elements/horizontalCalender.html", ctx);
          }
        
          return nunjucks.render("views/elements/horizontalCalender.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/elements/horizontalCalender.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        