import $ from "jquery";
import download from "@/util/download";
import store from "@/../state/store";

/**
 * Action to download file
 * @returns {Promise} Promise
 */
export default async function actionDownloadFile() {
  let url =
    "/Admin/Pages/DownloadFile.aspx?tableName=" +
    encodeURIComponent(this.output.Request.Subject) +
    `&Authorization=${encodeURIComponent(`Bearer ${store.state.accessToken}`)}`;
  let queue = [];

  for (let rowCriteria of this.selection) {
    url = url + "&" + $.param(rowCriteria);
  }

  queue.push(download(url));
  await Promise.all(queue);
}
