import downloadFunction from "@/util/download";

/**
 * Alias for download
 * @param {string} url - Download URL
 * @param {Object} opts - Download options
 * * @param {Object} method - Post or Get
 * @returns {Promise} Promise to download
 */
export default async function download(url, opts, method) {
  await downloadFunction(url, opts, method);
}
