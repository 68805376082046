export const processSidebarFields = ({
  columns,
  window,
  getValueByColumnName,
  getMetadataByColumnName,
  getComboboxByColumnName,
}) => {
  if (columns) {
    const sidebarFields = columns.filter((column) => column.IsSideBarColumn);
    sidebarFields.forEach((field) => {
      field.Value = getValueByColumnName(field.Name);
      field.Metadata = getMetadataByColumnName(field.Name);
      field.Combobox = getComboboxByColumnName(field.Name);
    });
    if (sidebarFields.length > 0 && window) return sidebarFields;
  }

  if (window?.sidebarFields && window.sidebarFields.length) {
    return window.sidebarFields.map((field) => ({...field, IsReadyOnly: true}));
  }

  return [];
};
