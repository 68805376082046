import {cleanTreeBranches} from "./cleanTreeBranches.js";

export const generateMenu = ({menu, arrayToTreeFn}) => {
  if (menu !== null) {
    // filter menu items check for ones that have a ParentID that does not exist in the menu array

    const filteredMenu = menu.filter(
      (menuItem) =>
        menu.some((item) => item.ID === menuItem.ParentID) ||
        menuItem.ParentID === null,
    );

    const tree = arrayToTreeFn(filteredMenu, {
      parentProperty: "ParentID",
      customID: "ID",
    });

    cleanTreeBranches({branches: tree});

    return tree;
  }
  return [];
};
