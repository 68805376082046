import {computeRowColor} from "./colorComputation";

export function getCachedRowColorOrComputeAndStore({
  row,
  windowId,
  windowOutput,
}) {
  const key = windowId;

  if (row) {
    let computedColor = computeRowColor({row, windowOutput});

    if (computedColor != null && computedColor != "inherit") {
      localStorage.setItem(key, computedColor);
    }
  }

  return localStorage.getItem(key);
}
