/**
 * Open category in new window
 * @param {*} categoryID - Category ID
 * @deprecated
 * @returns {Promise} Promise
 */
export default async function openCategory(categoryID) {
  // This wasn't defined..?
  let newInput = {};
  newInput.Criteria = [{CategoryID: categoryID}];
  await this.session.openWindow(newInput);
}
