<template>
  <div class="login-overlay">
    <div class="two-factor-setup p-[20px] relative">
      <div class="flex justify-start mb-10">
        <strong class="text-lg">
          {{ translations.TwoFactorSetupAppSecuredTitle }}
        </strong>
      </div>

      <div class="flex items-center flex-column mx-auto mb-8">
        <img
          src="../../../src/assets/static/RentMagic/favicon.png"
          class="w-[30%] h-auto"
        />
      </div>

      <div class="text-center mb-10">
        <p>
          {{ translations.TwoFactorSetupAppSecuredDescription }}
        </p>
      </div>

      <div class="mb-2.5 text-center">
        <strong class="text-xl">
          {{ translations.TwoFactorSetupAppBackUpCodesTitle }}
        </strong>
      </div>
      <div class="flex justify-center mb-1.5">
        <p class="text-center w-[90%]">
          {{ translations.TwoFactorSetupAppBackUpCodesDescription }}
        </p>
      </div>

      <div
        class="flex justify-center flex-wrap gap-2 mx-auto w-4/5 py-4 bg-gray-50 rounded-lg"
      >
        <input
          v-for="(code, index) in backupCodes"
          :key="index"
          :value="code"
          class="border-[1px] border-solid border-[#ced4da] !rounded h-[31px] max-w-[150px] inline-block text-[16px] text-black tracking-wide text-center bg-white"
          type="text"
          disabled
        />
      </div>

      <button
        class="absolute bottom-7 right-8 btn btn-primary ml-0"
        type="submit"
        @click="$emit('showComponent', 'loginSessionList')"
      >
        {{ translations.Confirm }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginDisplayBackupCodes",
  props: {
    totpData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      backupCodes: this.totpData.BackupCodes ?? [],
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .two-factor-setup {
    border-radius: 0px;
  }
}
.login-overlay {
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;
  background-color: #362f2f7a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.two-factor-setup {
  background-color: white;
  overflow: auto;
  max-height: 100%;
  position: fixed;
  width: 500px;
  min-height: 755px;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgb(0 0 0 / 30%);
}
</style>
