<template>
  <div id="canvas" :class="classes" :style="styleProperties">
    <div :class="{headless: headless}">
      <div v-if="headless" class="float-right">
        <a
          class="btn-secondary btn-sm btn headless-link"
          :href="href.replace('&Headless=true', '')"
          target="__blank"
        >
          {{ translations.OpenInRentMagic }}
        </a>
        <canvas-save-button
          :subject="subject"
          :window="mainWindow"
          :translations="translations"
        />
      </div>
      <div v-if="!headless" class="float-right">
        <canvas-currency-toggle
          v-if="windowID"
          :bulkedit="bulkedit"
          :window-i-d="windowID"
          :window="window"
          :sub-window="subWindow"
        />

        <canvas-history-button
          v-if="changeTrackingEnabled"
          :table="currentTable"
        />
        <canvas-reset-button :bulkedit="bulkedit" :window="window" />
        <canvas-dispose-button />

        <!-- END Currency check -->
      </div>
      <canvas-title
        :title="title"
        :sub-title="subTitle"
        :window="window"
        :sub-window="subWindow"
      />

      <hr />
    </div>

    <div data-window-anchor></div>
  </div>
</template>

<script>
import CanvasCurrencyToggle from "./canvas/CanvasCurrencyToggle.vue";
import CanvasSaveButton from "@/components/canvas/buttons/CanvasSaveButton";
import CanvasResetButton from "@/components/canvas/buttons/CanvasResetButton";
import CanvasDisposeButton from "@/components/canvas/buttons/CanvasDisposeButton";
import CanvasHistoryButton from "@/components/canvas/buttons/CanvasHistoryButton";
import CanvasTitle from "@/components/canvas/CanvasTitle.vue";

export default {
  components: {
    CanvasTitle,
    CanvasHistoryButton,
    CanvasResetButton,
    CanvasSaveButton,
    CanvasDisposeButton,
    "canvas-currency-toggle": CanvasCurrencyToggle,
  },
  props: {
    window: {
      type: Object,
      required: false,
    },
  },
  data: () => {
    return {
      windowHTML: "",
      windowID: "",
    };
  },
  computed: {
    currentTable() {
      const tables = this.$store.getters["tables/tables"];
      const window = this.subWindow?.id ? this.subWindow : this.window;
      const tableName = window?.output?.Request?.Subject;
      const table = tables.find((t) => t.FullTableName === tableName);

      return table ?? null;
    },
    changeTrackingEnabled() {
      if (this.currentTable) {
        return this.currentTable?.EnableChangeTracking ?? false;
      }
      return false;
    },
    subject() {
      return (
        this.subWindow?.output?.Request.Subject ??
        this.window?.output?.Request.Subject ??
        null
      );
    },
    prefix() {
      return (
        this.window?.output?.Request.Prefix ??
        this.subWindow?.output?.Request.Prefix
      );
    },
    translations() {
      return this.$store.state.translations;
    },
    loading: function () {
      return this.$store.state.loading;
    },
    href() {
      return window.location.href;
    },
    headless() {
      return this.$store.state.headless;
    },
    styleProperties: function () {
      if (this.loading) {
        return {
          opacity: 0.4,
          "pointer-events": "none",
        };
      }
      return {};
    },
    classes: function () {
      if (this.$store.state.settings.SimpleInterface) {
        return "simple-interface";
      }
      return "advanced-interface";
    },
    bulkedit: function () {
      if (this.window.bulkedit) return true;
      if (this.subWindow && this.subWindow.bulkedit) return true;
      return false;
    },
    subWindow: function () {
      return this.$store.getters.subWindow;
    },
    mainWindow: function () {
      return this.window;
    },
    title() {
      if (!this.window.output || this.loading) {
        return global.session.translations.LoadingWindow;
      }
      const titleSuffix =
        this.window.counter && this.window.counter !== 0
          ? ` (${this.window.counter})`
          : "";

      if (this.window.counter !== 0)
        return (
          (this.window.customTitle ||
            this.window.output.Title ||
            global.session.translations.LoadingWindow) + titleSuffix
        );
      return "";
    },

    subTitle() {
      if (window.output) {
        return window.output.SubTitle;
      }
      return "";
    },
  },
  watch: {
    subWindow() {
      if (this.subWindow) {
        this.windowID = this.subWindow.id;
        return;
      }
      this.windowID = this.window.id;
    },
    window() {
      if (this.subWindow) {
        this.windowID = this.subWindow.id;
        return;
      }
      this.windowID = this.window.id;
    },
  },
};
</script>
