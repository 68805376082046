import $ from "jquery";
import dayjs from "dayjs";
import formatter from "@/model/formatter";
import {extractSubWindowFromWindowObject} from "@/util/extractSubWindowFromWindowObject";

/**
 * Apply values to window
 * @param {*} newValues - New values
 * @returns {void}
 */
export default function applyChanges(newValues) {
  const relevantWindow =
    extractSubWindowFromWindowObject({window: this}) ?? this;
  const {Table} = relevantWindow.output;
  const {element, vueColumns} = relevantWindow;

  let inputElements = $(element).find("[name]").toArray();
  let inputs = {};
  for (let inputElement of inputElements) {
    let name = $(inputElement).attr("name");
    inputs[name] = inputElement;
  }

  let row = Table.Rows[0];

  this.previousPostbackValues = {...this.previousPostbackValues, ...newValues};

  for (let columnName in newValues) {
    let cell = row.find((x) => x.Column.Name == columnName);

    let dateFormattedValue = newValues[columnName];
    if (cell?.Column?.BaseType == "Date") {
      dateFormattedValue = dayjs(newValues[columnName], "YYYY-MM-DD").format(
        "DD-MM-YYYY",
      );
    }

    if (cell != null) {
      cell.NewValue = dateFormattedValue;
    }

    let inputElement = inputs[columnName];

    if (inputElement != null) {
      let serializedValue =
        cell != null
          ? formatter.parseValue(cell.Column, newValues[columnName])
          : newValues[columnName];

      if ($(inputElement).is("[contenteditable]")) {
        $(inputElement).text(serializedValue);
      } else if ($(inputElement).is("input[type=checkbox]")) {
        $(inputElement).prop("checked", serializedValue);
      } else {
        $(inputElement).val(serializedValue);
      }

      const isLegacyDateField =
        vueColumns[columnName]?.$children[0].updateStartDate;
      const isDateField =
        vueColumns[columnName]?.$children[0]?.$children[0]?.$children[0]
          ?.$children[0].handleFieldInput;

      if (isLegacyDateField) {
        vueColumns[columnName].$children[0].updateStartDate(
          dayjs(newValues[columnName], "YYYY-MM-DD"),
        );
      } else if (isDateField) {
        vueColumns[
          columnName
        ]?.$children[0]?.$children[0]?.$children[0]?.$children[0].handleFieldInput(
          dayjs(newValues[columnName], "YYYY-MM-DD"),
        );
      } else {
        $(inputElement).change();
      }
    }
  }
}
