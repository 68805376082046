
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        
        const __nunjucks_template_import__dep_0 = require("./inputField.html");
        
        const __nunjucks_template_import__dep_1 = require("./columnGroup.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets,
...__nunjucks_template_import__dep_1.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/form/inputForm.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/content/form/inputField.html", false, "views/content/form/inputForm.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("inputfield", t_1);
output += "\n";
env.getTemplate("views/content/form/columnGroup.html", false, "views/content/form/inputForm.html", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("columnGroup", t_4);
output += "\n\n";
var macro_t_7 = runtime.makeMacro(
["data", "rowIndex", "window"], 
[], 
function (l_data, l_rowIndex, l_window, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", l_data);
frame.set("rowIndex", l_rowIndex);
frame.set("window", l_window);
var t_8 = "";t_8 += "\n  <form class=\"form-view grid no-gutters row\" autocomplete=\"off\">\n\n      ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"HideRailRoad") == true) {
t_8 += "\n        <div class=\"col-xs-12 col-lg-10\">\n      ";
;
}
else {
t_8 += "\n        <div class=\"col-xs-12 col-lg-8\">\n      ";
;
}
t_8 += "\n\n      ";
frame = frame.push();
var t_11 = runtime.memberLookup((runtime.memberLookup((l_data),"Data")),"Sections");
if(t_11) {t_11 = runtime.fromIterator(t_11);
var t_9;
if(runtime.isArray(t_11)) {
var t_10 = t_11.length;
for(t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9][0];
frame.set("[object Object]", t_11[t_9][0]);
var t_13 = t_11[t_9][1];
frame.set("[object Object]", t_11[t_9][1]);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
t_8 += "\n        <div class=\"row no-gutters\">\n            ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"SimpleInterface") == true && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"HideFormSections") == false) {
t_8 += "\n                <div class=\"col-xs-12 col-md-12 col-lg-3 col-xl-3 pb-4 pb-lg-0\">\n\n                  <div class=\"section-title\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_13),"Title"), env.opts.autoescape);
t_8 += "</div>\n                  <div class=\"section-description\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_13),"Description"), env.opts.autoescape);
t_8 += "</div>\n                </div>\n            ";
;
}
t_8 += "\n\n            ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"ShowActionBar") == true) {
t_8 += "\n\n              ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"HideFormSections") == true) {
t_8 += "\n            <div class=\"col-xs-12 col-md-12 col-lg-12 col-xl-12\">\n              ";
;
}
else {
t_8 += "\n                <div class=\"col-xs-12 col-md-12 col-lg-9 col-xl-9\">\n              ";
;
}
t_8 += "\n\n                  ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"HideFormSections") == false && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"SimpleInterface") != true) {
t_8 += "\n                      <div class=\"ml-3\">\n                          ";
if(runtime.memberLookup((t_13),"Title") !== "" && runtime.memberLookup((t_13),"Description") !== "") {
t_8 += "\n                            <div class=\"section-title\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_13),"Title"), env.opts.autoescape);
t_8 += "</div>\n                            <div class=\"section-description grey-900\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_13),"Description"), env.opts.autoescape);
t_8 += "</div>\n                          ";
;
}
t_8 += "\n\n                      </div>\n                  ";
;
}
t_8 += "\n            ";
;
}
else {
t_8 += "\n              <div class=\"col-xs-12 col-md-12 col-lg-12\">\n                  ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"SimpleInterface") == true) {
t_8 += "\n                    <div class=\"ml-3\">\n                        ";
if(runtime.memberLookup((t_13),"Title") !== "" && runtime.memberLookup((t_13),"Description") !== "") {
t_8 += "\n                          <div class=\"section-title\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_13),"Title"), env.opts.autoescape);
t_8 += "</div>\n                          <div class=\"section-description grey-900\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_13),"Description"), env.opts.autoescape);
t_8 += "</div>\n                        ";
;
}
t_8 += "\n                      <hr>\n                    </div>\n                  ";
;
}
t_8 += "\n\n            ";
;
}
t_8 += "\n              <div class=\"row\">\n                ";
t_8 += runtime.suppressValue((lineno = 53, colno = 48, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "columnGroup")),"createColumnGroup"), "columnGroup[\"createColumnGroup\"]", context, [l_data,t_13,l_window])), env.opts.autoescape);
t_8 += "\n              </div>\n            </div>\n          ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"HideFormSections") == false) {
t_8 += "\n          <div class=\"col-12\">\n            <hr class=\"w-100 mt-4 mb-4 pb-0 mb-md-5\"/>\n          </div>\n          ";
;
}
t_8 += "\n        </div>\n        ";
;
}
} else {
t_9 = -1;
var t_10 = runtime.keys(t_11).length;
for(var t_14 in t_11) {
t_9++;
var t_15 = t_11[t_14];
frame.set("name", t_14);
frame.set("section", t_15);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
t_8 += "\n        <div class=\"row no-gutters\">\n            ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"SimpleInterface") == true && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"HideFormSections") == false) {
t_8 += "\n                <div class=\"col-xs-12 col-md-12 col-lg-3 col-xl-3 pb-4 pb-lg-0\">\n\n                  <div class=\"section-title\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_15),"Title"), env.opts.autoescape);
t_8 += "</div>\n                  <div class=\"section-description\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_15),"Description"), env.opts.autoescape);
t_8 += "</div>\n                </div>\n            ";
;
}
t_8 += "\n\n            ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"ShowActionBar") == true) {
t_8 += "\n\n              ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"HideFormSections") == true) {
t_8 += "\n            <div class=\"col-xs-12 col-md-12 col-lg-12 col-xl-12\">\n              ";
;
}
else {
t_8 += "\n                <div class=\"col-xs-12 col-md-12 col-lg-9 col-xl-9\">\n              ";
;
}
t_8 += "\n\n                  ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"HideFormSections") == false && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"SimpleInterface") != true) {
t_8 += "\n                      <div class=\"ml-3\">\n                          ";
if(runtime.memberLookup((t_15),"Title") !== "" && runtime.memberLookup((t_15),"Description") !== "") {
t_8 += "\n                            <div class=\"section-title\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_15),"Title"), env.opts.autoescape);
t_8 += "</div>\n                            <div class=\"section-description grey-900\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_15),"Description"), env.opts.autoescape);
t_8 += "</div>\n                          ";
;
}
t_8 += "\n\n                      </div>\n                  ";
;
}
t_8 += "\n            ";
;
}
else {
t_8 += "\n              <div class=\"col-xs-12 col-md-12 col-lg-12\">\n                  ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"SimpleInterface") == true) {
t_8 += "\n                    <div class=\"ml-3\">\n                        ";
if(runtime.memberLookup((t_15),"Title") !== "" && runtime.memberLookup((t_15),"Description") !== "") {
t_8 += "\n                          <div class=\"section-title\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_15),"Title"), env.opts.autoescape);
t_8 += "</div>\n                          <div class=\"section-description grey-900\">";
t_8 += runtime.suppressValue(runtime.memberLookup((t_15),"Description"), env.opts.autoescape);
t_8 += "</div>\n                        ";
;
}
t_8 += "\n                      <hr>\n                    </div>\n                  ";
;
}
t_8 += "\n\n            ";
;
}
t_8 += "\n              <div class=\"row\">\n                ";
t_8 += runtime.suppressValue((lineno = 53, colno = 48, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "columnGroup")),"createColumnGroup"), "columnGroup[\"createColumnGroup\"]", context, [l_data,t_15,l_window])), env.opts.autoescape);
t_8 += "\n              </div>\n            </div>\n          ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((l_window),"session")),"settings")),"HideFormSections") == false) {
t_8 += "\n          <div class=\"col-12\">\n            <hr class=\"w-100 mt-4 mb-4 pb-0 mb-md-5\"/>\n          </div>\n          ";
;
}
t_8 += "\n        </div>\n        ";
;
}
}
}
frame = frame.pop();
t_8 += "\n      </div>\n          <div sidebar window-id=\"";
t_8 += runtime.suppressValue(runtime.memberLookup((l_window),"id"), env.opts.autoescape);
t_8 += "\"></div>\n    </form>\n    <!-- END -->\n  ";
;
frame = callerFrame;
return new runtime.SafeString(t_8);
});
context.addExport("createInputForm");
context.setVariable("createInputForm", macro_t_7);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/form/inputForm.html", ctx);
          }
        
          return nunjucks.render("views/content/form/inputForm.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/form/inputForm.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        