import {rowIsMissingRequiredFormFields} from "./rowIsMissingRequiredFormFields.js";

export function hasMissingRequiredFields({activeWindow, translations}) {
  const rows =
    activeWindow.output?.Table?.Rows ??
    activeWindow.sub?.window.output?.Table?.Rows;

  if (!rows || rows?.length < 1) return false;

  return rowIsMissingRequiredFormFields({
    row: rows[0],
    activeWindow,
    translations,
  });
}
