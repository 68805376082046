import ComboboxUI from "@/interface/combobox";

export default function clearOnChange(fields) {
  const $dialogElement = $(document).find("#modalGenericSend").first();

  const relevantFields = fields.filter(
    (field) => field.ClearOnChange?.length > 0,
  );

  for (const field of relevantFields) {
    const $field = $dialogElement.find(`[name="${field.Name}"]`);
    const fieldsToClear = field.ClearOnChange;

    $field.on("change", () => {
      for (const fieldToClear of fieldsToClear) {
        const fieldObject = fields.find((f) => f.Name === fieldToClear);
        const $fieldToClear = $dialogElement.find(`[name="${fieldToClear}"]`);

        if (fieldObject.Dropdown) {
          const comboboxObject = ComboboxUI.getClass($fieldToClear.parent());
          ComboboxUI.reset(comboboxObject);
        } else {
          $fieldToClear.val("");
        }
      }
    });
  }
}
