/**
 * set and render Window error
 * @param {string} err - Error
 * @returns {void}
 */
export default function setError(err) {
  console.error(err);
  this.empty = false;
  this.loading = false;
  this.error = err;
  this.render();
}
