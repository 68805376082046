import $ from "jquery";
import Canvas from "./interface/canvas.js";
import Cookies from "js-cookie";
import Hook from "./hook";
import NotificationQueue from "./interface/notificationQueue";
import Session from "./model/session";
import interfaceTemplate from "../views/interface.vue";
import {getPersistedToken} from "./functions/session/localstorage/getPersistedToken";
import {getPersistedSessionId} from "./functions/session/localstorage/getPersistedSessionId";
import "./interface/vue";
import store from "../state/store";
import "./assets/css/tailwind.css";
import openRelatedCustomer from "@/actions/openRelatedCustomer";

import "regenerator-runtime/runtime";

import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";

import {loadHooks} from "./hooks";

import packageJson from "../package.json";
//import "@fontsource/montserrat";

import {
  resetColumns,
  resetTranslations,
  resetAll,
  resetSettings,
  resetRoles,
} from "./services/maintenance";

import "./styling";

import "./util/fontawesome";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import dayjs from "dayjs";

import emitter from "tiny-emitter/instance";

const eventBus = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};

global.eventBus = eventBus;
global.runningProcesses = [];

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Europe/Amsterdam");

global.openRelatedCustomer = openRelatedCustomer;

// check if url contains param Headless
const headless = window.location.search.includes("Headless");

const persistedToken = await getPersistedToken();

if (persistedToken) {
  store.commit("setToken", {
    token: persistedToken,
  });
}

const persistedSessionId = getPersistedSessionId();

if (persistedSessionId) {
  store.commit("setSessionId", {
    sessionId: persistedSessionId,
  });
}

if (headless) {
  store.commit("setHeadless", true);
  import("./assets/scss/headless.scss");
}

const {hostname} = window.location;

if (hostname !== "localhost") {
  let environment = "stable";

  if (hostname.split(/\./)[0].endsWith("-test")) environment = "testing";
  else if (hostname.endsWith(".staging.rentmagic.dev")) environment = "staging";
  /*
  // SENTRY PAS OP
  Sentry.init({
    release: packageJson.version,
    dsn: "https://6e58f28b7bf44698a84a86777c206ae7@sentry.rentmagic.net/3",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
    environment,
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (
        error &&
        typeof error == "object" &&
        error.data?.Data?.IsFlushed === true
      ) {
        return;
      }

      return event;
    },
  });
  // EINDE SENTRY PAS NIETMEER OP
}*/
}

// Create session and canvas
/**
 * Global session object
 * @type {Session}
 * @global
 */
let session;
let canvas;

// Initialize global broadcastChannel to listen for events send by other tabs in the same browser
$(window).on("storage", message_receive);

async function initialize() {
  // Create session and canvas
  /**
   * Global session object
   * @type {Session}
   * @global
   */
  session = new Session(String());
  canvas = new Canvas(session);

  session.canvas = canvas;

  // Set session globally
  global.session = session;

  // Set hook globally
  global.hook = Hook;

  // Set jquery globally
  global.$ = $;

  global.online = true;

  global.tools = {
    resetColumns,
    resetTranslations,
    resetAll,
    resetSettings,
    resetRoles,
  };

  global.window.rentMagicVue = new window.vue({
    store,
    render: (h) => h(interfaceTemplate),
  }).$mount("#app");
}

function message_receive(ev) {
  if (ev.originalEvent.key != "global-window-event") return; // ignore other keys

  const message = JSON.parse(ev.originalEvent.newValue);
  if (!message) return; // ignore empty msg or msg reset

  if (session.activeWindow) {
    const localFn = session.activeWindow[message.command];

    if (typeof localFn !== "function") {
      console.error(
        new Error("Broadcasted event not bound to a window-function"),
      );
      return;
    }

    localFn.call(session.activeWindow, ...message.data);
  }
}

/**
 * Enable password viewer
 * @private
 * @returns {void}
 */
function enablePasswordViewer() {
  $(document)
    .on("mousedown", ".login .show-password", function () {
      $(this).closest(".form-element").find("input").prop("type", "text");
    })
    .on("mouseup mouseleave", ".login .show-password", function () {
      $(this).closest(".form-element").find("input").prop("type", "password");
    });
}

/**
 * Save current environment in cookie, to be used in website
 * @private
 * @returns {void}
 */
function setEnvironmentCookie() {
  const url = window.location.hostname;
  const parts = url.split(".").reverse();
  const domain = parts[1] + "." + parts[0];
  const EXP_MAX = Math.pow(2, 31) - 1;

  let str = Cookies.get("live_envs") || "";

  if (str.indexOf(parts[2]) == -1) {
    if (str) {
      str += "|";
    }

    str += parts[2];

    Cookies.set("live_envs", str, {
      sameSite: "None",
      secure: true,
      expires: EXP_MAX,
      domain: "." + domain,
    });
  }
}

loadHooks();

/**
 * Load banner
 * @private
 * @returns {void}
 */
function load() {
  $("body").addClass("loaded");
}

initialize();
// Startup logic

enablePasswordViewer();
setEnvironmentCookie();
load();

async function notificationCheckTimer() {
  let notificationID = 0;
  const notificationQueue = new NotificationQueue(notificationID);

  // Interval of 5 sec for fetching notifications.
  window.setInterval(async () => {
    if (global.session.username && store.state.accessToken) {
      await notificationQueue.render();

      notificationID = notificationQueue.getNotificationID();
    }
  }, 15000);
}

// Start notification checker
notificationCheckTimer();
