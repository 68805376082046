import {stringContainsDateAutocompleteCharacter} from "./stringContainsDateAutocompleteCharacter";
import {convertPlaceholderToDate} from "./convertPlaceholderToDate";
import dayjs from "dayjs";
import {appendYearWhenNotPresent} from "./appendYearWhenNotPresent";
import {convertSixNumbersToDate} from "./convertSixNumbersToDate";
import {convertFourNumbersToDate} from "./convertFourNumbersToDate";
import {convertTwoNumbersToDate} from "./convertTwoNumbersToDate";

const patterns = ["DD-MM-YYYY", "D-MM-YYYY", "DD-M-YYYY", "D-M-YYYY"];

export const parseValueToDate = (value) => {
  let newValue = value;
  if (stringContainsDateAutocompleteCharacter(value)) {
    newValue = convertPlaceholderToDate({value: newValue});
  }

  newValue = appendYearWhenNotPresent(newValue);
  newValue = convertSixNumbersToDate(newValue);
  newValue = convertFourNumbersToDate(newValue);
  newValue = convertTwoNumbersToDate(newValue);

  for (let pattern of patterns) {
    if (dayjs(newValue, pattern).format("YYYY-MM-DD") === "Invalid Date")
      continue;

    return dayjs(newValue, pattern).format("YYYY-MM-DD");
  }

  return newValue;
};
