import Vue from "vue";
import $ from "jquery";

import genericModal from "../../views/interface/genericModal.vue";
import store from "../../state/store";

async function newVueModal({
  randomID,
  modalBodyID,
  modalTitle,
  modalSize,
  html,
  event,
  actionName,
  selectionInfo,
} = {}) {
  generateElement({elementID: randomID});
  const formData = await new Promise((resolve) => {
    new Vue({
      el: `#${randomID}`,
      store,
      render: (h) =>
        h(genericModal, {
          props: {
            modalBodyID,
            modalTitle,
            modalSize,
            modalBody: html,
            event,
            actionName,
            selectionInfo,
            done: resolve,
          },
        }),
    });
  });
  return formData;
}

function generateElement({elementID}) {
  let newElement = `<div id='${elementID}'></div>`;
  $(document).find(".active-window").prepend(newElement);
}

export {newVueModal};
