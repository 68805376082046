import Hook from "@/hook";
import $ from "jquery";
import store from "../../../state/store";

/**
 * Dummy method, hooked by canvas
 * @see {@link Canvas}
 * @returns {Promise} Promise
 */
export default async function render() {
  return await Hook.run("render", this, async () => {
    await this.session.canvas.update(this, null);

    store.commit("updateWindow");
    store.commit("refreshTabs");
  });
}
