<template>
  <div
    ref="btnRef"
    @mouseenter="toggleTooltip(true)"
    @mouseleave="toggleTooltip(false)"
  >
    <slot>
      <button class="btn btn-primary" type="button">Tooltip</button>
    </slot>
    <div
      ref="tooltipRef"
      class="bg-[#343a40] border-0 ml-3 font-normal leading-normal text-sm text-left no-underline break-words rounded-lg text-white p-3 z-[1000]"
      :class="{hidden: !tooltipShow, block: tooltipShow}"
    >
      <slot v-if="tooltipShow" name="tooltip-body">
        <p>{{ message }}</p>
        <p>
          <small class="mt-1">{{ subMessage }}</small>
        </p>
      </slot>
    </div>
  </div>
</template>

<script>
import {createPopper} from "@popperjs/core";

export default {
  name: "Tooltip",
  props: {
    message: {
      type: String,
      required: false,
    },
    subMessage: {
      type: String,
      required: false,
      default: null,
    },
    tooltipPosition: {
      type: String,
      required: false,
      default: "top",
    },
  },
  data() {
    return {
      tooltipShow: false,
      popperInstance: null,
    };
  },
  methods: {
    toggleTooltip(show) {
      if (show) {
        this.tooltipShow = true;
        this.$emit("tooltip-show");

        if (!this.popperInstance) {
          this.popperInstance = createPopper(
            this.$refs.btnRef,
            this.$refs.tooltipRef,
            {
              placement: this.tooltipPosition,
              strategy: "fixed",
              modifiers: [
                {
                  name: "computeStyles",
                },
              ],
            },
          );
        } else {
          this.popperInstance.update();
        }
      } else {
        this.tooltipShow = false;
        this.$emit("tooltip-hide");

        if (this.popperInstance) {
          this.popperInstance.destroy();
          this.popperInstance = null;
        }
      }
    },
  },
};
</script>
<style scoped>
[data-popper-placement="top"] {
  border-radius: 4px 4px 4px 4px;
}

p {
  white-space: break-spaces;
  margin-bottom: 0px;
}
</style>
