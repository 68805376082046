
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("./form.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/treeview.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/content/form.html", false, "views/content/treeview.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("form", t_1);
output += " ";
output += " \n";
var macro_t_4 = runtime.makeMacro(
["parent", "openTreeFunction"], 
[], 
function (l_parent, l_openTreeFunction, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("parent", l_parent);
frame.set("openTreeFunction", l_openTreeFunction);
var t_5 = "";t_5 += " \n\n  ";
frame = frame.push();
var t_8 = l_parent;
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("branch", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += " \n    ";
if(runtime.memberLookup((t_9),"children")) {
t_5 += "\n      <div\n        class='branch ";
t_5 += runtime.suppressValue((!runtime.memberLookup((t_9),4)?"collapsed":""), env.opts.autoescape);
t_5 += "'\n        data-tree-id=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),1), env.opts.autoescape);
t_5 += "\"\n      >\n        <div\n          data-window-tree-id=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),1), env.opts.autoescape);
t_5 += "\"\n          class='branch-name ";
t_5 += runtime.suppressValue((runtime.memberLookup((t_9),"Selected")?"selected":""), env.opts.autoescape);
t_5 += "'\n        >\n          ";
if(runtime.memberLookup((t_9),3) != runtime.contextOrFrameLookup(context, frame, "undefined")) {
t_5 += "\n          <label class=\"table-cell field checkbox\">\n            <input name=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),1), env.opts.autoescape);
t_5 += "\" type=\"checkbox\" ";
t_5 += runtime.suppressValue(((runtime.memberLookup((t_9),3))?"checked":""), env.opts.autoescape);
t_5 += "\n            <span>&nbsp;";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),0), env.opts.autoescape);
t_5 += "</span>\n          </label>\n          ";
;
}
else {
t_5 += "\n          <span data-window-event=\"";
t_5 += runtime.suppressValue(l_openTreeFunction, env.opts.autoescape);
t_5 += ":";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),1), env.opts.autoescape);
t_5 += "\">";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),0), env.opts.autoescape);
t_5 += "</span>\n          ";
;
}
t_5 += "\n        </div>\n        <div class=\"branches\">\n          ";
t_5 += runtime.suppressValue((lineno = 26, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "makeBranch"), "makeBranch", context, [runtime.memberLookup((t_9),"children"),l_openTreeFunction])), env.opts.autoescape);
t_5 += "\n        </div>\n      </div>\n    ";
;
}
else {
t_5 += "\n      <div\n        data-window-tree-id=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),1), env.opts.autoescape);
t_5 += "\"\n        class='stick ";
t_5 += runtime.suppressValue((runtime.memberLookup((t_9),"Selected")?"selected":""), env.opts.autoescape);
t_5 += "'\n      >\n        ";
if(runtime.memberLookup((t_9),3) != runtime.contextOrFrameLookup(context, frame, "undefined")) {
t_5 += "\n        <label class=\"table-cell field checkbox\">\n          <input name=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),1), env.opts.autoescape);
t_5 += "\" type=\"checkbox\" ";
t_5 += runtime.suppressValue(((runtime.memberLookup((t_9),3))?"checked":""), env.opts.autoescape);
t_5 += "\n          <span>&nbsp;";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),0), env.opts.autoescape);
t_5 += "</span>\n        </label>\n        ";
;
}
else {
t_5 += "\n        <span data-window-event=\"";
t_5 += runtime.suppressValue(l_openTreeFunction, env.opts.autoescape);
t_5 += ":";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),1), env.opts.autoescape);
t_5 += "\">";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),0), env.opts.autoescape);
t_5 += "</span>\n        ";
;
}
t_5 += "\n      </div>\n    ";
;
}
t_5 += " \n  ";
;
}
}
frame = frame.pop();
t_5 += " \n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("makeBranch");
context.setVariable("makeBranch", macro_t_4);
output += " \n\n";
var t_10;
t_10 = (runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Subject") === "Rental.Category"?"openWindowFromTree":"opentree");
frame.set("openTreeFunction", t_10, true);
if(frame.topLevel) {
context.setVariable("openTreeFunction", t_10);
}
if(frame.topLevel) {
context.addExport("openTreeFunction", t_10);
}
output += "\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"editItems")) {
output += "\n<div class=\"action-bar sticky-div\">\n  <div class=\"buttons\">\n    <button class=\"button button-default\" data-window-event=\"saveTreeView\">\n      ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Save"), env.opts.autoescape);
output += "\n    </button>\n    <button class=\"button button-default\" data-window-event=\"cancelTreeView\">\n      ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Cancel"), env.opts.autoescape);
output += "\n    </button>\n  </div>\n</div>\n";
;
}
output += "\n\n<div class=\"tree-view\">\n  <div class=\"content\">\n    <div class=\"tree-view smaller\">\n      ";
output += runtime.suppressValue((lineno = 64, colno = 19, runtime.callWrap(macro_t_4, "makeBranch", context, [runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"tree"),runtime.contextOrFrameLookup(context, frame, "openTreeFunction")])), env.opts.autoescape);
output += " ";
if(!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Data")),"editItems")) {
output += " ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Subject") == "Customer.DimensionObject") {
output += "\n      <div class=\"stick new\">\n        <span\n          data-window-event=\"action:DimensionObjectNewObjectNode:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Subject"), env.opts.autoescape);
output += ":../../Admin/WebServices/CommandWebService.asmx:DimensionObjectNewObjectNode\"\n          >";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"NewDimensionObject"), env.opts.autoescape);
output += "</span\n        >\n      </div>\n      <div class=\"stick new\">\n        <span\n          data-window-event=\"action:DimensionObjectNewObjectNodeSimple:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Subject"), env.opts.autoescape);
output += ":../../Admin/WebServices/CommandWebService.asmx:DimensionObjectNewObjectNodeSimple\"\n          >";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"NewSubDimensionObject"), env.opts.autoescape);
output += "</span\n        >\n      </div>\n      ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "openTreeFunction") == "openWindowFromTree") {
output += "\n        <div class=\"stick new\">\n          <span\n            data-window-event=\"action:New:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Subject"), env.opts.autoescape);
output += ":../../Admin/WebServices/CoreWebServices.asmx:OpenNew\"\n            >";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"New"), env.opts.autoescape);
output += "</span\n          >\n        </div>\n\n      ";
;
}
else {
output += "\n      <div class=\"stick new\">\n        <span\n          data-window-event=\"action:NewBranch:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"Request")),"Subject"), env.opts.autoescape);
output += ":../../Admin/WebServices/CoreWebServices.asmx:OpenNew\"\n          >";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"New"), env.opts.autoescape);
output += "</span\n        >\n      </div>\n      ";
;
}
;
}
output += " ";
;
}
output += "\n    </div>\n  </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/treeview.html", ctx);
          }
        
          return nunjucks.render("views/content/treeview.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/treeview.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        