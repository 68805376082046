import { render, staticRenderFns } from "./loginAfterBackupCode.vue?vue&type=template&id=33475ab2&scoped=true&"
import script from "./loginAfterBackupCode.vue?vue&type=script&lang=js&"
export * from "./loginAfterBackupCode.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33475ab2",
  null
  
)

export default component.exports