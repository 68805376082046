import store from "../../../state/store";
import {getActiveVueComponentFromWindow} from "@/functions/window/getActiveVueComponentFromWindow";
import {setActiveClassToWindow} from "@/functions/window/setActiveClassToWindow.js";

/**
 * Reset window completely
 * @param {string} shouldConfirm - If window should ask for comfirmation before closing
 * @param {boolean} shouldFocus - Should this window be focused?
 * @returns {Object} newly fetched data
 */
export default async function reset(shouldConfirm = null, shouldFocus = true) {
  const activeVueInstanceComponent = getActiveVueComponentFromWindow({
    window: this,
  });

  const vueFunction = activeVueInstanceComponent?.[`customWindowReset`];

  if (vueFunction) {
    this.toggleLoading(true);
    await vueFunction();
    this.toggleLoading(false);
    return;
  }

  if (activeVueInstanceComponent?.customReset) {
    this.toggleLoading(true);
    await this.vueInstance.$children[0].$children[0].customReset();
    this.toggleLoading(false);
    return;
  }

  if (this.output?.Data?.Type === "kanbanboard") {
    let url = `/Admin/WebServices/CoreWebServices.asmx/OpenWindow`;
    this.output = await this.session.request(
      url,
      {
        request: this.input,
      },
      {Refresh: true},
    );

    await this.process(this.output);

    store.commit("windows/setWindowOutput", {
      windowId: this.id,
      output: this.output,
    });
    store.commit("updateWindow");

    store.commit("windows/increaseReloadIteration", {windowId: this.id});
    return;
  }

  await store.dispatch("resetWindow", {
    windowid: this.id,
    shouldConfirm,
    shouldFocus,
  });
}
