import {changedFieldsAreVueFormFields} from "./changedFieldsAreVueFormFields.js";
import {hasSectionColumnsShift} from "./hasSectionColumnsShift.js";
import {processLegacyFormFieldPostback} from "./processLegacyFormFieldPostback.js";
import {handleLegacySelectfieldAfterPostbackFocus} from "./handleLegacySelectfieldAfterPostbackFocus.js";

export const processSelectfieldPostback = async ({
  windowId,
  window,
  field,
  fieldColumn,
  postbackResult,
}) => {
  const newSections = postbackResult.Events?.[0][1]?.Data?.Sections;
  const newMetadata = postbackResult.Events?.[0][1]?.Data?.Metadata;
  const newColumns = postbackResult.Events?.[0][1]?.Data?.Columns;
  const vueColumns = window.vueColumns;

  const newRow = postbackResult.Events?.[0][1]?.Data?.Rows[0];
  const oldRow = window.output?.FullTable?.Rows[0].reduce((acc, field) => {
    acc[field.Column.Name] = field.NewValue ?? field.Value;
    return acc;
  }, {});

  if (
    changedFieldsAreVueFormFields({newRow, oldRow, window}) &&
    !hasSectionColumnsShift({newColumns, newSections, window})
  ) {
    await processLegacyFormFieldPostback({
      newMetadata,
      vueColumns,
      newColumns,
      window,
      newRow,
      oldRow,
      field: field,
      windowId: windowId,
      fieldColumn: fieldColumn,
    });
  } else {
    await window.handleActionResponse(postbackResult);

    await handleLegacySelectfieldAfterPostbackFocus({
      fieldName: field.name,
    });
  }
};
