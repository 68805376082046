import {emitAlert} from "./emitAlert";
import {processButtons} from "./processButtons";

const createAlertPromise = ({text, icon, dangerMode, buttons, size}) => {
  return new Promise((resolve) => {
    emitAlert({
      text,
      icon,
      dangerMode,
      buttons: processButtons(buttons),
      size,
      resolve,
    });
  });
};

export {createAlertPromise};
