import store from "../../../state/store";

/**
 * Open window based on given values
 * @deprecated
 * @param {string} tableName - Table name
 * @param {string} primaryKeyName - Name of primary key column
 * @param {string} primaryKeyValue - Value of primary key column
 * @param {Array} extraParams - Extra parameters that have to be copied
 * @returns {Promise} To create window
 */
export default async function open(
  tableName,
  primaryKeyName,
  primaryKeyValue,
  extraParams,
) {
  let params = {
    Subject: tableName,
    Prefix: "Multi",
  };

  if (primaryKeyName != null) {
    params.Criteria = [{[primaryKeyName]: primaryKeyValue}];
    params.Prefix = "Single";
  }

  if (extraParams != null) {
    for (let key in extraParams) {
      params[key] = extraParams[key];
    }
  }

  await this.session.openWindow(params);
  store.commit("updateWindow");
  store.commit("refreshTabs");
}
