/**
 * Persists color rules to local storage.
 *
 * @function setPeristedColorRules
 * @param {Object} param0 - An object containing colorRules property.
 * @param {Array.<{
 *   FullTableName: string,
 *   ColorPolicy: string,
 *   Color: string,
 *   TableColorCriteria: Array.<{
 *     ColumnName: string,
 *     Criteria: string,
 *     Color: string,
 *     Ranking: null,
 *   }>
 * }>} param0.colorRules - An array of color rule objects to be persisted.
 * @example
 * // Color rule object structure:
 * {
 *   "FullTableName": "Rental.Order",
 *   "ColorPolicy": "SubtleColor",
 *   "Color": "#5778a2",
 *   "TableColorCriteria": [
 *     {
 *       "ColumnName": "CustomerID",
 *       "Criteria": "00003",
 *       "Color": "#28af82",
 *       "Ranking": null
 *     }
 *   ]
 * }
 */
export function setPeristedColorRules({colorRules}) {
  if (colorRules) {
    localStorage.setItem("color-rules", JSON.stringify(colorRules));
  }
}
