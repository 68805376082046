import Cookies from "js-cookie";

export async function getPersistedToken() {
  try {
    let tokenString = localStorage.getItem("token") ?? Cookies.get("token");

    if (!tokenString) {
      await document.requestStorageAccess().then(() => {
        tokenString = localStorage.getItem("token") ?? Cookies.get("token");
      });
    }

    return tokenString ? JSON.parse(tokenString) : null;
  } catch (error) {
    console.log(error);
  }
}
