export const updateWindowFormColumns = async ({newColumns, window}) => {
  let formTableRow = window.output?.Table?.Rows[0];
  let formFullTableRow = window.output?.FullTable?.Rows[0];
  let formColumns =
    window.output?.Table?.Columns ?? window.output.FullTable.Columns;

  if (formTableRow)
    for (const row in formTableRow) {
      const fieldColumnName = formTableRow[row].Column.Name;

      if (newColumns[fieldColumnName]) {
        formTableRow[row].Column = newColumns[fieldColumnName];
      }
    }

  if (formFullTableRow)
    for (const row in formFullTableRow) {
      const fieldColumnName = formFullTableRow[row].Column.Name;

      if (newColumns[fieldColumnName]) {
        formFullTableRow[row].Column = newColumns[fieldColumnName];
      }
    }

  for (const column in formColumns) {
    const formColumnName = formColumns[column].Name;

    if (newColumns[formColumnName]?.Name === formColumns[column].Name) {
      window.output.Table.Columns[column] = newColumns[formColumnName];
      window.output.FullTable.Columns[column] = newColumns[formColumnName];
      window.output.Data.Columns[column] = newColumns[formColumnName];
    }
  }
};
