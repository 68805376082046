import $ from "jquery";

export default function showHint(fieldName) {
  if (this.introJsInstance == null) {
    this.introJsInstance = global.introjs(this.element);
    this.introJsInstance.setOptions({hintPosition: "top-left"});

    // remove the old hints from the DOM-tree, preventing that hints will appear in the wrong tab
    // $("*[data-hint]").remove()

    this.introJsInstance.addHints();
  }

  let hint = this.introJsInstance._introItems.find(
    (x) =>
      (x.targetElement != null &&
        $(x.targetElement).attr("name") == fieldName) ||
      (x.element.dataset != null && $(x.element).attr("name") == fieldName),
  );

  if (hint != null) {
    let indexOfHint = this.introJsInstance._introItems.indexOf(hint);

    if (indexOfHint > -1) {
      this.introJsInstance.showHint(indexOfHint);
      this.introJsInstance.showHintDialog(indexOfHint);
    }
  }
}
