
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
        
        const __nunjucks_template_import__dep_0 = require("../elements/combobox.html");
        
    __nunjucks_module_dependencies__.templates = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.templates};
    __nunjucks_module_dependencies__.globals = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.globals};
    __nunjucks_module_dependencies__.extensions = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.extensions};
    __nunjucks_module_dependencies__.filters = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.filters};
    __nunjucks_module_dependencies__.assets = {...__nunjucks_template_import__dep_0.__nunjucks_module_dependencies__.assets};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/tiles.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("views/elements/combobox.html", false, "views/content/tiles.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("combo", t_1);
output += "\n\n<div class=\"grid big-ui\">\n  <div class=\"table-form\" style=\"padding: 15px 19px 0\">\n    <div class=\"col col-xs-12\">\n      ";
output += runtime.suppressValue((lineno = 5, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"constructCombobox"), "combo[\"constructCombobox\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session"),runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "custom")),"combobox")),"scan")])), env.opts.autoescape);
output += "\n    </div>\n\n    ";
output += "\n\n    <br style=\"clear:both\" />\n  </div>\n\n  ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data") == null || runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"Tiles") == null || runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"Tiles")),"length") < 1) {
output += "\n  <br /><br />\n  <p style=\"text-align: center\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"NoRowsFound"), env.opts.autoescape);
output += "</p>\n  ";
;
}
else {
output += " ";
frame = frame.push();
var t_6 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"Tiles");
if(t_6) {t_6 = runtime.fromIterator(t_6);
var t_5 = t_6.length;
for(var t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4];
frame.set("tile", t_7);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += " ";
if(runtime.memberLookup((t_7),"IsNewSection")) {
output += "\n  <div style=\"clear:both\"><hr /></div>\n  ";
;
}
output += "\n  <div class=\"col-xs-12 col-sm-6 col-md-3 col-lg-2\">\n    <div\n      style=\"margin: 15px; padding: 12px; border: 1px solid #cccccc\"\n      ";
if(runtime.memberLookup((t_7),"Event")) {
output += "\n      ";
output += "\n      ";
;
}
output += "\n    >\n      <strong style=\"display: block; text-align: center\"\n        >";
output += runtime.suppressValue(runtime.memberLookup((t_7),"Title"), env.opts.autoescape);
output += "</strong\n      >\n\n      <img\n        src=\"";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_7),"ImageUrl")), env.opts.autoescape);
output += "\"\n        onerror=\"this.src='http://placehold.it/350x350';\"\n        style=\"width: 100%; height: auto\"\n      />\n\n      ";
if(runtime.memberLookup((t_7),"Text")) {
output += "\n      <p style=\"text-align: center; height: 50px;\">";
output += runtime.suppressValue(runtime.memberLookup((t_7),"Text") || "-", env.opts.autoescape);
output += "</p>\n      ";
;
}
output += " ";
if(runtime.memberLookup((t_7),"DateDescription")) {
output += "\n      <p style=\"text-align: center; height: 50px;\">\n        ";
output += runtime.suppressValue(runtime.memberLookup((t_7),"DateDescription") || "-", env.opts.autoescape);
output += "\n      </p>\n      ";
;
}
output += "\n\n      <div style=\"text-align: center\">\n        <button class=\"button button-menu counter decrement\">-</button>\n        <button\n          class=\"button button-menu fake-button no-hover no-action counter display\"\n        >\n          ";
output += runtime.suppressValue(runtime.memberLookup((t_7),"DefaultAmount"), env.opts.autoescape);
output += "\n        </button>\n        <button class=\"button button-menu counter increment\">+</button>\n        <input\n          type=\"hidden\"\n          name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_7),"Id"), env.opts.autoescape);
output += "\"\n          min=\"0\"\n          max=\"";
output += runtime.suppressValue(runtime.memberLookup((t_7),"MaxAmount"), env.opts.autoescape);
output += "\"\n          value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_7),"DefaultAmount"), env.opts.autoescape);
output += "\"\n          class=\"counter value\"\n        />\n      </div>\n    </div>\n  </div>\n  ";
;
}
}
frame = frame.pop();
output += " ";
;
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/tiles.html", ctx);
          }
        
          return nunjucks.render("views/content/tiles.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/tiles.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        