/**
 * Toggle if column should be visible
 * @param {string} columnName - Name of column
 * @returns {Promise} Promise
 */
export default async function toggleColumn(columnName) {
  let index = this.output.Data.HiddenColumns.indexOf(columnName);
  let columnsToHide = this.output.Data.HiddenColumns;
  if (index === -1) {
    columnsToHide.push(columnName);
  } else {
    columnsToHide.splice(index, 1);
  }
}
