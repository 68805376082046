<template>
  <div class="option-email">
    <div v-if="translations">
      <div v-if="error" class="error-box">
        {{ error }}
      </div>
      <div class="options title mb-1">
        1. {{ translations.TwoFactorSetupEmailTitle }}
      </div>
      <div>
        <div class="qr">
          <p class="text-left ml-1">
            {{ translations.TwoFactorSetupEmailDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import postSetUpTOTPEmail from "@/services/totp/postSetUpTOTPEmail.js";

export default {
  name: "LoginTwoFactorOptionEmail",
  props: {
    loginToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      error: "",
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
  },
  async created() {
    await this.requestTOTPEmailSetUp();
  },
  methods: {
    async requestTOTPEmailSetUp() {
      this.$data.error = "";

      const response = await postSetUpTOTPEmail({
        loginToken: this.$props.loginToken,
      });

      if (response.status != 204 && response.status != 200) {
        this.$emit("showComponent", "loginPage", response.data.Error.Message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.option-email {
  text-align: left;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: 17.60px;
    font-weight: 600;
  }
}
.form-field .form-input input {
  box-shadow: 1px 1px 1px 0 rgb(0 0 0 / 9%);
  border: 1px solid #ced4da;
  height: 31.5px !important;
  border-radius: 3px;
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.form-field .form-input .editable-text {
  padding: 3px 1px;
  transition: border-bottom-color 0.12s;
}
.form-field .form-input,
.form-field .form-label {
  padding: 3px 8px;
}
</style>
