import openWindow from "@/actions/openWindow";

export default function openRelatedCustomer() {
  const window = global.session.window?.sub?.window ?? global.session.window;
  try {
    const customerId = window.output?.Data?.Rows[0].CustomerID;
    openWindow({
      Subject: "Customer.Customer",
      Criteria: [{CustomerID: customerId}],
      Prefix: "Single",
    });
  } catch (e) {
    console.log("No customer ID found for active window");
  }
}
