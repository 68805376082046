import {stringContainsInvalidAutocompleteCharacters} from "./stringContainsInvalidAutocompleteCharacters";
import {stringContainsMoreThenOneLetter} from "./stringContainsMoreThenOneLetter";
import {stringContainsMoreThenOneOperator} from "./stringContainsMoreThenOneOperator";

export function stringContainsValidAutocompleteSyntax({value}) {
  if (stringContainsInvalidAutocompleteCharacters({value})) {
    return false;
  }

  if (stringContainsMoreThenOneLetter({value})) {
    return false;
  }

  if (stringContainsMoreThenOneOperator({value})) {
    return false;
  }

  return value.match(/[a-z]/i) !== null;
}
