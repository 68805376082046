/**
 * Opens url in new tab in browser
 * @param {string} url - URL
 * @returns {void}
 */
export default async function openUrl(url) {
  if (url == null) {
    this.message("warning", this.session.translations.NoHelpAvailable);
    return;
  }

  global.window.open(url);
}
