import arrayToTree from "array-to-tree";

/**
 * Select permissions for table
 * @todo fill roleID, possilbly depricated
 * @param {string} tableName - Table name
 * @param {string} roleID - Role ID
 * @param {string} title - Title of permission screen
 * @param {boolean} parent - Parent permission screen
 * @returns {Promise} Promise
 */
export default async function selectPermissions(
  tableName,
  roleID,
  title,
  parent,
) {
  let url =
    "/Admin/WebServices/MembershipWebServices.asmx/GetRolePermissionData";
  let input = {
    tableName,
    roleID,
    title,
  };

  // Clear permission browsing history if opening parent permission screen
  if (parent === "true") {
    this.session.previousPermissionScreen = [];
  }

  // Check if permission screen tablename is not already in the history
  if (
    !this.session.previousPermissionScreen
      .map((permissionScreen) => {
        return permissionScreen.tableName;
      })
      .includes(tableName)
  ) {
    this.session.previousPermissionScreen.push({
      tableName,
      roleID,
      title,
    });
  }

  // Fixed strange browser bug, uncheck all checkboxes before reloading data.
  // Firefox & Chrome are remembering Checked action buttons.
  //$("input:checkbox", window.element).removeAttr("checked")

  let output = await this.session.request(url, input);
  output.FormPermissionMenu = arrayToTree(output.FormPermissionMenu, {
    parentProperty: "ParentID",
    customID: "ID",
  });

  output.inputVars = input;
  //output.breadCrumbs = [].concat(breadcrumbs.split(/;/g))

  this.previousPermissionScreen = this.session.previousPermissionScreen;

  this.setupCustomEnv("permissions", output, true);
}
