// initial state
// shape: [{ user, properties }]
import {handleDatagridService} from "../../src/services/table/handleDatagridService.js";
import postRequest from "@/services/client-action-requests/postRequest";
import {getActiveWindow} from "@/functions/window/getActiveWindow";
import {api} from "@/services/api";
import {notify} from "../../src/util/notify";
import {cloneDeep} from "lodash";

const state = () => ({
  title: "",
  template: "",
  data: "",
  originalData: "",
  show: false,
});

// getters
const getters = {
  getTitle: (state) => state.title,
  getTemplate: (state) => state.template,
  getData: (state) => state.data,
  getOriginalData: (state) => state.originalData,
  getFields: (state) => state.data.Fields,
  show: (state) => state.show,
};

// actions
const actions = {
  async postRequest({getters}, {data}) {
    if (data.IncludeFormFields) {
      const formFields = getFormFieldValues(getters);

      data.RequestBody = {...data.RequestBody, FormFields: formFields};
    }

    await postRequest(data);
  },
  async closeDialog({commit}) {
    commit("hideDialog");
  },
  async requestWithSelection({getters}, {data: {url, method, onSuccess}}) {
    const selection = getActiveWindow().selection;
    const formValues = getFormFieldValues(getters);

    const data = selection.map((row) => ({
      ...row,
      ...formValues,
    }));

    const response = await api.request({method, url, data});
    if (response.data?.Error?.Message) {
      notify({message: response.data.Error.Message});
    }

    if (
      response.status >= 200 &&
      response.status < 300 &&
      onSuccess?.GlobalActions
    ) {
      for (const globalAction of onSuccess.GlobalActions) {
        const importedModule = await import(
          `@/actions/${globalAction.Function}.js`
        );
        importedModule.default(globalAction.Data);
      }
    }
  },
  async save({rootState, rootGetters, state}) {
    const data = [];

    // list of objects
    const fields = {...state.data.Fields};

    Object.keys(fields).forEach((i) => {
      fields[i] = fields[i].Value;
    });

    const Values = fields;

    const rowData = {
      Criteria: state.data.Criteria,
      Values,
    };

    data.push(rowData);

    await handleDatagridService({
      operation: "saveTable",
      requestData: {
        subject: "Core.RailRoadTask",
        data,
        windowId: rootGetters["windows/activeWindowId"],
      },
    });

    rootState.loading = false;
  },
};

// mutations
const mutations = {
  setTitle(state, title) {
    state.title = title;
  },
  setTemplate(state, template) {
    state.template = template;
  },
  setData(state, data) {
    state.data = data;
  },
  setOriginalData(state, data) {
    state.originalData = cloneDeep(data);
  },
  hideDialog(state) {
    state.show = false;
  },
  showDialog(state) {
    state.show = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

const getFormFieldValues = ({getFields}) => {
  const formFields = {};

  for (const field in getFields) {
    formFields[field] = getFields[field].Value;
  }
  return formFields;
};
