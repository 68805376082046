<template>
  <div class="login-overlay">
    <loginTwoFactorDisplayBackupCodes
      :is="loginTwoFactorDisplayBackupCodes"
      v-if="totpData && totpType == 'TOTP' && verified"
      :totp-data="totpData"
      @showComponent="showComponent"
    />
    <div v-if="!verified" class="two-factor-setup">
      <div class="header">
        <h5 class="w-100">
          {{ translations.TwoFactorSetupTitle }}
        </h5>
      </div>
      <div>
        <div class="options">
          <login-two-factor-option-email
            v-if="totpType == 'TOTPEmail'"
            :login-token="loginToken"
            @showComponent="showComponent"
          />
          <login-two-factor-option-q-r
            v-if="totpType == 'TOTP'"
            :totp-data="totpData"
          />
        </div>
        <div>
          <login-two-factor-verify
            :login-token="loginToken"
            :totp-data="totpData"
            :step="totpType == 'TOTPEmail' ? 2 : 3"
            @showComponent="$emit('showComponent', $event)"
            @sendTotpData="$emit('sendTotpData', $event)"
            @processVerificationResult="processVerificationResult"
          />
        </div>
      </div>
      <div class="flex align-items-end justify-between ml-3 mr-3 mb-3">
        <r-button class="ml-0 primary" @click="back">{{
          translations.Back
        }}</r-button>

        <div class="text-right">
          <button
            class="text-primary cursor-pointer hover:underline bg-transparent border-none"
            @click="changeTOTPType"
          >
            {{
              totpType == "TOTP"
                ? translations.TwoFactorSetupSkipAndUseEmail
                : translations.TwoFactorSetupUseQR
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginTwoFactorOptionQR from "./loginTwoFactorOptionQR.vue";
import loginTwoFactorVerify from "./loginTwoFactorVerify.vue";

import postSetUpTOTP from "../../../src/services/totp/postSetUpTOTP.js";
import loginTwoFactorDisplayBackupCodes from "./loginTwoFactorDisplayBackupCodes.vue";
import loginTwoFactorOptionEmail from "./loginTwoFactorOptionEmail.vue";

import postMembershipLoginVerifyTOTP from "@/services/totp/postMembershipLoginVerifyTOTP.js";
import RButton from "../../../src/components/elements/RButton.vue";

export default {
  name: "LoginTwoFactorSetup",
  components: {
    RButton,
    loginTwoFactorVerify,
    loginTwoFactorOptionQR,
    loginTwoFactorOptionEmail,
    loginTwoFactorDisplayBackupCodes,
  },
  props: {
    loginToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      totpType: "TOTP",
      totpData: null,
      verified: false,
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    loginTwoFactorVerify() {
      return loginTwoFactorVerify;
    },
    loginTwoFactorOptionQR() {
      return loginTwoFactorOptionQR;
    },
    loginTwoFactorOptionEmail() {
      return loginTwoFactorOptionEmail;
    },
    loginTwoFactorDisplayBackupCodes() {
      return loginTwoFactorDisplayBackupCodes;
    },
  },
  watch: {
    loginToken: {
      async handler() {
        let result = await postSetUpTOTP({loginToken: this.$props.loginToken});

        if (result.status == 200) {
          this.$data.totpData = result.data;
        } else {
          this.$emit("showComponent", "loginPage", result.data.Error.Message);
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeTOTPType() {
      this.$data.totpType =
        this.$data.totpType == "TOTP" ? "TOTPEmail" : "TOTP";
    },
    async processVerificationResult(result, totpCode) {
      this.$data.verified = result;

      const response = await postMembershipLoginVerifyTOTP({
        loginToken: this.$props.loginToken,
        totpCode: totpCode,
      });

      if (response.status == 401) {
        this.$data.error = response.data.Error.Message;
        return;
      } else {
        this.$emit("setBearerToken", response.data.Token);

        if (this.$data.totpType == "TOTPEmail") {
          this.$emit("showComponent", "loginSessionList");
        }
      }
    },

    showComponent(component) {
      this.$emit("showComponent", component);
    },
    back() {
      this.$emit("showComponent", "loginPage");
    },
  },
};
</script>

<style scoped lang="scss">
.downloads {
  display: flex;
  padding: 20px 20px 10px 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  a {
    width: 30%;
    display: flex;
    justify-content: center;
  }

  img {
    display: block;
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .two-factor-setup {
    border-radius: 0px;
  }
}
.login-overlay {
  height: 100vh;
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;
  background-color: #362f2f7a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .two-factor-setup {
    background-color: white;

    overflow: auto;
    max-height: 100%;
    position: fixed;
    width: 500px;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 30%);
    .header {
      text-align: left;
      padding: 15px;
      border-bottom: 3px solid #f26522;
    }
    .options {
      margin-top: 15px;
      .title {
        font-size: 17.60px;
        font-weight: 600;
      }
      padding: 5px 15px 15px 15px;
    }
  }
}
</style>
