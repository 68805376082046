import $ from "jquery";
import calendarTemplate from "../../views/snippet/calendar.html";
// import clone from "clone"
import scroll from "./scrollContainer";

import moment from "moment";

moment.locale("nl");

/**
 * Calender component
 * @property {boolean} expectedChange - Keep track if the next change event was expected
 * @property {string} formatTime - Time format string
 * @property {string} formatDays - Day format string
 * @property {Object} formats - Datetime formats
 */
export default class Calendar {
  static expectedChange = false;
  // static changeTimer = null
  static formatTime = "HH:mm";
  static formatDays = "DD-MM-YYYY";

  /**
   * @property {string} date - Date format
   * @property {string} datetime - Datetime format
   * @property {string} time - Time format
   */
  static formats = {
    date: Calendar.formatDays,
    datetime: Calendar.formatDays + " " + Calendar.formatTime,
    time: Calendar.formatTime,
  };

  /**
   * Initialise component, set event listeners
   * @param {document} doc - Page document
   * @returns {void}
   */
  static init(doc) {
    // Calendar.el = $("<div>")
    // 	.addClass("calendar")
    // 	.addClass("calendar-menu")
    // 	.addClass("calendar-static")
    // $("body").append(Calendar.el)

    // Hide calender when clicking outside of it
    $(doc).on("click", function hideCalendar(e) {
      $(".menu").addClass("hide");
      $(".date-picker").removeClass("active");
    });

    $(doc).on(
      "click",
      ".date-picker [data-time-action-collapse]",
      function hideCalendarButton(e) {
        setTimeout(function () {
          $(".menu").addClass("hide");
          $(".date-picker").removeClass("active");
        }, 100);
      },
    );

    // Update view if user typed text, or if change event is triggered
    $(doc).on("keyup change", ".date-picker .date", function updateCalendar(e) {
      e.preventDefault();
      const $this = $(this);
      const columnName = $this.prop("name") || "DateTimeExpectedEnd";
      let columnData = null;
      let $date = $this.closest(".date-picker");
      let format = getFormat($date);
      // check if date is formatted correctly
      let date = moment(
        $this.is(".field") ? $this.text() : $this.val(),
        format,
        true,
      );
      if (global.session.activeWindow.sub) {
        if (global.session.activeWindow.sub.window.output.Data.Columns) {
          columnData =
            global.session.activeWindow.sub.window.output.Data.Columns[
              columnName
            ];
        } else {
          columnData = null;
        }
      } else {
        columnData =
          global.session.activeWindow.output.Data.Columns[columnName];
      }

      const formattedDate = date.format("YYYY-MM-DD");

      let olddate = moment($date.attr("data-set"), format);

      // dat is invalid, ignore event(user is probably still typing)

      let availableDates = null;
      if (columnData) {
        availableDates = columnData.AvailableDates;
      }
      let isAvailableDate =
        availableDates == null ||
        availableDates.includes(date.format("YYYY-MM-DD"));

      if (!isAvailableDate) {
        date = null;
      }

      let wasHidden = $date.find(".menu").hasClass("hide");

      // render calendar
      updateFull($date, date, columnName);
      // If was hidden, make sure it stays hidden
      if (wasHidden) {
        $date.find(".menu").addClass("hide");
        $date.removeClass("active");
      } else {
        e.stopImmediatePropagation();
      }
    });

    // on focus(tab event or click)
    // or fakefocus, a custom event for input[hidden] and for simulating focus events
    $(doc).on(
      "focus fakefocus",
      ".date-picker input, .date-picker .date",
      function onFocus(e) {
        e.stopImmediatePropagation();
        e.preventDefault();

        let $this = $(this).closest(".date-picker");
        let columnName =
          $this.prop("name") ||
          $this.find("input").attr("name") ||
          $this.find(".field").attr("name");

        if (columnName != null) {
          columnName = columnName.replace("[]", "");
        }

        if (columnName == null && $this.parents(".search-bar").length > 0) {
          columnName =
            typeof window.session.activeWindow.filters.usedate === "string"
              ? window.session.activeWindow.filters.usedate
              : "DateTimeCreated";
        }

        let columnData = null;

        if (window.session.activeWindow.sub) {
          if (window.session.activeWindow.sub.window.output.Data.Column) {
            columnData =
              window.session.activeWindow.sub.window.output.Data.Columns[
                columnName
              ];
          } else {
            columnData = null;
          }
        } else {
          if (!window.session.activeWindow.output.Data.Columns) {
            columnData = null;
          } else {
            columnData =
              window.session.activeWindow.output.Data?.Columns[columnName];
          }
        }

        // ignore disabled inputs
        if ($this.find(".date").is(":disabled")) {
          return;
        }

        let format = getFormat($this);

        // Get available dates
        let availableDates = null;
        if (columnData) {
          availableDates = columnData.AvailableDates;
        }

        // if not rendered
        if ($this.find(".calendar").length == 0 || availableDates) {
          // Ensure no previous version exists in the dom
          if (availableDates) {
            $this.find(".calendar").remove();
          }

          // get initial value
          let datestring = $this.attr("data-current");
          if (!datestring) {
            datestring = $this.attr("data-replace");
          }
          if (!datestring) {
            datestring = $this.text();
          }

          // format and render initial value
          let date = datestring
            ? moment(datestring, format)
            : moment().subtract(moment().minutes(), "minutes");
          if (!date.isValid()) {
            date = moment().subtract(moment().minutes(), "minutes");
          }
          let value = date.format(format);

          // set correct values
          $this.attr("data-set", value);

          // render for the first time
          let $newdiv = $(
            renderFromDate(
              date,
              date.clone(),
              getOptions($this),
              availableDates,
              global.session,
            ),
          );
          $this.append($newdiv);

          // Fix 16-06-2020 date switched to current month, when selecting a date in future month, based on start date in future month, e.g. start date: 01-07-2020, selected date 16-07-2020 became 16-06-2020
          let $date = $(this).closest(".date-picker");

          if (moment(date).isValid()) {
            $date.attr("data-current", value);
          }
        }
        let wasHidden = $this.find(".menu").hasClass("hide");

        $this.find(".menu").removeClass("hide");
        $this.addClass("active");

        if ($(e.target).is(".date") && wasHidden) {
          let $date = $this;
          let columnName = $(this).prop("name");
          setPickerTime($date, getSetTime($date), true, columnName);
        }

        // fix window overflowing
        scroll.positionMenu($this);
      },
    );

    $(doc).on("click", ".date-picker", function showCalendar(e) {
      e.stopImmediatePropagation();
      // hide other menu's
      $(".menu").addClass("hide");
      $(".date-picker").removeClass("active");

      if ($(this).find(".date").is(":disabled")) {
        return;
      }

      // show calendar
      $(this).find(".menu").removeClass("hide");
      $(this).addClass("active");
    });

    $(doc).on(
      "click",
      ".date-picker .week [data-day]",
      function onWeekClick(e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        let $this = $(this);
        let $date = $this.closest(".date-picker");
        let newdate = getPickerTime($date);
        let day = $this.attr("data-day");
        newdate.date(day);

        // Select value
        let columnName = $(this).prop("name");
        setPickerTime($date, newdate, false, columnName);
        setValue($date, newdate);

        // Set new date
        $this
          .closest(".date-picker")
          .find(".day.selected")
          .removeClass("selected");
        $this.parent().addClass("selected");
      },
    );

    // Go to other month
    $(doc).on(
      "click",
      ".date-picker [data-previous-month], .date-picker [data-next-month]",
      function stepMonth(e) {
        e.stopImmediatePropagation();
        e.preventDefault();

        let $this = $(this);
        let columnName =
          $this.attr("name") ||
          $this.find("input").attr("name") ||
          $this.parents(".date-picker").children("input").attr("name") ||
          $this.parents(".date-picker").children(".field").attr("name");

        if (columnName != null) {
          columnName = columnName.replace("[]", "");
        }

        let $date = $this.closest(".date-picker");
        let newdate = getPickerTime($date);
        // step
        let dm = $this.is("[data-next-month]") ? 1 : -1;

        // set only month
        let currentmonth = newdate.month();
        newdate.month(currentmonth + dm);
        newdate.date(1);

        setPickerTime($date, newdate, true, columnName);
      },
    );

    // add or subtract one from time
    $(doc).on(
      "click",
      ".date-picker [data-time-action]",
      function onTimeStepClick() {
        let $this = $(this);
        let [timepart, steptext] = $this.attr("data-time-action").split(":");
        let step = steptext === "up" ? 1 : -1;
        let $date = $this.closest(".date-picker");
        let newdate = getSetTime($date);
        // get momentjs object for the values
        let hour = newdate.hour();
        let minute = newdate.minute();

        // Set hour [0, 59]
        if (timepart === "hour") {
          hour += step;
          hour = hour >= 24 ? 0 : hour;
          hour = hour < 0 ? 23 : hour;
          newdate.hour(hour);
          $date.find("input.hours").val(HourMinuteFormat(hour));
          let columnName = $(this).prop("name");
          setPickerTime($date, newdate, false, columnName);
        }

        // Set minute [0, 59]
        if (timepart === "minute") {
          minute += step;
          minute = minute >= 60 ? 0 : minute;
          minute = minute < 0 ? 59 : minute;
          newdate.minute(minute);
          $date.find("input.minutes").val(HourMinuteFormat(minute));
          let columnName = $(this).prop("name");
          setPickerTime($date, newdate, false, columnName);
        }

        setValue($date, newdate);
      },
    );

    $(doc).on(
      "click",
      ".date-picker [data-time-action-clear]",
      function clearCalenderValue(e) {
        let $date = $(this).closest(".date-picker");
        clearValue($date);
      },
    );

    // Set time value when input changes(user typed)
    $(doc).on("change", "input.time-input", function onTimeInputChange(e) {
      let $date = $(this).closest(".date-picker");
      let newdate = getSetTime($date);
      let pickerdate = getPickerTime($date);
      let input = $(this).val();
      // remove non digits
      input = input.replace(/[^\d]/g, "");
      input = Number(input);

      if ($(this).is(".minutes")) {
        input = input >= 60 ? 59 : input;
        input = input < 0 ? 0 : input;
        newdate.minute(input);
        pickerdate.minute(input);
      } else {
        input = input >= 24 ? 23 : input;
        input = input < 0 ? 0 : input;
        newdate.hour(input);
        pickerdate.hour(input);
      }
      let columnName = $(this).prop("name");
      setPickerTime($date, pickerdate, false, columnName);
      $(this).val(HourMinuteFormat(input));
      setValue($date, newdate);
    });
  }
}

/**
 * Get correct format
 * @private
 * @param {jQuery} $date - jQuery datepicker element
 * @returns {string} format [date|datetime|time]
 */
function getFormat($date) {
  // let str = ""
  let type = $date.attr("data-type") || "DateTime";
  return Calendar.formats[type.toLowerCase()] || "";
}

/**
 * Get current time of picker
 * @private
 * @param {jQuery} $date - jQuery datepicker element
 * @returns {Object} moment time object
 */
function getPickerTime($date) {
  let datetime = $date.attr("data-current");
  let format = getFormat($date);
  let date = moment(datetime, format);
  if (!date.isValid()) {
    date = moment().subtract(moment().minutes(), "minutes");
  }
  return date;
}

/**
 * Get previously set time
 * @private
 * @param {jQuery} $date - jQuery datepicker element
 * @returns {Object} MomentJS time object
 */
function getSetTime($date) {
  let datetime = $date.attr("data-set");
  let format = getFormat($date);
  let date = moment(datetime, format);
  if (!date.isValid()) {
    date = moment().subtract(moment().minutes(), "minutes");
  }
  return date;
}

/**
 * Set datepicker time
 * @param {jQuery} $date - jQuery datepicker element
 * @param {moment} newdate - MomentJS new date
 * @param {boolean} update - Should we update?
 * @returns {void}
 */
function setPickerTime($date, newdate, update, columnName) {
  let setdate = null;

  if (columnName == null && $date.parents(".search-bar").length > 0) {
    columnName =
      typeof window.session.activeWindow.filters.usedate === "string"
        ? window.session.activeWindow.filters.usedate
        : "DateTimeCreated";
  }

  let columnData = null;

  if (window.session.activeWindow.sub) {
    if (window.session.activeWindow.sub.window.output.Data.Columns) {
      columnData =
        window.session.activeWindow.sub.window.output.Data.Columns[columnName];
    } else {
      columnData = null;
    }
  } else {
    if (window.session.activeWindow.output.Data.Columns) {
      columnData = window.session.activeWindow.output.Data.Columns[columnName];
    } else {
      columnData = null;
    }
  }

  if (moment(newdate).isValid() && !newdate.isValid()) {
    setdate = moment().subtract(moment().minutes(), "minutes");
  } else {
    setdate = newdate;
  }

  let format = getFormat($date);
  if (moment(newdate).isValid()) {
    $date.attr("data-current", setdate.format(format));
  } else {
    $date.attr("data-current", "");
  }

  if (moment(newdate).isValid() && update) {
    // Get available dates
    let availableDates = null;
    if (columnData) {
      availableDates = columnData.AvailableDates;
    }

    let setTime = moment($date.attr("data-set"), format);
    let $newdiv = $(
      renderFromDate(
        setdate,
        setTime,
        getOptions($date),
        availableDates,
        global.session,
      ),
    );
    $date.find(".menu-overflow").replaceWith($newdiv);
    scroll.positionMenu($newdiv.closest(".date-picker"));
    $newdiv.removeClass("hide");
  }
}

/**
 * Get datepicker options
 * @param {jQuery} el - jQuery datepicker element
 * @returns {Object} Options
 */
function getOptions(el) {
  let opt = {};

  let type = el.attr("data-type") || "DateTime";
  opt.type = type;
  opt.showDates = type == "DateTime" || type == "Date";
  opt.showTime = type == "DateTime" || type == "Time";

  return opt;
}

/**
 * Set actual value
 * @param {jQuery} $date - jQuery datepicker element
 * @param {moment} newdate - MomentJS new date
 * @returns {void}
 */
function setValue($date, newdate) {
  let $input = $date.find(".date");
  let format = getFormat($date);
  let value = null;

  if (newdate) {
    value = newdate.format(format);
  }

  if (value == "Invalid date") {
    return;
  }
  // set value with .text() if we are in bulkedit
  $input.is(".field") ? $input.text(value) : $input.val(value);
  // trigger custom event
  $input.trigger("value-change", value);
  if (newdate) {
    $date.attr("data-set", newdate.format(format));
  } else {
    $date.attr("data-set", "");
  }
  scroll.positionMenu($date.closest(".date-picker"));
}

/**
 * Clear calendar value
 * @param {jQuery} $date - jQuery datepicker element
 * @returns {void}
 */
function clearValue($date) {
  setValue($date, null);
  let columnName = $(this).prop("name");
  setPickerTime($date, "", true, columnName);
}

/**
 * Update everything
 * @param {jQuery} $date - jQuery datepicker element
 * @param {moment} newdate - MomentJS new date
 * @returns {void}
 */
function updateFull($date, newdate, columnName) {
  // set both actual and current value
  setValue($date, newdate.clone());

  // set text values
}

/**
 * Get two digit hour/minute string
 * @param {string} v - Value
 * @returns {string} Two character string
 * @example HourMinuteFormat(1) // -> "01"
 * @example HourMinuteFormat(59) // -> "59"
 */
function HourMinuteFormat(v) {
  let str = "" + v;
  if (str.length == 1) {
    str = "0" + str;
  }

  return str;
}

/**
 * Render calender based on date
 * @param {moment} date - MomentJS date object with any date inside a month
 * @param {moment} olddate - MomentJS date object with selected date
 * @param {Object} options - Render options
 */
function renderFromDate(date, olddate, options, availableDates, session) {
  // Get text values
  let data = {
    month: date.format("M"),
    monthname: date.format("MMMM"),
    year: date.format("YYYY"),
    hour: date.format("HH"),
    minute: date.format("mm"),
    day: date.format("D"),
    days: getDaysAsArray(date, olddate, availableDates),
    options,
    session,
  };

  // render
  return calendarTemplate(data);
}

/**
 * Return all days in month as an object
 * @param {moment} mom - MomentJS date object with any date inside a month
 * @param {moment} old - MomentJS date object with selected date
 * @returns {Object[]} Days in month
 */
function getDaysAsArray(mom, old, availableDates) {
  let res = [];
  let date = mom.endOf("month").startOf("day");
  let month = mom.month();
  let today = moment().startOf("day");

  while (date.month() === month || date.day() != 0) {
    // for each day get information
    let weekday = date.day();
    weekday = weekday == 0 ? 7 : weekday;

    let day = {
      day: date.date(),
      weekday: weekday,
      isToday: today.isSame(date),
      isOtherMonth: date.month() !== month,
      isSelected: old.startOf("day").isSame(date),
      month: date.month(),
      year: date.year(),
      completeDate: date.format("YYYY-MM-DD"),
      isEnabled:
        availableDates && availableDates.length > 0
          ? availableDates.includes(date.format("YYYY-MM-DD"))
          : null,
    };
    res.push(day);

    date.subtract(1, "days");
  }

  return res.reverse();
}
