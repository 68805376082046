import Hook from "@/hook";

/**
 * Go to page
 * @param {number} pageNo - Page number
 * @param {boolean} noReload - Should we reload?
 * @returns {void}
 */
export default function page(pageNo, noReload) {
  let event = {page: Number(pageNo)};

  return Hook.run(
    "paginate",
    this,
    async () => {
      if (event.page > 0) {
        this.input.Data.PageNumber = event.page;

        if (noReload) {
          return;
        }

        await this.reload(true);
      }
    },
    event,
  );
}
