import $ from "jquery";
import Window from "../model/window";
import formatter from "../model/formatter";

/**
 * Enables searchbar functionality
 * @property {string} updateMatch - Only for ".table-form"
 * @property {Session} session - Session reference
 */
export default class Searchbar {
  static updateMatch = ".table-form";
  static session = null;
  static processing = false;

  /**
   * A element was found with our selector
   * @param {element} el - Element
   * @returns {void}
   */
  static attach(el) {
    let splitTimer = null;

    $(el).off();
    $(el).on("click", "button[data-show-search]", async function (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      let window = Searchbar.session.getClosestWindow(el);
      let value = $(el).find(".search-box .input").val();
      let checkedFilters = $(el).find(
        ".filter-box input[type=checkbox]:checked",
      ).length;
      let $box = $(el).find(".search-box");
      let isVisible = !$box.hasClass("hide");

      let hideboxes = isVisible ? !value && !checkedFilters : false;
      // $box.toggleClass("hide", hideboxes )

      if ((value || checkedFilters) && isVisible) {
        await Searchbar.addTextFilter(el, window);
      }
      window.resize();
    });

    $(el).on("click", "button[data-show-filters]", function (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      let window = Searchbar.session.getClosestWindow(el);
      $(el).find(".filter-box").toggleClass("hide");
      window.resize();
    });

    $(el).on("click", ".filter-option.date-picker button", function (e) {
      let $cal = $(this).parent().find(".calendar");
      if ($cal.length && $cal.is(":visible")) {
        e.stopImmediatePropagation();
        $cal.addClass("hide");
      } else {
        $(this).closest(".date-picker").find("input").trigger("fakefocus");
      }
    });

    $(el).on(
      "value-change",
      ".filter-option.date-picker input",
      async function (e, arg) {
        let window = Searchbar.session.getClosestWindow(el);
        let filter = window.filters.date[$(this).attr("data-index")];

        if (!filter) {
          return;
        }

        let value = formatter.serializers.Date(arg);

        // For 'now and later' date selection, make sure date is at start of the day
        if (filter.suffix == ">=") {
          value = formatter.startOf("day", value).format("YYYY-MM-DD HH:mm:ss");
        }

        // For 'up and untill' date selection, make sure date is at end of the day
        if (filter.suffix == "<=") {
          value = formatter.endOf("day", value).format("YYYY-MM-DD HH:mm:ss");
        }

        filter.value = arg;
        filter.serializedValue = value;

        window.input.Data = window.input.Data || {};
        window.input.Data.Filters = window.input.Data.Filters || [];
        window.input.Data.Filters[filter.suffix] = value;
        window.input.Data.PageNumber = 1;
        window.filters.selectedOthers++;
        await window.reload();
      },
    );

    $(el).on("change", "[data-filter-target]", async function () {
      let window = Searchbar.session.getClosestWindow(el);
      let filter =
        window.output.Filters &&
        window.output.Filters.filter(
          (x) => x.Target == $(this).attr("data-filter-target"),
        )[0];
      if (!filter) {
        return;
      }
      let index = Number($(this).val());
      window.input.Data.Filters[filter.Target] = filter.Options[index].Value;
      await window.reload();
    });

    $(el).on("keydown", ".search-box .input", async function (e) {
      if (this.processing) {
        return;
      }
      this.processing = true;
      let window = Searchbar.session.getClosestWindow(el);
      if (e.which === 13) {
        e.preventDefault();
        if ($(this).val() && $(this).val().trim()) {
          await Searchbar.addTextFilter(el, window);
          $(window.element).find(".search-box .input").focus();
        }
      } else if (e.which === 27) {
        e.preventDefault();
        let checkedFilters = $(el).find(
          ".filter-box input[type=checkbox]:checked",
        ).length;
        if (
          !checkedFilters &&
          window.input.Data &&
          window.input.Data.Search &&
          window.input.Data.Search.length
        ) {
          await Searchbar.reset(window);
          $(window.element).find(".search-box .input").focus();
        } else {
          $(this).val("");
        }
      } else if (
        e.which === 8 &&
        getCaretPosition(this) === 0 &&
        window.output.Request.Data.Search.length > 0
      ) {
        e.preventDefault();
        let val = $(this).val();
        await window.removeTextQuery(
          window.output.Request.Data.Search.length - 1,
        );
        $(window.element).find(".search-box .input").val(val).focus();
      }
      this.processing = false;
    });
  }

  /**
   * Add a text filter to the window
   * @param {element} el - Element
   * @param {Window} window - Related window
   * @returns {Promise} A promise
   */
  static async addTextFilter(el, window) {
    let textFilter = $(el).find(".search-box .input").val().trim();
    const columnSelection = window.getSearchColumns();
    const filterKey = columnSelection.map((x) => x.Name).join(",");

    if (textFilter && window.input) {
      window.input.Data.Search.push({
        [filterKey]: textFilter,
      });
      window.input.Data.PageNumber = 1;
    }

    window.page(1, true);
    await window.reload();
  }

  /**
   * Reset filters
   * @param {Window} window - Related window
   * @returns {Promise} A promise
   */
  static async reset(window) {
    window.input.Data.Search = [];
    window.input.Data.PageNumber = 1;
    await window.reload();
    window.resize();
  }
}

/**
 * Place text caret at end of input
 * @param {element} el - Input element
 * @returns {void}
 */
function placeCaretAtEnd(el) {
  el.focus();
  if (
    typeof window.getSelection != "undefined" &&
    typeof document.createRange != "undefined"
  ) {
    let range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange != "undefined") {
    let textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
}

/**
 * Get position of caret
 * @param {element} editableDiv - Editable div
 * @return {number} index of carent
 */
function getCaretPosition(editableDiv) {
  if (window.getSelection().toString().length) {
    return -1;
  }

  if (window.getSelection) {
    // $(editableDiv).focus()
    if (typeof editableDiv.selectionStart == "number") {
      return editableDiv.selectionStart;
    }
    let sel = window.getSelection();
    if (sel.rangeCount) {
      let range = sel.getRangeAt(0);
      // if (range.commonAncestorContainer.parentNode == editableDiv) {
      //   caretPos = range.endOffset;
      // }
    }
  } else if (document.selection && document.selection.createRange) {
    let range = document.selection.createRange();
    if (range.parentElement() == editableDiv) {
      let tempEl = document.createElement("span");
      editableDiv.insertBefore(tempEl, editableDiv.firstChild);
      let tempRange = range.duplicate();
      tempRange.moveToElementText(tempEl);
      tempRange.setEndPoint("EndToEnd", range);
      return tempRange.text.length;
    }
  }

  return -1;
}
