import dayjs from "dayjs";

export const datesAreEqual = ({date1, date2}) => {
  if (date1 === null && date2 === null) return true;

  const parsedDate1 = dayjs(date1, ["DD-MM-YYYY", "YYYY-MM-DD"]);
  const parsedDate2 = dayjs(date2, ["DD-MM-YYYY", "YYYY-MM-DD"]);

  if (!parsedDate1.isValid() && !parsedDate2.isValid()) return true;

  return parsedDate1.isSame(parsedDate2, "day");
};
