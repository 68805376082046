import vueSlashSeparatedArray from "../../views/elements/slashSeparatedArrayField.vue";
import vueColor from "../../views/elements/colorField.vue";

const editors = {vueSlashSeparatedArray, vueColor};

const initializeTableElements = function (eventWindow) {
  const $vueElements = $(eventWindow.element).find("div [vue-editor]");

  for (const vueElement of $vueElements) {
    const editor = editors[$(vueElement).attr("data-editor-type")];
    new window.vue({
      el: `#${vueElement.id}`,
      render: (h) =>
        h(editor, {
          props: {
            name: $(vueElement).attr("name"),
            index: $(vueElement).attr("index"),
            value: $(vueElement).attr("value"),
            metadata: $(vueElement).attr("metadata"),
          },
        }),
    });
  }
};

export default initializeTableElements;
