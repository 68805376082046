export const updateVueDateValues = ({
  changedFieldValue,
  changedFieldName,
  vueColumns,
}) => {
  for (const column in vueColumns) {
    if (vueColumns[column].$children[0].name === changedFieldName)
      vueColumns[column].$children[0].updateStartDate(changedFieldValue);
  }
};
