import Hook from "../hook";
import {fetchAndSetColorRules} from "@/functions/login/fetchAndSetColorRules";

class TableColorHook extends Hook {
  async afterRender(window) {
    if (
      window.output.Request.Subject == "Core.EnvironmentTableColor" ||
      window.output.Request.Subject == "Core.EnvironmentTableColorCriteria"
    ) {
        fetchAndSetColorRules();
    }
  }
}

/** @ignore */
export default new TableColorHook();
