import Hook from "../hook";
import {notify} from "@/util/notify";
import {getTranslations} from "@/functions/session/localstorage/getTranslations";

function validateSerialCheckDuration($serialCheckDurationField) {
  const translations = getTranslations();
  const val = $serialCheckDurationField.val();
  if (val > 1000) {
    notify({
      message: translations.SerialCheck,
    });
    $serialCheckDurationField.val(1000);
  }
}

class CoreSettingsSingleSerialCheckDurationFieldValidator extends Hook {
  async afterRender(window) {
    const {Subject, Criteria} = window.output.Request;
    if (Subject === "Core.Settings" && Criteria[0]?.CategoryID === "Return") {
      const $serialCheckDurationField = $(window.element).find(
        "[name='SerialCheckDuration']",
      );

      $serialCheckDurationField.on("change", function () {
        validateSerialCheckDuration($(this));
      });
    }
  }
}

export default new CoreSettingsSingleSerialCheckDurationFieldValidator();
