<template>
  <div ref="draggableContainer" id="draggable-container">
    <div id="draggable-header" @mousedown="dragMouseDown">
      <slot name="header"></slot>
    </div>
    <slot name="main"></slot>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: "DraggableDialog",
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: 400,
        movementX: 300,
        movementY: 300,
      },
    };
  },
  created() {
    this.$refs.draggableContainer.style.bottom = "0px";
    this.$refs.draggableContainer.style.top =
      this.$refs.draggableContainer.offsetTop - this.positions.movementY + "px";
    this.$refs.draggableContainer.style.left =
      this.$refs.draggableContainer.offsetLeft -
      this.positions.movementX +
      "px";
  },
  methods: {
    dragMouseDown: function (event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        "px";
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        "px";
      this.$refs.draggableContainer.style.bottom = "unset";
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
};
</script>

<style>
#draggable-container {
  position: absolute;
  z-index: 9;
  background-color: #e9ecef;
  border-radius: 3px;
  font-weight: 700;
  padding: 3px 6px;
  cursor: grab;
  bottom: 0px;
}
#draggable-header {
  z-index: 10;
}
</style>
