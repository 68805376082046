import {getActiveWindow} from "./getActiveWindow.js";
import {getParentWindowFromWindowId} from "./getParentWindowFromWindowId.js";
import {getSubWindowFromWindowId} from "./getSubWindowFromWindowId.js";

export const isWindowIdActiveOrSubParentOfActive = ({windowId}) => {
  const activeWindowId = getActiveWindow()?.id;
  const activeSubWindowId = getSubWindowFromWindowId({
    windowId: activeWindowId,
  })?.id;
  const activeParentWindowId = getParentWindowFromWindowId({
    windowId: activeWindowId,
  })?.id;

  if (windowId === activeWindowId) return true;
  if (windowId === activeParentWindowId) return true;

  return windowId === activeSubWindowId;
};
