import $ from "jquery";

export const addTextFilter = async (el, window) => {
  let textFilter = $(el).val().trim();
  const columnSelection = window.getSearchColumns();
  const filterKey = columnSelection.map((x) => x.Name).join(",");

  if (textFilter && window.input) {
    window.input.Data.Search.push({
      [filterKey]: textFilter,
    });
    window.input.Data.PageNumber = 1;
  }

  window.page(1, true);
  await window.reload();
};
