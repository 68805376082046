import $ from "jquery";
import formatter from "@/model/formatter";
import {getCaretPosition} from "./searchbar/getCartPosition";
import {reset} from "./searchbar/reset";
import {addTextFilter} from "./searchbar/addTextFilter";

export const searchbarLogic = () => {
  $(document).on("click", "button[data-show-search]", async function (e) {
    const el = e.target;
    e.stopImmediatePropagation();
    e.preventDefault();
    let window = global.session.getClosestWindow(el);
    let value = $(el).find(".search-box .input").val();
    let checkedFilters = $(el).find(
      ".filter-box input[type=checkbox]:checked",
    ).length;
    let $box = $(el).find(".search-box");
    let isVisible = !$box.hasClass("hide");

    if ((value || checkedFilters) && isVisible) {
      await addTextFilter(el, window);
    }
    window.resize();
  });

  $(document).on("click", "button[data-show-filters]", function (e) {
    const el = e.target;
    e.stopImmediatePropagation();
    e.preventDefault();
    let window = global.session.getClosestWindow(el);
    $(el).find(".filter-box").toggleClass("hide");
    window.resize();
  });

  $(document).on("click", ".filter-option.date-picker button", function (e) {
    let $cal = $(this).parent().find(".calendar");
    if ($cal.length && $cal.is(":visible")) {
      e.stopImmediatePropagation();
      $cal.addClass("hide");
    } else {
      $(this).closest(".date-picker").find("input").trigger("fakefocus");
    }
  });

  $(document).on(
    "value-change",
    ".filter-option.date-picker input",
    async function (e, arg) {
      const el = e.target;
      let window = global.session.getClosestWindow(el);
      let filter = window.filters.date[$(this).attr("data-index")];

      if (!filter) {
        return;
      }

      let value = formatter.serializers.Date(arg);

      // For 'now and later' date selection, make sure date is at start of the day
      if (filter.suffix == ">=") {
        value = formatter.startOf("day", value).format("YYYY-MM-DD HH:mm:ss");
      }

      // For 'up and untill' date selection, make sure date is at end of the day
      if (filter.suffix == "<=") {
        value = formatter.endOf("day", value).format("YYYY-MM-DD HH:mm:ss");
      }

      filter.value = arg;
      filter.serializedValue = value;

      window.input.Data = window.input.Data || {};
      window.input.Data.Filters = window.input.Data.Filters || [];
      window.input.Data.Filters[filter.suffix] = value;
      window.input.Data.PageNumber = 1;
      window.filters.selectedOthers++;
      await window.reload();
    },
  );

  $(document).on("change", "[data-filter-target]", async function (e) {
    const el = e.target;
    let window = global.session.getClosestWindow(el);
    let filter =
      window.output.Filters &&
      window.output.Filters.filter(
        (x) => x.Target == $(this).attr("data-filter-target"),
      )[0];
    if (!filter) {
      return;
    }
    let index = Number($(this).val());
    window.input.Data.Filters[filter.Target] = filter.Options[index].Value;
    await window.reload();
  });

  $(document).on("keydown", ".search-box .input", async function (e) {
    const el = e.target;
    if (this.processing) {
      return;
    }
    this.processing = true;
    let window = global.session.getClosestWindow(el);
    if (e.which === 13) {
      e.preventDefault();
      if ($(this).val() && $(this).val().trim()) {
        await addTextFilter(el, window);
        $(window.element).find(".search-box .input").focus();
      }
    } else if (e.which === 27) {
      e.preventDefault();
      let checkedFilters = $(el).find(
        ".filter-box input[type=checkbox]:checked",
      ).length;
      if (
        !checkedFilters &&
        window.input.Data &&
        window.input.Data.Search &&
        window.input.Data.Search.length
      ) {
        await reset(window);
        $(window.element).find(".search-box .input").focus();
      } else {
        $(this).val("");
      }
    } else if (
      e.which === 8 &&
      getCaretPosition(this) === 0 &&
      window.output.Request.Data.Search.length > 0
    ) {
      e.preventDefault();
      let val = $(this).val();
      await window.removeTextQuery(
        window.output.Request.Data.Search.length - 1,
      );
      $(window.element).find(".search-box .input").val(val).focus();
    }
    this.processing = false;
  });
};
