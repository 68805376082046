
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/table/cellStatusIcon.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
var macro_t_1 = runtime.makeMacro(
["cellStatus"], 
[], 
function (l_cellStatus, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("cellStatus", l_cellStatus);
var t_2 = "";t_2 += "\n  ";
if(l_cellStatus == "success") {
t_2 += "\n  <i class=\"fas fa-check text-success\" style=\"float: left;\"></i>\n  ";
;
}
t_2 += "\n  ";
if(l_cellStatus == "danger") {
t_2 += "\n  <i class=\"fas fa-check text-danger\" style=\"float: left;\"></i>\n  ";
;
}
t_2 += "\n  ";
if(l_cellStatus == "warning") {
t_2 += "\n  <i class=\"fas fa-check text-warning\" style=\"float: left;\"></i>\n  ";
;
}
t_2 += "\n  ";
if(l_cellStatus == "info") {
t_2 += "\n  <i class=\"fas fa-info text-info\" style=\"float: left;\"></i>\n  ";
;
}
t_2 += "\n\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("render");
context.setVariable("render", macro_t_1);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/table/cellStatusIcon.html", ctx);
          }
        
          return nunjucks.render("views/content/table/cellStatusIcon.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/table/cellStatusIcon.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        