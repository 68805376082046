<template>
  <button
    class="btn-primary btn-sm btn"
    :data-window-event="`action:Save:${subject}:../../Admin/WebServices/CoreWebServices.asmx:SaveData`"
    :data-window-target="window.id"
  >
    <i class="fas fa-save"></i> {{ translations.Save }}
  </button>
</template>

<script>
export default {
  name: "CanvasSaveButton",
  props: {
    subject: {
      type: String,
      required: true,
    },
    window: {
      type: Object,
      required: true,
    },
    translations: {
      type: Object,
      required: true,
    },
  },
};
</script>
