import formatter from "@/model/formatter";

/**
 * Build criteria from primary keys of row
 * @param {Object} row - Row object
 * @returns {Object} criteria
 */
export default function buildCriteriaNew(row) {
  let criteria = {};

  for (let cell of row) {
    if (cell.Column.IsPrimaryKey) {
      criteria[cell.Column.Name] = cell.Value;

      if (cell.Column.Type == "Date") {
        criteria[cell.Column.Name] = formatter.serializers.Date(cell.Value);
      } else if (cell.Column.Type == "DateTime") {
        criteria[cell.Column.Name] = formatter.serializers.DateTime(cell.Value);
      }
    }
  }

  return criteria;
}
