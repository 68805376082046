/**
 * Set counter
 * @todo Document. What is this?
 * @returns {void}
 */
export default function setCounter() {
  if (this.parent !== null) {
    return;
  }

  let base = this.title();
  let counters = [];

  for (let window of this.session.tabs) {
    if (window === this) {
      continue;
    }

    if (window.title(true) === base) {
      counters.push(window.counter);
    }
  }

  while (counters.indexOf(this.counter) !== -1) {
    this.counter++;
  }
}
