import {updateLegacyFormFieldObjectsWithActionResponseData} from "./updateLegacyFormFieldObjectsWithActionResponseData.js";
import {updateWindowFormColumns} from "./updateWindowFormColumns.js";
import {updateWindowFormValues} from "./updateWindowFormValues.js";
import {updateVueColumnsRows} from "./updateVueColumnsRows.js";

export const processLegacyFormFieldPostback = async ({
  newColumns,
  newMetadata,
  window,
  vueColumns,
  newRow,
  oldRow,
  field,
  fieldColumn,
  windowId,
}) => {
  await updateWindowFormColumns({newColumns, window});
  await updateWindowFormValues({
    fieldColumn,
    vueColumns,
    windowId,
    newRow,
    oldRow,
    field,
  });
  await updateVueColumnsRows({
    vueColumns,
  });
  updateLegacyFormFieldObjectsWithActionResponseData({
    newMetadata,
    vueColumns,
    newColumns,
  });
};
