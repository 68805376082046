import $ from "jquery";
import Window from "@/model/window";

/**
 * Load sub window
 * @returns {Promise} Promise
 */
export default async function loadSub() {
  // we cannot load sub window we do not have
  if (!this.sub) {
    return;
  }

  $("body").addClass("avoid-clicks");
  // load sub
  if (this.sub.dummy) {
    let child = new Window(this.session, this);
    this.sub.window = child;
    child.empty = true;
  }
  $("body").removeClass("avoid-clicks");
}
