import $ from "jquery";
import store from "../../../state/store";

/**
 * Disposes all windows
 * @returns {void}
 */
export default async function disposeAll() {
  let i;
  let tabAmount = this.session.tabs.length;
  let windowCount = Object.keys(this.session.windows).length;
  for (i = 0; i < tabAmount; i++) {
    // Take the first tab in of the array.
    // Because after an delete in an array the next value moves from index number.
    let tab = this.session.tabs[0];
    $(tab.element).empty();
    $(tab.element).remove();
  }

  for (i = 0; i < windowCount; i++) {
    // Take the first tab in of the array.
    // Because after an delete in an array the next value moves from index number.
    let window = this.session.windows[Object.keys(this.session.windows)[0]];
    $(window.element).empty();
    $(window.element).remove();
  }

  this.session.tabs = [];

  this.session.windows = {};
  this.session.activeWindow = null;

  store.state.tabs = [];

  await store.commit("refreshTabs");
  store.commit("updateWindow");
}
