import {getRentalTables} from "@/services/table/getRentalTables";

const state = () => ({
  tables: [],
});

// getters
const getters = {
  tables: (state) => state.tables,
};

// actions
const actions = {
  async fetchTables({commit}) {
    const tables = await getRentalTables();
    commit("setTables", tables.Collection);
  },
};

// mutations
const mutations = {
  setTables(state, tables) {
    state.tables = tables;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
