export const getIconType = ({iconString}) => {
  if (iconString.includes("check")) return "success";
  if (iconString.includes("exclamation")) return "warning";
  if (
    iconString.includes("times") ||
    iconString.includes("exclamation-triangle")
  )
    return "danger";
  return "info";
};
