<template>
  <div
    style="pointer-events: auto !important"
    class="fixed inset-0 z-[5500] overflow-auto"
    @mousedown="handleOutsideClick"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    ></div>
    <div class="flex items-center justify-center min-h-screen p-4 min-w-">
      <div
        class="r-alert bg-white shadow-md rounded-md p-6 mx-auto relative z-10"
        :class="{
          'max-w-lg w-full': size === 'lg',
          'min-w-[500px] max-w-[90vw] max-h-[90vh] w-fit': size === 'xl',
        }"
      >
        <div class="flex justify-center mb-4">
          <r-alert-popup-icon
            :icon="iconString"
            :additional-classes="iconClasses"
          />
        </div>

        <div
          class="w-full flex justify-center"
          :class="{'overflow-y-auto max-h-[50vh]': size === 'xl'}"
        >
          <div class="prose max-w-full" v-html="markdownParsed" />
        </div>

        <div class="mt-4 flex justify-center flex-wrap space-x-2">
          <r-button
            v-for="(button, index) in buttons"
            :id="`r-alert-popup-button-${button.value}`"
            :key="index"
            :variant="button.variant || 'secondary'"
            :size="button.size || 'lg'"
            @click="handleButtonClick(button)"
            @enter="handleButtonClick(button)"
          >
            {{ button.text }}
          </r-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nmd from "nano-markdown";
import {getIconClasses} from "../../interface/alertPopup/getIconClasses";
import {getIconString} from "../../interface/alertPopup/getIconString";
import {getIconType} from "../../interface/alertPopup/getIconType";
import RButton from "./RButton.vue";
import RAlertPopupIcon from "./RAlertPopupIcon.vue";

export default {
  name: "RAlertPopup",
  components: {RButton, RAlertPopupIcon},
  props: {
    buttons: {
      type: Array,
      default: () => [
        {
          text: "OK",
          variant: "primary",
          value: "ok",
          runResolve: true,
          action: (vueInstance) => {
            vueInstance.$emit("close", {markdown: vueInstance.markdown});
          },
        },
      ],
    },
    markdown: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "far fa-check-circle",
    },
    iconAdditionalClasses: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "lg",
    },
    resolve: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    markdownParsed: ({markdown}) => nmd(markdown),
    iconString: ({icon}) => getIconString({icon}),
    iconClasses({iconAdditionalClasses, iconString}) {
      const iconType = getIconType({iconString});
      return getIconClasses({iconAdditionalClasses, iconType});
    },
  },
  created() {
    // focus the primary variant button
    this.$nextTick(() => {
      const primaryButton = this.buttons.find(
        (button) => button.variant === "primary",
      );

      if (primaryButton) {
        const id = `#r-alert-popup-button-${primaryButton.value}`;
        const button = document.querySelector(id);
        if (button) {
          button.focus();
        }
      }
    });

    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" && this.buttons.length === 1) {
        this.$emit("close", {markdown: this.markdown});
      }
    });
  },
  destroyed() {
    document.removeEventListener("keydown", (event) => {
      if (event.key === "Escape" && this.buttons.length === 1) {
        this.$emit("close", {markdown: this.markdown});
      }
    });
  },
  methods: {
    handleButtonClick({action, runResolve, value}) {
      if (action && typeof action === "function") {
        action(this);
      }
      if (runResolve !== false) {
        this.resolve(value);
        this.$emit("close", {markdown: this.markdown});
      }
      this.$emit("button-click", {action, runResolve, value});
    },
    handleOutsideClick(event) {
      if (
        !document.querySelector(".r-alert").contains(event.target) &&
        !event.target.closest(".r-alert") &&
        this.buttons.length === 1
      ) {
        this.resolve(true);
        this.$emit("close", {markdown: this.markdown});
      }
    },
  },
};
</script>
