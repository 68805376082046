import {getWindowFromWindowId} from "../window/getWindowFromWindowId.js";
import {getActiveWarehouse} from "../../util/getActiveWarehouse.js";
import {getActiveWindow} from "../window/getActiveWindow.js";

export const getWarehouseIdFromFormRow = ({windowId}) => {
  const window = windowId
    ? getWindowFromWindowId({windowId, session: global.session})
    : getActiveWindow();

  if (!window) return getActiveWarehouse();

  const formRow = window.sub
    ? window.sub.window.output.Table?.Rows?.[0]
    : window.output.Table?.Rows?.[0];

  const warehouseCell = formRow?.find(
    (row) => row.Column.Name === "WarehouseID",
  );

  if (warehouseCell) return warehouseCell.NewValue ?? warehouseCell.Value;

  return getActiveWarehouse();
};
