<template>
  <div class="flex items-center border-none leading-[26px] bg-transparent">
    <div
      class="w-[15px] h-[15px] rounded-full"
      :style="`background-color: ${color};`"
    ></div>
    <span class="ml-1 pt-[1px] font-medium">{{ color }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
  },
  computed: {
    color() {
      const color = this.value.replace(/['"]+/g, "");
      return color !== "null" ? color : "";
    },
  },
};
</script>
