<template>
  <div>
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="minus-circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="svg-inline--fa fa-minus-circle text-gray-300 bg-white"
      :class="{
        '!bg-[#eee]': !isAvailable && !small,
        'w-8 h-7 text-gray-300': !small,
        'w-6 h-5 ml-1.5 mt-1.5': small,
      }"
    >
      <path
        fill="currentColor"
        d="M140 284c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h232c6.6 0 12 5.4 12 12v32c0 6.6-5.4 12-12 12H140zm364-28c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "FormSidebarRailroadItemIconCancelled.vue",
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped></style>
