/**
 * Select permissions for table for sub
 * @todo fill roleID, possilbly depricated
 * @param {string} tableName - Table name
 * @param {*} roleID - Role ID
 * @param {string} breadcrumbs - breadcrums
 * @returns {Promise} Promise
 */
export default async function selectPermissionsSub(
  tableName = null,
  roleID,
  breadcrumbs = "",
) {
  roleID =
    global.session.activeWindow.sub.window.output.FullTable.Rows[0].filter(
      (row) => row.Column.Name === "RoleId",
    )[0].Value;

  this.injectSub({}, true, true);
  this.sub.window.selectPermissions(tableName, roleID, breadcrumbs);
}
