import $ from "jquery";
import modalImageTemplate from "@/../views/content/modals/modalImage.html";
import {newVueModal} from "../../interface/vueModalInjector";
import {generateID} from "../../util/generateID";
import store from "@/../state/store";

export default class ModalImage {
  /**
   *  Registers all events related to the image modals
   *  @returns {void}
   */
  registerEventListeners() {
    // Listen for a image icon click
    $("body").on("click", ".material-icons.image", this.openImageModal);
  }

  /**
   * Open image modal
   * This function should be called from an event containing a img-url in the element called from
   * @private
   * @returns {void}
   */
  openImageModal() {
    const image = `${$(this).attr("img-url")}?token=${store.state.accessToken}`;

    let html = modalImageTemplate({
      image,
      translations: window.session.translations,
    });
    newVueModal({
      html,
      modalSize: "xl",
      randomID: generateID(5),
      modalBodyID: generateID(5),
    });
    // window.largeModalInstance.setContent(html)
    // window.largeModalInstance.update()
    // window.largeModalInstance.show()
  }
}
