/**
 * Remove Date filter
 * @param {*} id - Date filter id
 * @returns {Promise} To re-render
 */
export default async function removeDateQuery(id) {
  //
  let otherFilter = this.filters.date[id];
  this.input.Data.Filters[otherFilter.suffix] = null;
  otherFilter.value = null;
  await this.reload();
}
