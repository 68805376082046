// initial state
// shape: [{ user, properties }]
const state = () => ({
  windowFilters: [],
});

// getters
const getters = {
  getFilters: (state) => (windowId) => {
    return state.windowFilters.filter((filter) => filter.windowId === windowId);
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  setFilter(state, {windowId, filter}) {
    const index = state.windowFilters.findIndex(
      (wFilter) =>
        wFilter.windowId === windowId && wFilter.type === filter.type,
    );

    if (index === -1) {
      state.windowFilters.push({windowId, ...filter});
    } else {
      state.windowFilters.splice(index, 1, {windowId, ...filter});
    }
  },
  removeFilter(state, {windowId, type}) {
    state.windowFilters = state.windowFilters.filter(
      (filter) => filter.windowId !== windowId && filter.type === type,
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
