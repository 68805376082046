import {fetchOptions} from "./fetchOptions.js";

export const getOptions = async ({field, row, searchValue, vueComponent}) => {
  if (field.Dropdown?.TableName) {
    if (vueComponent) vueComponent.loading = true;
    return await fetchOptions({field, row, searchValue});
  }

  if (field.Dropdown?.Items) {
    return await field.Dropdown.Items;
  }

  return field.Options ?? [];
};
