import Hook from "../hook";
import DaysCalculatedListener from "@/functions/events/daysCalculatedChangeEvent";

class NewRentalOrderDaysCalculatedHook extends Hook {
  listener;

  afterRender(eventWindow) {
    this.addEvent(eventWindow);
  }

  afterFocus(eventWindow) {
    this.addEvent(eventWindow);
  }

  addEvent(eventWindow) {
    /*
    const selectedWindow =
      extractSubWindowFromWindowObject({window: eventWindow}) ?? 
      */
    const selectedWindow = eventWindow?.sub?.window ?? eventWindow;
    const {Subject, Prefix} = selectedWindow.output?.Request;
    if (
      Subject === "Rental.Order" &&
      (Prefix === "New" || Prefix === "Single")
    ) {
      if (!this.listener) {
        this.listener = new DaysCalculatedListener();
      } else {
        this.listener.stop();
      }

      this.listener.start(selectedWindow);
    }
  }
}

export default new NewRentalOrderDaysCalculatedHook();
