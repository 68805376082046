import $ from "jquery";

export default class PlusMinusInput {
  /**
   * Create event listeners, initialise component
   * @param {document} doc - Page document
   */
  static init(doc) {
    $(doc).on("click", ".minus", function () {
      if (this != document.activeElement) {
        return; // when the input between minus and plus is blurred/entered the minus button is 'clicked' by jQuery. Ignore this click
      }

      var $input = $(this).parent().find("input");
      var newVal = parseInt($input.val().replace(",", ".")) - 1;
      $input.val(newVal);
      $input.change();

      // Microsoft Edge fix, otherwise the button stays focussed
      document.activeElement.blur();
    });

    $(doc).on("click", ".plus", function () {
      var $input = $(this).parent().find("input");
      var newVal = parseInt($input.val().replace(",", ".")) + 1;
      $input.val(newVal);
      $input.change();

      // Microsoft Edge fix, otherwise the button stays focussed
      document.activeElement.blur();
    });

    $(doc).on("change value-change", ".plusminusinput-input", function () {
      if (
        !window.session.activeWindow ||
        !window.session.activeWindow.customData
      ) {
        return;
      }

      let col =
        window.session.activeWindow.output.Data.Columns[$(this).attr("name")];
      /*
        window.session.activeWindow.customData.columns[
          $(this).attr("data-field-index")
        ]
      */

      if (!col) {
        return;
      }

      let type = $(this).is("input") ? "input" : "field";
      let val = $(this).val();

      let round = col.NumericScale;
      val = Number(val.replace(",", "."));

      $(this).toggleClass("incorrect", isNaN(val));

      // Round the value and check with the min and max value
      if (typeof round === "number" && val != null) {
        let updatedVal = val.toFixed(round);
        let newVal = updatedVal;

        if ($(this).attr("min")) {
          let minVal = Number($(this).attr("min"))
            .toFixed(round)
            .replace(",", ".");

          if (Number(minVal) > Number(updatedVal)) {
            newVal = minVal;
          }
        }

        if ($(this).attr("max")) {
          let maxVal = Number($(this).attr("max"))
            .toFixed(round)
            .replace(",", ".");

          if (Number(maxVal) < Number(updatedVal)) {
            newVal = maxVal;
          }
        }

        if (type == "input") {
          $(this).val(newVal);
        } else {
          $(this).text(newVal);
        }

        if (newVal != updatedVal) {
          $(this).change();
        }
      }
    });
  }
}
