import {parseStringToFloat} from "../../functions/parsing/parseStringToFloat.js";
import {formatDecimal} from "../../util/formatting/formatDecimal.js";

export const getTemplateFunctionObject = ({templateName}) => {
  if (templateName === "table") {
    return {
      formatDecimal,
      parseStringToFloat,
    };
  }

  return {};
};
