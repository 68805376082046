<template>
  <div v-if="show">
    <r-modal
      :id="randomID"
      :ref="modalBodyID"
      v-model="show"
      :show="show"
      :show-footer="false"
      :show-header="false"
      :size="modalSize"
      body-class="vue-generic-modal"
      @shown="afterRenderModal()"
      @hide="handleHide"
    >
      <!-- generic-body template for custom NJK styles -->
      <template #generic-body>
        <div :id="randomID">
          <div :id="modalBodyID" v-html="modalBody" />
        </div>
      </template>
    </r-modal>
  </div>
</template>

<script>
import $ from "jquery";
import serializeForm from "../../src/util/serializeForm";
import {generateID} from "../../src/util/generateID";
import vueDateTemplate from "../elements/vueDate.vue";
import RModal from "@/components/elements/RModal";
import store from "../../state/store";
import Vue from "../../src/interface/vue";
import setClearOnChangeListeners from "@/interface/listeners/modal/setClearOnChangeListeners";
import {parseBooleanAttribute} from "@/functions/parsing/parseBooleanAttribute";

export default {
  components: {
    RModal,
  },
  props: {
    modalBodyID: {
      type: String,
    },
    modalTitle: {
      type: String,
    },
    modalSize: {
      type: String,
    },
    modalBody: {
      type: String,
    },
    event: {
      type: Object,
      required: false,
      default: null,
    },
    actionName: {
      type: String,
    },
    selectionInfo: {
      type: Object,
    },
    done: {
      type: Function,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    randomID: function () {
      return generateID(5);
    },
    closeActiveModal: function () {
      return this.$store.state.closeActiveModal;
    },
  },
  watch: {
    closeActiveModal: function () {
      if (this.closeActiveModal) {
        this.closeModal();
      }
    },
  },
  async mounted() {
    this.$root.$on("bv::modal::hide", () => {
      window.session.activeWindow.toggleLoading();
    });
    await this.openModal();
    this.afterRenderModal();
  },
  created() {
    const closeModal = () => {
      this.show = false;
      window.session.activeWindow.toggleLoading();
    };

    $("body").on("click", ".modal-header [data-dismiss='modal']", function () {
      closeModal();
    });

    $("body").on("click", ".modal-footer [data-dismiss='modal']", function () {
      closeModal();
    });
    const {done} = this;
    $("body").on(
      "submit",
      `#${this.randomID} form#modalGenericSend`,
      async function sendMail() {
        let formData = serializeForm(this);

        closeModal();

        done(formData);
      },
    );
  },
  methods: {
    openModal: async function () {
      this.show = true;
    },
    handleHide() {
      this.show = false;
      window.session.activeWindow.toggleLoading();
    },
    closeModal: async function () {
      window.session.activeWindow.toggleLoading();
      this.show = false;
    },
    initiateCalendarFields: function () {
      const calendarFields = $(document).find(
        `#${this.randomID} div [data-editor-type='calendar']`,
      );

      for (const calendarField of calendarFields) {
        new Vue({
          el: `#${calendarField.id}`,
          store,

          render: (h) =>
            h(vueDateTemplate, {
              props: {
                name: $(calendarField).attr("name"),
                index: $(calendarField).attr("index"),
                providedValue: $(calendarField).attr("value"),
                filterOnOpeningsDay: $(calendarField).attr(
                  "filter-on-openings-day",
                ),
                readonly: $(calendarField).attr("readonly"),
                timePicker: $(calendarField).attr("timePicker"),
                required: parseBooleanAttribute(
                  $(calendarField).attr("isRequired"),
                ),
              },
            }),
        });
      }
    },
    afterRenderModal: function () {
      if (this.actionName === "MailReport") {
        const html = $("body")
          .find("#mailTemplateSelector")
          .find(".option.selected")
          .data("attr-body");
        $(document).find("#mailTemplate").html(html);
      }

      this.initiateCalendarFields();

      if (this.event) setClearOnChangeListeners(this.event.args.fields);
    },
  },
};
</script>
