import Hook from "@/hook";
import store from "../../../state/store";

/**
 * Set loading, get's proxied
 * @see {@link Canvas}
 * @param {boolean} isLoading - New value
 * @returns {Promise} Promise
 */
export default async function toggleLoading(isLoading) {
  await Hook.run("toggleLoading", this, () => {
    this.loading = isLoading;
    store.state.loading = isLoading;
  });
}
