import camelCase from "camelcase";
import $ from "jquery";

/**
 * Run event
 * @param {string} eventName - Event name
 * @param {Array} args - Arguments
 * @returns {Promise} Return a promise, maybe
 */
export default async function event(eventName, ...args) {
  let event = camelCase(eventName);
  try {
    // check if the event is a function
    if (typeof this[event] === "undefined") {
      console.log(
        `Event ${event} is not a function and is likely caught in a vue component`,
      );
      return;
    }

    // get what is hopefully a promise
    let maybePromise = this[event].call(this, ...args);

    // check if it is a promise
    if (maybePromise && typeof maybePromise.then === "function") {
      // then run it, with loading indication
      this.toggleLoading(true);
      maybePromise = await Promise.resolve(maybePromise);
      this.toggleLoading(false);
    }

    return maybePromise;
  } catch (err) {
    console.error(event, err);
    this.toggleLoading(false);
    $("body").removeClass("avoid-clicks");
    this.message("error", err.message || "An error occurred");
    throw err;
  }
}
