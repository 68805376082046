import {api} from "@/services/api";

export default async function postMembershipLoginVerifyTOTP({loginToken, totpCode}) {
  const result = await api.post(`/v2/login/membership/mfa/totp/verify?code=${totpCode}`, null, {
    headers: {
        Authorization: loginToken,
        NormalizeEnums: false
    },
  });

  return result;
}