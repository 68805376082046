import {getPermissionButtons} from "../../services/v2/core/getPermissionButtons";

export async function getButtonsForWindow({window}) {
  const subject = window.output.Request?.Subject;
  const prefix = window.output.Request?.Prefix;

  const {data} = await getPermissionButtons();
  const buttons = data;

  let activeButtons = [];

  buttons.map((x) => {
    if (x.FullTableName === subject && x.Prefix === prefix) {
      activeButtons.push(x);
    }
  });

  return activeButtons.sort((a, b) => a.Ranking - b.Ranking);
}
