export function getFormFieldColumn({field, window}) {
  const columns =
    window.output?.Table?.Columns ?? window.output?.FullTable?.Columns;

  if (!columns) return;

  for (const col of columns) {
    if (col?.Name === field?.name ?? field?.Name) {
      return col;
    }
  }

  return null;
}
