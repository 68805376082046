import dayjs from "dayjs";

export const parseDateTime = ({dateString}) => {
  if (dayjs(dateString).isValid()) {
    return dayjs(dateString).toISOString();
  }
  if (dayjs(dateString, "DD-MM-YYYY").isValid()) {
    return dayjs(dateString, "DD-MM-YYYY").toISOString();
  }

  return null;
};
