import {getButtonsForWindow} from "../../window/getButtonsForWindow";
import {getInboundInvoice} from "../../../services/v2/inbound/invoice/getInboundInvoice";
import {readonlyInboundInvoice} from "./readonlyInboundInvoice";

export const getInboundInvoiceItemButtons = async ({window}) => {
  const invoiceId = window.parent?.output?.Request?.Criteria?.[0]?.InvoiceID;

  if (!invoiceId) {
    return;
  }

  const invoiceResponse = await getInboundInvoice({invoiceId});

  if (
    !invoiceResponse ||
    invoiceResponse.status !== 200 ||
    !invoiceResponse.data
  ) {
    return;
  }

  const invoice = invoiceResponse.data;

  const buttonsToRemove = ["Bulk", "OpenInboundItemDatagrid", "Delete", "New"];
  if (readonlyInboundInvoice({invoice})) {
    buttonsToRemove.push("Save");
  }

  const unfilteredButtons = await getButtonsForWindow({window});

  const filteredButtons = unfilteredButtons.filter(
    (button) => !buttonsToRemove.includes(button?.ActionName),
  );

  return filteredButtons;
};
