import {api} from "@/services/api";

export default async function postResetPassword({password, code, userID}) {
  const result = await api.post(`/v2/login/membership/reset-password?code=${code}`,
  {
    userID,
    password
  }, { headers: { 'NormalizeEnums': false }});

  return result;
}