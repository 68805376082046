import equal from "deep-equal";
import $ from "jquery";

/**
 * Get selected filter item
 * @deprecated
 * @param {*} filter - Filter
 * @returns {*} Selected
 */
export default function selectedFilterItem(filter) {
  this.input.liDiFilterOptions = this.input.liDiFilterOptions || [];
  let selected = this.input.liDiFilterOptions
    .filter((f) => filter.list.filter((opt) => equal(f, opt.value)).length)
    .pop();

  if (!selected && filter.suffix) {
    selected = this.input.liDiFilterOptions
      .filter(
        (f) =>
          filter.list.filter((opt) => {
            let arg = $.extend({}, opt.value);

            for (let prop in arg) {
              if (arg.hasOwnProperty(prop)) {
                arg[prop] = arg[prop] + " " + filter.suffix;
              }
            }

            return equal(f, arg);
          }).length,
      )
      .pop();
  }

  return selected;
}
