/**
 * Get row css. Render function
 * @param {number} rowIndex - Row index
 * @returns {string} CSS information
 */
export default function getCssInfo(rowIndex) {
  let rowMeta = ((this.output.Data || {}).Metadata[rowIndex] || {})["*"] || {};

  let bg = rowMeta.BackgroundColor;
  let fc = rowMeta.Color;

  return (
    (bg != null ? "background-color: " + bg + ";" : "") +
    (fc != null ? "color: " + fc + ";" : "")
  );
}
