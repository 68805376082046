import clone from "clone";
import Window from "@/model/window";

/**
 * Load default options, output values, filters, options and renderOptions
 * @param {Array} ignore - Properties that have to be ignored
 * @returns {void}
 * @deprecated
 */
export default function loadDefaultValues(...ignore) {
  let template = new Window(this.session);

  for (let i of ["input", "output", "filters", "renderOptions", "options"]) {
    if (ignore.indexOf(i) === -1) {
      this[i] = clone(template[i]);
    }
  }
}
