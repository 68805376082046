<template>
  <button
    v-if="!window.options || (window.options.noReset == false && !bulkedit)"
    data-window-event="reset"
    :data-window-target="window.id"
    class="btn-link text-dark btn-icon mt-1 mr-2"
  >
    <span class="float-left">
      <i class="fas fa-sync-alt"></i>
    </span>
  </button>
</template>

<script>
export default {
  name: "CanvasResetButton",
  props: {
    window: {
      type: Object,
      required: true,
    },
    bulkedit: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped></style>
