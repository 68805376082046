/**
 * Open tab in parent
 * @param {*} tabId - Tab ID
 *  @returns {Promise} Promise to open tab in parent
 */
export default async function opentabparent(tabId) {
  // Do not open ourself
  if (this.parent.sub.Id === tabId) {
    return;
  }

  // open tab
  await this.parent.opentab(tabId);
}
