import {getTableColor} from "@/services/v2/core/getTableColor";
import {setPeristedColorRules} from "../session/localstorage/setPeristedColorRules.js";

export async function fetchAndSetColorRules() {
  try {
    const {data} = await getTableColor();

    const colorRules = data.Collection;

    if (colorRules.length) {
      setPeristedColorRules({colorRules});
    }
  } catch {
    // ignore
  }
}
