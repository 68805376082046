export const readonlyInboundInvoice = ({invoice}) => {
  /*
   *     Submitted	100
   *     Incomplete	200
   *     Accepted	300
   *     Approved	400
   *     Processed	500
   *     TurnedDown	900
   *     Cancelled	999
   */
  if (!invoice || !invoice.StatusCode) {
    return true;
  }

  return ["500", "900", "999"].includes(invoice.StatusCode);
};
