import shortid from "shortid";
import Treeview from "../model/treeview";
import formatter from "../model/formatter";

/**
 * Combobox logic class
 * @property {Specification} specification - Combobox specification
 * @property {Object} cell - Cell reference
 * @property {string} text - Displayed text
 * @property {string} value - Combobox value
 * @property {string} valueList - Combobox valueList for multi-selectors
 * @property {string} oldText - Previous displayed text
 * @property {string} oldValue - Previous combobox value
 * @property {string} oldValueList - Previous valueList for multi-selectors
 * @property {string} startingText - Initial displayed text
 * @property {string} startingValue - Initial combobox value
 * @property {Item[]} items - Array of items
 * @property {tree} tree - Treeview for treeview combobox
 * @property {element} el - HTML element reference
 * @property {string} id - Combobox id
 *
 * @property {Object} pool - Reference of all Combobox classes
 */
export default class Combobox {
  specification = new Specification();
  cell = null;

  text = "";
  value = "";
  valueList = {};

  oldText = "";
  oldValue = null;
  oldValueList = {};

  startingText = null;
  startingValue = null;

  items = [];
  tree = [];
  el = null;
  id = null;

  static pool = {};

  /**
   * Create new, proxy for new Combobox(args)
   * @param {Array} args - Arguments(cell, specification)
   * @return {Combobox} new Combobox
   */
  static new(...args) {
    let newCombo = new Combobox(...args);
    return newCombo;
  }

  /**
   * @param {Object} cell - Related cell
   * @param {Specification} specification - Combobox specification
   */
  constructor(cell, specification) {
    this.id = shortid.generate();
    this.cell = cell || null;
    if (this.cell) {
      this.buildFromCell();
    }

    if (specification) {
      this.specification.override(specification);
    }

    // add to pool
    Combobox.pool[this.id] = this;
    // return this
  }

  /**
   * Set all initial values
   * @param {string} text - Displayed text value
   * @param {string | null} value - Actual value
   * @returns {Combobox} self reference
   */
  setInitialValues(text = "", value) {
    if (formatter.isJson(value)) {
      this.valueList = JSON.parse(value);
      this.text = formatter.joinDescription(this.valueList);
      this.value = formatter.joinKeys(this.valueList);
    } else {
      if (text === null) this.text = "";
      this.text = String(text).trim() ?? "";
      this.value = value || null;

      if (value && text) {
        if (Object.prototype.hasOwnProperty.call(this.valueList, value)) {
          delete this.valueList[value];
        } else {
          if (text.trim == null) {
            this.valueList[value] = {description: text};
          } else {
            this.valueList[value] = {description: text?.trim() ?? ""};
          }
        }
      }
    }

    this.startingText = this.text;
    this.startingValue = this.value;
    this.oldText = this.text;
    this.oldValue = this.value;
    this.oldValueList = this.valueList;
    return this;
  }

  /**
   * Get the color white on black that works best with the provided color
   * This is used for making a good contrast on backgrounds
   * @param {string} bgColor Hexadecimal string
   * @returns {string} Either #000 or #fff (Black or White)
   */
  getColorByBgColor(bgColor) {
    if (!bgColor) {
      return "";
    }
    return parseInt(bgColor.replace("#", ""), 16) > 0xffffff / 2
      ? "#000"
      : "#fff";
  }

  /**
   * Set items
   * @param {Item[]} items - List of items
   * @returns {Combobox} self reference
   */
  setItems(items) {
    this.items = items || [];
    this.parseItems();
    return this;
  }

  /**
   * Parse items, create TreeviewItem or Item
   * @returns {void}
   */
  parseItems() {
    let _class = this.type == "treeview" ? TreeviewItem : Item;
    this.items = this.items.map((i) => new _class(i));
  }

  /**
   * Build tree from items
   * @returns {void}
   */
  buildTree() {
    let parentid = this.specification.treeviewParentProperty;
    let id = this.specification.treeviewCustomID;
    this.items.forEach((item) => {
      item.parentId =
        item[parentid] ||
        (item && item.attributes && item.attributes[parentid]) ||
        null;
    });
    this.tree = Treeview.fromRows(this.items, "parentId", id);
    Treeview.sort(this.tree, "value");
  }

  /**
   * If combobox has a cell reference, create specification and items from cell
   * @returns {Combobox} self reference
   */
  buildFromCell() {
    let cell = this.cell;
    let col = cell.Column;
    let initial = cell.Initial || col.initial;
    this.text = cell.Value || "";

    if (col.window && col.window.output.Request.Prefix === "New") {
      this.text = "";
    }

    this.value = this.text;
    if (initial && initial.Description != null) {
      this.text = initial.Description;
      this.value = initial.Value || this.value;
    } else if (col.Dropdown && col.Dropdown.Items) {
      this.text = col.Dropdown.Items.filter((x) => x.Value == this.text)
        .map((x) => x.Text)
        .pop();
    } else if (
      initial &&
      initial.Value != null &&
      formatter.isJson(initial.Value)
    ) {
      this.text = initial.Value;
      this.value = initial.Value || this.value;
    }

    this.setInitialValues(this.text, this.value);

    let spec = {
      name: col.Name,
      readOnly: col.IsReadOnly,
      nullable: !col.IsRequired,
      type: col.Editor == "tree-selector" ? "treeview" : col.Editor || "list",
    };

    if (spec.type == "multi-selector") {
      spec.openRef = false;
      spec.freeType = true;
    }

    if (col.Dropdown) {
      let items = col.Dropdown.Items || [];
      // this.populate(items.map(x => ({ Text: x.Value, Value: x.Key })))
      this.populate(items);
      spec.tableName = col.Dropdown.TableName;
      spec.columnName = col.Dropdown.ColumnName;
      spec.openRef = col.Dropdown.OpenRef;
    }

    this.specification.override(spec);
    return this;
  }
  /**
   * Clear items list
   * @returns {void}
   */
  clearItems() {
    this.items = [];
  }

  /**
   * Populate items list
   * @param {Array | Object} arrOrObj - Array or object of items
   * @returns {Combobox} self reference
   */
  populate(arrOrObj) {
    if (typeof arrOrObj !== "object") {
      return this;
    }

    if (Array.isArray(arrOrObj)) {
      arrOrObj.forEach((i) => {
        let isobj = typeof i === "object";
        this.items.push({
          value: isobj ? i.Value : i,
          text: isobj ? i.Text : i,
          attributes: isobj ? i.Attributes : null,
          checked: Object.prototype.hasOwnProperty.call(
            this.oldValueList,
            isobj ? i.Value : i,
          ),
          enabled: i.Enabled,
        });
      });
    } else {
      for (let value in arrOrObj) {
        this.items.push({
          value: value,
          text: arrOrObj[value],
          checked: Object.prototype.hasOwnProperty.call(
            this.oldValueList,
            value,
          ),
          enabled: true,
          // attributes: {},
        });
      }
    }

    this.parseItems();

    if (this.specification.type == "treeview") {
      this.buildTree();
    }

    return this;
  }

  /**
   * Delete self
   * @returns {void}
   */
  remove() {
    delete Combobox.pool[this.id];
  }

  /**
   * If option exists with given values
   * @param {string} searchText - Text value
   * @param {string} searchVal - Value
   * @returns {boolean} If option exists
   */
  optionExist(searchText, searchVal) {
    let exists = false;
    let useText = typeof searchText === "string";
    let useVal = typeof searchVal === "string";

    this.items.forEach((i) => {
      if (exists) {
        return;
      }

      if (useText && i.text == searchText) {
        exists = true;
        return;
      }

      if (useVal && i.value == searchVal) {
        exists = true;
        return;
      }
    });

    return exists;
  }

  /**
   * Set text value by finding item with a given value
   * @param {string} val - Value
   * @returns {void}
   */
  setTextByValue(val) {
    if (!this.items.length) {
      return;
    }

    let item = this.items.filter((i) => i.value == val).pop() || null;

    if (item) {
      this.setInitialValues(item.text, item.value);
    }
    return;
  }
}

/**
 * Specification class
 * @private
 * @property {boolean} readOnly - Disabled or not
 * @property {boolean} fixedItems - If there are items that could be fetched
 * @property {boolean} freeType - Whether items are suggestions or options
 * @property {string} type - Type (list | treeview)
 * @property {string} tableName - String defining foreign table or custom
 * @property {string} columnName - Column name
 * @property {boolean} openRef - If possible to open reference
 * @property {boolean} reference - Is a reference to a other column
 * @property {*} filter - Filter
 * @property {*} lazyLoading - lazyLoading, items are reloaded on key-up and down
 * @property {*} primaries - Primaries
 * @property {string} name - Input name for jquery
 * @property {boolean} nullable - Allow null value
 * @property {number} rowCount - Row count
 * @property {string} treeviewParentProperty - Treeview parent property @link{Treeview}
 * @property {string} treeviewCustomID - Treeview custom link @link{Treeview}
 *
 * @property {string[]} types - List of possible types. Default = "list"
 */
class Specification {
  static types = ["list", "treeview", "multi-selector"];

  readOnly = false;
  fixedItems = false;
  freeType = false;
  type = "list";
  tableName = null;
  columnName = null;
  openRef = true;
  // fetchAttributes = {}
  reference = false;
  filter = null;
  lazyLoading = false;
  primaries = null;
  name = "";
  nullable = false;
  rowCount = 10;
  extraKeys = [];
  passthroughValues = {}; // Values that are passed to the server (implemented for modal forms (They have no window))

  treeviewParentProperty = null;
  treeviewCustomID = "value";

  /**
   * Override current properties
   * @param {Object} obj - Object containing properties
   * @returns {void}
   */
  override(obj) {
    if (!obj) {
      return;
    }

    for (let i in obj) {
      this[i] = obj[i];
    }

    this.fixedItems = !this.tableName;

    if (Specification.types.indexOf(this.type) === -1) {
      this.type = Specification.types[0];
    }
  }
}

/**
 * Combobox item class
 * @property {*} value - Value
 * @property {string} text - Text value
 * @property {Object} attributes - Extra attributes
 */
class Item {
  value = null;
  text = "";
  attributes = {};

  /**
   * @param {Object} obj - Values
   */
  constructor(obj) {
    this.override(obj);
  }

  /**
   * Override current properties
   * @param {Object} obj - Object containing properties
   * @returns {void}
   */
  override(obj) {
    if (!obj) {
      return;
    }

    for (let i in obj) {
      i = i.toLowerCase();
      this[i] = obj[i];
    }
  }
}

/**
 * Treeview class
 * @property {string} parentId - Parent id
 * @extends {Item}
 * @private
 */
class TreeviewItem extends Item {
  parentId = "";
}
