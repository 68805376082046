export const updateWindowState = ({window, commit}) => {
  window.loading = false;
  commit("refreshTabs");
  commit("updateWindow");

  if (!window.element) {
    console.error("Missing window element, skipping element styling updates");
    return;
  }
  $(window.element).css("pointer-events", "auto").css("opacity", "1");
  $(window.sub ? window.sub.window.element : window.element).removeClass(
    "dirty",
  );
};
