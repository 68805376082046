/**
 * Opens help window
 * @returns {void}
 */
export default function help() {
  if (!this.output || !this.output.Options || !this.output.Options.HelpUrl) {
    this.message("warning", this.session.translations.NoHelpAvailable);
    return;
  }

  global.window.open(
    this.output.Options.HelpUrl.replace(
      "[Session.productDomain]",
      this.session.productDomain,
    ),
  );
}
