export const handleLegacySelectfieldAfterPostbackFocus = async ({
  fieldName,
}) => {
  const fieldInputElement = document.querySelector(`[name="${fieldName}"]`);

  const parentElement = fieldInputElement.parentElement;
  const rSelectInputElement = parentElement.querySelector(".vs__search");
  rSelectInputElement.focus();

  await delay(1);

  const escEvent = new KeyboardEvent("keydown", {
    key: "Escape",
    keyCode: 27,
    which: 27,
    bubbles: true,
    cancelable: true,
  });
  const focusedElement = document.activeElement;
  focusedElement
    ? focusedElement.dispatchEvent(escEvent)
    : document.dispatchEvent(escEvent);
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
