
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/elements/plusminusinput.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["col", "columnIndex", "index", "colval", "minNumber", "maxNumber", "classes", "name"], 
[], 
function (l_col, l_columnIndex, l_index, l_colval, l_minNumber, l_maxNumber, l_classes, l_name, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("col", l_col);
frame.set("columnIndex", l_columnIndex);
frame.set("index", l_index);
frame.set("colval", l_colval);
frame.set("minNumber", l_minNumber);
frame.set("maxNumber", l_maxNumber);
frame.set("classes", l_classes);
frame.set("name", l_name);
var t_2 = "";t_2 += "\n\t<div class=\"plusminusinput ";
t_2 += runtime.suppressValue(l_classes, env.opts.autoescape);
t_2 += "\" >\n    <button class=\"minus btn btn-sm\"><i class=\"fas fa-minus\"></i></button>\n      <input type=\"number\"\n        class=\"plusminusinput-input number Decimal\"\n        data-field-index=\"";
t_2 += runtime.suppressValue(l_index, env.opts.autoescape);
t_2 += "\"\n        value=\"";
t_2 += runtime.suppressValue(l_colval, env.opts.autoescape);
t_2 += "\"\n        ";
if(l_minNumber != null) {
t_2 += " \n          min=\"";
t_2 += runtime.suppressValue(l_minNumber, env.opts.autoescape);
t_2 += "\"\n        ";
;
}
t_2 += "\n        ";
if(l_maxNumber != null) {
t_2 += " \n          max=\"";
t_2 += runtime.suppressValue(l_maxNumber, env.opts.autoescape);
t_2 += "\"\n        ";
;
}
t_2 += "\n        ";
if(runtime.memberLookup((l_col),"MinNumber") != null) {
t_2 += " \n          min=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_col),"MinNumber"), env.opts.autoescape);
t_2 += "\"\n        ";
;
}
t_2 += "\n        ";
if(runtime.memberLookup((l_col),"MaxNumber") != null) {
t_2 += " \n          max=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_col),"MaxNumber"), env.opts.autoescape);
t_2 += "\"\n        ";
;
}
t_2 += "\n        ";
if(runtime.memberLookup((l_col),"Name") != null) {
t_2 += " \n          name=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_col),"Name"), env.opts.autoescape);
t_2 += "\"\n        ";
;
}
t_2 += "/>\n    <button class=\"plus btn btn-sm\"><i class=\"fas fa-plus\"></i></button>\n  </div>";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("constructPlusMinusInput");
context.setVariable("constructPlusMinusInput", macro_t_1);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/elements/plusminusinput.html", ctx);
          }
        
          return nunjucks.render("views/elements/plusminusinput.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/elements/plusminusinput.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        