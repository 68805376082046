/**
 * Insert sub, get's proxied to insertWindow
 * @param {Object} output - Window output
 * @param {boolean} noRender - should we render the window?
 * @param {boolean} noProcessing - Should we process the output?
 * @returns {Promise} Promise
 * DEPRECATED
 */
export default function injectSub(
  output,
  noRender = false,
  noProcessing = false,
  mustConfirm = true,
) {
  return this.insertWindow(output, true, noRender, noProcessing, mustConfirm);
}
