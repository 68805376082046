import {api} from "@/services/api";

export async function getTranslations({
  languageId,
}) {
  const result = await api.get(`/v2/translations`, {
    params: {
      languageId,
    },
  });

  return result;
}
