import {api} from "@/services/api";
import {getDataWebhooksNormalizeApiData} from "./getDataWebhooksNormalizeApiData";

export const getDataWebhooks = async ({event} = {}) => {
  const response = await api.get("/v2/core/datawebhooks", {
    params: {
      EventID: event,
    },
  });
  return getDataWebhooksNormalizeApiData({response});
};
