
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/dashboard.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"DashboardActive");
frame.set("activeTranslation", t_1, true);
if(frame.topLevel) {
context.setVariable("activeTranslation", t_1);
}
if(frame.topLevel) {
context.addExport("activeTranslation", t_1);
}
output += "\n\n";
var t_2;
t_2 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"User");
frame.set("usersTranslation", t_2, true);
if(frame.topLevel) {
context.setVariable("usersTranslation", t_2);
}
if(frame.topLevel) {
context.addExport("usersTranslation", t_2);
}
output += "\n\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"UsersCount") > 1) {
output += "\n    ";
var t_3;
t_3 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Users");
frame.set("usersTranslation", t_3, true);
if(frame.topLevel) {
context.setVariable("usersTranslation", t_3);
}
if(frame.topLevel) {
context.addExport("usersTranslation", t_3);
}
output += "\n";
;
}
output += "\n\n";
var t_4;
t_4 = runtime.contextOrFrameLookup(context, frame, "activeTranslation") + " " + runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"User");
frame.set("activeUsersTranslation", t_4, true);
if(frame.topLevel) {
context.setVariable("activeUsersTranslation", t_4);
}
if(frame.topLevel) {
context.addExport("activeUsersTranslation", t_4);
}
output += "\n\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"UsersOnlineCount") > 1) {
output += "\n    ";
var t_5;
t_5 = runtime.contextOrFrameLookup(context, frame, "activeTranslation") + " " + runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Users");
frame.set("activeUsersTranslation", t_5, true);
if(frame.topLevel) {
context.setVariable("activeUsersTranslation", t_5);
}
if(frame.topLevel) {
context.addExport("activeUsersTranslation", t_5);
}
output += "\n";
;
}
output += "\n\n";
var t_6;
t_6 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Customer");
frame.set("customersTranslation", t_6, true);
if(frame.topLevel) {
context.setVariable("customersTranslation", t_6);
}
if(frame.topLevel) {
context.addExport("customersTranslation", t_6);
}
output += "\n\n";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"ActiveCustomersCount") > 1) {
output += "\n    ";
var t_7;
t_7 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"Customers");
frame.set("customersTranslation", t_7, true);
if(frame.topLevel) {
context.setVariable("customersTranslation", t_7);
}
if(frame.topLevel) {
context.addExport("customersTranslation", t_7);
}
output += "\n";
;
}
output += "\n\n\n<div class=\"container-fluid\">\n    <div class=\"page-header mt-4\">\n        <h1>Dashboard</h1>\n        <hr>\n    </div>\n    <div class=\"row\">\n        <div class=\"col col-12 col-sm-6 col-md-4 mb-4\">\n            <div class=\"card card-default\" data-menu-id=\"211\" style=\"cursor: pointer;\">\n                <div class=\"card-body bg-secondary\">\n                    <i class=\"fa fa-users fa-5x\" aria-hidden=\"true\"></i>\n                    <h3 class=\"pull-right\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"UsersCount"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "usersTranslation"), env.opts.autoescape);
output += "</h3>\n                </div>\n            </div>\n        </div>\n        <div class=\"col col-12 col-sm-6 col-md-4 mb-4\">\n            <div class=\"card card-primary bg-primary text-white\">\n                <div class=\"card-body \">\n                    <div class=\"row\">\n                        <div class=\"col col-xs-3\">\n                            <i class=\"fa fa-user fa-5x\" aria-hidden=\"true\"></i>\n                        </div>\n                        <div class=\"col col-xs-9\">\n                            <h3 class=\"text-right\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"UsersOnlineCount"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "activeUsersTranslation"), env.opts.autoescape);
output += "</h3>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"col col-12 col-sm-6 col-md-4 mb-4\">\n            <div class=\"card card-default\" data-menu-id=\"121\" style=\"cursor: pointer;\">\n                <div class=\"card-body bg-secondary\">\n                    <div class=\"row\">\n                        <div class=\"col col-xs-3\">\n                            <i class=\"fa fa-cube fa-5x\" aria-hidden=\"true\"></i>\n                        </div>\n                        <div class=\"col col-xs-9\">\n                            <h3 class=\"text-right\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"DesiredDeliveryCount"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"PlanningDesiredDelivery"), env.opts.autoescape);
output += "</h3>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"col col-12 col-sm-6 col-md-4 mb-4\">\n            <div class=\"card card-primary bg-primary text-white\" data-menu-id=\"122\" style=\"cursor: pointer;\">\n                <div class=\"card-body\">\n                    <div class=\"row\">\n                        <div class=\"col col-xs-3\">\n                            <i class=\"fa fa-cube fa-5x\" aria-hidden=\"true\"></i>\n                        </div>\n                        <div class=\"col col-xs-9\">\n                            <h3 class=\"text-right\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"ExpectedItemsCount"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"session")),"translations")),"PlanningExpectedItems"), env.opts.autoescape);
output += "</h3>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"col col-12 col-sm-6 col-md-4 mb-4\">\n            <div class=\"card card-default\" data-menu-id=\"311\" style=\"cursor: pointer;\">\n                <div class=\"card-body bg-secondary\">\n                    <div class=\"row\">\n                        <div class=\"col col-xs-3\">\n                            <i class=\"fa fa-cubes fa-5x\" aria-hidden=\"true\"></i>\n                        </div>\n                        <div class=\"col col-xs-9\">\n                            <h3 class=\"text-right\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "window")),"output")),"Data")),"ActiveCustomersCount"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "customersTranslation"), env.opts.autoescape);
output += "</h3>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/dashboard.html", ctx);
          }
        
          return nunjucks.render("views/content/dashboard.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/dashboard.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        