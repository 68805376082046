import store from "../../state/store.js";

export const shouldPlaySound = async ({sound}) => {
  const settings = store.state.settings;

  return (
    settings[
      `Sound${sound.charAt(0).toUpperCase() + sound.substring(1)}Enabled`
    ] === true
  );
};
