import equal from "deep-equal";

/**
 * Select a row
 * @param {number} rowIndex - Index of row
 * @param {boolean} checked - If the row is selected
 * @returns {void}
 */
export default function selectRow(rowIndex, checked) {
  if (
    this.output == null ||
    this.output.Data == null ||
    (this.output.Data.Type != "table" &&
      this.output.Data.Type != "upload" &&
      this.output.Data.Type != "quickeditform")
  ) {
    return;
  }

  // Get criteria
  let primaryKeys = this.buildCriteriaNew(this.output.FullTable.Rows[rowIndex]);

  // Ignore if all primaryKeys are null
  if (Object.values(primaryKeys).every((x) => x === null)) {
    return;
  }

  if (checked) {
    if (
      this.selection.filter((existing) => equal(existing, primaryKeys))
        .length == 0
    ) {
      // append criteria
      this.selection.push(primaryKeys);
    }
  } else {
    // remove critiria
    this.selection = this.selection.filter(
      (existing) => !equal(existing, primaryKeys),
    );
  }
}
