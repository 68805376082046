
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/snippet/notification.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div role=\"alert\" >\n    <div class=\"row no-gutters shadow-md rounded-md sleek-notification\" data-notify=\"dismiss\">\n        <div class=\"sleek-notification-icon  col-3 text-center align-items-center flex content-center justify-center\">\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "type") === "success") {
output += "\n                <div class=\"logo-icon pl-0 pr-0 pt-1 text-success d-block\"><i class=\"fa fa-check\"></i></div>\n            ";
;
}
output += "\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "type") === "warning") {
output += "\n                <div class=\"logo-icon pl-0 pr-0 pt-1 text-warning d-block\"><i class=\"fas fa-exclamation-triangle\"></i></div>\n            ";
;
}
output += "\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "type") === "danger" || runtime.contextOrFrameLookup(context, frame, "type") === "error") {
output += "\n                <div class=\"logo-icon pl-0 pr-0 pt-1 text-danger d-block\"><i class=\"fas fa-times-circle\"></i></div>\n            ";
;
}
output += "\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "type") === "info") {
output += "\n                <span class=\"logo-icon pl-0 pr-0 infodatek-icon infodatek-icon-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.contextOrFrameLookup(context, frame, "product")), env.opts.autoescape);
output += " d-block\"></span>\n            ";
;
}
output += "\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "type") === "primary") {
output += "\n                <span class=\"logo-icon pl-0 pr-0 infodatek-icon infodatek-icon-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.contextOrFrameLookup(context, frame, "product")), env.opts.autoescape);
output += " d-block\" ></span>\n            ";
;
}
output += "\n\n        </div>\n        <div class=\"sleek-notification-body col-9\">\n            <a href=\"#closeNotification\" class=\"float-right pr-2 pt-1 no\" data-notify=\"dismiss\"><i class=\"far fa-times\"></i></a>\n            <div class=\"sleek-notification-body-text mr-4\">{2}</div>\n        </div>\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/snippet/notification.html", ctx);
          }
        
          return nunjucks.render("views/snippet/notification.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/snippet/notification.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        