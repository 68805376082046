<template>
  <div class="flex flex-wrap mx-[-15px]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "RRow",
};
</script>
