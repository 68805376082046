import store from "../../../state/store";
import dayjs from "dayjs";
import {initializeClientMetadata} from "@/functions/login/initializeClientMetadata";
import {getPersistedWarehouse} from "@/functions/session/localstorage/getPersistedWarehouse";
import Combobox from "@/interface/combobox.class";
import {setSettings} from "@/functions/settings/setSettings";
import arrayToTree from "array-to-tree";
import {generateMenu} from "../menu/generateMenu.js";
import {fetchAndSaveRegisteredDataWebhooks} from "./localstorage/fetchAndSaveRegisteredDataWebhooks.js";

export const login = async ({session, result, serviceName}) => {
  fetchAndSaveRegisteredDataWebhooks();

  store.commit("user/setUserSettings", result.UserSettings);

  dayjs.locale(session.language);

  initializeClientMetadata({languageId: session.language});
  global.window.rentMagicVue.$children[0].setTranslations();

  const warehouses = [];
  for (const warehouse of result.Comboboxes[0].values) {
    warehouses.indexOf(warehouse) === -1 && warehouses.push(warehouse.Value);
  }

  store.commit("setWarehouses", {
    warehouses,
  });

  if (store.state.activeWarehouse === null) {
    const userId = result.UserId;
    const peristedWarehouse = getPersistedWarehouse({userId});
    const selectedWarehouse =
      peristedWarehouse ?? result.Comboboxes[0].selectedValue;

    store.commit("setActiveWarehouse", {
      warehouse: selectedWarehouse,
    });
  }

  // log user in special case
  if (serviceName == "GetWelcomeInfo" && result.UserName == null) {
    session.canRegister = result.CanRegister;
    session.googleSSO = result.GoogleSSO;
    session.twoFactorAuthentication = result.TwoStepRequired;

    throw new Error("Not logged in yet");
  }
  store.dispatch("user/fetchUserData");
  store.dispatch("tables/fetchTables");

  // Show message
  if (result.Message != null) {
    // alert(result.Message)
    session.licenseExpirationMessage = result.Message;
  }

  // Load values
  session.switchLanguage(result.LanguageID, true);
  session.username = result.UserName;
  session.shortcuts = result.Shortcuts;
  session.scanbox = Combobox.new(null, {
    name: "Scanbox",
    tableName: "Core.virtual_Scanbox",
    columnName: "Value",
    openRef: false,
    lazyLoading: true,
  });

  session.profile = result.Profile;
  session.settings = result.SettingsValues;
  session.username = result.UserName;
  session.canViewSettingsMenu = result.CanViewSettingsMenu;

  session.defaultCurrencyId = result.DefaultCurrencyID ?? "EUR (€)";

  await setSettings();

  store.commit("setShortcuts", {shortcuts: result.Shortcuts});

  store.commit("setScanbox", session.scanbox);

  for (let combobox of result.Comboboxes) {
    if (combobox.id != null) {
      let selectedText = combobox.selectedValue;

      // set selected value
      if (combobox.selectedValue != null) {
        if (session.comboboxes.selectedValues == null) {
          session.comboboxes.selectedValues = {};
        }

        if (session.comboboxes.selectedValues[combobox.id] == null) {
          session.comboboxes.selectedValues[combobox.id] =
            combobox.selectedValue;
        } else {
          combobox.selectedValue =
            session.comboboxes.selectedValues[combobox.id];
        }
      }

      // create combobox containing available values
      if (combobox.values != null) {
        if (session.comboboxes.Combobox == null) {
          session.comboboxes.Combobox = {};
        }

        session.comboboxes.Combobox[combobox.id] = Combobox.new(null, null);
        session.comboboxes.Combobox[combobox.id].populate(combobox.values);

        // Gets the current selected text out of the selected combobox
        let currentCombobox = combobox;
        combobox.values.every(function (element) {
          if (element.Value == currentCombobox.selectedValue) {
            selectedText = element.Text;
            return false; // breaks the loop
          } else {
            return true; // continue
          }
        });

        session.comboboxes.Combobox[combobox.id].setInitialValues(
          selectedText,
          combobox.selectedValue,
        );
      }
    }
  }

  store.commit("setHeaderComboboxes", session.comboboxes);

  // create menu and set it to session
  session.moduleMenu = generateMenu({
    menu: result.Menu,
    session,
    arrayToTreeFn: arrayToTree,
  });

  // set more values
  session.fullscreen = session.profile.UseFullScreen.Value === true;

  session.locked = false;
  session.active = true;

  // Get user data from Rest API
  session.getUserData().then((data) => {
    session.currentUser = data;
    store.state.currentUser = data;
  });

  return {warehouses};
};
