<template>
  <div :class="`field field-${fieldType}`">
    <label v-if="!field.IsReadOnly">{{ field.Title }}:</label>
    <component
      :is="fieldComponent"
      class="field-content"
      :field="field"
      :fields="fields"
    >
    </component>
  </div>
</template>

<script>
import {capitalizeFirstCharInString} from "../../../src/interface/capitalizeFirstCharInString.js";

export default {
  props: {
    field: {
      required: true,
      type: Object,
    },
    fields: {
      required: true,
      type: Array,
    },
  },
  computed: {
    fieldType() {
      const fieldName = this.field.Editor ?? this.field.Type;
      return `${capitalizeFirstCharInString({string: fieldName})}${
        this.field.IsReadOnly ? "ReadOnly" : ""
      }`;
    },
    fieldComponent() {
      return () => import(`./Field${this.fieldType}.vue`);
    },
  },
};
</script>

<style scoped lang="scss">
.field-LabelSelector {
  border-top: 1px solid #eeeeee;
  padding-top: 10px;
}
.field {
  padding: 8px 0px;
  text-align: right;

  .field-content {
    margin-left: 5px;
  }

  label {
    font-weight: 900;
    color: #626262;
  }
}
</style>
