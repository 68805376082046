/**
 * Checks if it is related to the given tablename
 * @param {string} tablename - Table name
 * @returns {boolean} True if related
 */
export default function isRelated(tablename) {
  if (!tablename || (this.parent && this.parent.waitingForReload)) {
    return false;
  }

  return (
    (typeof tablename === "string" &&
      this.output.Request.Subject === tablename) ||
    (typeof tablename === "object" &&
      tablename.indexof(this.output.Request.Subject) > -1) ||
    (typeof tablename === "string" &&
      this.output.Request.LinkedSubject &&
      this.output.Request.LinkedSubject === tablename) ||
    (typeof tablename === "object" &&
      this.output.Request.LinkedSubject &&
      tablename.indexof(this.output.Request.LinkedSubject) > -1)
  );
}
