// GET /api/v2/serials/{serialID}
import {api} from "../api";
import store from "../../../state/store";

export async function getSerial({serialId}) {
  const result = await api.get(`/v2/serials/${serialId}`);

  if (result.data?.GlobalActions) {
    for (const action of result.data.GlobalActions) {
      const importedModule = await import(
        `../../actions/${action.Function}.js`
      );
      importedModule.default(action.Data);
    }
  }

  const {windowId} = result.config;
  const windowType = store.getters["windows/windowType"](windowId);

  if (windowId && windowType && result.data?.ComponentActions?.length) {
    // loop over every action in result.ComponentActions
    result.data?.ComponentActions.forEach((action) => {
      // call function updateRow
      store.dispatch(`${windowType}/${action.Function}`, {
        data: action.Data,
        windowId,
      });
    });
  }

  if (result.data?.IsActionResponse) {
    global.session.activeWindow.handleActionResponse(result.data);
  }

  return result.data;
}
