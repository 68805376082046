import {api} from "./api";
import store from "../../state/store";

export async function getGuides({subject, criteria}) {
  const result = await api.post(
    `/v2/guide/subject`,
    {...criteria},
    {
      params: {
        subject: subject,
        Warehouse: store.state.activeWarehouse,
      },
    },
  );

  return result.data;
}

export async function getGlobalGuides() {
  const result = await api.get(`/v2/guide/global`, {
    params: {
      Warehouse: store.state.activeWarehouse,
    },
  });

  return result;
}
