export const getChangedFormValueKeys = ({newRow, oldRow}) => {
  const changedFieldNames = [];
  for (const field in newRow) {
    if (
      newRow[field] !== oldRow[field] &&
      !(newRow[field] === null && oldRow[field] === undefined)
    ) {
      changedFieldNames.push(field);
    }
  }

  return changedFieldNames;
};
