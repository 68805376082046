<template>
  <div>{{ title }}</div>
</template>

<script>
export default {
  computed: {
    title() {
      const href = window.location.href;

      if (href.includes("test")) {
        return "TEST";
      }

      if (href.includes("localhost")) {
        return "DEVELOPMENT";
      }

      return "";
    },
  },
};
</script>

<style scoped>
div {
  position: fixed;
  bottom: -2px;
  left: 60px;
  font-weight: 900;
  font-size: 39.60px;
  z-index: 1200;
  color: #dc3545;
  opacity: 0.5;
  pointer-events: none;
  min-width: 290px;
}
</style>
