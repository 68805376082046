import {datesAreEqual} from "../date/datesAreEqual.js";

export const formRowIsDirty = ({originalRow, newRow}) => {
  const originalValues = originalRow.map((cell) => {
    if (
      typeof cell.Value === "object" &&
      cell.Value !== null &&
      cell.Column.Name === "Label"
    ) {
      return Object.keys(cell.Value)[0];
    }
    return cell.Value;
  });

  const newValues = newRow.map((cell) => {
    const value = cell.NewValue;
    if (
      typeof value === "object" &&
      value !== null &&
      cell.Column.Name === "Label"
    ) {
      return Object.keys(value)[0];
    }
    return value;
  });

  return rowIsDirty(newValues, originalValues, originalRow);
};

const rowIsDirty = (newValues, originalValues, originalRow) => {
  const exemptedColumns = ["DaysCalculated"];

  for (const value in originalValues) {
    if (exemptedColumns.includes(originalRow[value].Column.Name)) {
      continue;
    }

    if (
      originalRow[value]?.Column?.Type === "Date" &&
      !datesAreEqual({date1: originalValues[value], date2: newValues[value]})
    ) {
      return true;
    }

    if (
      (originalValues[value] === null && newValues[value] === false) ||
      (originalValues[value] === false && newValues[value] === null)
    ) {
      continue;
    }

    if (originalValues[value] !== newValues[value]) {
      return true;
    }
  }

  return false;
};
