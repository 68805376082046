import {getAudioSrc} from "./getAudioSrc.js";
import {shouldPlaySound} from "./shouldPlaySound.js";

export const playBehaviorSound = async ({sound}) => {
  if (!(await shouldPlaySound({sound}))) return;
  const audioSrc = getAudioSrc({sound});

  const audio = new Audio(audioSrc);
  audio.play();
};
