<template>
  <div class="w-100">
    <div class="select-scan-label">
      <i
        v-if="option.type"
        :class="{
          'fas fa-tag pl-1': option.type === 'ItemSerial',
          'fas fa-cube': option.type === 'Item',
          'fas fa-cubes': option.type === 'Composition',
        }"
      ></i>
      <span
        :class="{
          italic: option.type == 'Composition',
          'pl-1 serial': option.type == 'ItemSerial',
        }"
        v-html="highlightSearchCharacters(option.label)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    searchValue: {
      required: true,
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      iconTypes: {
        ItemSerial: "fas fa-tag pl-1",
        Item: "fas fa-cube",
        Composition: "fas fa-cubes",
      },
    };
  },
  methods: {
    getIconClassByType: function (type) {
      return this.iconTypes[type] ?? "";
    },
    highlightSearchCharacters: function (text) {
      return text.replace(
        this.searchValue,
        `<strong>${this.searchValue}</strong>`,
      );
    },
  },
};
</script>

<style lang="scss">
.select-scan-label {
  display: grid;
  grid-template-columns: 20px 1fr;

  .serial {
    font-size: 14.30px;
  }
}
.italic {
  font-style: italic;
}
</style>
