export const toggleLoadingOnLegacyFormFieldDropdowns = ({
  vueColumns,
  value,
}) => {
  for (const column in vueColumns) {
    if (vueColumns[column].$children[0].field?.editor === "Select") {
      vueColumns[column].$children[0].$children[0].$children[0].loading = value;
    }
  }
};
