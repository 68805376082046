import $ from "jquery";
import templater from "@/interface/templater";

/**
 * Render window
 * @returns {string} HTML code
 */
export default function html() {
  $("body").addClass("avoid-clicks");
  let result = templater.renderWindow(this);
  $("body").removeClass("avoid-clicks");
  return result;
}
