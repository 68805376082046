import Hook from "../hook";

/** Rent Order Item / Quickrent */
class TaskHook extends Hook {
  /**
   * Setup values
   * @param {Window} window - Window
   * @returns {Promise} Promise
   */
  async afterProcess(window) {
    if (window.output.Request.Subject !== "Core.vw_Tasks") return;

    let selectedTaskDescription =
      window.output.Request.Data.Filters.TaskDescription;
    let selection = 0;
    if (selectedTaskDescription === "IncomingItem") {
      selection = 1;
    } else if (selectedTaskDescription === "OutgoingItem") {
      selection = 2;
    }

    window.output.Data.Type = "horizontalagenda";
    window.filters.usedate = true;
    window.output.Filters = [];
    window.output.Filters.push({
      Location: 1,
      Ranking: 0,
      Target: "TaskDescription",
      Text: null,
      Type: "List",
      Selected: selection,
      Options: [
        {
          Key: window.session.translations.All,
          Value: null,
        },
      ],
    });
  }
}

/** @ignore */
export default new TaskHook();
