import {
  getUserInfo,
  createUserSetting,
  modifyUserSetting,
} from "@/services/user";

// initial state
// shape: [{ user, properties }]
const state = () => ({
  me: {},
  settings: {},
});

// getters
const getters = {};

// actions
const actions = {
  /**
   * Fetch user data and commit it to state
   *
   * @param {Object} commit Vuex commit object
   */
  async fetchUserData({commit}) {
    const result = await getUserInfo();
    commit("setUserData", result);
  },

  /**
   * Set User settings
   */
  setUserSetting({commit, state}, {key, value}) {
    if (state.settings?.hasOwnProperty(key)) {
      commit("modifyUserSetting", {key, value});
      return;
    }
    commit("createUserSetting", {key, value});
  },
};

// mutations
const mutations = {
  /**
   * Set user's state to the namespace
   *
   * @param {Object} state Vuex user state
   * @param {Object} user User object
   */
  setUserData(state, user) {
    state.me = user;
  },

  /**
   * Set user settings state to the namespace
   *
   * @param {Object} state Vuex user state
   * @param {Object} userSettings User properties object
   */
  setUserSettings(state, userSettings) {
    state.settings = userSettings;
  },

  /**
   * Create a new user setting
   *
   * @param {Object} state Vuex user state
   * @param {string} key Key of the property
   * @param {string} value Value of the property
   */
  createUserSetting(state, {key, value}) {
    state.settings[key] = {Value: value};
    createUserSetting({settingsCode: key, value});
  },

  /**
   * Modify an existing user setting
   *
   * @param {Object} state Vuex user state
   * @param {string} key Key of the property
   * @param {string} value Value of the property
   */
  modifyUserSetting(state, {key, value}) {
    state.settings[key] = {Value: value};
    modifyUserSetting({settingsCode: key, value});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
