import dayjs from "dayjs";

export const cellIsDirty = ({cell, newValue, originalValue}) => {
  if (cell.Column?.Type === "Date") {
    const parsedDate1 = dayjs(originalValue, ["DD-MM-YYYY", "YYYY-MM-DD"]);
    const parsedDate2 = dayjs(newValue, ["DD-MM-YYYY", "YYYY-MM-DD"]);
    return !parsedDate1.isSame(parsedDate2, "day");
  }

  return (
    originalValue !== newValue &&
    !(originalValue == null && newValue === false) &&
    !(originalValue === false && newValue == null)
  );
};
