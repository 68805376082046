
        
    const runtime = require("../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/content/table/branch.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["filter", "parent"], 
[], 
function (l_filter, l_parent, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("filter", l_filter);
frame.set("parent", l_parent);
var t_2 = "";t_2 += "\n  ";
frame = frame.push();
var t_5 = l_parent;
if(t_5) {t_5 = runtime.fromIterator(t_5);
var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("branch", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n    ";
if(runtime.memberLookup((t_6),"children")) {
t_2 += "\n    <div class='branch collapsed'>\n      <div class='branch-name'><span data-window-event=\"filterAction:";
t_2 += runtime.suppressValue(runtime.memberLookup((l_filter),"name"), env.opts.autoescape);
t_2 += ":";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"Value"), env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"Text"), env.opts.autoescape);
t_2 += "</span></div>\n      <div class=\"branches\">\n        ";
t_2 += runtime.suppressValue((lineno = 6, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "makeBranch"), "makeBranch", context, [l_filter,runtime.memberLookup((t_6),"children")])), env.opts.autoescape);
t_2 += "\n      </div>\n    </div>\n    ";
;
}
else {
t_2 += "\n    <div class=\"stick\" data-window-event=\"filterAction:";
t_2 += runtime.suppressValue(runtime.memberLookup((l_filter),"name"), env.opts.autoescape);
t_2 += ":";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"Value"), env.opts.autoescape);
t_2 += "\"><span>";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"Text"), env.opts.autoescape);
t_2 += "</span></div>\n    ";
;
}
t_2 += "\n  ";
;
}
}
frame = frame.pop();
t_2 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("render");
context.setVariable("render", macro_t_1);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/content/table/branch.html", ctx);
          }
        
          return nunjucks.render("views/content/table/branch.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/content/table/branch.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        