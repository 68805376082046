export const createOriginalFormRowOnWindow = ({window}) => {
  if (
    !window.output?.Table?.Rows[0] ||
    Object.keys(window.output?.Table?.Rows).length > 1
  )
    return;

  window.originalFormRow = window.output.Table.Rows[0].map((cell) => {
    return {
      Column: {
        Name: cell.Column.Name,
        Type: cell.Column.Type,
        Editor: cell.Column.Editor,
      },
      IsDirty: cell.IsDirty,
      Value: cell.Value,
    };
  });
};
