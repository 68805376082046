export const processGlobalActions = async ({globalActions}) => {
  if (!globalActions) {
    return;
  }

  const windowId = this?.id;

  for (const action of globalActions) {
    const importedModule = await import(`@/actions/${action.Function}.js`);
    await importedModule.default(action.Data, windowId);
  }
};
