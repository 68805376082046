import store from "../../state/store";

export default function (title = "") {
  const href = window.location.href;
  let environment = "";

  if (href.includes("test")) {
    environment = "[TEST] ";
  }

  if (href.includes("localhost")) {
    environment = "[DEVELOPMENT] ";
  }

  document.title = `${environment}${store.state.activeWarehouse} ${title} | RentMagic`;
}
