<template>
  <div>
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="check-circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      class="svg-inline--fa fa-check-circle text-green-500 bg-white"
      :class="{
        '!text-gray-300': !isAvailable,
        'w-8 h-7 text-green-500': !small,
        'w-6 h-5 ml-1.5 mt-1.5': small,
      }"
    >
      <path
        fill="currentColor"
        d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "FormSidebarRailroadItemIconSuccess",
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped></style>
