<template>
  <div class="w-20 h-20 flex items-center justify-center">
    <i :class="iconClasses"></i>
  </div>
</template>

<script>
export default {
  name: "RIcon",
  props: {
    icon: {
      type: String,
      default: "far fa-check-circle",
    },
    additionalClasses: {
      type: String,
      default: "text-5xl", // This makes the icon about 48px, adjust as needed
    },
  },
  computed: {
    iconClasses() {
      return `${this.icon} ${this.additionalClasses}`.trim();
    },
  },
};
</script>
