import {stringContainsValidAutocompleteSyntax} from "./stringContainsValidAutocompleteSyntax";
import {processAutocompleteSyntax} from "./processAutocompleteSyntax";

export function convertPlaceholderToDate({value}) {
  const newValue = value;

  if (stringContainsValidAutocompleteSyntax({value: newValue})) {
    return processAutocompleteSyntax({value: newValue});
  }

  return newValue;
}
