import {library, dom} from "@fortawesome/fontawesome-svg-core";

import {faYoutube} from "@fortawesome/free-brands-svg-icons/faYoutube";
import {faSearch} from "@fortawesome/pro-duotone-svg-icons/faSearch";
import {faWarehouse} from "@fortawesome/pro-duotone-svg-icons/faWarehouse";
import {faExpandArrows} from "@fortawesome/pro-duotone-svg-icons/faExpandArrows";
import {faCompressArrowsAlt} from "@fortawesome/pro-duotone-svg-icons/faCompressArrowsAlt";
import {faInfoSquare} from "@fortawesome/pro-light-svg-icons/faInfoSquare";
import {faSlidersVSquare} from "@fortawesome/pro-light-svg-icons/faSlidersVSquare";
import {faHandshake} from "@fortawesome/pro-light-svg-icons/faHandshake";
import {faForklift} from "@fortawesome/pro-light-svg-icons/faForklift";
import {faBox} from "@fortawesome/pro-light-svg-icons/faBox";
import {faCogs} from "@fortawesome/pro-light-svg-icons/faCogs";
import {faFileChartPie} from "@fortawesome/pro-light-svg-icons/faFileChartPie";
import {faGoogle} from "@fortawesome/free-brands-svg-icons/faGoogle";
import {faBusinessTime} from "@fortawesome/pro-duotone-svg-icons/faBusinessTime";

library.add(
  faYoutube,
  faSearch,
  faBusinessTime,
  faWarehouse,
  faExpandArrows,
  faCompressArrowsAlt,
  faInfoSquare,
  faSlidersVSquare,
  faHandshake,
  faForklift,
  faBox,
  faCogs,
  faFileChartPie,
  faGoogle,
);

dom.watch();
