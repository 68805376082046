import dayjs from "dayjs";

export const appendYearWhenNotPresent = (value) => {
  let newValue = value;
  // if newValue contains 5 characters, add a year
  if (newValue.length === 5) {
    newValue = newValue + "-" + dayjs().format("YYYY");
  }
  return newValue;
};
