var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative mb-3"},[_c('div',{staticClass:"flex-col w-full relative"},[_c('div',{staticClass:"flex w-full flex-row"},[_c('div',{staticClass:"relative"},[(_vm.railroadItem.Blocking)?_c('div',{staticClass:"absolute rounded-full border-2 border-primary",class:{
            'w-8 h-8 -top-1 -left-1': !_vm.isSubTask,
            'w-7 h-7 -top-1 -left-0.5': _vm.isSubTask,
          }}):_vm._e(),_c('div',{staticClass:"pr-2 flex flex-column align-items-center",class:{
            'pl-0.5': !_vm.isSubTask,
            'mt-4': _vm.isAvailable && !_vm.isSubTask,
            'mt-1.5': !_vm.isAvailable && !_vm.isSubTask,
            'bg-gray-30 h-1 w-10': _vm.isSubTask,
          }},[(_vm.railroadItem.Completed)?_c('form-sidebar-railroad-item-icon-success',{attrs:{"small":_vm.railroadItem.Sub,"read-only":_vm.railroadItem.Available,"is-available":_vm.isAvailable}}):(_vm.railroadItem.Cancelled || !_vm.railroadItem.Available)?_c('form-sidebar-railroad-item-icon-cancelled',{attrs:{"small":_vm.railroadItem.Sub,"read-only":_vm.railroadItem.Available,"is-available":_vm.isAvailable}}):(_vm.railroadItem.InProgress)?_c('form-sidebar-railroad-item-icon-in-progress',{attrs:{"small":_vm.railroadItem.Sub,"read-only":_vm.railroadItem.Available,"is-available":_vm.isAvailable}}):_c('form-sidebar-railroad-item-icon-todo',{attrs:{"small":_vm.railroadItem.Sub,"read-only":_vm.railroadItem.Available,"is-available":_vm.isAvailable}})],1)]),_c('div',{staticClass:"group w-full flex flex-column"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{class:{
              'pointer-events-none': _vm.$store.state.headless,
              'w-full': true,
            }},[_c('div',{},[(_vm.railroadItem.Resources.length && _vm.railroadItem.Sub)?_c('div',{staticClass:"flex justify-start"},[_c('form-sidebar-railroad-item-resources',{attrs:{"resources":_vm.railroadItem.Resources,"is-sub-task":_vm.isSubTask}})],1):_vm._e()]),_c('div',{staticClass:"flex justify-between hover:cursor-pointer"},[(!_vm.railroadItem.Sub)?_c('div',{staticClass:"font-bold w-full group-hover:text-primary'",on:{"click":function($event){return _vm.processEvent(
                    _vm.railroadItem.Action,
                    _vm.railroadItem.LegacyAction,
                    !_vm.railroadItem.Sub,
                  )}}},[_c('div',{staticClass:"flex justify-between"},[_c('div',[_vm._v(" "+_vm._s(_vm.railroadItem.Title)+" "),(_vm.railroadItem.IsQueued)?_c('div',{staticClass:"inline-block ml-[1px]"},[_c('tooltip',{attrs:{"message":_vm.translations.TaskIsQueued,"tooltip-position":"top-end"}},[_c('i',{staticClass:"fad fa-business-time"})])],1):_vm._e(),(_vm.railroadItem.Blocking)?_c('span',{staticClass:"text-primary"},[_vm._v("*")]):_vm._e()]),(_vm.railroadItem.NumberOfTasks > 1)?_c('span',{staticClass:"bg-gray-400 text-white rounded-full mx-1 text-xs w-5 h-5 flex items-center justify-center"},[_vm._v(" "+_vm._s(_vm.railroadItem.NumberOfTasks)+" ")]):_vm._e()])]):_vm._e()]),(!_vm.railroadItem.Sub)?_c('small',{staticClass:"hover:cursor-pointer text-muted",class:{
                'font-light': _vm.railroadItem.Sub,
              },on:{"click":function($event){return _vm.processEvent(
                  _vm.railroadItem.Action,
                  _vm.railroadItem.LegacyAction,
                  !_vm.railroadItem.Sub,
                )}}},[_vm._v(_vm._s(_vm.railroadItem.Description)+" ")]):_c('rail-road-item-sub-task-description',{attrs:{"railroad-item":_vm.railroadItem}})],1),(_vm.railroadItem.Resources.length && !_vm.railroadItem.Sub)?_c('div',{staticClass:"mt-1"},[_c('form-sidebar-railroad-item-resources',{attrs:{"resources":_vm.railroadItem.Resources,"is-sub-task":_vm.isSubTask}})],1):_vm._e()]),(_vm.isAvailable)?_c('div',{staticClass:"flex justify-between w-full"},[(_vm.railroadItem.AllowNewTasks)?_c('div',{staticClass:"text-primary cursor-copy mt-2",on:{"click":function($event){return _vm.openAddTaskDialog({parentTaskId: _vm.railroadItem.TaskID || null})}}},[_c('i',{staticClass:"fas fa-plus-square"})]):_vm._e(),(!_vm.railroadItem.Sub && _vm.railroadItem.Enabled)?_c('div',{staticClass:"text-primary cursor-alias mt-[0.5px] text-[18px] lg:mr-1",on:{"click":function($event){return _vm.processEvent(
                _vm.railroadItem.Action,
                _vm.railroadItem.LegacyAction,
                false,
              )}}},[_c('i',{staticClass:"fas fa-external-link-alt"})]):_vm._e()]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }