import {prompt} from "@/functions/prompt";

export async function handleSettingsSave(activeWindow, {success}) {
  if (success === false) {
    return;
  }
  const reloadBrowser = await prompt({
    text: global.session.translations[
      "Core.Settings-prompt-browser-reload-text"
    ],
    confirmText:
      global.session.translations[
        "Core.Settings-prompt-browser-reload-confirm"
      ],
    cancelText:
      global.session.translations["Core.Settings-prompt-browser-reload-cancel"],
  });

  if (reloadBrowser) {
    // Ignore the activeWindow as we need the native document window for this action.
    window.location.reload();
  }
}
