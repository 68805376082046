import { render, staticRenderFns } from "./FormSidebarRailroadItemIconSuccess.vue?vue&type=template&id=64e75cbd&scoped=true&"
import script from "./FormSidebarRailroadItemIconSuccess.vue?vue&type=script&lang=js&"
export * from "./FormSidebarRailroadItemIconSuccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e75cbd",
  null
  
)

export default component.exports