import serializeForm from "@/util/serializeForm";
import $ from "jquery";

/**
 * Save permissions
 * @param {string} tableName - Table name
 * @param {*} roleID - Role id
 * @returns {Promise} Promise
 */
export default async function savePermissions(tableName, roleID) {
  if (!roleID) {
    roleID =
      global.session.activeWindow.sub.window.output.Request.Criteria[0].RoleId;
  }
  let permissionInfo = {
    tableName: tableName,
    roleID: roleID,
  };

  ["FormPermissions", "Actions", "Columns", "Tabs"].forEach((i) => {
    let values = serializeForm(
      $(this.element)
        .find("[data-form-id=" + i + "]")
        .get(0),
    );

    let formattedNeed = false;
    let formatted = {};

    for (let j in values) {
      let bracketText = (j.match(/\[([^)]+)\]/) || [null, null])[1] || null;

      // let val = values[i] === "on"
      let val = values[j];
      if (bracketText) {
        formattedNeed = true;
        let colName = j.substr(0, j.length - bracketText.length - 2);
        formatted[colName] = formatted[colName] || {};
        formatted[colName][bracketText] = val;
      } else {
        values[j] = val;
      }
    }

    permissionInfo[i] = formattedNeed ? formatted : values || {};
  });

  let url =
    "/Admin/WebServices/MembershipWebServices.asmx/SetRolePermissionData";
  let output = await this.session.request(url, {
    permissionInfo,
  });

  this.handleActionResponse(output);
}
