function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function setActiveTab(ActionName, delayed) {
  if (delayed) {
    await sleep(500);
  }

  $(document).find(".active-window").find(".tab").removeClass("selected");
  $(document)
    .find(".active-window")
    .find(`.tab[action='${ActionName}']`)
    .addClass("selected");
}

export default function ({ActionName}) {
  setActiveTab(ActionName);
  setActiveTab(ActionName, true);
}
