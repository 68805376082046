import $ from "jquery";

/**
 * Tree view component logic
 */
export default class TreeViewComponent {
  /**
   * Initialise treeviews
   * @param {document} doc - page document
   * @returns {void}
   */
  static init(doc) {
    // The stopImediatePropogation here allows the user to navigate through and use the treeview
    // while still being able to click on branches
    $(doc).on("click", ".tree-view .branch-name", function toggleTreeView(e) {
      // prevent even when clicking on branch name
      e.stopImmediatePropagation();

      // don't collapse the div when it's disabled
      if (!$(this).parent().hasClass("disabled")) {
        $(this).parent().toggleClass("collapsed");
      }
    });

    $(doc).on(
      "click",
      ".tree-view .branch-name checkbox input",
      function toggleTreeView(e) {
        // prevent click even on branch name
        e.stopImmediatePropagation();
      },
    );
  }
}
