import $ from "jquery";

/**
 * Open from tree
 * @param {*} id - Tree id
 * @returns {Promise} Promise
 */
export default async function opentree(id) {
  if (!id) {
    return;
  }
  // find selected element and set 'selected'
  $(this.element).find("[data-window-tree-id]").removeClass("selected");
  $(this.element)
    .find("[data-window-tree-id='" + id + "']")
    .addClass("selected");

  // Criteria is TableName + ID (f.e. CategoryID)
  let criteria = this.output.Request.Subject.split(".")[1] + "ID";
  let input = {
    Subject: this.output.Request.Subject,
    Prefix: "Single",
    Criteria: [{[criteria]: id}],
  };

  // reload sub
  if (this.sub !== null) {
    this.sub.window.empty = false;
    this.sub.window.input = input;
    this.sub.window.reload();
    return;
  }
}
