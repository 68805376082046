import $ from "jquery";
import shortid from "shortid";

/**
 * Download file from given url using some hacky methods
 * @param {string} url - Download URL
 * @param {Object} data - Download variables
 * @returns {Promise} Promise to download file
 */
export default async function download(url, data, method) {
  // Pretty much: create frame, set url to target url
  // submit form. May be improved in the future,
  // But works, so yeah
  if (!method) {
    method = "post";
  }

  let id = shortid.generate();
  let $downloadFrame = $("iframe[name=" + id + "]");

  if (!$downloadFrame.length) {
    $downloadFrame = $("<iframe>");
    // $downloadFrame.prop("src", "/")
    $downloadFrame.prop("name", id);
    $downloadFrame.hide();
    $("body").append($downloadFrame);
  }

  let $downloadForm = $("<form>");

  $downloadForm.prop("method", method);
  $downloadForm.prop("action", url);
  $downloadForm.prop("target", id);

  for (let key in data) {
    let obj = data[key];
    let serialized =
      typeof obj === "object" ? JSON.stringify(obj) : String(obj);

    let $input = $("<input>");
    $input.prop("type", "hidden");
    $input.prop("name", key);
    $input.val(serialized);

    $downloadForm.append($input);
  }

  let $submitButton = $('<input type="submit">');
  $submitButton.hide();
  $downloadForm.append($submitButton);

  $(document.body).append($downloadForm);
  $downloadForm.get(0).submit();

  await new Promise((resolve, reject) => {
    $downloadFrame.one("load", () => {
      let content = $downloadFrame.contents().find("body").text();
      let err = new Error(content);

      if (err) {
        reject(err);
      }
    });

    setTimeout(resolve, 2500);
  });
}
