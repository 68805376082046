import $ from "jquery";

/**
 * Set page size
 * @param {number} size - page size
 * @returns {Promise} Promise
 */
export default async function pagesize(size) {
  // get current values
  // let currentSize = this.input.Data.PageSize
  // let currentPage = this.input.Data.PageNumber

  // calculate offset and new page size
  //let rowOffset = currentSize * currentPage
  //let newPage = Math.floor(rowOffset / size)
  const $pageSizeSelector = $(this.element).find(".number-of");

  $pageSizeSelector.prop("disabled", true);
  // set new values
  this.input.Data.PageSize = Number(size);
  this.input.Data.PageNumber = 1; //Math.max(newPage, 1)

  // get new values and re-render
  await this.fetch();
  $pageSizeSelector.prop("disabled", false);
}
