import {api} from "../api.js";
import store from "../../../state/store";

export default async function getRailroadGuides({subject, criteria}) {
  const result = await api.post(
    `/v3/railroad/${subject}`,
    {...criteria},
    {
      windowId: global.session.activeWindow.id,
      params: {
        Warehouse: store.state.activeWarehouse,
      },
    },
  );

  return result.data;
}
