import $ from "jquery";

/**
 * Action to upload file
 * @param {Array} droppedFiles - Array of files
 * @returns {Promise} Promise
 */
export default async function actionUploadFile(droppedFiles, primaryKey) {
  let files;
  if (droppedFiles) {
    files = droppedFiles;
  } else {
    files = $(this.element).find("[name='FileUpload']").prop("files");
  }

  if (!files || !files.length) {
    return;
  }

  let data = new FormData();

  for (let f of files) {
    data.append(f.name, f);
  }

  let requestUrl = this.output.Options.UploadUrl;

  let result;

  const fileUpload = $(this.element).find("[name='FileUpload']");

  // remove file from fileUpload
  fileUpload.val("");

  this.toggleLoading(true);
  try {
    if (primaryKey) {
      result = await this.session.request(requestUrl, data, primaryKey);
    } else {
      result = await this.session.request(requestUrl, data, {});
    }

    this.handleActionResponse(result);
  } finally {
    this.toggleLoading(false);
  }
}
