import Hook from "../hook";
import {disableComboboxFromVisibleInput} from "@/util/form/disableComboboxFromVisibileInput";

class JournalTypeFormHook extends Hook {
  async beforeRender(window) {
    if (window.output?.Request?.Subject === "Rental.JournalType") {
      const fields = [
        '[name="PeriodAmount"]',
        '[name="StartPeriodOffset"]',
        '[name="DayOfDepreciation"]',
      ];
      $(window.element).on("change", '[name="Type"]', function () {
        for (const field of fields) {
          const jqueryField = $(window.element).find(field);
          jqueryField.prop("disabled", this.value === "Manually");
          jqueryField.val(null);
        }

        disableComboboxFromVisibleInput(
          $(window.element).find('[field-name="DayOfDepreciation-combo"]'),
          this.value === "Manually",
        );
      });
    }
  }
}
export default new JournalTypeFormHook();
